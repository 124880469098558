import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormElementCoreComponent } from '../../../../shared/components/form-element-core/form-element-core.component';
import { SubmitOptions } from '../../../../tpi-models/general/form-models';
import { UtilsService } from '../../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'tpi-manage-user-form',
    templateUrl: './manage-user-form.component.html'
})
export class ManageUserFormComponent extends FormElementCoreComponent {
    @Input() parentForm: FormGroup;
    @Output() resetPasswordEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() showForm: boolean;
    @Input() submitOptions: SubmitOptions;
    @Output() unlockUserEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public utilsService: UtilsService) {
        super();
    }

    resetPassword(): void {
        this.resetPasswordEmit.emit(true);
    }

    unlock(): void {
        this.unlockUserEmit.emit(true);
    }
}
