import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormElementCoreComponent } from '../../../shared/components/form-element-core/form-element-core.component';
import { SubmitOptions } from '../../../tpi-models/general/form-models';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'tpi-property-search',
    templateUrl: './property-search.component.html'
})
export class PropertySearchComponent extends FormElementCoreComponent {
    @Input() parentFormGroup: FormGroup;
    @Input() submitOptions: SubmitOptions;

    constructor(public utilsService: UtilsService) {
        super();
    }
}
