import { Injectable } from '@angular/core';
import { PROPERTY_CONSTANTS } from '@nationwide/dgs-internet-servicing-policy-requests';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { tpiErrorTags } from '../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../CONSTANTS/tpi-search-errors';
export interface PolicyResponseMap {
    success: any;
    error: any;
}

@Injectable({
    providedIn: 'root'
})
export class PolicySearchAdapterService {
    getAndMapPropertyInformation(propertyInformation: any, zipCode: string): Readonly<PolicyResponseMap> {
        let mappingResponse: PolicyResponseMap = null;
        const allowedProperty = [commonConstants.PROPERTY.PRODUCT_TYPE_CONDO,
        commonConstants.PROPERTY.PRODUCT_TYPE_DWELLINGFIRE,
        commonConstants.PROPERTY.PRODUCT_TYPE_HOMEOWNER,
        commonConstants.PROPERTY.PRODUCT_TYPE_TENANT,
            'Auto'
        ];
        if (propertyInformation && propertyInformation.productType === commonConstants.PAGES.AUTO) {
            mappingResponse = this.mapErrorResponse(tpiErrorTags.POLICY_NOT_PROPERTY);
        } else if (!propertyInformation ||
            zipCode !== this.getZipCode(propertyInformation) ||
            propertyInformation.lineOfBusiness !== commonConstants.PAGES.PROPERTY) {
            mappingResponse = this.mapErrorResponse(tpiErrorTags.NULL_RESPONSE);
        } else if (propertyInformation.productType === commonConstants.PROPERTY.PRODUCT_TYPE_TENANT) {
            mappingResponse = this.mapErrorResponse(tpiErrorTags.TENANT_POLICY);
        } else if (allowedProperty.includes(propertyInformation.productType) === false) {
            mappingResponse = this.mapErrorResponse(tpiErrorTags.OTHER_PROPERTY_POLICY);
        } else {
            mappingResponse = this.mapSuccessResponse({
                product: propertyInformation.lineOfBusiness,
                productType: this.mapProductType(propertyInformation.productType),
                response: propertyInformation,
                zipCode,
                policyNumber: propertyInformation.agreementReference.referenceNumber,
                billingAccountNumber: propertyInformation.agreementReference.convertedReferenceNumber,
                ecn: propertyInformation.agreementRoles[0].customerInRole.enterpriseCustomerNumber
            });
        }
        return mappingResponse;
    }

    getZipCode(propertyInformation: any): string {
        const owner = propertyInformation?.agreementRoles?.filter((agreementRole) => agreementRole.roleType === 'Owner');
        const contactPoints = owner?.[0]?.agreementRoleContactPoints?.[0];
        return contactPoints?.contactPoints?.postalAddress?.postalCode || undefined;
    }

    mapErrorResponse(response: any): Readonly<PolicyResponseMap> {
        const errorResponse: PolicyResponseMap = {
            success: null,
            error: null
        };

        const errorResponseConstants = {
            [tpiErrorTags.NULL_RESPONSE]: tpiSearchError.POLICY_NOT_FOUND_ERROR,
            [tpiErrorTags.POLICY_NOT_PROPERTY]: tpiSearchError.POLICY_NOT_PROPERTY_ERROR,
            [tpiErrorTags.INVALID_ZIPCODE]: tpiSearchError.INVALID_ZIP_CODE_ERROR,
            [tpiErrorTags.POLICY_INELIGIBLE]: tpiSearchError.POLICY_INELIGIBLE_ERROR,
            [tpiErrorTags.POLICY_INFO_NOT_AVAILABLE_ONLINE]: tpiSearchError.POLICY_INFO_NOT_AVAILABLE_ONLINE_ERROR,
            [tpiErrorTags.UNKNOWN_ERROR]: tpiSearchError.UNKNOWN_APPLICATION_ERROR,
            [tpiErrorTags.TENANT_POLICY]: tpiSearchError.TENANT_POLICY_ERROR,
            [tpiErrorTags.OTHER_PROPERTY_POLICY]: tpiSearchError.OTHER_PROPERTY_POLICY_ERROR
        };

        errorResponse.error = errorResponseConstants[response] || tpiSearchError.POLICY_NOT_FOUND_ERROR;

        return errorResponse;
    }

    mapProductType(productResponse: string): string {
        let productType = '';
        switch (productResponse) {
            case commonConstants.PROPERTY.PRODUCT_TYPE_HOMEOWNER:
                productType = PROPERTY_CONSTANTS.HOME_OWNER;
                break;
            case commonConstants.PROPERTY.PRODUCT_TYPE_CONDO:
                productType = PROPERTY_CONSTANTS.CONDO;
                break;
            case commonConstants.PROPERTY.PRODUCT_TYPE_DWELLINGFIRE:
                productType = PROPERTY_CONSTANTS.DWELLING_FIRE;
                break;
        }
        return productType;
    }

    mapSuccessResponse(response: any): Readonly<PolicyResponseMap> {
        return {
            success: response,
            error: null
        };
    }
}
