import { StoreSearchFields } from '../tpi-models/store/storesearchfields.model';
import { commonConstants } from './../CONSTANTS/tpi-common-constants';

export function updateSearchFieldsReducer(state: StoreSearchFields, action): StoreSearchFields {
    if (action.type === commonConstants.STORE.MODIFY_SEARCHFIELDS) {
        return action.payload;
    } else {
        return state;
    }
}
