import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { SharedServicesModule } from '../../shared/services/shared-services.module';
import { AddModifyCompanyCoreComponent } from './manage-company-list/add-modify-company-core/add-modify-company-core.component';
import { AddNewCompanyComponent } from './manage-company-list/add-new-company/add-new-company.component';
import { CompanyInfoFormComponent } from './manage-company-list/company-info-form/company-info-form.component';
import { DeleteCompanyComponent } from './manage-company-list/delete-company/delete-company.component';
import { EditLienFormComponent } from './manage-company-list/edit-lien-form/edit-lien-form.component';
import { ManageCompanyListComponent } from './manage-company-list/manage-company-list.component';
import { ModifyCompanyComponent } from './manage-company-list/modify-company/modify-company.component';
import { FilterByNameOrEmailComponent } from './manage-user-accounts/filter-by-name-or-email/filter-by-name-or-email.component';
import { FilterByRoleComponent } from './manage-user-accounts/filter-by-role/filter-by-role.component';
import { ManageUserAccountsTableComponent } from './manage-user-accounts/manage-user-accounts-table/manage-user-accounts-table.component';
import { ManageUserAccountsComponent } from './manage-user-accounts/manage-user-accounts.component';
import { AddNewUserComponent } from './manage-user-accounts/modify-users/add-new-user/add-new-user.component';
import { DeleteResetUnlockUserComponent } from './manage-user-accounts/modify-users/delete-reset-unlock-user/delete-reset-unlock-user.component';
import { EditUserComponent } from './manage-user-accounts/modify-users/edit-user/edit-user.component';
import { ManageUserFormComponent } from './manage-user-web/manage-user-form/manage-user-form.component';
import { ManageUserWebComponent } from './manage-user-web/manage-user-web.component';
import { UserPanelComponent } from './manage-user-web/user-panel/user-panel.component';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { FilterComponentComponent } from './shared/filter-component/filter-component.component';
import { PaginationSettings } from './shared/pagination-settings/pagination-settings.service';
import { SearchUserFormComponent } from './shared/search-user-form/search-user-form.component';
import { SuccessModalComponent } from './shared/success-modal/success-modal.component';
import { UserInfoFormComponent } from './shared/user-info-form/user-info-form.component';
import { TermsAndConditionsTextComponent } from './terms-and-conditions/terms-and-conditions-text/terms-and-conditions-text.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    imports: [
        CommonModule,
        BrowserModule,
        SharedComponentsModule,
        SharedServicesModule,
        DirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        MatTableModule
    ],
    exports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatPaginatorModule,
        MatTableModule
    ],
    declarations: [
        ManageUserAccountsComponent,
        ManageCompanyListComponent,
        ManageUserWebComponent,
        ManageUserAccountsTableComponent,
        FilterByNameOrEmailComponent,
        FilterByRoleComponent,
        CompanyInfoFormComponent,
        EditLienFormComponent,
        SearchUserFormComponent,
        ManageUserFormComponent,
        AddNewUserComponent,
        EditUserComponent,
        UserInfoFormComponent,
        SuccessModalComponent,
        DeleteResetUnlockUserComponent,
        FilterComponentComponent,
        AddNewCompanyComponent,
        DeleteCompanyComponent,
        ModifyCompanyComponent,
        AddModifyCompanyCoreComponent,
        ConfirmationModalComponent,
        UserPanelComponent,
        TermsAndConditionsComponent,
        TermsAndConditionsTextComponent
    ],
    providers: [{ provide: 'window', useValue: window },
    { provide: MatPaginatorIntl, useClass: PaginationSettings }],
    bootstrap: []
})
export class ManageAccountsCompanyWebModule { }
