<ng-container [formGroup]='parentForm' *ngIf="parentForm && roleUserCounts">
    <div class="bolt-row">
        <div class="bolt-col role-container-superuser">
            <bolt-radio-group label="{{label}}" style="margin: 0;" name="'filterByRole'" formControlName="filterByRole" ngDefaultControl optionaltext="hide" (change)="emitFilter($event)">
                <bolt-contextual-help slot="help" type="push">
                    <strong>Find it fast</strong>
                    <br>
                    Use the radio buttons to narrow your search. You can only edit one user at a time. But you may
                    select as many as apply for unlock user, delete user, or reset password.
                </bolt-contextual-help>
                <bolt-radio [value]="allRoleOption.id">
                    <div>{{allRoleOption.roleDescription}}</div>
                </bolt-radio>
                <bolt-radio *ngFor="let userRole of roleUserCounts" [value]="userRole.roleId">
                    <div>{{userRole.role}} ({{userRole.totalUsers || '0'}})</div>
                </bolt-radio>
            </bolt-radio-group>
        </div>
    </div>
</ng-container>