import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { CHANGE_PASSWORD_RETURN_CODE } from '../../../CONSTANTS/change-password-return-codes';
import { FORM_STATUS } from '../../../CONSTANTS/tpi-common-constants';
import { ERROR_MAP_COPYTEXTS } from '../../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { tpiSearchSuccess } from '../../../CONSTANTS/tpi-search-success';
import { WaitIndicatorComponent } from '../../../shared/components/wait-indicator/wait-indicator.component';
import { ChangePasswordRequest } from '../../../tpi-models/user-credentials/change-password-request';
import { ChangePasswordResponse } from '../../../tpi-models/user-credentials/change-password-response';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { PasswordService } from '../../../tpi-services/user-credential-services/password.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
import { PasswordFormComponent } from '../shared/password-form/password-form.component';

@Component({
    selector: 'tpi-change-password',
    templateUrl: './change-password.component.html'
})

export class ChangePasswordComponent implements AfterViewInit, OnInit {
    @ViewChild(PasswordFormComponent) changePasswordFormComponent: PasswordFormComponent;
    @ViewChild(WaitIndicatorComponent) waitIndicator;

    changePasswordForm: FormGroup;
    errorMessage: string;
    hasDMXError = false;
    hasError: boolean;
    showSuccessMessage: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };
    successMessage: string;

    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        private passwordService: PasswordService,
        private loggerService: LoggerService,
        private route: Router,
        public formHelper: RxFormHelperService,
        public utilsService: UtilsService
    ) { }

    buildForm(): void {
        this.changePasswordForm = this.formHelper.build({
            currentPassword: [
                ''
            ],
            newPassword: [
                ''
            ],
            confirmNewPassword: [
                ''
            ]
        });

        this.resetFormValues();
    }

    decipherAndDisplayPasswordChangeError(changePasswordErrorResponse: ChangePasswordResponse): void {
        this.loggerService.info('decipherAndDisplayPasswordChangeError(): change password error ', changePasswordErrorResponse);
        const returnCode = changePasswordErrorResponse?.operationResponse?.returnCode;
        let errorMessage;
        if ([CHANGE_PASSWORD_RETURN_CODE.ACCOUNT_NOT_FOUND, CHANGE_PASSWORD_RETURN_CODE.INVALID_USERNAME_PASSWORD].includes(returnCode)) {
            this.changePasswordForm.get('currentPassword').setErrors({ passwordCurrentPasswordFieldError: true });
        } else if ([CHANGE_PASSWORD_RETURN_CODE.SECURITY_REQUIREMENTS].includes(returnCode)) {
            errorMessage = tpiSearchError.PASSWORD_WEAK_ERROR;
            this.setRepeatCharacterFormControlFieldError();
        } else if ([CHANGE_PASSWORD_RETURN_CODE.EXISTING_PASSWORD, CHANGE_PASSWORD_RETURN_CODE.PASSWORD_REUSED].includes(returnCode)) {
            errorMessage = tpiSearchError.PASSWORD_MATCH_PREVIOUS_ERROR;
            this.setPreviousUsedPasswordFormControlFieldError();
        } else {
            errorMessage = tpiSearchError.UNABLE_CHANGE_PASSWORD_ERROR;
        }
        let logErrorMessage = errorMessage;
        if (errorMessage) {
            this.showResultsError(errorMessage);
        } else {
            logErrorMessage = ERROR_MAP_COPYTEXTS.passwordCurrentPasswordFieldError;
        }
        this.loggerService.error(`decipherAndDisplayPasswordChangeError(): change password error; message: ${logErrorMessage}`, { errorMessage: logErrorMessage });
    }

    getFormFieldError(formController: any): string {
        let fieldMessage;
        if (formController.errors.required) {
            fieldMessage = ERROR_MAP_COPYTEXTS.required;
        } else if (formController.errors.minLengthForPassword) {
            fieldMessage = ERROR_MAP_COPYTEXTS.minLengthForPassword;
        } else if (formController.errors.passwordFormatFieldError) {
            fieldMessage = ERROR_MAP_COPYTEXTS.passwordFormatFieldError;
        } else if (formController.errors.passwordUserNameFieldError) {
            fieldMessage = ERROR_MAP_COPYTEXTS.passwordUserNameFieldError;
        }
        return fieldMessage;
    }

    logFormFieldErrors(): void {
        const currentPasswordController = this.changePasswordForm.get('currentPassword');
        const newPasswordController = this.changePasswordForm.get('newPassword');
        const confirmPasswordController = this.changePasswordForm.get('confirmNewPassword');

        if (currentPasswordController.invalid) {
            let fieldMessage = this.getFormFieldError(currentPasswordController);
            if (fieldMessage) {
                fieldMessage = fieldMessage.replace('@@fieldname@@', 'Current Password');
                this.loggerService.info(`logFormFieldErrors(): change-password form field validation error; current-password; message: ${fieldMessage}`, { errorMessage: fieldMessage, formType: 'current password' });
            }
        }

        if (newPasswordController.invalid) {
            let fieldMessage = this.getFormFieldError(newPasswordController);
            if (fieldMessage) {
                fieldMessage = fieldMessage.replace('@@fieldname@@', 'New Password');
                this.loggerService.info(`logFormFieldErrors(): change-password form field validation error; new-password; message: ${fieldMessage}`, { errorMessage: fieldMessage, formType: 'new password' });
            }
        }

        if (confirmPasswordController.invalid) {
            let fieldMessage = this.getFormFieldError(confirmPasswordController);
            if (fieldMessage) {
                fieldMessage = fieldMessage.replace('@@fieldname@@', 'Confirm New Password');
                this.loggerService.info(`logFormFieldErrors(): change-password form field validation error; confirm-password; message: ${fieldMessage}`, { errorMessage: fieldMessage, formType: 'confirm password' });
            }
        }

        this.loggerService.logCurrentContents();
    }

    ngAfterViewInit(): void {
        this.changePasswordFormComponent.setPasswordValidation(sessionStorage.getItem('userName'));
    }

    ngOnInit(): void {
        this.setVariables();
        this.buildForm();
    }

    performValidation($event?): void {
        if ($event) {
            this.submitOptions = {
                submitStatus: true,
                onSubmit: true
            };

            this.showSuccessMessage = false;
            this.hasError = false;

            this.showResults();
        }
    }

    redirectToLegacy($event): void {
        if ($event) {
            this.sessionService.redirectToLegacy();
        }
    }

    resetForm($event?): void {
        if ($event) {
            this.changePasswordForm.updateValueAndValidity();
            this.resetFormValues();
            this.submitOptions.submitStatus = false;
            this.hasError = false;
            this.showSuccessMessage = false;
            this.changePasswordFormComponent.resetValidation();
        }
    }

    resetFormControllerError(): void {
        this.changePasswordForm.updateValueAndValidity();
        this.changePasswordForm.get('currentPassword').updateValueAndValidity();
        this.changePasswordForm.get('newPassword').updateValueAndValidity();
        this.changePasswordForm.get('confirmNewPassword').updateValueAndValidity();
    }

    resetFormValues(): void {
        this.changePasswordForm.get('currentPassword').setValue('');
        this.changePasswordForm.get('newPassword').setValue('');
        this.changePasswordForm.get('confirmNewPassword').setValue('');
        this.hasError = false;
        this.showSuccessMessage = false;
    }

    setPreviousUsedPasswordFormControlFieldError(): void {
        const newPasswordFormController = this.changePasswordForm.get('newPassword');
        const confirmNewPasswordFormController = this.changePasswordForm.get('confirmNewPassword');
        newPasswordFormController.setErrors({ passwordPreviousUsedPasswordFieldError: true });
        confirmNewPasswordFormController.setErrors({ passwordPreviousUsedPasswordFieldError: true });
    }

    setRepeatCharacterFormControlFieldError(): void {
        const newPasswordFormController = this.changePasswordForm.get('newPassword');
        const confirmNewPasswordFormController = this.changePasswordForm.get('confirmNewPassword');
        newPasswordFormController.setErrors({ passwordWeakFieldError: true });
        confirmNewPasswordFormController.setErrors({ passwordWeakFieldError: true });
    }

    setVariables(): void {
        this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
    }

    showResults(): void {
        this.resetFormControllerError();
        if (!this.changePasswordFormComponent.newPasswordsMatch()) {
            const passwordMatchError = tpiSearchError.NEW_PASSWORD_NOT_MATCH_ERROR;
            this.loggerService.info(`showResults(): new and confirm passwords mismatch; message: ${passwordMatchError}`, { errorMessage: passwordMatchError });
            this.showResultsError(passwordMatchError);
            this.loggerService.logCurrentContents();
        } else if (this.changePasswordForm.status === FORM_STATUS.VALID) {
            this.waitIndicator.openWaitIndicator();
            const userAccount = this.sessionService?.getSession()?.context?.session?.account;
            const changePasswordRequest: ChangePasswordRequest = {
                username: userAccount.shortName,
                firstName: userAccount.firstName,
                email: userAccount.email,
                currentPassword: this.changePasswordForm.get('currentPassword').value,
                newPassword: this.changePasswordForm.get('newPassword').value
            };
            this.passwordService.doChangePassword(changePasswordRequest).subscribe({
                next: (successResponse: ChangePasswordResponse) => {
                    this.sessionService.changedPassword = true;
                    if (successResponse?.operationResponse.returnCode === CHANGE_PASSWORD_RETURN_CODE.DMX_DB_ERROR) {
                        this.waitIndicator.hideWaitIndicator();
                        this.hasDMXError = true;
                        this.sessionService.isLoggedIn = false;
                        this.loggerService.error('showResults(): changed password successfully; DMX Database failure', successResponse);
                        this.loggerService.logCurrentContents();
                    } else {
                        this.waitIndicator.hideWaitIndicator();
                        this.route.navigate(['password-change', 'success']).then(() => {
                            this.loggerService.info('showResults(): changed password successfully; redirecting to /password-change/success', successResponse);
                            this.loggerService.logCurrentContents();
                        }).catch((error) => {
                            this.loggerService.error('showResults(): changed password successfully; failed to redirect[/password-change/success] on successful password update',
                                {
                                    IAMResponse: successResponse,
                                    redirectError: error
                                });
                            this.loggerService.logCurrentContents();
                        });
                    }
                },
                error: (error: any) => {
                    this.waitIndicator.hideWaitIndicator();
                    if (error?.operationResponse) {
                        this.decipherAndDisplayPasswordChangeError(error);
                    } else {
                        const displayErrorMessage = tpiSearchError.UNABLE_CHANGE_PASSWORD_ERROR;
                        this.loggerService.error(`showResults(): change password Error; System error; message: ${displayErrorMessage}`, { errorMessage: displayErrorMessage, error });
                        this.showResultsError(displayErrorMessage);
                    }
                    this.loggerService.logCurrentContents();
                }
            });
        } else {
            this.logFormFieldErrors();
        }
        this.utilsService.scrollToTopOfPage();
    }

    showResultsError(errorMessage: string): void {
        this.hasError = true;
        this.showSuccessMessage = false;
        this.errorMessage = errorMessage;
    }

    showResultsSuccess(): void {
        this.resetForm(true);
        this.hasError = false;
        this.showSuccessMessage = true;
        this.successMessage = tpiSearchSuccess.CHANGE_PASSWORD_SUCCESS;
    }
}
