import { Component, Input, OnInit } from '@angular/core';
import { PolicyInformationDetails } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/policyInformationDetails';
import { environment } from '../../../../environments/environment';
import { AutoDriver } from '../../../tpi-models/auto-driver';
import { CancellationDetailsForTPI } from '../../../tpi-models/general/cancellationReasons';
import { PolicyInformation } from '../../../tpi-models/general/policy-information';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { LoggerService } from './../../../tpi-services/logger/logger.service';
@Component({
    selector: 'app-policy-info',
    templateUrl: './policy-details.component.html',
    styleUrls: ['./policy-details.component.scss']
})
export class PolicyDetailsComponent implements OnInit {
    constructor(
        private loggerService: LoggerService,
        private sessionService: SessionService
    ) { }
    @Input() autoDrivers: AutoDriver;
    @Input() cancellationDetailsForTPIs: CancellationDetailsForTPI;
    @Input() isActiveTab: string;
    @Input() policyInformationDetail: PolicyInformationDetails;
    @Input() policyInformations: PolicyInformation;
    contractsEndorsements;

    contractsEndorsementsURL(): void {
        this.loggerService.info('Click of Policy Contracts & Endorsements', {
            UserId: sessionStorage.getItem('userName'),
            PolicyNumber: this.policyInformationDetail.policyNumber,
            PolicySystem: 'Policy Center',
            LOB: 'Auto',
            Company:
                this.sessionService.getSession().context.session.account.company
                    .companyDescription
        });
    }

    ngOnInit(): void {
        this.contractsEndorsements = environment.contractsEndorsementsURL;
    }
}
