<h1 class="bolt-heading-md margin-bottom-auto">
    Mortgage Company Details
</h1>
<div class="bolt-container padding-top-sm" *ngIf="!mortgageInformation[0]?.isError">
    <div *ngIf="mortgageInformation[0]?.isEmpty || mortgageInformation[0]?.isEdit" class="margin-top-x1 bolt-row edit-button bolt-background-pale-gray-50 noprint">
        <div class="bolt-col-4"></div>
        <div class="bolt-col-auto">
            <bolt-button iconleft="pencil" (click)="openEditModal()">Edit</bolt-button>
        </div>
    </div>
    <div class="bolt-row bolt-body-copy-sm margin-top-sm" *ngIf="mortgageInformation[0]?.isEmpty || mortgageInformation[0]?.isEdit">
        No mortgage company records on file at this time.
    </div>
    <div *ngIf="!mortgageInformation[0]?.isEmpty">
        <div *ngFor="let mortgageInfo of mortgageInformation" class="bolt-space-bottom-md">
            <div class="bolt-row">
                <h2 class="bolt-heading-sm bolt-body-copy-sm element-text" *ngIf="mortgageInfo.type === 'Mortgagee1'">
                    Primary mortgage loan number:
                </h2>
                <h2 class="bolt-heading-sm bolt-body-copy-sm element-text" *ngIf="mortgageInfo.type !== 'Mortgagee1'">
                    Loan number:
                </h2>
            </div>
            <div class="bolt-row bolt-body-copy-sm text-padding-bottom"><span class="nwpii"> {{ mortgageInfo.loanNumber
                    ||
                    'Not available'}} </span>
            </div>
            <div class="bolt-row bolt-body-copy-sm">{{mortgageInfo.companyName}}</div>
            <div class="bolt-row bolt-body-copy-sm" *ngIf="mortgageInfo.legalInformationName">{{mortgageInfo.legalInformationName}}</div>
            <div class="bolt-row bolt-body-copy-sm">{{mortgageInfo.address.addressLine1}}</div>
            <div class="bolt-row bolt-body-copy-sm">{{mortgageInfo.address.city}}, {{mortgageInfo.address.state}}
                {{mortgageInfo.address.postalCode}}
            </div>
            <div *ngIf="mortgageInfo.type==='Mortgagee1' && this.policyInformationDetails && !(this.policyInformationDetails.cancellationDetails)"
                class="margin-top-x1 bolt-row edit-button bolt-background-pale-gray-50 noprint">
                <div class="bolt-col-4"></div>
                <div class="bolt-col-auto">
                    <bolt-button iconleft="pencil" (click)="openEditModal()">Edit</bolt-button>
                </div>
            </div>
            <div class="bolt-row bolt-body-copy-sm margin-top-sm noprint" *ngIf="mortgageInfo.type==='Mortgagee1'">
                Note: only the&nbsp; <strong> primary mortgage </strong> &nbsp;can be edited.
            </div>
        </div>
    </div>


    <div class="bolt-container padding-top-sm" *ngIf="mortgageInformation[0]?.isError">
        <div class="bolt-row bolt-body-copy-sm text-padding-top">
            Mortgage company information is not available at this time. Please call us or the agent listed on the policy
            to
            complete the update.
        </div>
    </div>
</div>

<bolt-modal #propertyEdit id="basicModal" type="basic" heading="Update mortgage company details" primarybutton="Save" secondarybutton="Cancel" (bolt-modal-primary-button-click)="onSave($event)"
    (bolt-modal-secondary-button-click)="clearForm()">
    <div class="bolt-container">
        <div class="bolt-row">
            <div class="bolt-col-12 bolt-body-copy-sm bolt-space-bottom-md">
                Note: only the <strong> primary mortgage </strong> can be edited.
            </div>
        </div>
        <form [formGroup]="companyForm">
            <div class="bolt-row">
                <div class="bolt-col-12 nwpii">
                    <bolt-textfield ngDefaultControl formControlName="loanNumber" type="text" aria-label="Please provide loan number" label="Loan number" optionaltext="hide" minlength="2"
                        maxlength="20" inputsize="24" [attr.error]="
                            checkForError(
                                'loanNumber',
                                companyForm,
                                appendOption(submitOptions, {
                                    label: 'Loan Number'
                                })
                            )
                        ">
                    </bolt-textfield>
                </div>
            </div>
            <div claas="bolt-row">
                <p class="header-text">
                    Select new mortgage company by state and name
                </p>
            </div>
            <div [formGroup]="companySearchForm">
                <div class="bolt-row margin-bottom-md">
                    <app-state class="col-state bolt-col-12 padding-right-0 mobile-margin-bottom-md" [type]="property" [parentFormGroup]="companySearchForm" [stateError]="stateRequired"
                        (change)="setStateError()"></app-state>
                    <bolt-textfield type="text" class="col-company bolt-col-12 padding-right-0" aria-label="Please provide Company Name" label="" optionaltext="hide" maxlength="100" ngDefaultControl
                        formControlName="companyName" (keypress)="utilsService.keyPressCompanyName($event)" (paste)="utilsService.pasteCompanyName($event)" tpiCompanyNameDirective [invalid]="
                            (companySearchForm.get('companyName').touched &&
                                companySearchForm.get('companyName').invalid) ||
                            (companyNameRequired && companySearchForm.get('companyName').untouched)
                        ">
                    </bolt-textfield>
                    <div class="col-button bolt-col-12 button-bar-padding">
                        <bolt-button type="primary" class="button-margin button-width-87 button-right-padding" [disabled]=disableSearch (click)="onSearchCompany()">Search</bolt-button>
                        <bolt-button class="button-width-76" (click)="onResetCompany()">Reset</bolt-button>
                    </div>
                </div>
                <div *ngIf="searchComapnyError && this.companySearchForm.status === 'INVALID'" class="bolt-row">
                    <bolt-field-error class="bolt-col-12">
                        {{mortgageInformationErrorMsg}}
                    </bolt-field-error>
                </div>
                <div *ngIf="performSearchError" class="bolt-row">
                    <bolt-field-error class="bolt-col-12">
                        {{mortgageComanySelactionRequired}}
                    </bolt-field-error>
                </div>
            </div>
            <div class="bolt-row" *ngIf="companyMap.size > 0">
                <div class="bolt-col-12 margin-bottom-md">
                    <bolt-select label="Select mortgage company address" optionaltext="hide" placeholder="Select" bolt-defined ngDefaultControl formControlName="selectedCompany"
                        (change)="selectedCompanyhandler()">
                        <option *ngFor="let company of companyMap | keyvalue" value={{company.key}}>
                            {{ company.value.displayText }}
                        </option>
                    </bolt-select>
                </div>
            </div>
            <div *ngIf="emptyCompanyResult" class="bolt-row">
                <bolt-field-error class="bolt-col-12">
                    {{mortgageRecordsNotFound}}
                </bolt-field-error>
            </div>
            <div *ngIf="companyAddressRequired" class="bolt-row">
                <bolt-field-error class="bolt-col-12">
                    {{mortgageCompanyAddressRequired}}
                </bolt-field-error>
            </div>
            <div class="bolt-row">
                <div class="bolt-col-md-12 bolt-col-lg-12 bolt-col">
                    <bolt-date-picker ngDefaultControl formControlName="changeEffectiveDate" label="Change effective date" [min]="minCalDate" optionaltext="hide"
                        (keypress)="utilsService.keyPressDate($event)" (paste)="utilsService.pasteDate($event)" inputsize="10" [attr.error]="
                            checkForError(
                                'changeEffectiveDate',
                                companyForm,
                                appendOption(submitOptions, {
                                    label: 'Change effective date'
                                })
                            )
                        ">
                    </bolt-date-picker>
                </div>
                <div class="bolt-col-md-6 bolt-col-lg-6 bolt-col"></div>
            </div>
        </form>
    </div>
</bolt-modal>