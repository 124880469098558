import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AlphanumericSpacesDirective } from './alphanumeric-spaces.directive';
import { AlphanumericDirective } from './alphanumeric.directive';
import { ButtonClicktDirective } from './button-click.directive';
import { CompanyNameDirective } from './company-name.directive';
import { NumbersOnlyDirective } from './numbersonly.directive';
import { ToUppercaseDirective } from './to-uppercase.directive';
@NgModule({
    schemas: [],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [ToUppercaseDirective, NumbersOnlyDirective, AlphanumericDirective, AlphanumericSpacesDirective, CompanyNameDirective, ButtonClicktDirective],
    declarations: [ToUppercaseDirective, NumbersOnlyDirective, AlphanumericDirective, AlphanumericSpacesDirective, CompanyNameDirective, ButtonClicktDirective],
    providers: []
})
export class DirectivesModule { }
