import { Component } from '@angular/core';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { LOGGER_CONSTANTS } from '../../../../CONSTANTS/tpi-common-constants';
import { WebSupportActionsEnum } from '../../../../CONSTANTS/web-support-actions';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';
import { AddModifyCompanyCoreComponent } from '../add-modify-company-core/add-modify-company-core.component';

@Component({
    selector: 'tpi-add-new-company',
    templateUrl: './add-new-company.component.html'
})
export class AddNewCompanyComponent extends AddModifyCompanyCoreComponent {
    // eslint-disable-next-line max-params
    constructor(
        public loggerService: LoggerService,
        public formHelper: RxFormHelperService,
        public accountService: ManageUserAccountsService,
        public sessionService: SessionService) {
        super(sessionService, loggerService, formHelper, accountService);
        this.action = WebSupportActionsEnum.CREATE;
        this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
    }

    openModal($event): void {
        if ($event) {
            this.showModal = true;
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: openModal() ${this.authorDetails.userName} opened add new company`);
            this.buildCompanyForm();
        }
    }
}
