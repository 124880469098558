import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { RESPONSE_CODES } from '../../CONSTANTS/tpi-error-tags';
import { WebSupportActionsEnum } from '../../CONSTANTS/web-support-actions';
import { CompanyInfo, RetrieveCompanyPayload, WebSupportCompanyPayload } from '../../tpi-models/general/company-info';
import { ModifyUserAccountInfo } from '../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { ModifyUserRequest } from '../../tpi-models/manage-accounts-company-web/modify-user-request';
import { WebSupportUserRequest } from '../../tpi-models/manage-accounts-company-web/web-support-request';
import { LoggerService } from '../logger/logger.service';
import { UtilsService } from '../utility/utils-service.service';

@Injectable({
    providedIn: 'root'
})
export class ManageUserAccountsService {
    constructor(
        private httpClient: HttpClient,
        private loggerService: LoggerService,
        private utilsService: UtilsService) { }

    agreeToTermsAndConditions(modifyUserAccountInfo: ModifyUserAccountInfo): Observable<any> {
        if (modifyUserAccountInfo?.userNumber && modifyUserAccountInfo?.userId) {
            const euaToken = sessionStorage.getItem('jwt');
            const headers = this.utilsService.getHttpOptions(euaToken);
            const url = `${environment.APIs.apigeeEnvironment}${environment.APIs.termsAndConditions}`;
            return this.httpClient.patch(url, modifyUserAccountInfo, { headers });
        }
        return throwError(() => new HttpErrorResponse({
            status: commonConstants.HTTP_RESPONSE_CODE.STATUS_CODE_500,
            error: {
                message: 'Invalid request object',
                request: modifyUserAccountInfo
            }
        }));
    }

    createDeleteOrModifyCompany(createOrModifyCompany: WebSupportCompanyPayload, action: string): Observable<any> {
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.utilsService.getHttpOptions(euaToken);
        if (action === WebSupportActionsEnum.EDIT) {
            return this.httpClient.patch(`${environment.APIs.apigeeEnvironment}${environment.APIs.webSupportEditCompany}`, createOrModifyCompany, { headers });
        } else if (action === WebSupportActionsEnum.CREATE) {
            return this.httpClient.post(`${environment.APIs.apigeeEnvironment}${environment.APIs.webSupportCreateCompany}`, createOrModifyCompany, { headers });
        } else if (action === WebSupportActionsEnum.DELETE) {
            return this.httpClient.post(`${environment.APIs.apigeeEnvironment}${environment.APIs.webSupportDeleteCompany}`, createOrModifyCompany, { headers });
        }

        return throwError(() => new HttpErrorResponse({ status: commonConstants.HTTP_RESPONSE_CODE.STATUS_CODE_500, error: `Invalid action ${action}` }));
    }

    createNewUser(modifyUserAccountInfo: ModifyUserAccountInfo): Observable<any> {
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post(`${environment.APIs.apigeeEnvironment}${environment.APIs.createNewUser}`, modifyUserAccountInfo, { headers });
    }

    editUser(modifyUserAccountInfo: ModifyUserAccountInfo): Observable<any> {
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.patch(`${environment.APIs.apigeeEnvironment}${environment.APIs.editUser}`, modifyUserAccountInfo, { headers });
    }

    findUsersByFilter(webSupportUserRequest: WebSupportUserRequest): Observable<any> {
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post(`${environment.APIs.apigeeEnvironment}${environment.APIs.webSupportUsers}`, webSupportUserRequest, { headers });
    }

    modifyUsers(modifyUserRequest: ModifyUserRequest): Observable<any> {
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post(`${environment.APIs.apigeeEnvironment}${environment.APIs.manageUsers}`, modifyUserRequest, { headers });
    }

    retrieveCompanies(userDetails: RetrieveCompanyPayload): Observable<Array<CompanyInfo>> {
        const url = environment.APIs.apigeeEnvironment + environment.APIs.retrieveCompanies;
        const body = userDetails;
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post<Array<CompanyInfo>>(url, body, { headers });
    }

    retrieveUsersByCompany(companyNumber: number): Observable<any> {
        const url = environment.APIs.apigeeEnvironment + environment.APIs.retrieveUsersByCompany;
        const body = { companyNumber };
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post(url, body, { headers }).pipe(
            catchError((backendError: HttpErrorResponse) => {
                let response = null;
                if (backendError.status === RESPONSE_CODES.ERROR_404) {
                    switch (backendError.error.errorMessage) {
                        case 'Error Processing Record':
                            this.loggerService.error(`retrieveUsersByCompany - SQL Processing Record Error`, { companyNumber, backendError });
                            response = throwError(() => backendError);
                            break;
                        case 'No Results Found':
                            this.loggerService.error(`retrieveUsersByCompany - No Results Found`, { companyNumber, backendError });
                            response = of([]);
                            break;
                        default:
                            response = throwError(() => backendError);
                    }
                } else {
                    response = throwError(() => backendError);
                }
                this.loggerService.error(`retrieveUsersByCompany - Error`, { companyNumber, backendError });
                return response;
            })
        );
    }

    updateUserLastLogin(modifyUserAccountInfo: ModifyUserAccountInfo): Observable<any> {
        const url = environment.APIs.apigeeEnvironment + environment.APIs.userLastLogin;
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post(url, modifyUserAccountInfo, { headers });
    }
}
