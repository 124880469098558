import {
    Component,
    EventEmitter, Input, Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementCoreComponent } from '../../../shared/components/form-element-core/form-element-core.component';
import { SubmitOptions } from '../../../tpi-models/general/form-models';
import { SearchFields } from '../../../tpi-models/general/search-forms';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
@Component({
    selector: 'app-auto-search',
    templateUrl: './auto-search.component.html',
    styleUrls: ['./auto-search.component.css']
})
export class AutoSearchComponent extends FormElementCoreComponent {
    @Output() autoSearchEmit: EventEmitter<SearchFields> = new EventEmitter();
    @Input() parentForm: UntypedFormGroup;
    @Input() submitOptions: SubmitOptions;
    autoSearchFields: SearchFields = {
        policyNumber: '',
        vin: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        fullVin: '',
        hasError: false,
        errorMessage: '',
        invalidForm: true,
        isYesSearch: true,
        isNoSearch: false,
        action: {
            displayResults: false,
            vin: '',
            policyNumber: ''
        },
        isPropertySearch: false
    };

    constructor(public utilsService: UtilsService) {
        super();
    }
}
