import { environment } from '../../environments/environment';

export const tpiErrorTags = {
    ADDRESS_OR_POLICY_NOT_FOUND: 'Error:address-or-policy-not-found',
    INVALID_ZIPCODE: 'Error:invalid-Zip-Code',
    INVALID_VIN: 'Error:invalid-vin',
    NULL_RESPONSE: 'undefined',
    POLICY_NOT_AUTO: 'Error:policy-not-auto',
    UNKNOWN_ERROR: 'Error:unknown-error',
    POLICY_NOT_PROPERTY: 'Error: policy-not-property',
    POLICY_INELIGIBLE: 'Error: policy-not-eligible',
    POLICY_INFO_NOT_AVAILABLE_ONLINE: 'Error: policy-info-not-available',
    TENANT_POLICY: 'Error: Tenant-Policy',
    OTHER_PROPERTY_POLICY: 'Error: Other-property-policy',
    EXPIRED_VERIFICATION_CODE: 'EXPIRED_VERIFICATION_CODE',
    MFALOCKERROR: 'MFALOCKERROR'
};

export const VALIDATION_REGEX = {
    isVinNumber: /^[0-9a-zA-Z]{6}$/,
    isPolicyNumber: /^\d{4}[a-zA-Z\s]{0,2}\d{6}$/,
    isValidCompanyName: /^[a-zA-Z0-9/ \-&'.]*$/,
    isZipCode: /^\d{5}$/,
    isEmail: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
    isLoanNumber: /^\d{0,14}$/,
    isAlphaNumeric: /^[a-zA-Z0-9\s]*$/,
    isAlphaNumericWithoutSpaces: /^[a-zA-Z0-9]*$/,
    isNumeric: /^[0-9]*$/,
    isUserId: /^[\w\-.@]*$/,
    isValidDate01to28: /^(0\d|1[0-2])\/(0\d|1\d|2[0-8])\/(19|20)\d{2}$/,
    isValidDate29to30: /^(01|0[3-9]|1[0-2])\/(29|30)\/(19|20)\d{2}$/,
    isValidDate31: /^(0[13578]|1[02])\/31\/(19|20)\d{2}$/,
    isValidLeapDay: /^(02)\/29\/(((19|20)(04|08|[2468][048]|[13579][26]))|2000)$/,
    isValidPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~_!@#$:|=,+{}[\]])[a-zA-Z0-9~_!@#$:|=,+{}[\]]*$/,
    isValidName: /^[^0-9~_!?.@#"$:;%^|`=,&*+<>{}()/[\]\\\s]*$/,
    isValidFirstName: /^[^0-9~_!?.@#"$:;%^|`=,&*+<>{}()/[\]\\\t\r\n\f]*$/,
    containsUppercase: /^.*(?=.*[A-Z]).*$/,
    containsLowercase: /^.*(?=.*[a-z]).*$/,
    containsNumber: /^.*(?=.*\d).*$/,
    containsSpecialCharacter: /^.*(?=.*[~_!@#$:|=,+{}[\]]).*$/,
    forbiddenSpecialCharacter: /^(?=.*[^a-zA-Z0-9~_!@#$:|=,+{}[\]\s]).*$/,
    notContainSpace: /^((?!\s).)*$/,
    isBulkLoanNumber: /^[A-Za-z0-9]{2,20}$/,
    webSupportMinLength: /^.{4,}$/
};

export const CONST_SIZES = {
    POLICYNUMBER_MAX_LENGTH: 12,
    ZIPCODE_MAX_LENGTH: 5,
    VIN_SHORT_MIN_LENGTH: 6,
    VIN_MAX_LENGTH: 17,
    PASSWORD_MIN_LENGTH: 12,
    MFA_LENGTH: 6
};

export const ERROR_MAP_COPYTEXTS = {
    maxlength: '@@fieldname@@ should not exceed with specified characters.',
    minlength: '@@fieldname@@ must have at least 2 digits.',
    vinNumberValidationError: 'Format for VIN is invalid. Please enter the last 6 digits of the VIN.',
    required: '@@fieldname@@ is required.',
    pattern: 'Format for @@fieldname@@ is invalid.',
    policyFormatError: 'Format for policy number is invalid.',
    minLengthForVinNumber: 'Format for VIN is invalid. Please enter the last 6 digits of the VIN.',
    minLengthForPassword: 'Password must have at least 12 characters.',
    lengthForMFA: 'Verification Code must have 6 characters.',
    fullVinFormatError: `The format for full VIN is invalid. Please check the VIN for accuracy. If the vehicle is older than 1981, call 1-${environment.nonSA360Contact}.`,
    usernameError: 'This username is not in use. Please check your information and try again.',
    emailError: 'Format for email address is invalid (e.g. name@example.com).',
    emailDomainError: 'Personal emails are not permitted.',
    loanFormatError: 'Special characters are not permitted. Please remove and try again.',
    passwordFormatFieldError: 'Review password format rules.',
    passwordUserNameFieldError: 'The format for the password is invalid. Please check the format and try again.',
    passwordCurrentPasswordFieldError: 'Your current password was entered incorrectly.',
    passwordPreviousUsedPasswordFieldError: 'New password cannot be a previously used password.',
    passwordWeakFieldError: `Password didn't meet security requirements.`,
    dateFormatError: 'Date format should be: MM/DD/YYYY.',
    zipCodePropertySearchError: 'ZIP code is invalid. Please enter a 5 digit ZIP code to continue.',
    dateMinErrorFive: 'Policy changes cannot be backdated more than 5 days.',
    dateMinErrorThirty: 'Policy changes cannot be backdated more than 30 days.',
    webSupportMinLength: 'Must enter at least 4 characters.'
};

export const MORTGAGE_EDIT_ERROR = {
    mortgageInformationError: 'Please enter mortgage information to search mortgage company.',
    mortgageCompanySelectionrequired: 'Mortgage company selection is required.',
    mortgageRecordsNotFound: 'No mortgage records found.',
    mortgageCompanyAddressRequired: 'Mortgage company address is required.'
};

export const RESPONSE_CODES = {
    ERROR_404: 404,
    ERROR_400: 400,
    ERROR_503: 503,
    ERROR_500: 500
};

export const fieldsForDirectErrorMessages = ['vinNumberValidationError', 'policyFormatError'];
export const fieldConstants = { thisField: 'This field' };
export const exceptionLowerCaseErrors = ['VIN', 'ZIP'];
export const customErrorFlags = {
    ZIP_CODE_PROPERTY_ERROR: 'ZIPCodePropertyError',
    PASSWORD_LOWERCASE_ERROR: 'passwordLowercaseError',
    PASSWORD_UPPERCASE_ERROR: 'passwordUppercaseError',
    PASSWORD_NUMBER_ERROR: 'passwordNumberError',
    PASSWORD_SPECIAL_CHARACTER_ERROR: 'passwordSpecialCharacterError',
    PASSWORD_SPACE_ERROR: 'passwordSpaceError'
};

export const DATE_VALIDATION_TAG = {
    ISVALID_01TO28: 'isValidDate01to28',
    ISVALID_29TO30: 'isValidDate29to30',
    ISVALID_31: 'isValidDate31',
    ISVALID_LEAPDAY: 'isValidLeapDay'
};

export const BULK_EDIT = {
    NOTES: {
        REVIEW_FORMATTING: `Review for proper formatting`
    },
    ERROR_MESSAGE: {
        UNABLE_TO_PROCESS: `We are unable to process the below policies.`,
        ALL_REQUIRED: `All fields are required`,
        PROPERTY_MAIL_DIFFERENT: `If the property & mailing address are different, try the other Zip`,
        REMOVE_DUPLICATION: `Remove duplicates`,
        PROPERTY_MAIL_NOT_MATCH: `We can't match the policy numbers and ZIP codes for the rows below. If the property & mailing address are different, try the other ZIP.`,
        SYSTEM_DB_ERROR: `We are unable to update the policies below at this time. Try again or call ${environment.nonSA360Contact} to complete the update.`
    }
};

export const BULK_ERROR_MESSAGE_GROUP = {
    getCommonErrorList(duplicatePolicy: boolean): string {
        const duplicateError = `<li>${BULK_EDIT.ERROR_MESSAGE.REMOVE_DUPLICATION}</li>`;
        return `${BULK_EDIT.ERROR_MESSAGE.UNABLE_TO_PROCESS}</br>
        <ul class="error-list">
        <li>${BULK_EDIT.NOTES.REVIEW_FORMATTING}</li>
        <li>${BULK_EDIT.ERROR_MESSAGE.ALL_REQUIRED}</li>
        <li>${BULK_EDIT.ERROR_MESSAGE.PROPERTY_MAIL_DIFFERENT}</li>
        ${duplicatePolicy ? duplicateError : ''}</ul>`;
    }
};

export const DISALLOWED_EMAIL_DOMAINS = ['gmail.com', 'yahoo.com', 'protonmail.com', 'aol.com', 'hotmail.com', 'msn.com', 'comcast.net', 'live.com', 'outlook.com', 'yammer.com'];
