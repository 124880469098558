import { Directive, HostListener } from '@angular/core';
import { EVENTS } from '../../CONSTANTS/tpi-common-constants';
import { UtilsService } from '../../tpi-services/utility/utils-service.service';

@Directive({
    selector: '[tpiCompanyNameDirective]'
})
export class CompanyNameDirective {
    constructor(public utilsService: UtilsService) { }

    @HostListener(EVENTS.KEY_PRESS, ['$event']) onKeyPress(event): void {
        this.utilsService.keyPressCompanyName(event);
    }

    @HostListener(EVENTS.PASTE, ['$event']) onPaste(event): void {
        this.utilsService.pasteCompanyName(event);
    }
}
