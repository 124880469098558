import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'tpi-notification-modal',
    templateUrl: './notification-modal.component.html'
})
export class NotificationModalComponent implements OnInit, OnDestroy {
    constructor(private elementReference: ElementRef) { }

    ngOnDestroy(): void {
        this.elementReference.nativeElement.remove();
    }

    ngOnInit(): void {
        document.body.appendChild(this.elementReference.nativeElement);
    }
}
