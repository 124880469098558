import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment.st';
import { MailingAddress } from './../../tpi-models/general/mailingAddress';

@Component({
    selector: 'app-mailing-address',
    templateUrl: './mailing-address.component.html',
    styleUrls: ['./mailing-address.component.scss']
})
export class MailingAddressComponent {
    @Input() isActiveTab: string;
    @Input() isSA360: boolean;
    @Input() mailingAddress: MailingAddress;

    nonSA360Contact = environment.nonSA360Contact;
    sa360Contact = environment.sa360Contact;
}
