import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DistributionPartnerInformationService,
    DPIMInfoQueryParamsHelperService,
    GetDPIMQueryParameters
} from '@nationwide/internet-servicing-dpim-requests';
import { DpimRoleInfoResponse } from '@nationwide/internet-servicing-dpim-requests/api-response-types/distribution-partner-information-api';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ECIF } from '../../../CONSTANTS/tpi-ecif-constants';
import { EcifService } from '../../../service/ecif.service';
import { ECIFResponse } from '../../../tpi-models/general/ecifResponse';
@Injectable()
export class DistributionPartnerIndividualInfoService {
    constructor(
        private ecifService: EcifService,
        private distributionPartnerInformationService: DistributionPartnerInformationService,
        private dpimInfoQueryParametersHelperService: DPIMInfoQueryParamsHelperService
    ) { }

    fetchDistributionPartnerIndividualInfo(policyNumber: string, euaToken: string): Observable<DpimRoleInfoResponse> {
        return this.ecifService.getEcifPolicyAgreement(policyNumber, euaToken).pipe(
            switchMap((ecifResponse: ECIFResponse): Observable<any> => {
                let agentStateCode: string;
                let agentNumber: string;
                let producerType: string;
                if (ecifResponse.relatedDistributionPartners) {
                    for (const relatedDistributionPartner of ecifResponse.relatedDistributionPartners) {
                        if (relatedDistributionPartner.distributionPartnerType === ECIF.PRODUCER_VALUES.PRODUCER) {
                            agentNumber = relatedDistributionPartner.externalIdentifier;
                        }
                        if (
                            relatedDistributionPartner.distributionPartnerType ===
                            ECIF.PRODUCER_VALUES.RESIDENT_PRODUCER &&
                            relatedDistributionPartner.roleDistributionChannel
                        ) {
                            producerType =
                                relatedDistributionPartner.roleDistributionChannel ===
                                    ECIF.PRODUCER_VALUES.INDEPENDENT_CHANNEL_AGENT ? ECIF.PRODUCER_VALUES.AMF : ECIF.PRODUCER_VALUES.PDS;
                        }

                        agentStateCode = ecifResponse.relatedDistributionPartners[0].licenseState;
                    }
                }
                const queryparams: GetDPIMQueryParameters =
                    this.dpimInfoQueryParametersHelperService.retrieveDPIMQueryParams(agentNumber, agentStateCode);
                return this.distributionPartnerInformationService
                    .retrieveDistributionPartnerRoleInfo({
                        accessToken: euaToken,
                        agentNumber: queryparams?.agentNumber,
                        query: this.generateQueryString(producerType, queryparams)
                    })
                    .pipe(catchError((error: HttpErrorResponse) => of(error)));
            })
        );
    }

    generateQueryString(producerType: string, queryparams: GetDPIMQueryParameters): string {
        return `legacyIdentifierType=${queryparams.legacyIdentifierType}&legacyAdministrativeSystem=${queryparams.legacyAdministrativeSystem}&client_id=${environment.authorize.client_id}`;
    }
}
