import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PipesModule } from '../pipes/pipes.module';
import { BulkGridComponent } from './bulk-grid/bulk-grid.component';
import { FormElementCoreComponent } from './form-element-core/form-element-core.component';
import { GenericPromptComponent } from './generic-prompt/generic-prompt.component';
import { PingIFrameComponent } from './ping-iframe/ping-iframe.component';
import { SearchErrorPanelComponent } from './search-error-panel/search-error-panel.component';
import { SearchSuccessPanelComponent } from './search-success-panel/search-success-panel.component';
import { WaitIndicatorComponent } from './wait-indicator/wait-indicator.component';
@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        PipesModule
    ],
    declarations: [
        FormElementCoreComponent,
        SearchErrorPanelComponent,
        SearchSuccessPanelComponent,
        BulkGridComponent,
        WaitIndicatorComponent,
        PingIFrameComponent,
        GenericPromptComponent
    ],
    exports: [
        FormElementCoreComponent,
        SearchErrorPanelComponent,
        SearchSuccessPanelComponent,
        BulkGridComponent,
        WaitIndicatorComponent,
        PingIFrameComponent,
        GenericPromptComponent
    ]
})
export class SharedComponentsModule { }
