<h1 class="bolt-heading-md margin-bottom-auto">Endorsement</h1>
<div *ngIf="propertyEndorsement">
    <div class="bolt-row">
        <h2 class="bolt-col bolt-body-copy-md title-padding">
            <strong>Current Details</strong>
        </h2>
    </div>
    <div class="bolt-row flex-wrap-container">
        <div class="bolt-col-6">
            <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">
                Current Dwelling amount:
            </h2>
            <span>{{ propertyEndorsement?.currentDwellingAmount?.value || 'Not available'
                }}</span>
        </div>
        <div class="bolt-col-6">
            <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">
                Dwelling replacement cost:
            </h2>
            <span>{{ propertyEndorsement?.dwellingReplacementCost?.value || 'Not available' }}
            </span>
        </div>
        <div class="bolt-col-6">
            <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">
                Current deductible:
            </h2>
            <span>{{ propertyEndorsement?.currentDetectibleAmount?.value || 'Not available'
                }}</span>
        </div>
        <div class="bolt-col-6">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                Current premium:
            </h2>
            <span>{{ (propertyEndorsement?.currentPremium.amount | currency: 'USD') || 'Not
                available' }}</span>
        </div>
        <div class="bolt-col-6" *ngIf="propertyEndorsement?.windHailDedectible">
            <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">
                {{ propertyEndorsement.windHailDedectible.label }}:
            </h2>
            <span>{{ propertyEndorsement.windHailDedectible.value }}</span>
        </div>
        <div class="bolt-col-6" *ngIf="propertyEndorsement?.hurricanceDedectible">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                {{ propertyEndorsement.hurricanceDedectible.label }}:
            </h2>
            <span>{{ propertyEndorsement.hurricanceDedectible.value }}</span>
        </div>
    </div>
    <div class="bolt-row">
        <h2 class="bolt-col bolt-body-copy-md"><strong>Renewal Details</strong></h2>
    </div>
    <div class="bolt-row" *ngIf="!checkRenewalInformation()">
        <div class="bolt-col bolt-body-copy-sm">
            Renewal information available in {{ renewalDays() }} days
        </div>
    </div>
    <div *ngIf="checkRenewalInformation()">
        <div class="bolt-row">
            <div class="bolt-col-6" *ngIf="renewalInformation?.currentDwellingAmount">
                <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">
                    renewal dwelling amount:
                </h2>
                <span>{{ renewalInformation?.currentDwellingAmount?.value }}</span>
            </div>
        </div>
        <div class="bolt-row">
            <div class="bolt-col-6" *ngIf="renewalInformation?.currentDetectibleAmount">
                <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">
                    renewal deductible:
                </h2>
                <span>{{ renewalInformation?.currentDetectibleAmount?.value }}</span>
            </div>
            <div class="bolt-col-6" *ngIf="renewalInformation?.currentPremium">
                <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">renewal premium:</h2>
                <span>{{ (renewalInformation?.currentPremium?.amount | currency: 'USD')
                    }}</span>
            </div>
        </div>
        <div class="bolt-row">
            <div class="bolt-col-6" *ngIf="renewalInformation?.windHailDedectible">
                <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">
                    {{ renewalInformation.windHailDedectible.label }}:
                </h2>
                <span>{{ renewalInformation.windHailDedectible.value }}</span>
            </div>
            <div class="bolt-col-6" *ngIf="propertyEndorsement?.hurricanceDedectible">
                <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                    {{ renewalInformation.hurricanceDedectible.label }}:
                </h2>
                <span>{{ renewalInformation.hurricanceDedectible.value }}</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!propertyEndorsement" class="bolt-row padding-top-lg bolt-body-copy-sm">
    <div class="bolt-col">Endorsement information is not available at this time.</div>
</div>
<div class="bolt-row noprint">
    <div class="bolt-col link">
        <a [href]="contractsEndorsements" target="_blank" (click)="contractsEndorsementsURL()" class="bolt-body-copy-sm">Policy Contracts & Endorsements</a>
    </div>
</div>