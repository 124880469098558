<div class="bolt-row">
    <div class="bolt-col bolt-space-bottom-2xl scroll-table">
        <div class="bolt-table bolt-space-bottom-2xl">
            <table aria-label="Table of user accounts with the columns name, username, and role.">
                <thead>
                    <tr class="selected-row sticky-heading-first-row">
                        <td>
                            <bolt-checkbox #selectAllCheckbox arialabel="select all users in the table checkbox" (click)="selectAll($event)"></bolt-checkbox>
                        </td>
                        <td>
                            {{selectAllLabel}}
                        </td>
                        <td>
                            {{selectedTableEntries.length}} of {{tableEntries.length}} selected
                            users
                        </td>
                        <td></td>
                    </tr>
                    <tr class="sticky-heading-second-row">
                        <th id="Select"></th>
                        <th id="Name">NAME</th>
                        <th id="Username">USERNAME</th>
                        <th id="Role">ROLE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of tableEntries; let i=index">
                        <td *ngIf="isSelected(row)" class="selected-row">
                            <bolt-checkbox [checked]="isSelected(row)" arialabel="unselect checkbox of user {{row.firstName}} {{row.lastName}}" (change)="selectUser(row)"></bolt-checkbox>
                        </td>
                        <td *ngIf="!isSelected(row)">
                            <bolt-checkbox [checked]="isSelected(row)" arialabel="select checkbox of user {{row.firstName}} {{row.lastName}}" (change)="selectUser(row)"></bolt-checkbox>
                        </td>

                        <td *ngIf="isSelected(row)" class="selected-row">{{row.firstName}} {{row.lastName}}</td>
                        <td *ngIf="!isSelected(row)">{{row.firstName}} {{row.lastName}}</td>

                        <td *ngIf="isSelected(row)" class="selected-row">{{row.userId}}</td>
                        <td *ngIf="!isSelected(row)">{{row.userId}}</td>

                        <td *ngIf="isSelected(row)" class="selected-row">{{row.role.roleDescription}}</td>
                        <td *ngIf="!isSelected(row)">{{row.role.roleDescription}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>