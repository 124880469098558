import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginLogoutErrorsComponent } from './tpi-components/login-logout/login-logout-errors/login-logout-errors.component';
import { LoginLogoutLandingComponent } from './tpi-components/login-logout/login-logout-landing/login-logout-landing.component';
import { LogoutComponent } from './tpi-components/login-logout/logout/logout.component';
import { ManageCompanyListComponent } from './tpi-components/manage-accounts-company-web/manage-company-list/manage-company-list.component';
import { ManageUserAccountsComponent } from './tpi-components/manage-accounts-company-web/manage-user-accounts/manage-user-accounts.component';
import { ManageUserWebComponent } from './tpi-components/manage-accounts-company-web/manage-user-web/manage-user-web.component';
import { TermsAndConditionsComponent } from './tpi-components/manage-accounts-company-web/terms-and-conditions/terms-and-conditions.component';
import { MortgageBulkUpdateComponent } from './tpi-components/mortgage-bulk-update/mortgage-bulk-update.component';
import { PolicyInquiryComponent } from './tpi-components/policy-inquiry/policy-inquiry.component';
import { ChangePasswordComponent } from './tpi-components/user-credentials/change-password/change-password.component';
import { ExpiredPasswordComponent } from './tpi-components/user-credentials/expired-password/expired-password.component';
import { LoginErrorComponent } from './tpi-components/user-credentials/expired-password/login-error/login-error.component';
import { ForgotIdComponent } from './tpi-components/user-credentials/forgot-id/forgot-id.component';
import { EmailConfirmationComponent } from './tpi-components/user-credentials/forgot-password/email-confirmation/email-confirmation.component';
import { ForgotPasswordComponent } from './tpi-components/user-credentials/forgot-password/forgot-password.component';
import { MFAChallengeComponent } from './tpi-components/user-credentials/forgot-password/mfa-challenge/mfa-challenge.component';
import { AccountLockedFailureComponent } from './tpi-components/user-credentials/shared/password-notification/account-locked-failure/account-locked-failure.component';
import { PasswordChangeSuccessComponent } from './tpi-components/user-credentials/shared/password-notification/password-change-success/password-change-success.component';
import { TemporaryPasswordFailureComponent } from './tpi-components/user-credentials/shared/password-notification/temporary-password-failure/temporary-password-failure.component';
import { TemporaryPasswordSuccessComponent } from './tpi-components/user-credentials/shared/password-notification/temporary-password-success/temporary-password-success.component';
import { TemporaryPasswordComponent } from './tpi-components/user-credentials/temp-password/temp-password.component';
import { AccountLockedGuard } from './tpi-guard/account-locked.guard';
import { ConfirmedEmailAddressGuard } from './tpi-guard/confirmed-email-address.guard';
import { EnteredEmailAddressGuard } from './tpi-guard/entered-email-address.guard';
import { LoadChangePasswordSuccessGuard } from './tpi-guard/load-change-password-success.guard';
import { LogOnLoadAndRefreshGuard } from './tpi-guard/log-on-load-and-refresh.guard';
import { OnlyDevelopmentGuard } from './tpi-guard/only-development.guard';
import { OnlyLoggedInGuard } from './tpi-guard/only-logged-in.guard';
import { OnlyLoggedOutGuard } from './tpi-guard/only-logged-out.guard';
import { OnlySuperAdminUserGuard } from './tpi-guard/only-super-admin-user.guard';
import { OnlySuperUserGuard } from './tpi-guard/only-super-user.guard';
import { OnlyWebSupportGuard } from './tpi-guard/only-web-support.guard';

const routes: Routes = [
    {
        path: 'policy-search',
        component: PolicyInquiryComponent,
        canActivate: [OnlyLoggedInGuard],
        data: { showNavigation: true }
    },
    {
        path: 'mortgage-bulk-update',
        component: MortgageBulkUpdateComponent,
        title: 'Mortgage Bulk Update - Nationwide',
        canActivate: [OnlyLoggedInGuard],
        data: { showNavigation: true }
    },
    {
        path: 'manage-users',
        component: ManageUserAccountsComponent,
        title: 'Manage User Accounts - Nationwide',
        canActivate: [LogOnLoadAndRefreshGuard, OnlyLoggedInGuard, OnlySuperAdminUserGuard],
        data: { showNavigation: true }
    },
    {
        path: 'company-list',
        component: ManageCompanyListComponent,
        title: 'Manage Company List - Nationwide',
        canActivate: [LogOnLoadAndRefreshGuard, OnlyLoggedInGuard, OnlySuperUserGuard],
        data: { showNavigation: true }
    },
    {
        path: 'web-support',
        component: ManageUserWebComponent,
        title: 'Manage User Account - Nationwide',
        canActivate: [OnlyLoggedInGuard, OnlyWebSupportGuard],
        data: { showNavigation: true }
    },
    {
        path: 'password-change',
        component: ChangePasswordComponent,
        title: 'Change Password - Nationwide',
        canActivate: [OnlyLoggedInGuard],
        data: { showNavigation: true }
    },
    {
        path: 'password-change/success',
        component: PasswordChangeSuccessComponent,
        title: 'Success! Change Password - Nationwide',
        canActivate: [OnlyLoggedInGuard, LoadChangePasswordSuccessGuard],
        data: { showNavigation: false }
    },
    {
        path: 'login-error',
        component: LoginErrorComponent,
        title: 'Login Error - Nationwide',
        canActivate: [OnlyDevelopmentGuard],
        data: { showNavigation: false }
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        title: 'Forgot My Password - Nationwide',
        canActivate: [OnlyLoggedOutGuard],
        data: { showNavigation: false }
    },
    {
        path: 'forgot-password-challenge',
        component: MFAChallengeComponent,
        title: 'Verify Identity - Nationwide',
        canActivate: [OnlyLoggedOutGuard, ConfirmedEmailAddressGuard],
        data: { showNavigation: false }
    },
    {
        path: 'forgot-password-contact',
        component: EmailConfirmationComponent,
        title: 'Contact Method - Nationwide',
        canActivate: [OnlyLoggedOutGuard, EnteredEmailAddressGuard],
        data: { showNavigation: false }
    },
    {
        path: 'forgot-password/success',
        component: TemporaryPasswordSuccessComponent,
        title: 'Success! Temporary Password - Nationwide',
        canActivate: [OnlyLoggedOutGuard, LoadChangePasswordSuccessGuard],
        data: { showNavigation: false }
    },
    {
        path: 'forgot-password/error',
        component: TemporaryPasswordFailureComponent,
        title: 'Error Temporary Password - Nationwide',
        canActivate: [OnlyLoggedOutGuard, LoadChangePasswordSuccessGuard],
        data: { showNavigation: false }
    },
    {
        path: 'forgot-password/account-locked',
        component: AccountLockedFailureComponent,
        title: 'Error Account Locked - Nationwide',
        canActivate: [OnlyLoggedOutGuard, AccountLockedGuard],
        data: { showNavigation: false }
    },
    {
        path: 'forgotid',
        component: ForgotIdComponent,
        canActivate: [OnlyDevelopmentGuard],
        data: { showNavigation: false }
    },
    {
        path: 'update-expired-password',
        component: ExpiredPasswordComponent,
        title: 'Expired Password - Nationwide',
        data: { showNavigation: false }
    },
    {
        path: 'update-expired-password/success',
        component: PasswordChangeSuccessComponent,
        title: 'Success! Change Password - Nationwide',
        canActivate: [LoadChangePasswordSuccessGuard],
        data: { showNavigation: false }
    },
    {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
        title: 'Terms And Conditions - Nationwide',
        canActivate: [OnlyLoggedInGuard],
        data: { showNavigation: false, deepLink: true }
    },
    {
        path: 'new-user-password-change/:token',
        component: TemporaryPasswordComponent,
        title: 'New User Password Change - Nationwide',
        canActivate: [LogOnLoadAndRefreshGuard],
        data: { showNavigation: false }
    },
    {
        path: 'new-user-password-change/:token/success',
        component: PasswordChangeSuccessComponent,
        title: 'Success! New User Password Change - Nationwide',
        canActivate: [LogOnLoadAndRefreshGuard],
        data: { showNavigation: false }
    },
    {
        path: 'error',
        title: 'Nationwide Policy Inquiry - Error',
        component: LoginLogoutErrorsComponent,
        canActivate: [OnlyLoggedOutGuard],
        data: { hideTitle: true }
    },
    {
        path: 'landing',
        component: LoginLogoutLandingComponent,
        title: 'Nationwide Policy Inquiry - Landing',
        canActivate: [OnlyLoggedOutGuard],
        data: { hideTitle: true }
    },
    {
        path: 'logout',
        component: LogoutComponent,
        title: 'Nationwide Policy Inquiry - Logout',
        canActivate: [OnlyLoggedOutGuard],
        data: { showNavigation: false }
    },
    { path: '', redirectTo: 'landing', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
