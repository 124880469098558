import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'maskEmail'
})
export class MaskEmailPipe implements PipeTransform {
    /*
    * Masks the email address
    * Replaces all but the first 2 characters of the email address with '*'
    * Usage:
    *   value | maskEmail
    * Example:
    *   {{ 'test@gmail.com' | maskEmail }}
    *   formats to: 'te**@gmail.com'
    */
    transform(email: any): string {
        if (email) {
            email = email.split('');
            const finalArray: any = [];
            const length = email.indexOf('@');
            for (const [index] of email.entries()) {
                if (index >= 2 && index <= length - 1) {
                    finalArray.push('*');
                } else {
                    finalArray.push(email[index]);
                }
            }
            return finalArray.join('');
        } else {
            return null;
        }
    }
}
