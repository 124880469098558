import {
    ChangeDetectionStrategy, Component,
    EventEmitter, Input, Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { usStates } from '../../../CONSTANTS/tpi-common-constants';
import { FormElementCoreComponent } from '../../../shared/components/form-element-core/form-element-core.component';
import { SubmitOptions } from '../../../tpi-models/general/form-models';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
@Component({
    selector: 'app-mortgage-search',
    templateUrl: './mortgage-search.component.html',
    styleUrls: ['./mortgage-search.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MortgageSearchComponent extends FormElementCoreComponent {
    @Output() companySearch = new EventEmitter<boolean>();
    @Input() displaybankName: any;
    @Input() parentForm: FormGroup;
    @Output() resetCompanySearch = new EventEmitter<boolean>();
    @Input() submitOptions: SubmitOptions;
    states = usStates;

    constructor(public utilsService: UtilsService) {
        super();
    }

    resetMortgageCompany(): void {
        this.resetCompanySearch.emit();
    }

    searchMortgageCompany(event): void {
        const KEY_CODE_ENTER = 13;
        if (event?.keyCode === KEY_CODE_ENTER || event === true) {
            this.companySearch.emit();
        }
    }
}
