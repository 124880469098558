<app-ping-iframe></app-ping-iframe>
<div class="bolt-space-bottom-2xl error-container">
    <div *ngIf="errors.SYSTEM_ERROR">
        <h1>Uh oh! Something went wrong</h1>
        We've encountered an unexpected issue. Please try again later or call 1-800-240-1135.
    </div>
    <div *ngIf="errors.MFA_LOCKED">
        <h1>Authorization failed</h1>
        Your account is locked. Please contact your company admin for access.
    </div>
    <div *ngIf="errors.ACCOUNT_DISABLED">
        <h1>System is not responding</h1>
        Your account is locked. Please contact your company admin for access.
    </div>
    <div *ngIf="errors.USER_NOT_LOCATED">
        <h1>User not located</h1>
        We are unable to locate this username. Contact your company admin for access.
    </div>
</div>
