import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../tpi-services/tpi-session/session.service';

@Injectable({
    providedIn: 'root'
})
export class LoadChangePasswordSuccessGuard {
    constructor(private sessionService: SessionService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.sessionService.changedPassword;
    }
}
