import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { LOGGER_CONSTANTS } from '../../../../CONSTANTS/tpi-common-constants';
import { VALIDATION_REGEX } from '../../../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../../../CONSTANTS/tpi-search-errors';
import { WebSupportCompanyPayload } from '../../../../tpi-models/general/company-info';
import { SubmitOptions } from '../../../../tpi-models/general/form-models';
import { AuthorDetails } from '../../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';
import { SuccessModalComponent } from '../../shared/success-modal/success-modal.component';

@Component({
    selector: 'tpi-add-edit-company-core',
    template: ''
})
export class AddModifyCompanyCoreComponent implements OnInit {
    @Output() onError: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSuccess: EventEmitter<string> = new EventEmitter<string>();
    @Output() reload = new EventEmitter<boolean>(false);
    @ViewChild(SuccessModalComponent) successModalComponent;
    @Output() waitIndicator = new EventEmitter<boolean>(false);

    CLOSED_FROM_SUCCESS = 2;
    action: string;
    authorDetails: AuthorDetails;
    companyForm: FormGroup;
    companyNumber = 0;
    showModal = false;
    submitOptions: SubmitOptions;

    // eslint-disable-next-line max-params
    constructor(
        public sessionService: SessionService,
        public loggerService: LoggerService,
        public formHelper: RxFormHelperService,
        public accountService: ManageUserAccountsService) { }

    buildCompanyForm(): void {
        this.companyForm = this.formHelper.build({
            companyName: [undefined, Validators.compose([Validators.required, Validators.pattern(VALIDATION_REGEX.isValidCompanyName)])],
            policyAccessAutoAuth: [false, Validators.compose([Validators.required])],
            policyAccessPropertyAuth: [false, Validators.compose([Validators.required])]
        });
    }

    closeModal(closedStatus?: number): void {
        this.showModal = false;
        this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
        if (closedStatus === this.CLOSED_FROM_SUCCESS) {
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: closeModal: ${this.authorDetails.userName} closed ${LOGGER_CONSTANTS.company[this.action].createOrModifiedTitle} modal - success`);
        } else {
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: closeModal: ${this.authorDetails.userName} closed ${LOGGER_CONSTANTS.company[this.action].createOrModifiedTitle} modal`);
        }
        this.companyForm.reset();
    }

    createOrModifyCompany(createModifyCompanyPayload: WebSupportCompanyPayload): void {
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: createOrModifyCompany: ${this.authorDetails.userName} ${LOGGER_CONSTANTS.company[this.action].createOrModifiedStarted}`, {
            createModifyCompanyPayload,
            authorDetails: this.authorDetails
        });
        this.accountService.createDeleteOrModifyCompany(createModifyCompanyPayload, this.action).subscribe({
            next: (response) => {
                this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: createOrModifyCompany: ${this.authorDetails.userName} ${LOGGER_CONSTANTS.company[this.action].createdOrModifiedCompany} ${createModifyCompanyPayload.company.companyDescription}`, {
                    response,
                    createModifyCompanyPayload,
                    company: createModifyCompanyPayload.company,
                    authorDetails: this.authorDetails
                });
                this.loggerService.logCurrentContents();
                this.showWaitIndicator(false);
                this.successModalComponent.openModal(true);
                this.onSuccess.emit();
            },
            error: (error) => {
                this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: createOrModifyCompany: ${this.authorDetails.userName} ${LOGGER_CONSTANTS.company[this.action].createOrModifiedFailed} ${createModifyCompanyPayload.company.companyDescription}`, {
                    error,
                    createModifyCompanyPayload,
                    authorDetails: this.authorDetails
                });
                this.loggerService.logCurrentContents();
                this.showWaitIndicator(false);
                this.onError.emit(tpiSearchError.UNABLE_PROCESS_ERROR);
            }
        });
    }

    isInvalidPolicyAccess(): boolean {
        return !this.companyForm.get('policyAccessPropertyAuth').value && !this.companyForm.get('policyAccessAutoAuth').value;
    }

    isValidForm(): boolean {
        let isValid = false;
        if (this.companyForm.get('companyName').valid && !this.isInvalidPolicyAccess()) {
            isValid = true;
        } else if (this.isInvalidPolicyAccess()) {
            this.companyForm.setErrors({ policyAccessError: true });
        }
        return isValid;
    }

    ngOnInit(): void {
        this.authorDetails = this.sessionService.getAuthorDetails();
    }

    reloadPage($isOpen: boolean): void {
        if ($isOpen === false) {
            this.reload.emit($isOpen);
        }
    }

    saveCompany($event): void {
        if ($event) {
            this.submitOptions = {
                submitStatus: true,
                onSubmit: true
            };
            if (this.isValidForm()) {
                const companyDescription = this.companyForm.get('companyName').value.trim();
                const createModifyCompanyPayload: WebSupportCompanyPayload = {
                    userId: this.authorDetails.userName,
                    userNumber: this.authorDetails.userId,
                    company: {
                        id: this.companyNumber,
                        companyDescription,
                        autoAuthorization: this.companyForm.get('policyAccessAutoAuth').value ? 1 : 0,
                        propertyAuthorization: this.companyForm.get('policyAccessPropertyAuth').value ? 1 : 0
                    }
                };
                this.showWaitIndicator(true);
                this.closeModal(this.CLOSED_FROM_SUCCESS);
                this.createOrModifyCompany(createModifyCompanyPayload);
            }
        }
        $event.preventDefault();
    }

    showWaitIndicator(show: boolean): void {
        this.waitIndicator.emit(show);
    }
}
