<app-ping-iframe></app-ping-iframe>
<article aria-live="assertive" *ngIf="authorDetails">
    <ng-container aria-live="polite">
        <div>
            <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>
        </div>
        <div class="bolt-row">
            <div class="bolt-col">
                <h2 class="bolt-heading-md">Nationwide Policy Inquiry Terms and Conditions</h2>
            </div>
        </div>
        <tpi-terms-and-conditions-text></tpi-terms-and-conditions-text>
        <div class="bolt-row">
            <div class="bolt-col">
                <p class="bolt-body-copy-md"><strong>You must agree to the terms and conditions before
                        proceeding.</strong>
                </p>
            </div>
        </div>
        <div class="bolt-row">
            <div class="bolt-col">
                <bolt-button-bar class="noprint">
                    <bolt-button slot="cancel" type="secondary" (click)="cancel()">Cancel</bolt-button>
                    <bolt-button slot="forward" type="primary" (click)="agree()">Agree and Continue</bolt-button>
                </bolt-button-bar>
            </div>
        </div>
    </ng-container>
</article>
<bolt-waiting-indicator id="userManagementWaitIndicator" fullscreen starthidden></bolt-waiting-indicator>