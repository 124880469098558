import { Component, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { commonConstants } from '../../../CONSTANTS/tpi-common-constants';
import { tpiErrorTags, VALIDATION_REGEX } from '../../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { WaitIndicatorComponent } from '../../../shared/components/wait-indicator/wait-indicator.component';
import { ValidatorService } from '../../../shared/services/validator.service';
import { ForgotPasswordRetrieveEmailRequest } from '../../../tpi-models/user-credentials/forgot-password-retrieve-email-request';
import { ForgotPasswordRetrieveEmailResponse } from '../../../tpi-models/user-credentials/forgot-password-retrieve-email-response';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { UserFieldsStateService } from '../../../tpi-services/tpi-statemanagement/user-fields-state.service';
import { PasswordService } from '../../../tpi-services/user-credential-services/password.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';

interface Window {
    waitIndicatorFullscreen: any;
}
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent {
    @ViewChild(WaitIndicatorComponent) waitIndicator;

    errorMessage: string;
    hasError: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };
    usernameForm: FormGroup;

    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        private loggerService: LoggerService,
        private route: Router,
        public formHelper: RxFormHelperService,
        public utilsService: UtilsService,
        private validatorService: ValidatorService,
        private passwordService: PasswordService,
        private storeUserFieldsService: UserFieldsStateService
    ) { }

    buildForm(): void {
        this.usernameForm = this.formHelper.build({
            username: [
                '',
                Validators.compose([
                    Validators.required,
                    this.validatorService.validateUserId(VALIDATION_REGEX.isUserId)
                ])
            ]
        });
        this.resetFormValues();
    }

    ngOnInit(): void {
        this.sessionService.isLoggedIn = false;
        this.sessionService.deleteAllCookies();
        this.sessionService.resetForgotPasswordTokens();
        this.setVariables();
        this.buildForm();
    }

    performValidation($event?): void {
        if ($event) {
            this.submitOptions = {
                submitStatus: true,
                onSubmit: true
            };
            this.hasError = false;
            this.resetFormControllerError();

            if (this.usernameForm.valid) {
                this.waitIndicator.openWaitIndicator();
                this.retrieveEmailAddress(this.usernameForm.get('username').value);
            }
            this.utilsService.scrollToTopOfPage();
        }
    }

    redirectToAccountLockedErrorPage(): void {
        this.waitIndicator.hideWaitIndicator();
        this.utilsService.scrollToTopOfPage();
        this.route.navigate(['forgot-password/account-locked']).then(() => {
            this.loggerService.info('redirectToAccountLockedErrorPage(): entered username on forgot password page; redirecting to /forgot-password/account-locked');
            this.loggerService.logCurrentContents();
        }).catch((error) => {
            this.loggerService.error('redirectToAccountLockedErrorPage(): entered username on forgot password page; failed to redirect to /forgot-password/account-locked',
                {
                    redirectError: error
                });
            this.loggerService.logCurrentContents();
        });
    }

    redirectToEmailConfirmationPage(): void {
        this.waitIndicator.hideWaitIndicator();
        this.utilsService.scrollToTopOfPage();
        this.route.navigate(['forgot-password-contact']).then(() => {
            this.loggerService.info('redirectToEmailConfirmationPage(): entered username on forgot password page; redirecting to /forgot-password-contact');
            this.loggerService.logCurrentContents();
        }).catch((error) => {
            this.loggerService.error('redirectToEmailConfirmationPage(): entered username on forgot password page; failed to redirect to /forgot-password-contact',
                {
                    redirectError: error
                });
            this.loggerService.logCurrentContents();
        });
    }

    redirectToLegacy($event): void {
        this.loggerService.info('redirectToLegacy(): clicked cancel on forgot password');
        this.loggerService.logCurrentContents();
        if ($event) {
            this.sessionService.redirectToLegacy();
        }
    }

    resetForm($event?): void {
        if ($event) {
            this.usernameForm.updateValueAndValidity();
            this.resetFormValues();
            this.submitOptions.submitStatus = false;
            this.hasError = false;
        }
    }

    resetFormControllerError(): void {
        this.usernameForm.updateValueAndValidity();
        this.usernameForm.get('username').updateValueAndValidity();
    }

    resetFormValues(): void {
        this.usernameForm.get('username').setValue('');
        this.hasError = false;
    }

    retrieveEmailAddress(username: string): void {
        const retrieveEmailRequest: ForgotPasswordRetrieveEmailRequest = {
            user: username
        };

        this.passwordService.doRetrieveEmail(retrieveEmailRequest).subscribe(
            {
                next: (forgotPasswordRetrieveEmailResponse: ForgotPasswordRetrieveEmailResponse) => {
                    if (forgotPasswordRetrieveEmailResponse.status.toLowerCase() === commonConstants.RESPONSE_STATUS.SUCCESS.toLowerCase() && forgotPasswordRetrieveEmailResponse?.contactPoint?.emailAddresses[0]?.email !== undefined) {
                        this.loggerService.info('updateExpiredPassword: successful retrieve user email address from username', forgotPasswordRetrieveEmailResponse);
                        this.storeUserFieldsService.updateUserFields({
                            emailAddress: forgotPasswordRetrieveEmailResponse.contactPoint.emailAddresses[0].email,
                            username
                        });
                        this.sessionService.enteredEmailAddress = true;
                        this.redirectToEmailConfirmationPage();
                    } else if (forgotPasswordRetrieveEmailResponse.exception === tpiErrorTags.MFALOCKERROR) {
                        this.loggerService.info('updateExpiredPassword: user account is locked', forgotPasswordRetrieveEmailResponse);
                        this.sessionService.accountLocked = true;
                        this.redirectToAccountLockedErrorPage();
                    } else {
                        this.loggerService.error('updateExpiredPassword: failed retrieve user email address from username', forgotPasswordRetrieveEmailResponse);
                        this.showResultsError(tpiSearchError.LOCATE_ACCOUNT_ERROR);
                    }
                },

                error: (errorResponse) => {
                    this.waitIndicator.hideWaitIndicator();
                    this.loggerService.error('updateExpiredPassword: error retrieve user email address from username', errorResponse);
                    this.showResultsError(tpiSearchError.LOCATE_ACCOUNT_ERROR);
                }
            }
        );
    }

    setVariables(): void {
        this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
    }

    showResultsError(errorMessage: string): void {
        this.loggerService.info(`showResultsError(): message: ${errorMessage}`);
        this.waitIndicator.hideWaitIndicator();
        this.hasError = true;
        this.errorMessage = errorMessage;
        this.loggerService.logCurrentContents();
    }
}
