import { environment } from '../../environments/environment';

export const tpiSearchError = {
    POLICY_NOT_FOUND_ERROR: `No results found. Check what you entered and try again. If property & mailing addresses are different, try the other ZIP code.`,
    SYSTEM_DOWN_ERROR: `We're sorry. Our online system is temporarily unavailable. Please try again later.`,
    UNKNOWN_APPLICATION_ERROR: `We're sorry. Our online system is temporarily unavailable. Please try again later.`,
    ADD_NEW_USER_DUPLICATE_ERROR: `This user already exists. If further assistance is needed, call 1-800-240-1135.`,
    UNABLE_PROCESS_ERROR: `We're unable to process your request. Please try again. If the issue persists, call 1-800-240-1135.`,
    UNABLE_CHANGE_PASSWORD_ERROR: 'We are unable to update the password at this time. Try again or call 800-240-1135.',
    ADDRESS_OR_POLICY_NOT_FOUND: `We cannot find the policy number and VIN in our database. Please verify the information.`,
    INVALID_POLICY_NUMBER_ERROR: `The policy number is invalid. Please enter a valid policy number.`,
    INVALID_ZIP_CODE_ERROR: `ZIP Code is invalid. If property & mailing addresses are different, try the other ZIP.`,
    INVALID_AUTO_ADDRESS: `We aren't able to validate the address you entered. Please check the information below and try again.`,
    INVALID_VIN_ERROR: `We cannot find the policy number and VIN in our database. Please verify the information.`,
    MULTIPLE_ADDRESS_FOUND_ERROR: `The policy you're attempting to access has multiple associated property addresses. At this time,
     we can display only one address per policy. This restriction is temporary and you will soon be able to view all addresses associated
     with policies. In the meantime, please contact Nationwide or Allied Service Centers with any questions related to such policies.`,
    INVALID_VIN_NUMBER_PAST_CLIENT_ERROR: `The VIN is invalid. Please enter the last 6 digits of the VIN.`,
    POLICY_NOT_AUTO_ERROR: `This policy can't be found. If you're searching for a property policy, please click the Property tab.`,
    POLICY_TYPE_MISMATCH_ERROR: `This policy can't be found. If you're searching for an auto policy, please click the Auto tab.`,
    EDIT_FLOW_ERROR: `We are unable to update the policy at this time. Try again or call ${environment.nonSA360Contact}.`,
    EDIT_FLOW_CONDO_ERROR: 'We are unable to update the policy at this time. Please contact the agent listed on the policy to complete the update.',
    PROPERTY_ZIP_CODE_ERROR: 'ZIP Code is invalid. If property & mailing addresses are different, try the other ZIP.',
    POLICY_NOT_PROPERTY_ERROR: `This policy can't be found. If you're searching for an auto policy, please click the Auto tab.`,
    POLICY_INELIGIBLE_ERROR: `This Policy Type is ineligible. Please contact us for policy details.`,
    POLICY_INFO_NOT_AVAILABLE_ONLINE_ERROR: `Policy information is not available for this policy type. Please contact us for policy details.`,
    TENANT_POLICY_ERROR: `This Policy Type is ineligible. Please contact us for policy details.`,
    OTHER_PROPERTY_POLICY_ERROR: `Policy information is not available for this policy type. Please contact us for policy details.`,
    USER_WEB_ACCOUNTS_ERROR: `We're unable to process your request. Please try again`,
    NEW_PASSWORD_NOT_MATCH_ERROR: `Your new passwords do not match. Please try again.`,
    PASSWORD_MATCH_PREVIOUS_ERROR: `Your new password cannot be a previously used password.`,
    PASSWORD_ACCOUNT_LOCK_ERROR: 'Account is locked. You have exceeded the number of preset failure attempts.',
    PASSWORD_WEAK_ERROR: `Sorry, your new password is still vulnerable. Consider using uncommon words, words that don't repeat letters, or phrases that are harder to duplicate.`,
    WRONG_PASSWORD_ERROR: 'The values for UserID or Password are incorrect. Please check your information and try again.',
    REUSE_PASSWORD_ERROR: 'This Password has already been used. Please try a different one.',
    PASSWORD_TOO_NEW_ERROR: 'Your password is less than two days old and cannot be changed yet. Please see your delegate or contact Web Support at 1-800-240-1135 to reset your password.',
    PASSWORD_DEFAULT_ERROR: 'One or more fields are invalid.',
    LOCATE_ACCOUNT_ERROR: 'We are unable to locate an account for this username. Please try again.',
    SEND_MFA_ERROR: 'We are unable to send a verification code to this email. Please try again.',
    INVALID_MFA_ERROR: 'Please correct the following to continue. You have entered an invalid verification code. Try again or request a new code.',
    EXPIRED_MFA_ERROR: 'Your verification code has expired. Request a new code to try again.',
    ACCOUNT_LOCKED_ERROR: 'This account is locked. Please see your admin or contact Web Support at 1-800-240-1135 to unlock your account.',
    BULK_UPLOAD_API_FAIL: `We are unable to update the policy at this time. Try again or call ${environment.nonSA360Contact}.`,
    BULK_UPLOAD_API_NOMORTGAGE: `No mortgage records found.`,
    BULK_UPLOAD_MORTGAGE_COMPANY_REQUIRED: `Please enter the mortgage information to search mortgage company.`,
    RETRIEVE_ROLE_NAME_ERROR: `Attempted to retrieve the account name of a role number that was not found: @@roleId. Available roles: @@availableAccounts.`,
    MODIFY_USER_ERROR: `We were unable to @@action@@ the following users. Try again. If the issue persists, call 1-800-240-1135.`,
    MODIFY_USER_EXCEED_LIMIT_ERROR: `There are more than @@limit@@ users who have failed to @@action@@. Try again with a smaller group of users. If the issue persists, call 1-800-240-1135.`
};
