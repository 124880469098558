import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { commonConstants, LOGGER_CONSTANTS } from '../../CONSTANTS/tpi-common-constants';
import { BULK_EDIT, BULK_ERROR_MESSAGE_GROUP } from '../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../CONSTANTS/tpi-search-errors';
import { BulkGridComponent } from '../../shared/components/bulk-grid/bulk-grid.component';
import { FormElementCoreComponent } from '../../shared/components/form-element-core/form-element-core.component';
import { ValidatorService } from '../../shared/services/validator.service';
import { Window } from '../../tpi-models/general/model-window';
import { MortgageCompany } from '../../tpi-models/general/mortgage-companies-response';
import { MortgageBulkInformationResponse } from '../../tpi-models/general/mortgage-company';
import { LoggerService } from '../../tpi-services/logger/logger.service';
import { MortgageBulkUploadAdapterService } from '../../tpi-services/tpi-bulk-upload-services/mortgage-bulk-upload-adapter.service';
import { MortgageBulkUploadService } from '../../tpi-services/tpi-bulk-upload-services/mortgage-bulk-upload.service';
import { MortgageCompaniesAdapterService } from '../../tpi-services/tpi-policy-inquiry/mortgage-companies-adapter.service';
import { UtilsService } from '../../tpi-services/utility/utils-service.service';
import { BulkButtonBarComponent } from './bulk-button-bar/bulk-button-bar.component';

interface SubmitOptions {
    submitStatus: boolean;
    onSubmit: boolean;
}
@Component({
    selector: 'tpi-mortgage-bulk-update',
    templateUrl: './mortgage-bulk-update.component.html',
    styleUrls: ['./mortgage-bulk-update.component.css']
})

export class MortgageBulkUpdateComponent extends FormElementCoreComponent implements OnInit {
    @ViewChild(BulkButtonBarComponent) bulkButtonBarComponent: BulkButtonBarComponent;
    @ViewChild('formBulkEdit') formBulkEdit: ElementRef<any>;
    @ViewChild('gridComp') gridComp: BulkGridComponent;
    bulkEditForm: FormGroup;
    companyMap: Map<string, MortgageCompany> | {} = null;
    companySearchForm: FormGroup;
    displaybankName: boolean;
    errorMessage: string = null;
    errorMessageBulkEdit: string;
    resetGrid = false;
    submitOptions: SubmitOptions = this.submitStatus(true, false);
    submitOptionsForCompanySearch: SubmitOptions = this.submitStatus(true, false);
    successMessage: string = null;
    successfulPolicies: number;
    totalPolicies: number;

    // eslint-disable-next-line max-params
    constructor(
        private formHelper: RxFormHelperService,
        private mortgageCompaniesAdapterService: MortgageCompaniesAdapterService,
        private validator: ValidatorService,
        private loggerService: LoggerService,
        private utilService: UtilsService,
        private bulkUploadService: MortgageBulkUploadService,
        private bulkUploadAdapterService: MortgageBulkUploadAdapterService,
        @Inject('window') public window: Window,
        @Inject(PLATFORM_ID) private platformId: Object) {
        super();
    }

    buildForm(): void {
        this.companySearchForm = this.formHelper.build({
            state: ['', Validators.compose([Validators.required])],
            companyName: ['', Validators.compose([Validators.required])]
        });

        this.bulkEditForm = this.formHelper.build({
            bankName: ['', Validators.compose([Validators.required])],
            grid: [this.utilService.gridReset(), Validators.compose([this.validator.validateBulkEditForGrid()])]
        });

        this.bulkEditForm.get('grid').valueChanges.subscribe((response: Array<Array<string>>) => {
            this.bulkEditForm.get('grid').setValue(this.utilService.gridRemoveWhiteSpace(response), { emitEvent: false });
        });
    }

    companySearch(): void {
        this.errorMessage = null;
        this.companyMap = {};
        this.successMessage = null;
        this.bulkEditForm.get('bankName').setValue('');
        this.submitOptionsForCompanySearch = this.submitStatus(true, true);
        this.submitOptions = this.submitStatus(true, false);
        if (this.companySearchForm.valid) {
            this.loggerService.info(
                `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}-Start mortgage company search`,
                {
                    UserId: sessionStorage.getItem('userName'),
                    bulkEditDetails: this.bulkEditForm.value,
                    userCompanyName: sessionStorage.getItem('companyName')
                }
            );
            const companyName = this.companySearchForm.get('companyName').value;
            const companyState = this.companySearchForm.get('state').value;
            this.window.selectMortgageField.show();
            this.mortgageCompaniesAdapterService.getCompanyList(companyState, companyName).subscribe((companyList) => {
                if (companyList.error) {
                    this.loggerService.debug(
                        `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}- No mortgage company found`,
                        {
                            UserId: sessionStorage.getItem('userName'),
                            userCompanyName: sessionStorage.getItem('companyName'),
                            searchedCompanyName: this.companySearchForm.value
                        }
                    );
                    this.errorMessage = tpiSearchError.BULK_UPLOAD_API_NOMORTGAGE;
                    this.window.selectMortgageField.hide();
                } else if (companyList.apiError) {
                    this.loggerService.debug(
                        `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}-Failed to retrieve mortgage company`,
                        {
                            UserId: sessionStorage.getItem('userName'),
                            userCompanyName: sessionStorage.getItem('companyName'),
                            searchedCompanyName: this.companySearchForm.value
                        }
                    );
                    this.errorMessage = tpiSearchError.BULK_UPLOAD_API_FAIL;
                    this.window.selectMortgageField.hide();
                } else {
                    this.loggerService.info(
                        `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}-Success mortgage company search`,
                        {
                            UserId: sessionStorage.getItem('userName'),
                            userCompanyName: sessionStorage.getItem('companyName'),
                            searchedCompanyName: this.companySearchForm.value
                        }
                    );
                    this.displaybankName = true;
                    this.companyMap = companyList;
                }
                this.window.selectMortgageField.hide();
            });
        }
    }

    downloadSampleBulkXLSX(): void {
        this.loggerService.info(
            `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit} download sample xlsx`,
            {
                UserId: sessionStorage.getItem('userName'),
                userCompanyName: sessionStorage.getItem('companyName')
            }
        );
        this.window.open(commonConstants.BULK_CONSTANTS.SAMPLE_XLSX_DOWNLOAD_URL, '_blank');
    }

    ngOnInit(): void {
        this.loggerService.info(
            `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}-Load page`,
            {
                UserId: sessionStorage.getItem('userName'),
                userCompanyName: sessionStorage.getItem('companyName')
            }
        );
        this.buildForm();
    }

    resetAll(): void {
        this.resetMortgageSubmit();
        this.resetErrorsAndSuccess();
        this.resetGrid = true;
    }

    resetCompanySearch(): void {
        this.companySearchForm.reset();
        this.submitOptionsForCompanySearch = this.submitStatus(true, false);
        this.errorMessage = null;
        this.companyMap = {};
    }

    resetErrorsAndSuccess(): void {
        this.resetGrid = false;
        this.errorMessage = null;
        this.errorMessageBulkEdit = '';
        this.successMessage = '';
    }

    resetMortgageSubmit(): void {
        this.resetCompanySearch();
        this.bulkEditForm.reset();
        this.submitOptions = this.submitStatus(true, false);
        this.submitOptionsForCompanySearch = this.submitStatus(true, false);
        this.bulkEditForm.get('grid').setValue(this.utilService.gridReset());
        this.resetGrid = true;
        this.companyMap = {};
        this.scrollToMessage();
    }

    scrollToMessage(): void {
        const scrollDelay = 500;
        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                this.formBulkEdit.nativeElement.scrollIntoView();
            }
        }, scrollDelay);
    }

    submit(): void {
        this.resetErrorsAndSuccess();
        this.totalPolicies = 0;
        this.successfulPolicies = 0;
        this.submitOptions = this.submitStatus(true, true);
        this.submitOptionsForCompanySearch = this.submitStatus(true, true);
        if (this.companySearchForm.valid && this.bulkEditForm.get('bankName').valid && !this.bulkEditForm.get('grid')?.errors?.required) {
            this.window.confirmationToSubmit.openModal();
        } else if (!this.bulkEditForm.get('bankName').valid || !this.companySearchForm.valid) {
            this.errorMessage = tpiSearchError.BULK_UPLOAD_MORTGAGE_COMPANY_REQUIRED;
            this.scrollToMessage();
        } else if (this.bulkEditForm.get('grid')?.errors?.required) {
            this.errorMessageBulkEdit = BULK_ERROR_MESSAGE_GROUP.getCommonErrorList(false);
            this.scrollToMessage();
        }
    }

    submitCancel(): boolean {
        return true;
    }

    submitFormAfterValidation(): void {
        const bulkGridValues = this.utilService.getNonEmptyValuesForGrid(this.bulkEditForm.get('grid').value);
        const bulkGridSanitizedValues = this.utilService.sanitizedValueAgainstInvalid(this.bulkEditForm.get('grid'));
        this.totalPolicies = bulkGridSanitizedValues.validGrid.length + bulkGridSanitizedValues.invalidGrid.length;
        this.successfulPolicies = 0;
        this.loggerService.info(
            `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}-Start submit`,
            {
                UserId: sessionStorage.getItem('userName'),
                bulkGridValues,
                mortgageCompany: this.bulkEditForm.get('bankName').value,
                userCompanyName: sessionStorage.getItem('companyName')
            }
        );
        this.window.bulkRedirectWait.show();
        if (bulkGridSanitizedValues?.validGrid?.length > 0) {
            const mortgageCompany = this.bulkUploadAdapterService.createMortgageCompanyRequest(this.bulkEditForm.get('bankName').value, this.companyMap);
            this.bulkUploadService.doUpload(this.bulkUploadAdapterService.createBulkRequest(mortgageCompany, bulkGridSanitizedValues.validGrid)).subscribe({
                next: (response: MortgageBulkInformationResponse) => {
                    this.loggerService.info(
                        `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}-Start submit-Success- received response form bulkUploadService`,
                        {
                            UserId: sessionStorage.getItem('userName'),
                            userCompanyName: sessionStorage.getItem('companyName'),
                            response,
                            mortgageCompany
                        }
                    );
                    const messages = this.bulkUploadAdapterService.setErrorOrSuccessMessage(response, {
                        bulkGridSanitizedValues,
                        bulkGridValues,
                        totalPolicies: this.totalPolicies
                    });
                    this.successMessage = messages.successMessage;
                    this.successfulPolicies = messages.successfulPolicies;
                    this.errorMessageBulkEdit = messages.errorMessage;
                    if (this.successfulPolicies === this.totalPolicies) {
                        this.resetGrid = true;
                        this.bulkEditForm.get('grid').setValue(this.utilService.gridReset());
                    } else {
                        this.resetGrid = true;
                        this.bulkEditForm.get('grid').setValue(this.utilService.setEmptyValuesForGrid(messages.failedPolicies));
                    }
                    this.scrollToMessage();
                },
                error: (error) => {
                    this.loggerService.info(
                        `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}-Start submit-Error- with bulkUploadService`,
                        {
                            UserId: sessionStorage.getItem('userName'),
                            error,
                            bulkGridValues,
                            mortgageCompany: this.bulkEditForm.get('bankName').value,
                            userCompanyName: sessionStorage.getItem('companyName')
                        }
                    );
                    this.errorMessageBulkEdit += BULK_EDIT.ERROR_MESSAGE.SYSTEM_DB_ERROR;
                    this.window.bulkRedirectWait.hide();
                    this.scrollToMessage();
                    this.window.bulkRedirectWait.hide();
                },
                complete: () => {
                    this.loggerService.info(
                        `${LOGGER_CONSTANTS.prefixApp} ${LOGGER_CONSTANTS.bulkEdit}-Start submit-Complete`,
                        {
                            UserId: sessionStorage.getItem('userName'),
                            bulkGridValues,
                            mortgageCompany: this.bulkEditForm.get('bankName').value,
                            userCompanyName: sessionStorage.getItem('companyName')
                        }
                    );
                    this.window.bulkRedirectWait.hide();
                }
            });
        } else {
            this.errorMessageBulkEdit = BULK_ERROR_MESSAGE_GROUP.getCommonErrorList(bulkGridSanitizedValues.duplicates.length > 0);
            this.window.bulkRedirectWait.hide();
            this.scrollToMessage();
        }
    }

    submitOk(): void {
        this.submitFormAfterValidation();
    }

    submitStatus(onSubmit: boolean, submitStatus: boolean): SubmitOptions {
        return {
            onSubmit,
            submitStatus
        };
    }
}
