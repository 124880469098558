<app-ping-iframe></app-ping-iframe>

<div>
    <h2 class="bolt-heading-md">Forgot Password?</h2>

    <div class="margin-bottom-xl">
        <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>
    </div>

    <div class="margin-bottom-xl">
        <app-username-form [submitOptions]="submitOptions" [parentForm]="usernameForm"></app-username-form>
    </div>
</div>

<app-password-button-bar [secondaryLabel]="'Cancel'" (secondaryEmit)="redirectToLegacy($event)" [primaryLabel]="'Submit'" (primaryEmit)="performValidation($event)">
</app-password-button-bar>

<app-wait-indicator></app-wait-indicator>