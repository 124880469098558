import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN_LOGOUT_ERRORS } from '../../../CONSTANTS/tpi-common-constants';
import { UserInfoResponse } from '../../../tpi-models/login-logout/user-info';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'tpi-login-logout-errors',
    templateUrl: './login-logout-errors.component.html',
    styleUrls: ['./login-logout-errors.component.scss']
})
export class LoginLogoutErrorsComponent implements OnInit {
    errorResponse: UserInfoResponse;
    errors = {
        SYSTEM_ERROR: false,
        MFA_LOCKED: false,
        USER_NOT_LOCATED: false,
        ACCOUNT_DISABLED: false
    };
    loggerService: any;

    // eslint-disable-next-line max-params
    constructor(private route: Router,
        private logger: LoggerService,
        private location: Location,
        private sessionService: SessionService) {
    }

    ngOnInit(): void {
        const state: any = this.location.getState();
        this.errorResponse = state.errorResponse;
        this.logger.info(`Retrieved error response from navigation`, {
            errorResponse: this.errorResponse
        });
        this.sessionService.isLoggedIn = false;
        sessionStorage.clear();
        this.sessionService.deleteAllCookies();
        this.errors.MFA_LOCKED = this.errorResponse?.systemMessage === LOGIN_LOGOUT_ERRORS.RESPONSE.MFA_LOCKED;
        this.errors.USER_NOT_LOCATED = this.errorResponse?.systemMessage === LOGIN_LOGOUT_ERRORS.RESPONSE.USER_NOT_LOCATED;
        this.errors.ACCOUNT_DISABLED = this.errorResponse?.systemMessage === LOGIN_LOGOUT_ERRORS.RESPONSE.ACCOUNT_DISABLED;
        if (!this.errors.MFA_LOCKED && !this.errors.USER_NOT_LOCATED && !this.errors.ACCOUNT_DISABLED) {
            this.errors.SYSTEM_ERROR = true;
        }
        this.logger.info(`Extracted errors from response`, {
            errors: this.errors
        });
        this.logger.logCurrentContents();
    }
}
