<div class="parent-grid-container">
    <bolt-contextual-help class="help-context-loan-number">
        Must be at least 2 characters, 20 characters max. Special characters are not allowed.
    </bolt-contextual-help>
    <bolt-contextual-help class="help-context-policy-number">
        Format example: 9234HR123456
    </bolt-contextual-help>
    <bolt-contextual-help class="help-context-zip-code">
        If the policy location and mailing address differ, switch the ZIP codes (If needed).
    </bolt-contextual-help>
    <div #spreadsheet></div>
</div>