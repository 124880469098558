<div class="companyContainer container">
    <div class="bolt-row" *ngIf="options">
        <div class="bolt-col-sm-8">
            <bolt-textfield [label]="options.label" [required]="options.required" ngDefaultControl iconname="search" (input)="filterEmit($event)" (blur)="logUnfocusedEvent()"></bolt-textfield>
        </div>
        <div class="bolt-col-sm-4 countSection" *ngIf="options.totalArray">
            <span>{{options.listCountLabel.replace("@x@", options.totalArray) || ''}}</span>
        </div>
    </div>

</div>