import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-interactive-requirements-icon',
    templateUrl: './interactive-requirements-icon.html',
    styleUrls: ['./interactive-requirements-icon.component.scss']
})

export class InteractiveRequirementsIconComponent {
    @Input() description: string;
    @Input() showCheckmark: boolean;
}
