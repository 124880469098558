<div *ngIf=showInteractiveRequirements class="bolt-row bolt-space-bottom-lg">
    <div class="bolt-col">
        <app-interactive-requirements-icon [showCheckmark]="!this.parentForm.get('newPassword')?.errors?.minLengthForPassword"
            [description]="'At least 12 characters'"></app-interactive-requirements-icon>
        <app-interactive-requirements-icon [showCheckmark]="!this.parentForm.get('newPassword')?.errors?.passwordLowercaseError"
            [description]="'At least one lowercase letter'"></app-interactive-requirements-icon>
        <app-interactive-requirements-icon [showCheckmark]="!this.parentForm.get('newPassword')?.errors?.passwordUppercaseError"
            [description]="'At least one uppercase letter'"></app-interactive-requirements-icon>
        <app-interactive-requirements-icon [showCheckmark]="!this.parentForm.get('newPassword')?.errors?.passwordNumberError" [description]="'At least one number'"></app-interactive-requirements-icon>
        <app-interactive-requirements-icon [showCheckmark]="!this.parentForm.get('newPassword')?.errors?.passwordSpecialCharacterError"
            [description]="'At least one of these special characters: ! @ # $ ~ : | = , _ + { } [ ]'">
        </app-interactive-requirements-icon>
        <app-interactive-requirements-icon [showCheckmark]="!this.parentForm.get('newPassword')?.errors?.passwordSpaceError" [description]="'No spaces'"></app-interactive-requirements-icon>
        <app-interactive-requirements-icon *ngIf="this.parentForm.get('currentPassword')" [showCheckmark]="!this.parentForm.get('newPassword')?.errors?.passwordUserNameFieldError"
            [description]="'No username'"></app-interactive-requirements-icon>
    </div>
</div>