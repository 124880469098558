import { Component, Input } from '@angular/core';
import { Vehicles } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/vehicles';

@Component({
    selector: 'app-vehicle-info',
    templateUrl: './vehicle-details.component.html',
    styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent {
    @Input() vehicles: Array<Vehicles>;
}
