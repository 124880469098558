import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { LOGGER_CONSTANTS } from '../../../CONSTANTS/tpi-common-constants';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { AuthorDetails, ModifyUserAccountInfo } from '../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';

interface Window {
    userManagementWaitIndicator: any;
}
@Component({
    selector: 'tpi-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html'
})
export class TermsAndConditionsComponent implements OnInit {
    authorDetails: AuthorDetails;
    errorMessage: string;
    hasError: boolean;

    // eslint-disable-next-line max-params
    constructor(private loggerService: LoggerService,
        private manageUserAccountsService: ManageUserAccountsService,
        private sessionService: SessionService,
        public utilsService: UtilsService,
        @Inject('window') private window: Window
    ) { }

    agree(): void {
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: agree: ${this.authorDetails.userName} begin agree to terms and conditions`, {
            authorDetails: this.authorDetails
        });
        this.resetResultsError();
        this.showWaitIndicator(true);
        const request: ModifyUserAccountInfo = {
            firstName: '',
            lastName: '',
            email: '',
            userId: this.authorDetails.userName,
            role: 0,
            userNumber: this.authorDetails.userId,
            companyNumber: 0
        };
        this.manageUserAccountsService.agreeToTermsAndConditions(request).subscribe({
            error: (error: HttpErrorResponse) => {
                this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: agree: Error when ${this.authorDetails.userName} tried to agree to the terms and services`, {
                    error,
                    request,
                    authorDetails: this.authorDetails
                });
                this.showResultsError();
                this.showWaitIndicator(false);
            },
            complete: () => {
                this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: agree: ${this.authorDetails.userName} successfully agreed to the terms and services`, {
                    authorDetails: this.authorDetails,
                    request
                });
                this.showWaitIndicator(false);
                this.sessionService.routeTo('policy-search');
            }
        });
        this.loggerService.logCurrentContents();
    }

    cancel(): void {
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: cancel: ${this.authorDetails.userName} selected cancel and rejected terms and conditions`);
        sessionStorage.clear();
        this.sessionService.deleteAllCookies();
        this.sessionService.redirectToLegacy();
    }

    ngOnInit(): void {
        this.authorDetails = this.sessionService.getAuthorDetails();
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.authorDetails?.userName} opened terms and conditions page`,
            {
                authorDetails: this.authorDetails
            });
    }

    resetResultsError(): void {
        this.hasError = false;
        this.errorMessage = '';
    }

    showResultsError(): void {
        this.hasError = true;
        this.errorMessage = tpiSearchError.UNABLE_PROCESS_ERROR;
        this.utilsService.scrollToTopOfPage();
    }

    showWaitIndicator(show: boolean): void {
        if (show) {
            this.window.userManagementWaitIndicator.show();
        } else {
            this.window.userManagementWaitIndicator.hide();
        }
    }
}
