import { Component, OnInit } from '@angular/core';
import { tpiSearchError } from '../../../../../CONSTANTS/tpi-search-errors';
import { SessionService } from '../../../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'app-account-locked-failure',
    templateUrl: './account-locked-failure.component.html'
})
export class AccountLockedFailureComponent implements OnInit {
    errorMessage = tpiSearchError.ACCOUNT_LOCKED_ERROR;

    constructor(
        private sessionService: SessionService
    ) { }

    ngOnInit(): void {
        this.sessionService.isLoggedIn = false;
        this.sessionService.deleteAllCookies();
        this.sessionService.resetForgotPasswordTokens();
    }
}
