import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/core-component-library-base';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MortgageBulkInformationRequest, MortgageBulkInformationResponse } from '../../tpi-models/general/mortgage-company';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root'
})
export class MortgageBulkUploadService {
    constructor(private http: HttpClient,
        private apiCommonService: APICommonService,
        private loggerService: LoggerService) { }

    doUpload(mortgageBulkRequest: MortgageBulkInformationRequest): Observable<MortgageBulkInformationResponse> {
        const httpOptions = this.getHttpHeaders();
        const url = `${environment.APIs.apigeeEnvironment}${environment.APIs.bulkUploadApi}`;
        return this.http.post<MortgageBulkInformationResponse>(url, mortgageBulkRequest, { headers: httpOptions });
    }

    getHttpHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('client_id', environment.authorize.client_id);
        headers = headers.append('X-NW-Message-ID', this.apiCommonService.generateTransactionId());
        headers = headers.append('X-NW-Session-ID', sessionStorage.getItem('sessionId'));
        headers = headers.set('Authorization', `Bearer ${sessionStorage.getItem('jwt')}`);
        return headers;
    }
}
