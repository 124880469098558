import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tpiManageUsers } from '../CONSTANTS/tpi-manage-users';
import { SessionService } from '../tpi-services/tpi-session/session.service';
import { UtilsService } from '../tpi-services/utility/utils-service.service';

@Injectable({
    providedIn: 'root'
})
export class OnlySuperAdminUserGuard {
    constructor(
        private sessionService: SessionService,
        private utilsService: UtilsService
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const authorDetails = this.sessionService.getAuthorDetails();
        const userRole = Number(authorDetails.role);
        if (userRole === tpiManageUsers.SUPER_USER_ACCOUNT.id || userRole === tpiManageUsers.ADMIN_ACCOUNT.id) {
            return true;
        }
        this.sessionService.routeTo(this.utilsService.getPagePathForUserRole(userRole));
        return false;
    }
}
