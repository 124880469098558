import { DatePipe } from '@angular/common';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    PolicyConsumerData,
    PolicyRequestsService,
    Vehicles
} from '@nationwide/dgs-internet-servicing-policy-requests';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { StartPolicyChangeResponse } from '../../tpi-models/general/policy-change-models';
import { LoggerService } from '../logger/logger.service';
import { UtilsService } from '../utility/utils-service.service';
import { LienHolder, LienHolderResponse } from './../../tpi-models/general/lien-holder';

@Injectable({
    providedIn: 'root'
})
export class EditLienholderService {
    Vehicles: Array<Vehicles>;
    policyChangeId: any;

    // eslint-disable-next-line max-params
    constructor(
        private policyRequest: PolicyRequestsService,
        private http: HttpClient,
        private datePipe: DatePipe,
        private loggerService: LoggerService,
        private utilsService: UtilsService
    ) { }
    editLeinHolder(
        policyNumber: string,
        lienholder: LienHolder,
        vehicle: Vehicles
    ): Observable<any> {
        const euaAccessToken = sessionStorage.getItem('jwt');
        const consumerData: PolicyConsumerData = {
            accessToken: euaAccessToken,
            camSessionId: '',
            policyNumber
        };
        this.policyRequest.clearCache();
        return this.policyRequest
            .startPolicyChange(
                consumerData,
                policyNumber,
                this.datePipe.transform(
                    lienholder.changeEffectiveDate,
                    'yyyy-MM-dd'
                )
            )
            .pipe(
                switchMap((startPolicyChangeResponse: StartPolicyChangeResponse) => {
                    if (
                        startPolicyChangeResponse instanceof HttpErrorResponse
                    ) {
                        this.loggerService.error(
                            'Error in StartPolicyChange API',
                            {
                                startPolicyChangeResponse
                            }
                        );
                        return throwError(() => startPolicyChangeResponse);
                    } else {
                        this.policyChangeId =
                            startPolicyChangeResponse.policyChangeId;
                        this.loggerService.info(
                            ' StartPolicyChange API completed',
                            {
                                startPolicyChangeResponse
                            }
                        );
                        return this.getLienholderDetails(vehicle, lienholder);
                    }
                }),

                switchMap((getLienholderResponse: LienHolderResponse) => {
                    if (getLienholderResponse instanceof HttpErrorResponse) {
                        this.loggerService.error(
                            'Error in getLienholderDetails API',
                            {
                                getLienholderResponse
                            }
                        );
                        return throwError(() => getLienholderResponse);
                    } else {
                        const config: any = {
                            consumerData,
                            policyChangeId: this.policyChangeId,
                            ratePolicyChangeData: getLienholderResponse
                        };
                        this.loggerService.info(
                            ' getLienholderDetails API completed successfully',
                            {
                                getLienholderResponse
                            }
                        );
                        return this.policyRequest.ratePolicyChange(config);
                    }
                }),

                switchMap((rateResponse) => {
                    if (rateResponse instanceof HttpErrorResponse) {
                        this.loggerService.error(
                            'Error in Rate Policy Change API',
                            {
                                rateResponse
                            }
                        );
                        return throwError(() => rateResponse);
                    } else {
                        this.loggerService.info(
                            'Rate Policy Change API completed successfully',
                            {
                                rateResponse
                            }
                        );
                        const issuePolicyChangeData = rateResponse;

                        return this.policyRequest
                            .issuePolicyChange({
                                consumerData,
                                issuePolicyChangeData,
                                policyNumber
                            })

                            .pipe(
                                switchMap((issuePolicyResponse) => {
                                    if (
                                        issuePolicyResponse instanceof
                                        HttpErrorResponse
                                    ) {
                                        this.loggerService.error(
                                            ' Error In Issue Policy Change API ',
                                            {
                                                issuePolicyResponse
                                            }
                                        );
                                        return throwError(() => issuePolicyResponse);
                                    } else {
                                        this.loggerService.info(
                                            'Issue Policy Change API completed successfully',
                                            {
                                                issuePolicyResponse
                                            }
                                        );
                                        const result = {
                                            error: false,
                                            issuePolicyResponse
                                        };

                                        return of(result);
                                    }
                                })
                            );
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    if (this.policyChangeId == null) {
                        this.loggerService.info('Delete policy change api starts, policy change id is null', { consumerData, policyChangeId: this.policyChangeId });
                        return of({ error: true });
                    } else {
                        this.loggerService.info('Delete policy change api starts..', { consumerData, policyChangeId: this.policyChangeId });
                        return this.policyRequest
                            .withdrawPolicyChange(consumerData, this.policyChangeId)
                            .pipe(
                                switchMap((response: any) => {
                                    const result = {
                                        error: true,
                                        response
                                    };
                                    this.loggerService.info(
                                        'Delete Policy Change API completed successfully',
                                        {
                                            error
                                        }
                                    );
                                    return of(result);
                                })
                            );
                    }
                }
                ));
    }

    getLienholderDetails(
        vehicle: Vehicles,
        lienholder: LienHolder
    ): Observable<LienHolderResponse | HttpErrorResponse> {
        const euaToken = sessionStorage.getItem('jwt');
        let httpOptions: HttpHeaders = this.utilsService.getHttpOptions(euaToken, 'X-NW-Message-ID');
        httpOptions = httpOptions.append(
            'X-NW-Target-Env',
            environment.policyCenterShortName
        );
        if (lienholder.loanOrLeaseDetails === commonConstants.AUTO.LOAN) {
            lienholder.additionalInterest.additionalInterestType = 'LIEN';
        } else {
            lienholder.additionalInterest.additionalInterestType = 'LESSOR';
        }
        if (vehicle.additionalInterests == null) {
            const url = `${environment.apigeeEnvironment}${environment.APIs.autoPoliciesServicing}${this.policyChangeId}/vehicles/${vehicle.vehicleId}/third-party-lien-holders`;
            lienholder.additionalInterest.expirationDate =
                this.datePipe.transform(
                    lienholder.additionalInterest.expirationDate,
                    'YYYY-MM-dd'
                );
            const body = {
                additionalInterest: lienholder.additionalInterest
            };
            return this.http.post<LienHolderResponse>(url, body, { headers: httpOptions });
        } else {
            const url = `${environment.apigeeEnvironment}${environment.APIs.autoPoliciesServicing}${this.policyChangeId}/vehicles/${vehicle.vehicleId}/third-party-lien-holders/${vehicle.additionalInterests[0].additionalInterestId}`;
            lienholder.additionalInterest.expirationDate =
                this.datePipe.transform(
                    lienholder.additionalInterest.expirationDate,
                    'YYYY-MM-dd'
                );
            const body = {
                additionalInterest: lienholder.additionalInterest
            };
            return this.http.put<LienHolderResponse>(url, body, { headers: httpOptions });
        }
    }
}
