<app-ping-iframe></app-ping-iframe>
<ng-container>
    <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>

    <h2 class="bolt-heading-md">Reset Password</h2>

    <app-password-form [submitOptions]="submitOptions" [disableCurrentPassword]="disableCurrentPassword" [parentForm]="temporaryPasswordForm"></app-password-form>

</ng-container>

<app-password-button-bar [secondaryLabel]="'Cancel'" (secondaryEmit)="goToLogin()" [primaryLabel]="'Save'" (primaryEmit)="performValidation($event)">
</app-password-button-bar>

<app-wait-indicator></app-wait-indicator>

<app-dmx-error-modal (clickOKButtonEmit)="goToLogin()" [showModal]="hasDMXError">
</app-dmx-error-modal>