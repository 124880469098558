import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { StoreSelectedCompanyFields } from '../../tpi-models/store/store-selected-company-fields.model';
import { AppState } from '../../tpi-states/app.state';

@Injectable({
    providedIn: 'root'
})
export class SelectedCompanyStateService {
    constructor(private storeSelectedCompanyFields: Store<AppState>) { }

    getSelectedCompanyFields(): Observable<StoreSelectedCompanyFields> {
        return this.storeSelectedCompanyFields.select((state) => state.storeSelectedCompanyFields);
    }

    updateSelectedCompanyFields(selectedCompanyFields: StoreSelectedCompanyFields): void {
        this.storeSelectedCompanyFields.dispatch({
            type: commonConstants.STORE.MODIFY_SELECTED_COMPANY,
            payload: selectedCompanyFields
        });
    }
}
