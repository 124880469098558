import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { CHANGE_PASSWORD_RETURN_CODE } from '../../../CONSTANTS/change-password-return-codes';
import { FORM_STATUS, LOGGER_CONSTANTS } from '../../../CONSTANTS/tpi-common-constants';
import { ERROR_MAP_COPYTEXTS, VALIDATION_REGEX } from '../../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { WaitIndicatorComponent } from '../../../shared/components/wait-indicator/wait-indicator.component';
import { ChangePasswordResponse } from '../../../tpi-models/user-credentials/change-password-response';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { PasswordService } from '../../../tpi-services/user-credential-services/password.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
import { PasswordFormComponent } from '../shared/password-form/password-form.component';

@Component({
    selector: 'tpi-temp-password',
    templateUrl: './temp-password.component.html',
    styleUrls: ['./temp-password.component.scss']
})
export class TemporaryPasswordComponent {
    @ViewChild(PasswordFormComponent) temporaryPasswordFormComponent: PasswordFormComponent;
    @ViewChild(WaitIndicatorComponent) waitIndicator;
    cacheKey: string;
    disableCurrentPassword = true;
    errorMessage: string;
    hasDMXError = false;
    hasError: boolean;
    showSuccessMessage: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };
    successMessage: string;
    temporaryPasswordForm: FormGroup;

    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        private passwordService: PasswordService,
        private loggerService: LoggerService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public formHelper: RxFormHelperService,
        public utilsService: UtilsService
    ) {
        this.cacheKey = this.activatedRoute.snapshot.params.token;
    }

    buildForm(): void {
        this.temporaryPasswordForm = this.formHelper.build({
            newPassword: [
                ''
            ],
            confirmNewPassword: [
                ''
            ]
        });

        this.resetForm();
    }

    decipherAndDisplayPasswordChangeError(changePasswordErrorResponse: ChangePasswordResponse): void {
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: decipherAndDisplayPasswordChangeError: change password error `, changePasswordErrorResponse);
        const returnCode = changePasswordErrorResponse?.operationResponse?.returnCode;
        let errorMessage;
        if ([CHANGE_PASSWORD_RETURN_CODE.SECURITY_REQUIREMENTS].includes(returnCode)) {
            errorMessage = tpiSearchError.PASSWORD_WEAK_ERROR;
            this.setRepeatCharacterFormControlFieldError();
        } else if ([CHANGE_PASSWORD_RETURN_CODE.EXISTING_PASSWORD, CHANGE_PASSWORD_RETURN_CODE.PASSWORD_REUSED].includes(returnCode)) {
            errorMessage = tpiSearchError.PASSWORD_MATCH_PREVIOUS_ERROR;
            this.setPreviousUsedPasswordFormControlFieldError();
        } else {
            errorMessage = tpiSearchError.UNABLE_CHANGE_PASSWORD_ERROR;
        }
        let logErrorMessage = errorMessage;
        if (errorMessage) {
            this.showResultsError(errorMessage);
        } else {
            logErrorMessage = ERROR_MAP_COPYTEXTS.passwordCurrentPasswordFieldError;
        }
        this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: decipherAndDisplayPasswordChangeError: change password error; message: ${logErrorMessage}`, { errorMessage: logErrorMessage });
    }

    goToLogin(): void {
        this.sessionService.redirectToLegacy();
    }

    ngAfterViewInit(): void {
        this.temporaryPasswordFormComponent.setPasswordValidation('');
    }

    ngOnInit(): void {
        this.sessionService.isLoggedIn = false;
        this.sessionService.deleteAllCookies();
        this.setVariables();
        this.buildForm();
    }

    performValidation($event?): void {
        if ($event) {
            this.submitOptions = {
                submitStatus: true,
                onSubmit: true
            };

            this.showSuccessMessage = false;
            this.hasError = false;

            this.showResults();
        }
    }

    resetForm(): void {
        this.temporaryPasswordForm.get('newPassword').setValue('');
        this.temporaryPasswordForm.get('confirmNewPassword').setValue('');
        this.hasError = false;
        this.showSuccessMessage = false;
    }

    setPreviousUsedPasswordFormControlFieldError(): void {
        const newPasswordFormController = this.temporaryPasswordForm.get('newPassword');
        const confirmNewPasswordFormController = this.temporaryPasswordForm.get('confirmNewPassword');
        newPasswordFormController.setErrors({ passwordPreviousUsedPasswordFieldError: true });
        confirmNewPasswordFormController.setErrors({ passwordPreviousUsedPasswordFieldError: true });
    }

    setRepeatCharacterFormControlFieldError(): void {
        const newPasswordFormController = this.temporaryPasswordForm.get('newPassword');
        const confirmNewPasswordFormController = this.temporaryPasswordForm.get('confirmNewPassword');
        newPasswordFormController.setErrors({ passwordWeakFieldError: true });
        confirmNewPasswordFormController.setErrors({ passwordWeakFieldError: true });
    }

    setVariables(): void {
        this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
    }

    showResults(): void {
        if (!this.temporaryPasswordFormComponent.newPasswordsMatch()) {
            const passwordMatchError = tpiSearchError.NEW_PASSWORD_NOT_MATCH_ERROR;
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: showResults: new and confirm passwords mismatch; message: ${passwordMatchError}`, { errorMessage: passwordMatchError });
            this.showResultsError(passwordMatchError);
        } else if (!VALIDATION_REGEX.isAlphaNumericWithoutSpaces.test(this.cacheKey)) {
            this.showSystemError(`Invalid cache key format ${this.cacheKey}`);
        } else if (this.temporaryPasswordForm.status === FORM_STATUS.VALID) {
            this.waitIndicator.openWaitIndicator();
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: showResults: start password reset`);
            const updatePasswordRequest = {
                newPassword: this.temporaryPasswordForm.get('newPassword').value,
                token: this.cacheKey
            };
            this.passwordService.doChangePassword(updatePasswordRequest).subscribe({
                next: (successResponse: ChangePasswordResponse) => {
                    this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: showResults: changed password successfully `, successResponse);
                    this.waitIndicator.hideWaitIndicator();
                    if (successResponse?.operationResponse.returnCode === CHANGE_PASSWORD_RETURN_CODE.DMX_DB_ERROR) {
                        this.hasDMXError = true;
                        this.sessionService.isLoggedIn = false;
                        this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: showResults(): changed temp-password successfully; DMX Database failure`, successResponse);
                        this.loggerService.logCurrentContents();
                    } else {
                        this.router.navigate(['new-user-password-change', this.cacheKey, 'success']).then(() => {
                            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: showResults(): changed temp-password successfully; redirecting to new-user-password-change/${this.cacheKey}/success`, successResponse);
                            this.loggerService.logCurrentContents();
                        }).catch((error) => {
                            this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}:showResults(): changed temp-password successfully; failed to redirect[new-user-password-change/${this.cacheKey}/success] on successful password update`,
                                {
                                    IAMResponse: successResponse,
                                    redirectError: error
                                });
                            this.loggerService.logCurrentContents();
                        });
                    }
                },
                error: (error: any) => {
                    this.waitIndicator.hideWaitIndicator();
                    if (error?.operationResponse) {
                        this.decipherAndDisplayPasswordChangeError(error);
                    } else {
                        this.showSystemError(error);
                    }
                    this.loggerService.logCurrentContents();
                }
            });
        }
        this.loggerService.logCurrentContents();
        this.utilsService.scrollToTopOfPage();
    }

    showResultsError(errorMessage: string): void {
        this.hasError = true;
        this.showSuccessMessage = false;
        this.errorMessage = errorMessage;
    }

    showSystemError(error: any): void {
        const displayErrorMessage = tpiSearchError.UNABLE_CHANGE_PASSWORD_ERROR;
        this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: showResults: change password Error; System error; message: ${displayErrorMessage}`, { errorMessage: displayErrorMessage, error });
        this.showResultsError(displayErrorMessage);
    }
}
