import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EcifService } from '../../service/ecif.service';
import { BillingAccountBalances } from '../../tpi-models/general/billing-account-balances';
import { BillingAccountBills } from '../../tpi-models/general/billing-account-bills';
import { BillingAccountRelatedAccounts } from '../../tpi-models/general/billing-account-related-accounts';
import { BillingAccountRelatedPolicies } from '../../tpi-models/general/billing-account-related-policies';
import { LoggerService } from '../logger/logger.service';
import { UtilsService } from '../utility/utils-service.service';

@Injectable({
    providedIn: 'root'
})
export class BillingService {
    // eslint-disable-next-line max-params
    constructor(
        private ecifService: EcifService,
        private loggerService: LoggerService,
        private http: HttpClient,
        private utilsService: UtilsService
    ) { }

    getBillingAcountBalances(
        accountNumber: string
    ): Observable<BillingAccountBalances> {
        if (accountNumber == null) {
            this.loggerService.error('Billing account number is null');
            return throwError(() => new Error('Null Bill Account Number'));
        }
        const httpOptions = this.getHttpOptions(sessionStorage.getItem('jwt'));
        const url = `${environment.PERSONAL_LINES_BILLING_API_HOST}${environment.PERSONAL_BILLING_ROOT}/billing-accounts/${accountNumber}/balances`;
        return this.http.get<BillingAccountBalances>(url, {
            headers: httpOptions
        });
    }

    getBillingAcountBills(accountNumber: string): Observable<Array<BillingAccountBills>> {
        if (accountNumber == null) {
            this.loggerService.error('Billing account number is null');
            return throwError(() => new Error('Null Bill Account Number'));
        }
        const httpOptions = this.getHttpOptions(sessionStorage.getItem('jwt'));
        const url = `${environment.PERSONAL_LINES_BILLING_API_HOST}${environment.PERSONAL_BILLING_ROOT}/billing-accounts/${accountNumber}/bills`;
        return this.http.get<Array<BillingAccountBills>>(url, {
            headers: httpOptions
        });
    }

    getBillingAcountRelatedPolicies(
        accountNumber: string
    ): Observable<BillingAccountRelatedPolicies> {
        if (accountNumber == null) {
            this.loggerService.error('Billing account number is null');
            return throwError(() => new Error('Null Bill Account Number'));
        }
        const httpOptions = this.getHttpOptions(sessionStorage.getItem('jwt'));
        const url = `${environment.PERSONAL_LINES_BILLING_API_HOST}${environment.PERSONAL_BILLING_ROOT}/billing-accounts/${accountNumber}/related-policies`;
        return this.http.get<BillingAccountRelatedPolicies>(url, {
            headers: httpOptions
        });
    }

    getBillingInformations(ecn: string): Observable<any> {
        const euaToken = sessionStorage.getItem('jwt');
        return this.ecifService.getEcifCustomerwithECN(ecn, euaToken).pipe(
            switchMap((response) => {
                const billingAccountNumber = this.getBillingNumber(response);
                if (billingAccountNumber) {
                    const billingcalls = [this.getBillingAcountBills(billingAccountNumber)];
                    return forkJoin(billingcalls);
                }
                return of(null);
            }),
            catchError((response) => of(response))
        );
    }

    getBillingNumber(agreementResponse: any): string {
        let accountNumber = null;
        try {
            const billingResponse = agreementResponse.customerRoles.filter(
                (response) =>
                    response.agreement.lineOfBusiness === 'Billing Account'
            );
            if (billingResponse.length > 0) {
                accountNumber = billingResponse[0].agreement.agreementReference.referenceNumber;
            }
        } catch (error) {
            return accountNumber;
        }
        return accountNumber;
    }

    getBillingNumberFromRelatedAccountsResponse(response: BillingAccountRelatedAccounts): string {
        let accountNumber: string;
        let activeAccountNumberNotFound = true;
        try {
            for (const data of response.billingAccountDetails) {
                this.loggerService.info(`Billing Related Accounts: ${data?.billingAccountNumber} :  ${data?.policyAccountRelationshipStatus}`);
                if (activeAccountNumberNotFound) {
                    accountNumber = data?.billingAccountNumber;
                    if (data?.policyAccountRelationshipStatus === 'Active') {
                        activeAccountNumberNotFound = false;
                    }
                }
            }
        } catch (error) {
            return null;
        }
        return accountNumber;
    }

    getBillingRelatedAccounts(policyNumber: string): Observable<BillingAccountRelatedAccounts> {
        if (policyNumber == null) {
            this.loggerService.error('Policy Number is null');
            return throwError(() => new Error('Null Policy Number'));
        }
        const httpOptions = this.getHttpOptions(sessionStorage.getItem('jwt'));
        const url = `${environment.PERSONAL_LINES_BILLING_API_HOST}${environment.PERSONAL_BILLING_ROOT}/billing-accounts/${policyNumber}/related-accounts?policyAccountRelationship=all`;
        return this.http.get<BillingAccountRelatedAccounts>(url, { headers: httpOptions });
    }

    getHttpOptions(euaToken: string): HttpHeaders {
        return this.utilsService.getHttpOptions(euaToken, 'X-NW-Message-ID');
    }
}
