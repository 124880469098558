import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { LOGGER_CONSTANTS } from '../../../../../CONSTANTS/tpi-common-constants';
import { ModifyUserActions, tpiManageUsers } from '../../../../../CONSTANTS/tpi-manage-users';
import { CompanyInfo } from '../../../../../tpi-models/general/company-info';
import { ManageUserAccountsAccountInfo } from '../../../../../tpi-models/manage-accounts-company-web/manage-user-accounts-account-info';
import { AuthorDetails, ModifyUserAccountInfo } from '../../../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { ModifyUserInput } from '../../../../../tpi-models/manage-accounts-company-web/modify-user-input';
import { ModifyUserRequest } from '../../../../../tpi-models/manage-accounts-company-web/modify-user-request';
import { LoggerService } from '../../../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../../../tpi-services/tpi-session/session.service';
import { UtilsService } from '../../../../../tpi-services/utility/utils-service.service';
import { SuccessModalComponent } from '../../../shared/success-modal/success-modal.component';

interface Window {
    userManagementWaitIndicator: any;
}
@Component({
    selector: 'tpi-delete-reset-unlock-user',
    templateUrl: './delete-reset-unlock-user.component.html'
})
export class DeleteResetUnlockUserComponent {
    @Input() action: ModifyUserActions;
    @Input() company: CompanyInfo;
    @Output() errorMessageEmit: EventEmitter<string> = new EventEmitter<string>();
    @Input() modifyUserInput: ModifyUserInput;
    @Output() resetResultMessagesEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() resetTableSelectionEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() retrieveUsersByCompanyEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() selectedTableEntries: Array<ManageUserAccountsAccountInfo>;
    @Output() successMessageEmit: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild(SuccessModalComponent) successModalComponent;

    authorDetails: AuthorDetails;
    showModal: boolean;

    // eslint-disable-next-line max-params
    constructor(private loggerService: LoggerService,
        private sessionService: SessionService,
        private manageUserAccountsService: ManageUserAccountsService,
        public utilsService: UtilsService,
        @Inject('window') private window: Window
    ) { }

    closeModal($event): void {
        if ($event) {
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: closeModal: ${this.authorDetails.userName} closed ${this.action} modal`, {
                authorDetails: this.authorDetails,
                request: this.getModifyUserRequest()
            });
            this.loggerService.logCurrentContents();
            this.showModal = false;
        }
    }

    decipherErrorMessage(errorResponse: any, request: ModifyUserRequest): void {
        if (errorResponse instanceof Array) {
            const usersFailed: Array<ModifyUserAccountInfo> = this.utilsService.getUsersFailedFromResponse(errorResponse);
            const failCount = usersFailed.length;
            const successCount = request?.users?.length === undefined || request?.users?.length === 0 ? 0 : request?.users?.length - failCount;

            if (usersFailed != null && usersFailed.length > 0) {
                const usersSuccess: Array<ModifyUserAccountInfo> = this.utilsService.getUsersSucceed(usersFailed, request);
                if (successCount === 0) {
                    this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: decipherErrorMessage: ${this.authorDetails.userName} failed to ${this.action} ${failCount} user(s) from the company`, {
                        error: errorResponse,
                        authorDetails: this.authorDetails,
                        request,
                        failCount,
                        usersFailed,
                        usersSuccess
                    });
                } else {
                    this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: decipherErrorMessage: ${this.authorDetails.userName} successfully ${this.action} ${successCount} user(s) from the company. ${this.authorDetails.userName} failed to ${this.action} ${usersFailed.length} user(s)`, {
                        error: errorResponse,
                        authorDetails: this.authorDetails,
                        companyName: this.company.companyDescription,
                        request,
                        failCount,
                        successCount,
                        usersFailed,
                        usersSuccess
                    });
                    this.successMessageEmit.emit(this.modifyUserInput.partialSuccessMessage);
                }
                this.emitError(this.utilsService.getUserIdsFromModifyUserAccountInfo(usersFailed));
            }
        } else { // System Error
            const usersFailed = request.users;
            this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: decipherErrorMessage: System error when ${this.authorDetails.userName} tried to ${this.action} ${usersFailed.length} user(s) from the company`, {
                error: errorResponse,
                authorDetails: this.authorDetails,
                request,
                failCount: usersFailed.length
            });
            this.emitError(this.utilsService.getUserIdsFromModifyUserAccountInfo(usersFailed));
        }
    }

    emitError(usersFailed: Array<string>): void {
        this.closeModal(true);
        if (usersFailed.length > tpiManageUsers.LIMIT_USERS_LIST) {
            this.errorMessageEmit.emit(this.modifyUserInput.errorMessageExceedLimit);
        } else {
            const usersList = this.utilsService.getHtmlList(usersFailed);
            this.errorMessageEmit.emit(`${this.modifyUserInput.errorMessage}${usersList}`);
        }
    }

    emitUpdate(): void {
        this.window.userManagementWaitIndicator.hide();

        // Only update the table on delete
        if (this.action === ModifyUserActions.Delete) {
            this.retrieveUsersByCompanyEmit.emit(true);
        }
        this.resetTableSelectionEmit.emit(true);
    }

    getModifyUserRequest(): ModifyUserRequest {
        return {
            action: this.action,
            userId: this.authorDetails.userName,
            users: this.utilsService.mapManageUserAccountInfo(this.selectedTableEntries)
        };
    }

    ngOnInit(): void {
        this.authorDetails = this.sessionService.getAuthorDetails();
    }

    openModal(action: ModifyUserActions, $event): void {
        if ($event) {
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: openModal: ${this.authorDetails.userName} opened ${action} modal`, {
                authorDetails: this.authorDetails,
                action,
                request: this.getModifyUserRequest()
            });
            this.showModal = true;
            this.resetResultMessagesEmit.emit(true);
        }
    }

    openSuccessModal($event): void {
        if ($event) {
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: openSuccessModal: ${this.authorDetails.userName} viewed ${this.action} success modal`, {
                authorDetails: this.authorDetails,
                request: this.getModifyUserRequest()
            });
            this.emitUpdate();
            this.closeModal(true);
            this.successModalComponent.openModal(true);
        }
    }

    tryModifyUser($event): void {
        if ($event) {
            this.window.userManagementWaitIndicator.show();
            const request: ModifyUserRequest = this.getModifyUserRequest();
            const usersCount = request.users.length;
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: tryModifyUser: ${this.authorDetails.userName} submitted ${this.action} modal for ${usersCount} user(s)`, {
                authorDetails: this.authorDetails,
                action: this.action,
                request,
                usersCount
            });

            this.manageUserAccountsService.modifyUsers(request).subscribe({
                error: (error: HttpErrorResponse) => {
                    this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: tryModifyUser: Error when ${this.authorDetails.userName} tried to ${request.action} user(s)`, {
                        error,
                        request
                    });
                    this.decipherErrorMessage(error?.error, request);
                    this.emitUpdate();
                },
                complete: () => {
                    this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: tryModifyUser: ${this.authorDetails.userName} successfully ${this.action} ${usersCount} user(s) from the company`, {
                        authorDetails: this.authorDetails,
                        companyName: this.company.companyDescription,
                        action: this.action,
                        request,
                        successCount: usersCount,
                        usersSuccess: request.users
                    });
                    this.openSuccessModal(true);
                }
            });
        }
    }
}
