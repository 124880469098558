import { commonConstants } from '../CONSTANTS/tpi-common-constants';
import { StoreUserFields } from '../tpi-models/store/store-user-fields.model';

export function updateUserFieldsReducer(state: StoreUserFields, action): StoreUserFields {
    if (action.type === commonConstants.STORE.MODIFY_USERFIELDS) {
        return action.payload;
    } else {
        return state;
    }
}
