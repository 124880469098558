<app-ping-iframe></app-ping-iframe>
<div>
    <div class="margin-bottom" *ngIf="!isError">
        <bolt-notification type="success">{{message}}</bolt-notification>
    </div>
    <div class="margin-bottom" *ngIf="isError">
        <bolt-notification type="error">{{message}}</bolt-notification>
    </div>
    <bolt-button-bar>
        <bolt-button slot="forward" type="primary" (click)="login()">Log in</bolt-button>
    </bolt-button-bar>
</div>