import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { BulkButtonBarComponent } from './bulk-button-bar/bulk-button-bar.component';
import { MortgageBulkUpdateComponent } from './mortgage-bulk-update.component';
import { MortgageSearchComponent } from './mortgage-search/mortgage-search.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule, BrowserModule, ReactiveFormsModule, FormsModule, SharedComponentsModule, PipesModule],
    exports: [BulkButtonBarComponent, MortgageSearchComponent, ReactiveFormsModule, FormsModule],
    declarations: [
        MortgageBulkUpdateComponent,
        BulkButtonBarComponent,
        MortgageSearchComponent
    ],
    providers: [],
    bootstrap: [MortgageBulkUpdateComponent]
})
export class MortgageBulkUpdateModule { }
