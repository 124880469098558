import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModifyModalInput } from '../../../../tpi-models/manage-accounts-company-web/modify-modal-input';

@Component({
    selector: 'tpi-confirmation-modal',
    templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {
    @Input() modifyModalInput: ModifyModalInput;
    @Output() onPrimaryEvent = new EventEmitter<any>();
    @Output() onSecondaryEvent = new EventEmitter<any>();
    @Input() showModal: boolean;

    onPrimaryButtonClick(): void {
        this.onPrimaryEvent.emit();
    }

    onTertiaryButtonClick(): void {
        this.onSecondaryEvent.emit();
    }
}
