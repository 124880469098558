export const CHANGE_PASSWORD_RETURN_CODE = {
    SUCCESS: '0000', // Successful
    DMX_DB_ERROR: 'DMX', // DMX Database; Password Update Successful
    ACCOUNT_NOT_FOUND: '5100', // User account not found
    INVALID_USERNAME_PASSWORD: '5200', // Invalid username or password
    ACCOUNT_LOCKED: '5320', // Account is locked. User exceeded number of preset failure attempts
    INVALID_FIELDS: '5400', // One or more fields are invalid : <field-name>
    SECURITY_REQUIREMENTS: '5430', // Password does not match security requirements
    EXISTING_PASSWORD: '5431', // New password can not be same as existing password
    PASSWORD_REUSED: '5432' // Password is being reused
};

export const EXPIRED_PASSWORD_RETURN_CODE = {
    SUCCESS: 'SUCCESS',
    DMX_DB_ERROR: 'DMX_FAILURE',
    REUSE_PASSWORD: 'REUSE_PASSWORD',
    PASSWORD_TOO_NEW: 'PASSWORD_TOO_NEW',
    PASSWORD_NOT_COMPLIANT: 'PASSWORD_NOT_COMPLIANT',
    WRONG_PASSWORD: 'WRONG_PASSWORD',
    DMX_DB_FAILURE: 'DMX_DB_FAILURE',
    EMAIL_RESOLUTION_FAILURE: 'EMAIL_RESOLUTION_FAILURE'
};
