import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { APICommonService } from '@nationwide/core-component-library-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LANDING_PAGES } from '../../CONSTANTS/tpi-common-constants';
import { UserInfoRequest } from '../../tpi-models/login-logout/user-info';
import { SessionService } from '../tpi-session/session.service';

@Injectable({
    providedIn: 'root'
})
export class LoginLogoutService {
    // eslint-disable-next-line max-params
    constructor(
        private httpClient: HttpClient,
        private apiCommonService: APICommonService,
        private ngZone: NgZone,
        private router: Router,
        private sessionService: SessionService,
        private oidcSecurityService: OidcSecurityService) { }

    getHttpOptions(euaToken: string): HttpHeaders {
        return this.apiCommonService.buildHeader({
            appClientId: environment.authorize.client_id,
            authToken: euaToken,
            nwAppId: '',
            contentType: 'application/json',
            headerType: 'X-NW-MessageID'
        });
    }

    getUserInfo(userInfoRequest: UserInfoRequest): Observable<any> {
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.getHttpOptions(euaToken);
        return this.httpClient.post(`${environment.APIs.apigeeEnvironment}${environment.APIs.userInfo}?showDetails=true`, userInfoRequest, { headers });
    }

    login(): void {
        this.oidcSecurityService.authorize();
    }

    logout(): void {
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => {
            this.oidcSecurityService.revokeAccessToken();
            this.sessionService.isLoggedIn = false;
            this.ngZone.run(() => {
                this.router.navigate([LANDING_PAGES.LOGOUT]);
            });
        });
    }
}
