import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { commonConstants, SESSION_KEYS } from '../../../../CONSTANTS/tpi-common-constants';
import { tpiSearchError } from '../../../../CONSTANTS/tpi-search-errors';
import { WaitIndicatorComponent } from '../../../../shared/components/wait-indicator/wait-indicator.component';
import { StoreUserFields } from '../../../../tpi-models/store/store-user-fields.model';
import { ForgotPasswordRequestMFARequest } from '../../../../tpi-models/user-credentials/forgot-password-request-mfa-request';
import { ForgotPasswordRequestMFAResponse } from '../../../../tpi-models/user-credentials/forgot-password-request-mfa-response';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';
import { UserFieldsStateService } from '../../../../tpi-services/tpi-statemanagement/user-fields-state.service';
import { PasswordService } from '../../../../tpi-services/user-credential-services/password.service';
import { UtilsService } from '../../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'app-email-confirmation',
    templateUrl: './email-confirmation.component.html'
})
export class EmailConfirmationComponent implements OnInit {
    @ViewChild(WaitIndicatorComponent) waitIndicator;

    errorMessage: string;
    hasError: boolean;
    userFields: StoreUserFields;

    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        private loggerService: LoggerService,
        private route: Router,
        public formHelper: RxFormHelperService,
        private passwordService: PasswordService,
        public utilsService: UtilsService,
        private storeUserFieldsService: UserFieldsStateService
    ) { }

    ngOnInit(): void {
        this.sessionService.deleteAllCookies();
        this.storeUserFieldsService.getUserFields().subscribe((searchFields: StoreUserFields) => {
            this.userFields = searchFields;
        });
    }

    redirectToLegacy(): void {
        this.waitIndicator.hideWaitIndicator();
        this.utilsService.scrollToTopOfPage();
        this.loggerService.info('redirectToLegacy(): clicked cancel on email confirmation page');
        this.loggerService.logCurrentContents();
        this.sessionService.redirectToLegacy();
    }

    redirectToMFAChallengePage(): void {
        this.waitIndicator.hideWaitIndicator();
        this.utilsService.scrollToTopOfPage();
        this.sessionService.confirmedEmailAddress = true;
        this.route.navigate(['forgot-password-challenge']).then(() => {
            this.loggerService.info('redirectToMFAChallengePage(): confirmed email; redirecting to /forgot-password-challenge');
            this.loggerService.logCurrentContents();
        }).catch((error) => {
            this.loggerService.error('redirectToMFAChallengePage(): confirmed email; failed to redirect/forgot-password-challenge',
                {
                    redirectError: error
                });
            this.loggerService.logCurrentContents();
        });
    }

    requestMFAChallenge(): void {
        this.waitIndicator.openWaitIndicator();
        const mfaRequest: ForgotPasswordRequestMFARequest = {
            user: this.userFields.username,
            email: this.userFields.emailAddress,
            device: {
                channel: commonConstants.WEB,
                deviceToken: commonConstants.PLACEHOLDER,
                transactionId: sessionStorage.getItem(SESSION_KEYS.transactionId)
            }
        };

        this.passwordService.doRequestMFAChallenge(mfaRequest).subscribe(
            {
                next: (forgotPasswordRequestMFAResponse: ForgotPasswordRequestMFAResponse) => {
                    if (forgotPasswordRequestMFAResponse.result.toLowerCase() === commonConstants.RESPONSE_STATUS.SUCCESS.toLowerCase()) {
                        this.loggerService.info('requestMFAChallenge: successful request mfa challenge for forgot password', forgotPasswordRequestMFAResponse);
                        sessionStorage.setItem(SESSION_KEYS.sessionId, forgotPasswordRequestMFAResponse.sessionId);
                        this.redirectToMFAChallengePage();
                    } else {
                        this.loggerService.error('requestMFAChallenge: failed request mfa challenge for forgot password', forgotPasswordRequestMFAResponse);
                        this.waitIndicator.hideWaitIndicator();
                        this.showResultsError(tpiSearchError.SEND_MFA_ERROR);
                    }
                },

                error: (errorResponse) => {
                    this.loggerService.error('requestMFAChallenge: error request mfa challenge for forgot password', errorResponse);
                    this.waitIndicator.hideWaitIndicator();
                    this.showResultsError(tpiSearchError.SEND_MFA_ERROR);
                }
            }
        );
    }

    showResultsError(errorMessage: string): void {
        this.loggerService.info(`showResultsError(): message: ${errorMessage}`);
        this.hasError = true;
        this.errorMessage = errorMessage;
        this.loggerService.logCurrentContents();
    }
}
