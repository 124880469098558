import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/core-component-library-base';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import {
    AwdWorkflowApiRequest,
    AwdWorkflowServiceRequest,
    CreateInstance,
    FieldValue,
    Instance
} from '../../tpi-models/general/awd-workflow-service-request';

interface PolicyNumberSection {
    REGN: number;
    STTE: number;
    PRFX: number;
    AUTO_PNUM: number;
    PROPERTY_PNUM: number;
}
@Injectable({
    providedIn: 'root'
})
export class AwdWorkflowService {
    constructor(private http: HttpClient, private apiCommonService: APICommonService) { }

    createAwdWorkflowApiRequest(request: AwdWorkflowServiceRequest): AwdWorkflowApiRequest {
        let fieldValueList: Array<FieldValue> = [];
        if (request?.policyType === commonConstants.PAGES.AUTO) {
            fieldValueList = this.mapAutoFieldValueList(fieldValueList, request);
        } else {
            fieldValueList = this.mapPropertyFieldValueList(fieldValueList, request);
        }

        const policyNumber = request?.policyNumber?.trim().replace(/\s+/g, '');
        const instance: Instance = {};
        instance.statusName = 'CREATED';

        if (request?.policyType === commonConstants.PAGES.AUTO) {
            instance.businessAreaName = 'AUTO';
            instance.typeName = 'APTHIRDP';
        } else {
            instance.businessAreaName = 'PROPERTY';
            instance.typeName = 'PP3PTYCHG';
        }
        fieldValueList = this.mapPolicyNumberFields(fieldValueList, policyNumber, instance.businessAreaName);
        instance.fieldValues = {
            fieldValue: fieldValueList
        };

        const createInstance: CreateInstance = {
            createInstance: instance
        };

        const createList: Array<CreateInstance> = [];
        createList.push(createInstance);
        const awdWorkflowApiRequest: AwdWorkflowApiRequest = {};
        awdWorkflowApiRequest.createList = createList;
        return awdWorkflowApiRequest;
    }

    getAwdWorkflowApiUrl(): string {
        return `${environment.awdWorkflow.endpoint}`;
    }

    getHttpHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('X-NW-Message-ID', this.apiCommonService.generateTransactionId());
        headers = headers.set('Authorization', `Bearer ${sessionStorage.getItem('jwt')}`);
        if (environment.awdWorkflow.awdWorkflowTargetHost) {
            headers = headers.append('X-Target-Host', environment.awdWorkflow.awdWorkflowTargetHost);
        }
        return headers;
    }

    mapAWDAutoAddressFields(fieldValueList: Array<FieldValue>, request: AwdWorkflowServiceRequest): Array<FieldValue> {
        if (request?.lienholderDetails?.address?.addressLine1) {
            fieldValueList.push(this.populateAWDFieldValues('COM3', request?.lienholderDetails?.address?.addressLine1));
        }
        if (request?.lienholderDetails?.address?.addressLine2) {
            fieldValueList.push(this.populateAWDFieldValues('COM4', request?.lienholderDetails?.address?.addressLine2));
        }
        if (request?.lienholderDetails?.address?.city) {
            fieldValueList.push(this.populateAWDFieldValues('COM5', request?.lienholderDetails?.address?.city));
        }
        if (request?.lienholderDetails?.address?.state) {
            fieldValueList.push(this.populateAWDFieldValues('COM6', request?.lienholderDetails?.address?.state));
        }
        if (request?.lienholderDetails?.address?.postalCode) {
            fieldValueList.push(this.populateAWDFieldValues('COM7', request?.lienholderDetails?.address?.postalCode));
        }
        return fieldValueList;
    }

    mapAWDPropertyAddressFields(fieldValueList: Array<FieldValue>, request: AwdWorkflowServiceRequest): Array<FieldValue> {
        if (request?.mortgageCompany?.address?.addressLine1) {
            fieldValueList.push(this.populateAWDFieldValues('CM03', request?.mortgageCompany?.address?.addressLine1));
        }
        if (request?.mortgageCompany?.address?.city) {
            fieldValueList.push(this.populateAWDFieldValues('CM04', request?.mortgageCompany?.address?.city));
        }
        if (request?.mortgageCompany?.address?.postalCode) {
            fieldValueList.push(this.populateAWDFieldValues('CM05', request?.mortgageCompany?.address?.postalCode));
        }
        if (request?.mortgageCompany?.address?.state) {
            fieldValueList.push(this.populateAWDFieldValues('CM06', request?.mortgageCompany?.address?.state));
        }
        return fieldValueList;
    }

    mapAutoFieldValueList(fieldValueList: Array<FieldValue>, request: AwdWorkflowServiceRequest): Array<FieldValue> {
        if (request?.vin) {
            fieldValueList.push(this.populateAWDFieldValues('VIN', request?.vin));
        }
        if (request?.lienholderDetails?.companyName) {
            fieldValueList.push(this.populateAWDFieldValues('COMM', request?.lienholderDetails?.companyName));
        }
        if (request?.changeEffectiveDate) {
            fieldValueList.push(this.populateAWDFieldValues('EFFD', request?.changeEffectiveDate));
        }
        if (request?.lienholderDetails?.expirationDate) {
            fieldValueList.push(this.populateAWDFieldValues('EFDT', request?.lienholderDetails?.expirationDate));
        }
        if (request?.loanOrLeaseDetails) {
            fieldValueList.push(this.populateAWDFieldValues('COM2', request?.loanOrLeaseDetails));
        }
        fieldValueList = this.mapAWDAutoAddressFields(fieldValueList, request);
        return fieldValueList;
    }

    mapPolicyNumberFields(fieldValueList: Array<FieldValue>, policyNumber: string, productId: string): Array<FieldValue> {
        const POLICY_NUMBER_SECTIONS: PolicyNumberSection = {
            REGN: 0,
            STTE: 2,
            PRFX: 4,
            AUTO_PNUM: 5,
            PROPERTY_PNUM: 6
        };
        if (policyNumber?.slice(POLICY_NUMBER_SECTIONS.REGN, POLICY_NUMBER_SECTIONS.STTE)) {
            fieldValueList.push(this.populateAWDFieldValues('REGN', policyNumber?.slice(POLICY_NUMBER_SECTIONS.REGN, POLICY_NUMBER_SECTIONS.STTE)));
        }
        if (policyNumber?.slice(POLICY_NUMBER_SECTIONS.STTE, POLICY_NUMBER_SECTIONS.PRFX)) {
            fieldValueList.push(this.populateAWDFieldValues('STTE', policyNumber?.slice(POLICY_NUMBER_SECTIONS.STTE, POLICY_NUMBER_SECTIONS.PRFX)));
        }

        this.mapPrefixFieldValues(policyNumber, { productId, fieldValueList, policyNumberSection: POLICY_NUMBER_SECTIONS });
        return fieldValueList;
    }

    mapPrefixFieldValues(policyNumber: string, options: {
        productId: string;
        fieldValueList: Array<FieldValue>;
        policyNumberSection: PolicyNumberSection;
    }): Array<FieldValue> {
        if (options.productId.toLowerCase() === commonConstants.AUTO_FLOW) {
            if (policyNumber?.slice(options.policyNumberSection.PRFX, options.policyNumberSection.AUTO_PNUM)) {
                options.fieldValueList.push(this.populateAWDFieldValues('PRFX', policyNumber.slice(options.policyNumberSection.PRFX, options.policyNumberSection.AUTO_PNUM)));
            }
            if (policyNumber?.slice(options.policyNumberSection.AUTO_PNUM, policyNumber?.length)) {
                options.fieldValueList.push(this.populateAWDFieldValues('PNUM', policyNumber.slice(options.policyNumberSection.AUTO_PNUM, policyNumber?.length)));
            }
        } else {
            if (policyNumber?.slice(options.policyNumberSection.PRFX, options.policyNumberSection.PROPERTY_PNUM)) {
                options.fieldValueList.push(this.populateAWDFieldValues('PRFX', policyNumber.slice(options.policyNumberSection.PRFX, options.policyNumberSection.AUTO_PNUM)));
            }
            if (policyNumber?.slice(options.policyNumberSection.PROPERTY_PNUM, policyNumber?.length)) {
                options.fieldValueList.push(this.populateAWDFieldValues('PNUM', policyNumber.slice(options.policyNumberSection.AUTO_PNUM, policyNumber?.length)));
            }
        }
        return options.fieldValueList;
    }

    mapPropertyFieldValueList(fieldValueList: Array<FieldValue>, request: AwdWorkflowServiceRequest): Array<FieldValue> {
        if (request?.loanNumber) {
            fieldValueList.push(this.populateAWDFieldValues('RF01', request.loanNumber));
        }
        if (request?.policyZipCode) {
            fieldValueList.push(this.populateAWDFieldValues('RF02', request.policyZipCode));
        }
        if (request?.mortgageCompany?.name || request?.mortgageCompany?.companyName) {
            fieldValueList.push(
                this.populateAWDFieldValues(
                    'CM01',
                    request?.mortgageCompany?.name || request?.mortgageCompany?.companyName
                )
            );
        }
        if (request?.mortgageCompany?.ediNumber) {
            fieldValueList.push(this.populateAWDFieldValues('CM02', request?.mortgageCompany?.ediNumber));
        }
        fieldValueList = this.mapAWDPropertyAddressFields(fieldValueList, request);
        return fieldValueList;
    }

    populateAWDFieldValues(name: string, value: string): FieldValue {
        if (name && value) {
            const fieldValue: FieldValue = {};
            fieldValue.value = value;
            fieldValue['@name'] = name;
            return fieldValue;
        }
        return undefined;
    }

    postAwdWorkflowService(request: AwdWorkflowServiceRequest): Observable<any> {
        const apiRequest: AwdWorkflowApiRequest = this.createAwdWorkflowApiRequest(request);
        const headers: HttpHeaders = this.getHttpHeaders();
        const url: string = this.getAwdWorkflowApiUrl();
        return this.http.post(url, apiRequest, { headers }).pipe(
            switchMap((awdWorkflowApiResponse: any) => {
                const result = {
                    error: false,
                    awdWorkflowApiResponse
                };
                return of(result);
            }),

            catchError((error: HttpErrorResponse) => of(error))
        );
    }
}
