import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { VALIDATION_REGEX } from '../../CONSTANTS/tpi-error-tags';
import { ErrorFields } from '../../tpi-models/general/form-models';
import { UtilsService } from '../../tpi-services/utility/utils-service.service';

@Injectable({
    providedIn: 'root'
})
export class GridValidatorService {
    constructor(private utilService: UtilsService) { }

    allNull(validators: {
        [x: string]: boolean;
    }): boolean {
        return validators.loanNumberFieldRequired && validators.zipCodeRequired && validators.policyNumberRequired;
    }

    captureBulkError(notEmptyValues: Array<Array<string>>): Array<ErrorFields> {
        const errorField = [];
        let row = 1;

        for (const bulkForm of notEmptyValues) {
            const validators = this.checkColumnAgainstValidators(bulkForm);
            if ((this.checkLoanNumberFormatCheck(validators) ||
                validators.zipCodeRequired || validators.zipCodeFormat || validators.policyNumberRequired ||
                validators.policyNumberFormat) && !this.allNull(validators)) {
                errorField.push(
                    {
                        loanNumber: {
                            required: validators.loanNumberFieldRequired,
                            format: validators.loanNumberFormat
                        },
                        zipCode: {
                            required: validators.zipCodeRequired,
                            format: validators.zipCodeFormat
                        },
                        policyNumber: {
                            required: validators.policyNumberRequired,
                            format: validators.policyNumberFormat
                        },
                        rowNo: row,
                        details: bulkForm
                    });
            }
            row++;
        }
        return errorField;
    }

    checkColumnAgainstValidators(bulkForm): {
        [x: string]: boolean;
    } {
        const policyNumberRegex = RegExp(VALIDATION_REGEX.isPolicyNumber);
        const zipCodeRegEx = RegExp(VALIDATION_REGEX.isZipCode);
        const loanNumberRegEx = RegExp(VALIDATION_REGEX.isBulkLoanNumber);
        return {
            loanNumberFieldRequired: bulkForm[0].toString() === '',
            loanNumberFormat: loanNumberRegEx.test(bulkForm[0].toString()) === false,
            policyNumberRequired: bulkForm[1].toString() === '',
            policyNumberFormat: policyNumberRegex.test(bulkForm[1].toString()) === false,
            zipCodeRequired: bulkForm[2].toString() === '',
            zipCodeFormat: zipCodeRegEx.test(bulkForm[2].toString()) === false
        };
    }

    checkLoanNumberFormatCheck(validators: {
        [x: string]: boolean;
    }): boolean {
        return validators.loanNumberFieldRequired || validators.loanNumberFormat;
    }

    getDuplicatePoliciesAndNonEmptyValues(gridObject: {
        policyNumbers: string[];
        formControl: UntypedFormControl;
    }): any {
        let duplicatePolicies = [];
        if (gridObject.policyNumbers.length > 0) {
            duplicatePolicies = [...new Set(gridObject.policyNumbers.filter((item, index) => gridObject.policyNumbers.indexOf(item) !== index && item !== ''))];
        }
        const notEmptyValues = gridObject.formControl.value;
        return { duplicatePolicies, notEmptyValues };
    }

    validateBulkEditReturn(errorField: Array<ErrorFields>, duplicatePolicies: string[]): {
        bulkError?: Array<ErrorFields>;
        duplicatePolicyNumber?: string;
    } {
        if (errorField?.length > 0 || duplicatePolicies?.length > 0) {
            return {
                ...errorField?.length > 0 && { bulkError: errorField },
                ...duplicatePolicies?.length > 0 && { duplicatePolicyNumber: duplicatePolicies?.join(',') || '' }
            };
        } else {
            return null;
        }
    }
}
