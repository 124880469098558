import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { StoreSearchFields } from '../../tpi-models/store/storesearchfields.model';
import { AppState } from '../../tpi-states/app.state';

@Injectable({
    providedIn: 'root'
})
export class PolicySearchStateServiceService {
    constructor(private storeSearchFields: Store<AppState>) { }

    getSearchFields(): Observable<StoreSearchFields> {
        return this.storeSearchFields.select((state) => state.storeSearchFields);
    }

    updateSearchFields(autoSearchFields: StoreSearchFields): void {
        this.storeSearchFields.dispatch({
            type: commonConstants.STORE.MODIFY_SEARCHFIELDS,
            payload: autoSearchFields
        });
    }
}
