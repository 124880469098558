<h1 class="bolt-heading-md margin-bottom-auto">Property Address</h1>
<div class="bolt-row margin-top-x1 mobile-padding-top-lg mobile-padding-bottom-lg" *ngIf="propertyAddress">
    <div class="bolt-col-3">
        <img src="../../../assets/image/location-circle.svg" class="userimg" alt="user image" />
    </div>
    <div class="bolt-col-9 bolt-body-copy-sm">
        <span class="nwpii">{{propertyAddress.addressLine1}}</span>
        <br />
        <span class="nwpii">{{propertyAddress.city}}, {{propertyAddress.state}}</span> {{propertyAddress.postalCode}}
    </div>
</div>
<div class="bolt-row padding-top-lg bolt-body-copy-sm" *ngIf="!propertyAddress">
    <div class="bolt-col">
        Property address is not available at this time.
    </div>
</div>