<h2 class="bolt-heading-md bolt-space-bottom-lg">
    Loan/Lease Details
</h2>
<div class="bolt-container" *ngIf="policyInformationDetails != null">
    <div id="loanLease" *ngIf="showLienHolder; else elseBlock">
        <div class="bolt-row bolt-body-copy-sm">
            {{ vehicles[0].additionalInterests[0].companyName }}
        </div>
        <div class="bolt-row bolt-body-copy-sm">
            {{ vehicles[0].additionalInterests[0].address.addressLine1 }}
        </div>
        <div *ngIf="showAddressLine2" class="bolt-row bolt-body-copy-sm">
            {{ vehicles[0].additionalInterests[0].address.addressLine2 }}
        </div>
        <div class="bolt-row bolt-body-copy-sm">
            {{ vehicles[0].additionalInterests[0].address.city }},
            {{ vehicles[0].additionalInterests[0].address.state }}
            {{ vehicles[0].additionalInterests[0].address.postalCode }}
        </div>
    </div>
    <ng-template #elseBlock>
        <div class="bolt-body-copy-sm bolt-row padding-top-sm">
            Loan/Lease information is not available.
        </div>
        <br />
    </ng-template>
    <div class="margin-top-x1 bolt-row edit-button bolt-background-pale-gray-50 noprint" *ngIf="showEditButton">
        <div class="bolt-col-4"></div>
        <div class="bolt-col-auto">
            <bolt-button iconleft="pencil" (click)="openEditModal()">Edit</bolt-button>
        </div>
        <div class="bolt-col-3"></div>
    </div>
</div>

<div class="bolt-row padding-top-lg bolt-body-copy-sm" *ngIf="policyInformationDetails == null">
    <div class="bolt-col">
        Loan/lease information is not available at this time.
    </div>
</div>

<bolt-modal #autoEdit type="basic" id="editLoanLeaseModal" heading="Edit loan/lease details" primarybutton="Save" secondarybutton="Cancel" (bolt-modal-primary-button-click)="submitModalForm($event)"
    (bolt-modal-secondary-button-click)="cancelModalForm()">
    <div class="bolt-container">
        <form [formGroup]="lienHolderForm">
            <div class="bolt-row bolt-space-bottom-md">
                <div class="bolt-col">
                    <bolt-textfield type="text" aria-label="Please provide Lien Holder Name" label="Name" optionaltext="hide" maxlength="100" (keypress)="utilsService.keyPressCompanyName($event)"
                        (paste)="utilsService.pasteCompanyName($event)" ngDefaultControl formControlName="companyName" [attr.error]="
                            checkForError(
                                'companyName',
                                lienHolderForm,
                                appendOption(submitOptions, {
                                    label: 'Name'
                                })
                            )
                        ">
                    </bolt-textfield>
                </div>
            </div>
            <div class="bolt-row bolt-space-bottom-md">
                <div class="bolt-col">
                    <bolt-textfield type="text" aria-label="Please provide Address line one" label="Address line one" optionaltext="hide" maxlength="60" ngDefaultControl formControlName="addressLine1"
                        [attr.error]="
                            checkForError(
                                'addressLine1',
                                lienHolderForm,
                                appendOption(submitOptions, {
                                    label: 'Address'
                                })
                            )
                        ">
                    </bolt-textfield>
                </div>
            </div>
            <div class="bolt-row bolt-space-bottom-md">
                <div class="bolt-col">
                    <bolt-textfield type="text" aria-label="Please provide Address line two" label="Address line two" optionaltext="hide" maxlength="60" ngDefaultControl
                        formControlName="addressLine2">
                    </bolt-textfield>
                </div>
            </div>
            <div class="bolt-row bolt-space-bottom-md">
                <div class="bolt-col-sm-6 bolt-col-12">
                    <bolt-textfield type="text" aria-label="Please provide city" label="City" optionaltext="hide" maxlength="60" ngDefaultControl formControlName="city" [attr.error]="
                            checkForError(
                                'city',
                                lienHolderForm,
                                appendOption(submitOptions, {
                                    label: 'City'
                                })
                            )
                        "></bolt-textfield>
                </div>
                <div class="bolt-col-md-6 bolt-col-lg-6 bolt-col">
                    <app-state [type]="auto" [parentFormGroup]="lienHolderForm" (change)="setErrorState()" [stateError]="stateError"></app-state>
                    <div *ngIf="
                            stateError ||
                            (this.lienHolderForm.controls.state.dirty &&
                                this.lienHolderForm.controls.state.status ===
                                    'INVALID')
                        ">
                        <bolt-field-error>State is required.</bolt-field-error>
                    </div>
                </div>
            </div>
            <div class="bolt-row bolt-space-bottom-md">
                <div class="bolt-col-sm-6 bolt-col-12">
                    <bolt-textfield type="text" aria-label="Please provide zip" label="ZIP code" optionaltext="hide" (keypress)="utilsService.keyPressNumbers($event)"
                        (paste)="utilsService.pasteNumbers($event)" maxlength="5" ngDefaultControl formControlName="zipCode" [attr.error]="
                            checkForError(
                                'zipCode',
                                lienHolderForm,
                                appendOption(submitOptions, {
                                    label: 'ZIP'
                                })
                            )
                        "></bolt-textfield>
                </div>
                <div class="bolt-col-sm-6 bolt-col-12"></div>
            </div>
            <div class="bolt-row bolt-space-bottom-md">
                <div class="bolt-col-sm-6 bolt-col-12">
                    <bolt-radio-group label="Is this a loan or lease?" name="testname2" horizontal optionaltext="hide" ngDefaultControl formControlName="loanOrLease" [attr.error]="
                            checkForError(
                                'loanOrLease',
                                lienHolderForm,
                                appendOption(submitOptions, {
                                    label: 'Loan or Lease'
                                })
                            )
                        ">
                        <bolt-radio value="Loan">Loan</bolt-radio>
                        <bolt-radio value="Lease">Lease</bolt-radio>
                    </bolt-radio-group>
                </div>
                <div class="bolt-col-sm-6 bolt-col-12">
                    <div *ngIf="lienHolderForm.value.loanOrLease === 'Loan'">

                        <bolt-date-picker label="Loan expiration date" optionaltext="hide" (keypress)="utilsService.keyPressDate($event)" (paste)="utilsService.pasteDate($event)"
                            (change)="setErrorExpDate($event)" ngDefaultControl formControlName="expirationDate" [attr.error]="
                            checkForError(
                                'expirationDate',
                                lienHolderForm,
                                appendOption(submitOptions, {
                                    label: 'Loan expiration date'
                                })
                            )
                        ">
                        </bolt-date-picker>
                    </div>
                </div>
            </div>
            <div class="bolt-row bolt-space-bottom-md">
                <div class="bolt-col-sm-6 bolt-col-12">
                    <bolt-date-picker label="Change effective date" [min]="minDate" optionaltext="hide" (keypress)="utilsService.keyPressDate($event)" (paste)="utilsService.pasteDate($event)"
                        ngDefaultControl formControlName="changeEffectiveDate" [attr.error]="
                            checkForError(
                                'changeEffectiveDate',
                                lienHolderForm,
                                appendOption(submitOptions, {
                                    label: 'Change effective date'
                                })
                            )
                        ">
                    </bolt-date-picker>
                </div>
                <div class="bolt-col-sm-6 bolt-col-12"></div>
            </div>
        </form>
    </div>
</bolt-modal>