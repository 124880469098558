import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { COMPANY_CONSTANTS, LOGGER_CONSTANTS } from '../../../../CONSTANTS/tpi-common-constants';
import { tpiSearchError } from '../../../../CONSTANTS/tpi-search-errors';
import { WebSupportActionsEnum } from '../../../../CONSTANTS/web-support-actions';
import { WebSupportCompanyPayload } from '../../../../tpi-models/general/company-info';
import { Window } from '../../../../tpi-models/general/model-window';
import { ModifyModalInput } from '../../../../tpi-models/manage-accounts-company-web/modify-modal-input';
import { AuthorDetails } from '../../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { SuccessModalComponent } from '../../shared/success-modal/success-modal.component';

@Component({
    selector: 'tpi-delete-company',
    templateUrl: './delete-company.component.html'
})
export class DeleteCompanyComponent implements OnInit {
    @ViewChild(ConfirmationModalComponent) confirmationModal: ConfirmationModalComponent;
    @Input() deleteCompanyInfo: WebSupportCompanyPayload;
    @Output() onError = new EventEmitter<any>();
    @Output() onSuccess = new EventEmitter<any>();
    @Output() reload = new EventEmitter<boolean>(false);
    @ViewChild(SuccessModalComponent) successModalComponent: SuccessModalComponent;

    authorDetails: AuthorDetails;
    deleteCompanyInput: ModifyModalInput = COMPANY_CONSTANTS.deleteModal;
    showModal: boolean;

    // eslint-disable-next-line max-params
    constructor(private manageCompanyService: ManageUserAccountsService,
        private loggerService: LoggerService,
        private sessionService: SessionService,
        @Inject('window') private window: Window) { }

    ngOnInit(): void {
        this.authorDetails = this.sessionService.getAuthorDetails();
    }

    onCancel(): void {
        this.showModal = false;
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: onCancel: ${this.authorDetails.userName} closed delete company modal ${this.deleteCompanyInfo.company.companyDescription}`, {
            company: this.deleteCompanyInfo,
            authorDetails: this.authorDetails
        });
        this.loggerService.logCurrentContents();
    }

    onDelete(): void {
        this.window.manageCompanyWaitingIndicator.show();
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: onDelete: ${this.authorDetails.userName} started deleting company ${this.deleteCompanyInfo.company.companyDescription}`);
        this.manageCompanyService.createDeleteOrModifyCompany(this.deleteCompanyInfo, WebSupportActionsEnum.DELETE).subscribe({
            next: (response) => {
                this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: onDelete: ${this.authorDetails.userName} successfully deleted company ${this.deleteCompanyInfo.company.companyDescription}`, {
                    company: this.deleteCompanyInfo,
                    response,
                    authorDetails: this.authorDetails
                });
                this.window.manageCompanyWaitingIndicator.hide();
                this.showModal = false;
                this.successModalComponent.openModal(true);
                this.onSuccess.emit();
            },
            error: (error) => {
                this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: onDelete: ${this.authorDetails.userName} failed to delete company ${this.deleteCompanyInfo.company.companyDescription}`, {
                    company: this.deleteCompanyInfo,
                    error,
                    authorDetails: this.authorDetails
                });
                this.window.manageCompanyWaitingIndicator.hide();
                this.showModal = false;
                this.onError.emit(tpiSearchError.UNABLE_PROCESS_ERROR);
            },
            complete: () => {
                this.loggerService.logCurrentContents();
            }
        });
    }

    reloadPage($isOpen: boolean): void {
        if ($isOpen === false) {
            this.reload.emit($isOpen);
        }
    }
}
