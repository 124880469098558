import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LOGGER_CONSTANTS } from '../../../../CONSTANTS/tpi-common-constants';
import { RESPONSE_CODES } from '../../../../CONSTANTS/tpi-error-tags';
import { ModifyUserActions, tpiModifyUserInputs } from '../../../../CONSTANTS/tpi-manage-users';
import { tpiSearchError } from '../../../../CONSTANTS/tpi-search-errors';
import { ManageUserAccountsAccountInfo } from '../../../../tpi-models/manage-accounts-company-web/manage-user-accounts-account-info';
import { AuthorDetails } from '../../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { ModifyUserRequest } from '../../../../tpi-models/manage-accounts-company-web/modify-user-request';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'tpi-user-panel',
    templateUrl: './user-panel.component.html',
    styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit {
    @Output() errorEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Input() manageUserAccountsAccountInfo: ManageUserAccountsAccountInfo;
    @ViewChild('modalSuccess') modalSuccess;
    @ViewChild('resetComfirmation') resetComfirmation;
    @ViewChild('unlockComfirmation') unlockComfirmation;
    @Output() waitIndicatorEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    authorDetails: AuthorDetails;
    openResetModal = false;
    openSuccessModal = false;
    openUnlockModal = false;
    successUnlockMessage = true;

    webSupportModalTextReset = tpiModifyUserInputs.MODIFY_USER_INPUT_RESET.modalTextWebSupport;
    webSupportModalTextUnlock = tpiModifyUserInputs.MODIFY_USER_INPUT_UNLOCK.modalTextWebSupport;
    webSupportSuccessModalReset = tpiModifyUserInputs.MODIFY_USER_INPUT_RESET.successModalTextWebSupport;
    webSupportSuccessModalUnlock = tpiModifyUserInputs.MODIFY_USER_INPUT_UNLOCK.successModalText;

    constructor(private loggerService: LoggerService,
        private manageUsersService: ManageUserAccountsService,
        private sessionService: SessionService) { }

    close(): void {
        this.waitIndicatorEmitter.emit(false);
        this.unlockComfirmation.nativeElement.closeModal();
        this.resetComfirmation.nativeElement.closeModal();
        this.modalSuccess.nativeElement.closeModal();
    }

    getRequest(action: ModifyUserActions): ModifyUserRequest {
        return {
            action,
            userId: this.authorDetails.userName,
            users: [{
                companyNumber: this.manageUserAccountsAccountInfo.company.id,
                email: this.manageUserAccountsAccountInfo.email,
                firstName: this.manageUserAccountsAccountInfo.firstName,
                lastName: this.manageUserAccountsAccountInfo.lastName,
                role: this.manageUserAccountsAccountInfo.role.id,
                userId: this.manageUserAccountsAccountInfo.userId,
                userNumber: this.manageUserAccountsAccountInfo.id
            }]
        };
    }

    ngOnInit(): void {
        this.authorDetails = this.sessionService.getAuthorDetails();
    }

    open(type: ModifyUserActions): void {
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: web support opening modal ${type}`, {
            user: this.authorDetails,
            request: this.getRequest(type)
        });
        this.close();
        switch (type) {
            case ModifyUserActions.Unlock:
                this.unlockComfirmation.nativeElement.openModal();
                break;
            case ModifyUserActions.Reset:
                this.resetComfirmation.nativeElement.openModal();
                break;
            case ModifyUserActions.Success:
                this.modalSuccess.nativeElement.openModal();
                break;
            default:
                this.close();
        }
    }

    passwordMFAUnlock(): void {
        this.waitIndicatorEmitter.emit(true);
        const request = this.getRequest(ModifyUserActions.Unlock);
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: passwordMFAUnlock: start acount and mfa unlock ${request.users[0].userId}`, {
            user: this.authorDetails,
            request
        });
        if (this.manageUserAccountsAccountInfo?.id && this.manageUserAccountsAccountInfo?.userId) {
            this.manageUsersService.modifyUsers(this.getRequest(ModifyUserActions.Unlock)).subscribe({
                next: () => {
                    this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: passwordMFAUnlock: successfully unlocked user on web-support ${request.users[0].userId}`, {
                        user: this.authorDetails,
                        request
                    });
                    this.loggerService.logCurrentContents();
                    this.successUnlockMessage = true;
                    this.open(ModifyUserActions.Success);
                },
                error: (error) => {
                    this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: passwordMFAUnlock: web support failed to unlock user ${request.users[0].userId}`, {
                        user: this.authorDetails,
                        request,
                        error
                    });
                    this.loggerService.logCurrentContents();
                    this.close();
                    let errorMessage = tpiSearchError.SYSTEM_DOWN_ERROR;
                    if (error.status === RESPONSE_CODES.ERROR_400) {
                        errorMessage = tpiModifyUserInputs.MODIFY_USER_INPUT_UNLOCK.errorMessage;
                    }
                    this.errorEmitter.emit({
                        hasError: true,
                        errorMessage
                    });
                }
            });
        } else {
            this.close();
        }
    }

    passwordReset(): void {
        this.waitIndicatorEmitter.emit(true);
        const request = this.getRequest(ModifyUserActions.Reset);
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: passwordReset: start password reset `, {
            user: this.authorDetails,
            request
        });
        if (this.manageUserAccountsAccountInfo?.id && this.manageUserAccountsAccountInfo?.userId) {
            this.manageUsersService.modifyUsers(request).subscribe({
                next: () => {
                    this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: passwordReset: web-support succuessfuly reset password for user ${request.users[0].userId}`, {
                        user: this.authorDetails,
                        request
                    });
                    this.loggerService.logCurrentContents();
                    this.successUnlockMessage = false;
                    this.open(ModifyUserActions.Success);
                },
                error: (error) => {
                    this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: passwordReset: web support failed to reset user ${request.users[0].userId}`, {
                        user: this.authorDetails,
                        request,
                        error
                    });
                    this.loggerService.logCurrentContents();
                    this.close();
                    let errorMessage = tpiSearchError.SYSTEM_DOWN_ERROR;
                    if (error.status === RESPONSE_CODES.ERROR_400) {
                        errorMessage = tpiModifyUserInputs.MODIFY_USER_INPUT_RESET.errorMessage;
                    }
                    this.errorEmitter.emit({
                        hasError: true,
                        errorMessage
                    });
                }
            });
        } else {
            this.close();
        }
    }
}
