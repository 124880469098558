import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/core-component-library-base';
import {
    DPIMInfoQueryParamsHelperService,
    GetDPIMQueryParameters
} from '@nationwide/internet-servicing-dpim-requests';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ECIF } from '../../../CONSTANTS/tpi-ecif-constants';
import { EcifService } from '../../../service/ecif.service';
import { ECIFResponse } from '../../../tpi-models/general/ecifResponse';
import { SA360Response } from '../../../tpi-models/general/sa360Response';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';

@Injectable()
export class SA360Service {
    // eslint-disable-next-line max-params
    constructor(
        private http: HttpClient,
        private apiCommonService: APICommonService,
        private ecifService: EcifService,
        private dpimInfoQueryParametersHelperService: DPIMInfoQueryParamsHelperService,
        private utilsService: UtilsService
    ) { }

    fetchSA360Info(policyNumber: string, euaToken: string): Observable<SA360Response> {
        return this.ecifService.getEcifPolicyAgreement(policyNumber, euaToken).pipe(
            switchMap((ecifResponse: ECIFResponse): Observable<any> => {
                let agentStateCode: string;
                let agentNumber: string;
                if (ecifResponse.relatedDistributionPartners) {
                    for (const relatedDistributionPartner of ecifResponse.relatedDistributionPartners) {
                        if (relatedDistributionPartner.distributionPartnerType === ECIF.PRODUCER_VALUES.PRODUCER) {
                            agentNumber = relatedDistributionPartner.externalIdentifier;
                        }
                        agentStateCode = ecifResponse.relatedDistributionPartners[0].licenseState;
                    }
                }
                const queryparams: GetDPIMQueryParameters =
                    this.dpimInfoQueryParametersHelperService.retrieveDPIMQueryParams(agentNumber, agentStateCode);
                const query = this.generateQueryString(queryparams);
                return this.getSA360Response(euaToken, queryparams.agentNumber, query)
                    .pipe(catchError((error: HttpErrorResponse) => of(error)));
            })
        );
    }

    generateQueryString(queryparams: GetDPIMQueryParameters): string {
        return `legacyIdentifierType=Legacy Producer Organization Role Number&legacyAdministrativeSystem=${queryparams.legacyAdministrativeSystem}`;
    }

    getHttpOptions(euaToken: string): HttpHeaders {
        let headers = this.utilsService.getHttpOptions(euaToken, 'X-NW-Message-ID');
        headers = headers.append('X-NWD-Source', 'DPIM');
        return headers;
    }

    getSA360Response(euaToken: string, agentIndentifier: string, query: string): Observable<any> {
        const httpOptions: HttpHeaders = this.getHttpOptions(euaToken);
        const url = `${environment.GETSA360_INFO.url(agentIndentifier, query)}`;
        return this.http.get(url, { headers: httpOptions });
    }
}
