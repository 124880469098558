import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CHANGE_PASSWORD_RETURN_CODE, EXPIRED_PASSWORD_RETURN_CODE } from '../../CONSTANTS/change-password-return-codes';
import { ChangePasswordRequest, UpdatePasswordRequest } from '../../tpi-models/user-credentials/change-password-request';
import { ChangePasswordResponse } from '../../tpi-models/user-credentials/change-password-response';
import { ForgotPasswordRequestMFARequest } from '../../tpi-models/user-credentials/forgot-password-request-mfa-request';
import { ForgotPasswordRequestMFAResponse } from '../../tpi-models/user-credentials/forgot-password-request-mfa-response';
import { ForgotPasswordRetrieveEmailRequest } from '../../tpi-models/user-credentials/forgot-password-retrieve-email-request';
import { ForgotPasswordRetrieveEmailResponse } from '../../tpi-models/user-credentials/forgot-password-retrieve-email-response';
import { ForgotPasswordVerifyMFARequest } from '../../tpi-models/user-credentials/forgot-password-verify-mfa-request';
import { ForgotPasswordVerifyMFAResponse } from '../../tpi-models/user-credentials/forgot-password-verify-mfa-response';
import { UpdateExpiredPasswordResponse } from '../../tpi-models/user-credentials/update-expired-password-response';
import { LoggerService } from '../logger/logger.service';
import { UtilsService } from '../utility/utils-service.service';

@Injectable({
    providedIn: 'root'
})
export class PasswordService {
    constructor(private httpClient: HttpClient,
        private loggerService: LoggerService,
        private utilsService: UtilsService) { }

    doChangePassword(passwordChangeRequest: ChangePasswordRequest | UpdatePasswordRequest): Observable<ChangePasswordResponse> {
        let url = environment.apigeeEnvironment + environment.APIs.passwordChange;
        if (passwordChangeRequest.token && passwordChangeRequest.newPassword) {
            url = environment.apigeeEnvironment + environment.APIs.passwordUpdate;
        }
        const euaToken = sessionStorage.getItem('jwt');
        const httpOptions = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post<ChangePasswordResponse>(url, passwordChangeRequest, { headers: httpOptions })
            .pipe(
                switchMap((response: any): Observable<any> => {
                    if ([CHANGE_PASSWORD_RETURN_CODE.SUCCESS, CHANGE_PASSWORD_RETURN_CODE.DMX_DB_ERROR].includes(response.operationResponse.returnCode)) {
                        return of(response);
                    }
                    if (response?.operationResponse) {
                        this.loggerService.error('doChangePassword(): failed change-password response', { errorMessage: `IAM: ${response?.operationResponse.returnMessage}` });
                    }
                    return throwError(() => response);
                })
            );
    }

    doRequestMFAChallenge(forgotPasswordRequestMFARequest: ForgotPasswordRequestMFARequest): Observable<ForgotPasswordRequestMFAResponse> {
        const url = environment.apigeeEnvironment + environment.APIs.forgotPasswordRequestMFA;
        const euaToken = sessionStorage.getItem('jwt');
        const httpOptions = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post<any>(url, forgotPasswordRequestMFARequest, { headers: httpOptions }).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                this.loggerService.info('doRequestMFAChallenge: request MFA challenge for forgot password failed ', errorResponse);
                return throwError(() => errorResponse);
            })
        );
    }

    doRetrieveEmail(forgotPasswordRetrieveEmailRequest: ForgotPasswordRetrieveEmailRequest): Observable<ForgotPasswordRetrieveEmailResponse> {
        const url = environment.apigeeEnvironment + environment.APIs.forgotPasswordRetrieveEmail;
        const euaToken = sessionStorage.getItem('jwt');
        const httpOptions = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post<any>(url, forgotPasswordRetrieveEmailRequest, { headers: httpOptions }).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                this.loggerService.info('doRetrieveEmail: retrieve email address failed ', errorResponse);
                return throwError(() => errorResponse);
            })
        );
    }

    doUpdateExpiredPassword(passwordChangeRequest: ChangePasswordRequest): Observable<UpdateExpiredPasswordResponse> {
        const url = environment.apigeeEnvironment + environment.APIs.expiredPassword;
        const euaToken = sessionStorage.getItem('jwt');
        const httpOptions = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post<any>(url, passwordChangeRequest, { headers: httpOptions }).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.error?.user && errorResponse.error.exception === EXPIRED_PASSWORD_RETURN_CODE.DMX_DB_ERROR) {
                    this.loggerService.error('doUpdateExpiredPassword: DMX failure', errorResponse);
                    return of(errorResponse.error);
                }
                this.loggerService.info('doUpdateExpiredPassword: update expired password failed ', errorResponse);
                return throwError(() => errorResponse);
            })
        );
    }

    doVerifyMFAChallenge(forgotPasswordVerifyMFARequest: ForgotPasswordVerifyMFARequest): Observable<ForgotPasswordVerifyMFAResponse> {
        const url = environment.apigeeEnvironment + environment.APIs.forgotPasswordVerifyMFA;
        const euaToken = sessionStorage.getItem('jwt');
        const httpOptions = this.utilsService.getHttpOptions(euaToken);
        return this.httpClient.post<any>(url, forgotPasswordVerifyMFARequest, { headers: httpOptions }).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                this.loggerService.info('doVerifyMFAChallenge: verify MFA challenge for forgot password failed ', errorResponse);
                return throwError(() => errorResponse);
            })
        );
    }
}
