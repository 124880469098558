import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'tpi-login-error',
    templateUrl: './login-error.component.html',
    styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit {
    pingLogoutUrl: string = environment.pingLogoutUrl;

    constructor(
        private sessionService: SessionService
    ) { }

    ngOnInit(): void {
        this.sessionService.isLoggedIn = false;
        this.sessionService.deleteAllCookies();
    }
}
