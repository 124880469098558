import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-ping-iframe',
    templateUrl: './ping-iframe.component.html'
})

export class PingIFrameComponent {
    pingLogoutUrl: string = environment.pingLogoutUrl;
}
