import { Component, Inject } from '@angular/core';

interface Window {
    waitIndicatorFullscreen: any;
}
@Component({
    selector: 'app-wait-indicator',
    templateUrl: './wait-indicator.component.html'
})

export class WaitIndicatorComponent {
    constructor(
        @Inject('window') private window: Window
    ) { }

    /**
     * hide wait panel
     * @protected
     * @return {void}
     */
    hideWaitIndicator(): void {
        this.window.waitIndicatorFullscreen.hide();
    }
    /**
     * Display wait panel
     * @protected
     * @return {void}
     */
    openWaitIndicator(): void {
        this.window.waitIndicatorFullscreen.show();
    }
}
