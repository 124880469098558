import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root'
})
export class RequestResponseLoggingInterceptorService implements HttpInterceptor {
    constructor(private logger: LoggerService) { }

    endpointsOmitRequest = [environment.APIs.apigeeEnvironment + environment.APIs.passwordUpdate, environment.APIs.apigeeEnvironment + environment.APIs.expiredPassword, environment.APIs.apigeeEnvironment + environment.APIs.passwordChange];

    getHeaders(headers: HttpHeaders): {} {
        const headerReturn = {};
        const keys: Array<string> = headers.keys();
        for (const key of keys) {
            headerReturn[key] = headers.get(key);
        }
        return headerReturn;
    }

    getRequest(httpRequest: HttpRequest<any>): {} {
        return {
            url: httpRequest?.urlWithParams,
            body: httpRequest?.body,
            headers: this.getHeaders(httpRequest?.headers),
            method: httpRequest?.method
        };
    }

    getService(urlWithParameters: string): string {
        let service = 'n/a';
        if (urlWithParameters) {
            const urlArray: Array<string> = urlWithParameters.split('/');
            const experienceUrlIndex = 3;
            const experienceServiceIndex = 4;
            const experienceVersionIndex = 5;
            if (urlArray[experienceUrlIndex] === commonConstants.API_CONSTANT.TPI_API_NAME) {
                service = urlArray[experienceServiceIndex];
                if (urlArray[experienceVersionIndex] !== commonConstants.API_CONSTANT.VERSION_V1) {
                    service += `-${urlArray[experienceVersionIndex]}`;
                }
            } else {
                service = `${urlArray[experienceUrlIndex]}-${urlArray[experienceServiceIndex]
                    }-${urlArray[experienceVersionIndex]}`;
            }
        }
        return service;
    }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started: number = Date.now();
        let servicestatus: string;
        const service: string = this.getService(httpRequest?.urlWithParams);
        const request: {} = this.getRequest(httpRequest);
        let loggingObject: {};
        return next.handle(httpRequest).pipe(
            tap({
                next: (event) => {
                    if (event instanceof HttpResponse && !httpRequest.urlWithParams.includes(commonConstants.PAGES.CLIENT_LOGGING)) {
                        const response = {
                            body: this.updateLoggerResponseBody(event?.body),
                            headers: this.getHeaders(event?.headers),
                            status: event?.status,
                            statusText: event?.statusText,
                            type: event?.type
                        };
                        if (this.endpointsOmitRequest.includes(httpRequest.url)) {
                            loggingObject = JSON.parse(JSON.stringify({
                                response
                            }));
                        } else {
                            loggingObject = JSON.parse(JSON.stringify({
                                request,
                                response
                            }));
                        }
                        servicestatus = commonConstants.RESPONSE_STATUS.SUCCESS;
                    }
                },
                error: (event: HttpErrorResponse) => {
                    const response = {
                        error: event?.error,
                        message: event?.message,
                        name: event?.name,
                        headers: this.getHeaders(event?.headers),
                        status: event?.status,
                        statusText: event?.statusText,
                        type: event?.type
                    };
                    if (this.endpointsOmitRequest.includes(httpRequest.url)) {
                        loggingObject = JSON.parse(JSON.stringify({
                            response
                        }));
                    } else {
                        loggingObject = JSON.parse(JSON.stringify({
                            request,
                            response
                        }));
                    }
                    servicestatus = commonConstants.RESPONSE_STATUS.FAILED;
                }
            }),
            finalize(() => {
                if (!httpRequest.urlWithParams.includes(commonConstants.PAGES.CLIENT_LOGGING)) {
                    const elapsed = Date.now() - started;
                    const message = `Performance logging metrics.`;
                    const telematics = {
                        service,
                        method: httpRequest?.method,
                        url: httpRequest?.urlWithParams,
                        servicestatus,
                        elapsedInMS: elapsed
                    };
                    this.logger.info(message, {}, telematics);
                    if (servicestatus === commonConstants.RESPONSE_STATUS.SUCCESS) {
                        this.logger.info(`Successful call: ${service}`, telematics, loggingObject);
                    } else if (servicestatus === commonConstants.RESPONSE_STATUS.FAILED) {
                        this.logger.error(`Unsuccessful call: ${service}`, telematics, loggingObject);
                    }
                }
            })
        );
    }

    updateLoggerResponseBody(body: any): any {
        if (body instanceof Array && body.length > environment.arrayElementCountLogging) {
            const size = body.length;
            body = body.slice(0, environment.arrayElementCountLogging - 1);
            body = [...body, `MASKED ${size - environment.arrayElementCountLogging} RECORD(S)`];
        }
        return body;
    }
}
