import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'tpi-success-modal',
    templateUrl: './success-modal.component.html'
})
export class SuccessModalComponent {
    @Output() isOpenedEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() modalText: string;
    showModal: boolean;

    closeModal($event): void {
        if ($event) {
            this.showModal = false;
            this.isOpenedEmit.emit(false);
        }
    }

    openModal($event): void {
        if ($event) {
            this.showModal = true;
            this.isOpenedEmit.emit(true);
        }
    }
}
