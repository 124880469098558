import { Component, Input, OnInit } from '@angular/core';
import { PolicyInformationDetails } from '@nationwide/dgs-internet-servicing-policy-requests';
import { environment } from '../../../../environments/environment';
import { PropertyEndorsement } from '../../../tpi-models/general/property-endorsement';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
import { LoggerService } from './../../../tpi-services/logger/logger.service';
import { SessionService } from './../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'app-property-endorsement',
    templateUrl: './property-endorsement.component.html',
    styleUrls: ['./property-endorsement.component.scss']
})
export class PropertyEndorsementComponent implements OnInit {
    constructor(private loggerService: LoggerService, private sessionService: SessionService, private utilsService: UtilsService) { }
    @Input() policyInformationDetail: PolicyInformationDetails;
    @Input() propertyEndorsement: PropertyEndorsement;
    @Input() renewalInformation: PropertyEndorsement;

    contractsEndorsements;

    // TODO: Conditionalize Renewal Wind/Hail and Hurricane Deductibles - ASTRALIS-1162
    enableRenewalDeductible = false;
    nonSA360Contact = environment.nonSA360Contact;

    checkRenewalInformation(): boolean {
        return Object.keys(this.renewalInformation).length > 0;
    }

    contractsEndorsementsURL(): void {
        this.loggerService.info('Click of Policy Contracts & Endorsements', {
            UserId: sessionStorage.getItem('userName'),
            PolicyNumber: this.policyInformationDetail.policyNumber,
            PolicySystem: 'Policy Center',
            LOB: 'Property',
            Company: this.sessionService.getSession().context.session.account.company.companyDescription
        });
    }

    ngOnInit(): void {
        this.contractsEndorsements = environment.contractsEndorsementsURL;
    }

    renewalDays(): string {
        let diffTime = 0;
        let renewalDays = 0;
        const ROUNDVALUE = 1000;
        const MINUTES = 60;
        const SECONDS = 60;
        const HOURS = 24;
        const DAYS_TO_DIFFERENCE = 23;
        if (this.policyInformationDetail?.policyEndDate) {
            diffTime = new Date(this.policyInformationDetail.policyEndDate).valueOf() - Date.now();
            renewalDays = Math.round(diffTime / (ROUNDVALUE * MINUTES * SECONDS * HOURS)) - DAYS_TO_DIFFERENCE;
        }
        return renewalDays > 0 ? renewalDays.toString() : '0';
    }
}
