import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-interactive-requirements',
    templateUrl: './interactive-requirements.component.html',
    styleUrls: ['./interactive-requirements.component.scss']
})
export class InteractiveRequirementsComponent {
    @Input() parentForm: FormGroup;
    @Input() showInteractiveRequirements: boolean;
}
