import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormElementCoreComponent } from '../../../../shared/components/form-element-core/form-element-core.component';
import { SubmitOptions } from '../../../../tpi-models/general/form-models';
import { UtilsService } from '../../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'tpi-search-user-form',
    templateUrl: './search-user-form.component.html',
    styleUrls: ['./search-user-form.component.scss']
})
export class SearchUserFormComponent extends FormElementCoreComponent {
    @Input() label: string;
    @Input() parentForm: FormGroup;
    @Output() searchEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() submitOptions: SubmitOptions;
    formControlName = 'searchQuery';
    constructor(public utilsService: UtilsService) {
        super();
    }

    get error(): boolean {
        const controller = this.parentForm.get(this.formControlName);
        return controller.touched && !controller.pending && controller.invalid;
    }

    search(): void {
        this.searchEmit.emit(true);
    }
}
