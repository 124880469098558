import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MortgageCompany } from '../../tpi-models/general/mortgage-companies-response';
import { LoggerService } from '../logger/logger.service';
import { EditMortgageService } from '../shared-services/edit-mortgage.service';

@Injectable({
    providedIn: 'root'
})
export class MortgageCompaniesAdapterService {
    constructor(
        private loggerService: LoggerService,
        private editMortgageService: EditMortgageService) { }

    checkForLegalInformation(displayText: string, company: MortgageCompany): string {
        if (company?.legalInformation?.description) {
            displayText = `${displayText}${company.legalInformation.description}, `;
        }
        return displayText;
    }

    getCompanyList(state, companyName): Observable<any> {
        return this.editMortgageService.getMortgageCompanies(encodeURIComponent(companyName), state)
            .pipe(
                switchMap(
                    (response) => {
                        this.loggerService.info('Retrieved mortgage company list response - getCompanyList()', {
                            component: 'PropertyMortgageInfoComponent',
                            state,
                            companyName,
                            response
                        });
                        const error400 = 400;
                        if (response instanceof HttpErrorResponse && response.status !== error400) {
                            this.loggerService.error('Retrieved mortgage company list api call failed - getCompanyList()', {
                                component: 'PropertyMortgageInfoComponent',
                                state,
                                companyName,
                                error: response
                            });
                            return throwError(() => response);
                        }
                        if (response?.mortgageCompanies?.length > 0) {
                            let address;
                            const companyMap = new Map<string, MortgageCompany>();
                            for (const company of response.mortgageCompanies) {
                                address = company.address.addressLine1.concat(', ').concat(company.address.city).concat(', ').concat(company.address.state).concat(' ').concat(company.address.postalCode);
                                company.displayText = company.name.concat(' ');
                                company.displayText = this.checkForLegalInformation(company.displayText, company).concat(address);
                                companyMap.set(company.companyId, company);
                            }
                            return of(companyMap);
                        } else {
                            const result = {
                                error: true
                            };
                            return of(result);
                        }
                    }),
                catchError((errorDetails: HttpErrorResponse) => {
                    const result = {
                        errorDetails,
                        apiError: true
                    };
                    return of(result);
                }));
    }
}
