import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { commonConstants } from '../../../../CONSTANTS/tpi-common-constants';

@Component({
    selector: 'app-state',
    templateUrl: './state.component.html'
})
export class StateComponent {
    @Input() parentFormGroup: UntypedFormGroup;
    @Input() state: string;
    @Output() stateChange = new EventEmitter<string>();
    @Input() stateError: boolean;
    label: string;
    text: string;
    widthValue: any;
    @Input() set type(inputType: string) {
        if (inputType === commonConstants.PROPERTY_FLOW) {
            this.widthValue = 20;
        } else if (inputType === commonConstants.AUTO_FLOW) {
            this.text = 'Select';
            this.label = 'State';
            this.widthValue = 'full';
        }
    }
}
