<div *ngIf="isPrintConfirmation" class="bolt-container noscreen">
    <div class="bolt-row border-bottom-header padding-header-x1">
        <div class="bolt-col-4"></div>
        <div class="bolt-col-4 print-padding-left-3x1">
            <bolt-logo type="nw-mark"></bolt-logo>
        </div>
        <div class="bolt-col-4"></div>
    </div>
    <div class="bolt-row border-solid-confirmation bolt-space-inner-xl bolt-space-right-x1 bolt-space-left-x1">
        <div class="bolt-row">
            <bolt-icon class="padding-top-xs" name="checkmark-circle-filled" color="dark-mint"></bolt-icon>
            <div class="print-padding-left-lg bolt-heading-md">Your request has been submitted</div>
        </div>
        <div class="print-padding-content bolt-row">
            <ng-template [ngTemplateOutlet]="lienHolderTemp"></ng-template>
            <ng-template [ngTemplateOutlet]="mortgageCompanyTemp"></ng-template>
        </div>
    </div>
</div>

<bolt-modal class="noprint" #elModal type="confirm" heading="Your request has been submitted" primarybutton="Close" tertiarybutton="Print"
    (bolt-modal-tertiary-button-click)="printSearchEditFlow($event)" (bolt-modal-primary-button-click)="closeModalForm()">
    <ng-template [ngTemplateOutlet]="lienHolderTemp"></ng-template>
    <ng-template [ngTemplateOutlet]="mortgageCompanyTemp"></ng-template>
</bolt-modal>

<ng-template #lienHolderTemp>
    <div id="main" *ngIf="lienHolder != undefined && type === 'auto'" class="bolt-space-left-md text-details">
        <div class="bolt-row">
            <p class="long-text">Thank you for submitting your request. Please allow 48 hours for the change to be made
                and reflected on the website.
                A policy change notice will be sent to the updated address.</p>
        </div>

        <div class="bolt-row">
            <div class="header-text">policy number:</div>
            <div class="bolt-space-left-md">{{lienHolder.policyNumber}}</div>
        </div>
        <div class="bolt-row bolt-space-bottom-md">
            <div class="header-text">policy term:</div>
            <div class="bolt-space-left-md">{{lienHolder.policyTerm}}</div>
        </div>

        <div class="bolt-row long-text">
            <p><span class="bold-text bolt-space-right-md">New loan/lease
                    address:</span>{{lienHolder.additionalInterest.companyName}},
                {{lienHolder.additionalInterest.address.addressLine1}},
                {{lienHolder.additionalInterest.address.addressLine2 ?
                lienHolder.additionalInterest.address.addressLine2 + ',' : ''}}
                {{lienHolder.additionalInterest.address.city}},
                {{lienHolder.additionalInterest.address.state}} {{lienHolder.additionalInterest.address.postalCode}}
            </p>
        </div>
        <div class="bolt-row">
            <div class="header-text">change effective date:</div>
            <div class="bolt-space-left-md">{{lienHolder.changeEffectiveDate}}</div>
        </div>
    </div>
</ng-template>


<ng-template #mortgageCompanyTemp>

    <div id="main" *ngIf="mortgageCompany != undefined && type==='property'" class="bolt-space-left-md text-details">
        <div class="bolt-row">
            <p class="long-text">The change(s) won’t be reflected on the site until the <span class="header-text">Change
                    effective date</span>. <span class="header-text">Print</span> this page as a reminder. A policy
                change notice will be sent to the updated address.</p>
        </div>
        <div class="bolt-row">
            <div class="header-text">policy number:</div>
            <div class="bolt-space-left-md">{{mortgageCompany.policyNumber}}</div>
        </div>
        <div class="bolt-row">
            <div class="header-text">loan number:</div>
            <div class="bolt-space-left-md nwpii">{{mortgageCompany.loanNumber}}</div>
        </div>
        <div class="bolt-row bolt-space-bottom-md">
            <div class="header-text">policy term:</div>
            <div class="bolt-space-left-md">{{mortgageCompany.policyTerm}}</div>
        </div>

        <div class="bolt-row long-text">
            <p><span class="bold-text bolt-space-right-md">
                    New mortgage company address:</span>{{mortgageCompany.mortgageCompanyAddress.displayText}}
            </p>
        </div>
        <div class="bolt-row">
            <div class="header-text">change effective date:</div>
            <div class="bolt-space-left-md">{{mortgageCompany.changeEffectiveDate}}</div>
        </div>
    </div>

</ng-template>