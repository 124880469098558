import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LANDING_PAGES } from '../../../CONSTANTS/tpi-common-constants';
import { LoggerService } from '../../../tpi-services/logger/logger.service';

@Component({
    selector: 'tpi-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
    constructor(private logger: LoggerService,
        private router: Router) { }

    routeToLogin($event): void {
        this.logger.info('routeToLogin: clicked login to account');
        this.router.navigate([LANDING_PAGES.LANDING]);
    }
}
