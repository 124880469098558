import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dmx-error-modal',
    templateUrl: './dmx-error-modal.component.html'
})
export class DMXErrorModalComponent {
    @Output() clickOKButtonEmit = new EventEmitter<boolean>();
    @Input() showModal: boolean;

    clickOKButton(): void {
        this.clickOKButtonEmit.emit(true);
    }
}
