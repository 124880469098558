import { Component, Inject, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TPISession } from '../../tpi-models/general/tpisession';
import { LoggerService } from '../../tpi-services/logger/logger.service';
import { LoginLogoutService } from '../../tpi-services/login-logout/login-logout.service';
import { SessionService } from '../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent {
    @Input() session: TPISession;
    isLoggedIn = false;

    // eslint-disable-next-line max-params
    constructor(private sessionService: SessionService,
        private loggerService: LoggerService,
        private loginLogoutService: LoginLogoutService,
        private router: Router,
        private ngZone: NgZone,
        @Inject('window') private window) {
        this.sessionService.loginSubject().subscribe({
            next: (isLogin: boolean) => {
                this.isLoggedIn = isLogin;
            }
        });
    }

    logout(): void {
        this.loggerService.info('User logout', { session: this.sessionService.getSession() });
        this.loggerService.logCurrentContents();
        sessionStorage.clear();
        this.sessionService.isLoggedIn = false;
        this.sessionService.deleteAllCookies();
        this.loginLogoutService.logout();
    }
}
