<h1 class="bolt-heading-md margin-bottom-auto">
    Policy Details
</h1>
<div *ngIf="!policyInformationDetail" class="bolt-row padding-top-lg bolt-body-copy-sm">
    <div class="bolt-col">Policy details are not available at this time.</div>
</div>
<div *ngIf="policyInformationDetail">
    <div class="bolt-row padding-top-lg">
        <div class="bolt-col-6 section-full">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                policy number:
            </h2>
            <span class="bolt-body-copy-sm">{{
                policyInformationDetail.policyNumber
                }}</span>
        </div>
        <div class="bolt-col-6 section-full">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                policy status:
            </h2>
            <span>{{
                policyInformations.policyStatus
                }}</span>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-6 section-full">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">policy type:</h2>
            <span class="bolt-body-copy-sm">{{
                policyInformations.policyType
                }}</span>
        </div>
        <div class="bolt-col-6 section-full">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                {{
                isActiveTab == 'auto'
                ? 'effective/term date:'
                : 'effective date:'
                }}
            </h2>
            <span class="bolt-body-copy-sm">{{
                policyInformationDetail.policyStartDate | date: 'MM/dd/yyyy'
                }}</span>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-6 section-full">
            <h2 class="element-text bolt-heading-sm bolt-body-copy-sm" *ngIf="isActiveTab == 'auto'">
                renewal/<br class="noscreen show-element-md" />expiration date:
            </h2>
            <span class="bolt-body-copy-sm" *ngIf="isActiveTab == 'auto'">{{
                policyInformationDetail.policyEndDate | date: 'MM/dd/yyyy'
                }}</span>
            <h2 class="element-text bolt-heading-sm bolt-body-copy-sm" *ngIf="isActiveTab == 'property'">
                renewal date:
            </h2>
            <span class="bolt-body-copy-sm" *ngIf="isActiveTab == 'property'">{{
                policyInformationDetail.policyEndDate | date: 'MM/dd/yyyy'
                }}</span>
        </div>
        <div class="bolt-col-6 section-full">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                original policy date:
            </h2>
            <span class="bolt-body-copy-sm">{{
                policyInformationDetail.originalPolicyStartDate
                | date: 'MM/dd/yyyy'
                }}</span>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-6 section-full">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                cancellation date:
            </h2>
            <span class="bolt-body-copy-sm">{{
                policyInformations.policyStatus != 'Active'
                ? cancellationDetailsForTPIs === null
                ? 'Not Available'
                : (cancellationDetailsForTPIs.cancellationEffectiveDate
                | date: 'MM/dd/yyyy')
                : 'Not Applicable'
                }}</span>
        </div>
        <div class="bolt-col-6 section-full">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                cancellation reason:
            </h2>
            <span class="bolt-body-copy-sm">{{
                policyInformations.policyStatus != 'Active'
                ? cancellationDetailsForTPIs === null
                ? 'Not Available'
                : cancellationDetailsForTPIs.reason
                : 'Not Applicable'
                }}</span>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-6 section-full">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">lapse date:</h2>
            <span class="bolt-body-copy-sm">Not Available</span>
        </div>
        <div class="bolt-col-6 section-full" *ngIf="isActiveTab == 'auto'">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">
                excluded/non-driver(s):
            </h2>
            <div class="bolt-body-copy-sm" *ngIf="autoDrivers.excludedNonDriver">
                <div *ngIf="autoDrivers.excludedNonDriver.length > 0">
                    <div *ngFor="let item of autoDrivers.excludedNonDriver" class="bolt-body-copy-sm">
                        {{ item | titlecase }}
                        <br />
                    </div>
                </div>
                <div *ngIf="autoDrivers.excludedNonDriver.length == 0" class="bolt-body-copy-sm">
                    None
                </div>
            </div>
            <div *ngIf="!autoDrivers.excludedNonDriver" class="bolt-body-copy-sm">
                None
            </div>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-6 section-full" *ngIf="isActiveTab == 'auto'">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">driver(s):</h2>
            <div *ngIf="autoDrivers?.driversFullName?.length > 0">
                <div *ngFor="let item of autoDrivers.driversFullName" class="bolt-body-copy-sm">
                    {{ item | titlecase }}
                </div>
            </div>

            <div *ngIf="!(autoDrivers?.driversFullName?.length > 0)" class="bolt-body-copy-sm">
                Not Available
            </div>
        </div>
    </div>
    <div class="bolt-row noprint mobile-margin-bottom-lg" *ngIf="isActiveTab == 'auto'">
        <a [href]="contractsEndorsements" target="_blank" (click)="contractsEndorsementsURL()" class="link bolt-body-copy-sm">Policy Contracts & Endorsements</a>
    </div>
</div>