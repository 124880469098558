import { CommonModule, DatePipe } from '@angular/common';
import {
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SplunkService } from '@nationwide/client-logging';
import { APICommonService } from '@nationwide/core-component-library-base';
import {
    CoveragesRequestsService,
    DriverRequestsService,
    PolicyRequestsModule,
    PolicyRequestsService,
    VehicleRequestsService
} from '@nationwide/dgs-internet-servicing-policy-requests';
import {
    DistributionPartnerInformationServiceConfig,
    distributionPartnerInformationServiceConfigToken,
    DpimInformationRoleModule
} from '@nationwide/internet-servicing-dpim-requests';
import { environment } from '../../../environments/environment';
import { EcifService } from '../../service/ecif.service';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { DistributionPartnerIndividualInfoService } from '../../shared/services/distribution-partner-individual-info/distribution-partner-individual-info.service';
import { SA360Service } from '../../shared/services/sa360-service/sa360-service';
import { SharedServicesModule } from '../../shared/services/shared-services.module';
import { LoggerService } from '../../tpi-services/logger/logger.service';
import { EditMortgageService } from '../../tpi-services/shared-services/edit-mortgage.service';
import { GisService } from '../../tpi-services/shared-services/gis.service';
import { PolicySearchResultsService } from '../../tpi-services/tpi-policy-inquiry/policy-search-results.service';
import { PolicySearchService } from '../../tpi-services/tpi-policy-inquiry/policy-search.service';
import { SessionService } from '../../tpi-services/tpi-session/session.service';
import { UtilsService } from '../../tpi-services/utility/utils-service.service';
import { EditLienholderService } from './../../tpi-services/shared-services/edit-lienholder.service';
import { MailingAddressComponent } from './../mailing-address/mailing-address.component';
import { AgentDetailsComponent } from './agent-details/agent-details.component';
import { LoanLeaseDetailsComponent } from './auto-lienholder/loan-lease-details.component';
import { AutoNoSearchComponent } from './auto-no-search/auto-no-search.component';
import { AutoSearchComponent } from './auto-search/auto-search.component';
import { ButtonBarComponent } from './button-bar/button-bar.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CoverageDetailsComponent } from './coverage-details/coverage-details.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { PolicyInquiryComponent } from './policy-inquiry.component';
import { PropertyAddressComponent } from './property-address/property-address.component';
import { PropertyBillingInfoComponent } from './property-billing-info/property-billing-info.component';
import { PropertyEndorsementComponent } from './property-endorsement/property-endorsement.component';
import { PropertyMortgageInfoComponent } from './property-mortgage-info/property-mortgage-info.component';
import { PropertySearchComponent } from './property-search/property-search.component';
import { SearchResultsComponent } from './shared-components/search-results/search-results.component';
import { StateComponent } from './shared-components/state/state.component';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';

const distributionPartnerInformationServiceConfig: DistributionPartnerInformationServiceConfig =
{
    retrieveDistributionPartnerInfoUrl:
        environment.GETDISTRIBUTIONPARTNER_INFO.url,
    clientId: environment.authorize.client_id
};

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedComponentsModule,
        PolicyRequestsModule,
        ReactiveFormsModule,
        SharedServicesModule,
        DirectivesModule,
        DpimInformationRoleModule
    ],
    exports: [
        AutoSearchComponent,
        SearchResultsComponent,
        ButtonBarComponent,
        AutoNoSearchComponent,
        LoanLeaseDetailsComponent,
        StateComponent,
        ClientInfoComponent,
        PolicyDetailsComponent,
        VehicleDetailsComponent,
        CoverageDetailsComponent,
        AgentDetailsComponent,
        PolicyInquiryComponent,
        PolicyRequestsModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        PropertySearchComponent,
        ReactiveFormsModule
    ],
    declarations: [
        AutoSearchComponent,
        SearchResultsComponent,
        ButtonBarComponent,
        AutoNoSearchComponent,
        LoanLeaseDetailsComponent,
        MailingAddressComponent,
        StateComponent,
        ClientInfoComponent,
        PolicyDetailsComponent,
        VehicleDetailsComponent,
        CoverageDetailsComponent,
        AgentDetailsComponent,
        PolicyInquiryComponent,
        PropertyEndorsementComponent,
        PropertyAddressComponent,
        PropertyBillingInfoComponent,
        PropertyMortgageInfoComponent,
        ConfirmationModalComponent,
        PropertySearchComponent
    ],
    providers: [
        { provide: 'policyRequestsConfig', useValue: environment },
        { provide: 'window', useValue: window },
        { provide: 'logger', useClass: LoggerService },
        CoveragesRequestsService,
        APICommonService,
        SessionService,
        HttpClient,
        EcifService,
        GisService,
        PolicyRequestsService,
        DriverRequestsService,
        VehicleRequestsService,
        DatePipe,
        UtilsService,
        LoggerService,
        SplunkService,
        EditLienholderService,
        EditMortgageService,
        DistributionPartnerIndividualInfoService,
        SA360Service,
        PolicySearchService,
        PolicySearchResultsService,
        {
            provide: distributionPartnerInformationServiceConfigToken,
            useFactory: (): DistributionPartnerInformationServiceConfig =>
                distributionPartnerInformationServiceConfig
        }
    ],
    bootstrap: [PolicyInquiryComponent]
})
export class PolicyInquiryModule { }
