/* eslint-disable @typescript-eslint/naming-convention */
export const commonConstants = {
    ADMIN_SYSTEM: 'PolicyCenter',
    AUTO_FLOW: 'auto',
    PROPERTY_FLOW: 'property',
    JWT: 'jwt',
    WEB: 'web',
    PLACEHOLDER: 'placeholder',
    COVERAGES: {
        FULL_SAFTY_GLASS_DESCRIPTION: 'Full Coverage Safety Glass',
        FULL_SAFTY_GLASS_CODE: 'COMPSafetyGlass'
    },
    VEHICLE: 'Vehicle',
    POLICY: 'Policy',
    TRUE_STRING: 'true',
    FALSE_STRING: 'false',
    POLICY_STATUS_RENEWING: 'Renewing',
    POLICY_STATUS_BOUND: 'Bound',
    POLICY_STATUS_QUOTED: 'Quoted',
    DEFAULT_WAIT_INDICATOR_DURATION: 7000,
    VALIDATION_CONSTANT: {
        MIN_FIVE_DAYS: 5,
        MIN_THIRTY_DAYS: 30
    },
    POLICY_STATUS: {
        ACTIVE: 'Active',
        CANCELLED: 'Cancelled'
    },
    AUTO: {
        LOAN: 'Loan',
        AUTO_SEARCH_SERVICE_REQUEST: {
            ADDRESS_LIMIT: '1',
            CUSTOMER_TYPE: 'A',
            DEPTH: '3',
            RETURN_EMAIL: 'Y'
        }
    },
    PROPERTY: {
        PRODUCT_TYPE_HOMEOWNER: 'Home Owner',
        PRODUCT_TYPE_CONDO: 'Condo',
        PRODUCT_TYPE_DWELLINGFIRE: 'Dwelling Fire',
        PRODUCT_TYPE_TENANT: 'Tenant'
    },
    WINDOWS: {
        SELF: '_self',
        PARENT: '_parent'
    },
    PAGES: {
        PERSONAL_LINES_BILLING: 'personal-lines-billing',
        AUTO: 'Auto',
        PROPERTY: 'Property',
        CLIENT_LOGGING: 'client-logging',
        LOCAL_DOMAIN: 'localhost:4200'
    },
    POLICY_NUMBER_CONSTANTS: {
        X_DIGIT: 'X'
    },
    API_CONSTANT: {
        TPI_API_NAME: 'thirdpartyinquiryexperience_1',
        VERSION_V1: 'v1',
        RESPONSE_MAPPING: {
            US: 'US',
            NOT_AVAILABLE: 'Not Available'
        }
    },
    STORE: {
        MODIFY_SEARCHFIELDS: 'MODIFY_SEARCHFIELDS',
        MODIFY_SELECTED_COMPANY: 'MODIFY_SELECTED_COMPANY',
        MODIFY_USERFIELDS: 'MODIFY_USERFIELDS'
    },
    RESPONSE_STATUS: {
        SUCCESS: 'success',
        ERROR: 'error',
        FAILED: 'failed'
    },
    BULK_CONSTANTS: {
        JSPREADSHEET_TOKEN: 'NjNiMDNmNDJiZjVlN2FlOGIzMjM5ZjA3ODg5NjA1MzI5ZWMxM2I5NzBlMWViMDEwNzBjYTM5MTc5MTkxYjgwZDEyYzgxZjRiYzE3OTVmYTQ5Y2RmNjJkZGIzN2Q0ZDc5N2QyY2YwNWI2OTdmNzk3NmNlZDIzYjA5Njg4NGRlOTEsZXlKdVlXMWxJam9pVkdsdGIzUm9lU0JJYjJ4dFpYTWlMQ0prWVhSbElqb3hOek00TnpFek5qQXdMQ0prYjIxaGFXNGlPbHNpYm1GMGFXOXVkMmxrWlM1amIyMGlMQ0ozWldJaUxDSnNiMk5oYkdodmMzUWlYU3dpY0d4aGJpSTZJak16SWl3aWMyTnZjR1VpT2xzaWRqY2lMQ0oyT0NJc0luWTVJaXdpZGpFd0lpd2labTl5YlhWc1lTSXNJbVp2Y20xeklpd2ljbVZ1WkdWeUlpd2ljR0Z5YzJWeUlpd2lhVzF3YjNKMFpYSWlMQ0oyWVd4cFpHRjBhVzl1Y3lJc0ltTnZiVzFsYm5Seklpd2ljMlZoY21Ob0lpd2lZMmhoY25SeklsMTk=',
        LOAN_NUMBER_TITLE: 'Loan Number',
        ZIPCODE_TITLE: 'Policy ZIP Code',
        POLICY_NUMBER_TITLE: 'Policy Number',
        STYLE_CONSTANTS: {
            PROP_STYLE: 'style',
            ERROR_STYLE: 'color: #CB0003; font-style: italic;',
            ERROR_RESET: 'color: #000000; font-style: normal;'
        },
        MAX_UPLOAD: 30,
        SAMPLE_XLSX_DOWNLOAD_URL: '/assets/bulk/samplebulkmortgageupload.xlsx'
    },
    getPropertyTypeDisplayName: (productType: string): string => {
        switch (productType) {
            case 'homeowner':
                return 'Homeowner';
            case 'condo':
                return 'Condominium';
            case 'dwellingFire':
                return 'Dwelling fire';
            case 'tenant':
                return 'Tenant';
            default:
                return 'Property';
        }
    },
    ERROR_CONSTANTS: {
        ERROR_404: '404',
        ERROR_403: '403',
        ERROR_406: '406',
        ERROR_461: '461',
        ERROR_500: '500'
    },
    HTTP_RESPONSE_CODE: {
        STATUS_CODE_200: 200,
        STATUS_CODE_201: 201,
        STATUS_CODE_400: 400,
        STATUS_CODE_401: 401,
        STATUS_CODE_500: 500

    },
    USER_STATUS: {
        PENDING: '3',
        DISABLED: '2',
        ACTIVE: '1'
    },
    COOKIES: {
        EXPIRES: 'expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
};
import { MailingAddress } from '../tpi-models/general/mailingAddress';

const nwPOBox = {

    getGroupA: {
        mailPaymentTo: {
            to: 'Nationwide Insurance',
            address: {
                addressLine1: 'PO Box 742522',
                addressLine2: '',
                city: 'Cincinnati',
                state: 'OH',
                postalCode: '45274-2522'
            }
        },
        overnightPaymentsTo: {
            to: 'Nationwide Insurance',
            address: {
                addressLine1: 'Fifth Third Retail Lockbox',
                addressLine2: 'Lockbox#742522',
                addressLine3: '5050 Kingsley Dr.',
                addressLine4: '1MOC1N',
                city: 'Cincinnati',
                state: 'OH',
                postalCode: '45227'
            }
        },
        generalCorrespondence: {
            to: 'Nationwide Insurance',
            address: {
                addressLine1: 'PO Box 183260',
                addressLine2: '',
                city: 'Columbus',
                state: 'OH',
                postalCode: '43218-4260'
            }
        }
    }
};
export const retrieveMailingAddress: {
    [state: string]: MailingAddress;
} = {
    CT: nwPOBox.getGroupA,
    DE: nwPOBox.getGroupA,
    ME: nwPOBox.getGroupA,
    NH: nwPOBox.getGroupA,
    NY: nwPOBox.getGroupA,
    NJ: nwPOBox.getGroupA,
    PA: nwPOBox.getGroupA,
    RI: nwPOBox.getGroupA,
    VT: nwPOBox.getGroupA,
    AL: nwPOBox.getGroupA,
    FL: nwPOBox.getGroupA,
    GA: nwPOBox.getGroupA,
    LA: nwPOBox.getGroupA,
    MA: nwPOBox.getGroupA,
    MD: nwPOBox.getGroupA,
    NC: nwPOBox.getGroupA,
    PR: nwPOBox.getGroupA,
    SC: nwPOBox.getGroupA,
    VA: nwPOBox.getGroupA,
    DC: nwPOBox.getGroupA,
    IL: nwPOBox.getGroupA,
    IN: nwPOBox.getGroupA,
    KY: nwPOBox.getGroupA,
    MI: nwPOBox.getGroupA,
    OH: nwPOBox.getGroupA,
    TN: nwPOBox.getGroupA,
    WV: nwPOBox.getGroupA,
    AK: nwPOBox.getGroupA,
    AR: nwPOBox.getGroupA,
    AZ: nwPOBox.getGroupA,
    CA: nwPOBox.getGroupA,
    CO: nwPOBox.getGroupA,
    HI: nwPOBox.getGroupA,
    ID: nwPOBox.getGroupA,
    IA: nwPOBox.getGroupA,
    KS: nwPOBox.getGroupA,
    MN: nwPOBox.getGroupA,
    MO: nwPOBox.getGroupA,
    MS: nwPOBox.getGroupA,
    MT: nwPOBox.getGroupA,
    NE: nwPOBox.getGroupA,
    NM: nwPOBox.getGroupA,
    NV: nwPOBox.getGroupA,
    ND: nwPOBox.getGroupA,
    OK: nwPOBox.getGroupA,
    OR: nwPOBox.getGroupA,
    SD: nwPOBox.getGroupA,
    TX: nwPOBox.getGroupA,
    UT: nwPOBox.getGroupA,
    WA: nwPOBox.getGroupA,
    WI: nwPOBox.getGroupA,
    WY: nwPOBox.getGroupA
};
export const CancellationDetailsReason: {
    [key: string]: string;
} = {
    BackOut_Ext: 'Other',
    Deceased_Ext: 'Other',
    Destroyed_Ext: 'Other',
    DuplicatePolicy_Ext: 'Other',
    FlatCancel_Ext: 'Other',
    FullTermIRT_Ext: 'Other',
    InterRegionalTransfer_Ext: 'Other',
    InterimIRT_Ext: 'Other',
    Military_Ext: 'Other',
    Miscellaneous_Ext: 'Other',
    MovedOutofTerritoy_Ext: 'Other',
    NBCorrection_Ext: 'Other',
    Other_Ext: 'Other',
    OutOfOperatingTerritory_Ext: 'Other',
    Repossessed_Ext: 'Other',
    RequestCorrection_Ext: 'Other',
    RequestedByOtherThanInsured_Ext: 'Other',
    RequestedForcedProRate_Ext: 'Other',
    TierTransfer_Ext: 'Other',
    Transfer_Ext: 'Other',
    TransferRewrite_Ext: 'Other',
    TransferOtherLine_Ext: 'Other',
    Wrecked_Ext: 'Other',
    unresolvedcontingency: 'Other',
    Miscellaneous: 'Other',
    Request_Ext: 'Insured request',
    RequestedInsured_Ext: 'Insured request',
    sold: 'Insured request',
    Badcheck_Ext: 'Non-payment',
    NonpayBalanceDue_Ext: 'Non-payment',
    NonpayRenewal_Ext: 'Non-payment',
    CompanyCancellation_Ext: 'Company cancellation',
    Reject_Ext: 'Company cancellation',
    RejectDiscovery_Ext: 'Company cancellation',
    CompanySecondTerm_Ext: 'Company cancellation'
};

export const propertyEndorsementConstants = {
    BasicCoverageId: 'BasicCoverage',
    DwellingReplacementCostId: 'DwellingReplacementCost',
    FireBuildingId: 'FireBuilding',
    FireBuildingLimitId: 'FireBuildingLimit',
    BasicCoverageLimitId: 'BasicCoverageLimit',
    BasicCoverageDeductibleId: 'BasicCoverageDeductible',
    BuildingAdditionsAlterationsLimitId: 'BuildingAdditionsAlterationsLimit',
    BuildingAdditionsAlterationsId: 'BuildingAdditionsAlterations',
    GuaranteedReplacementCostId: 'GuaranteedReplacementCost',
    SectionIDeductibleId: 'SectionIDeductibles',
    WindHailPerilDeductibleId: 'WindHailPerilDeductible',
    HurricanePerilDeductibleId: 'HurricanePerilDeductible',
    BasicCoverage: 'Basic Coverage',
    Dwelling: 'Dwelling',
    BasicCoverageLimit: 'CURRENT DWELLING AMOUNT',
    BasicCoverageDeductible: '	CURRENT DEDUCTIBLE',
    DwellingReplacementCost: 'Dwelling Replacement Cost',
    DwellingReplacementCost150: 'Dwelling Replacement Cost - 150%',
    CurrentPremium: 'current premium',
    WindHailPerilDeductible: 'Wind/Hail deductible',
    HurricanePerilDeductible: 'Hurricane deductible',
    GuaranteedReplacementCost: 'Dwelling Guaranteed Replacement Cost',
    AllPerilDeductibleId: 'AllPerilDeductible',
    SameAsAllPeril: 'Same as All Peril',
    None: 'None',
    GUARANTEED: 'guaranteed',
    DWELLING_FIRE_CONSTANT: 'dwellingFire',
    CONDO_CONSTANT: 'condo'
};

export const mortgageConstant = {
    mortgagee1: 'Mortgagee1',
    additionalInsuredExt: 'AdditionalInsured_Ext',
    mortgageeExtn: 'Mortgagee_Ext'
};

export const EVENTS = {
    CLICK: 'click',
    KEY_PRESS: 'keypress',
    PASTE: 'paste',
    INPUT: 'input'
};

export const ACCEPT_REGEX = {
    ONLY_DIGITS: /[^\d]*/g
};

export const FORM_STATUS = {
    INVALID: 'INVALID',
    VALID: 'VALID'
};

export const BILLING_CONSTANT = {
    RECONCILED: 'Reconciled'
};

export const usStates = [{ value: 'AL', label: 'AL' },
{ value: 'AK', label: 'AK' },
{ value: 'AR', label: 'AR' },
{ value: 'AZ', label: 'AZ' },
{ value: 'CA', label: 'CA' },
{ value: 'CO', label: 'CO' },
{ value: 'CT', label: 'CT' },
{ value: 'DC', label: 'DC' },
{ value: 'DE', label: 'DE' },
{ value: 'FL', label: 'FL' },
{ value: 'GA', label: 'GA' },
{ value: 'HI', label: 'HI' },
{ value: 'IA', label: 'IA' },
{ value: 'ID', label: 'ID' },
{ value: 'IL', label: 'IL' },
{ value: 'IN', label: 'IN' },
{ value: 'KS', label: 'KS' },
{ value: 'KY', label: 'KY' },
{ value: 'LA', label: 'LA' },
{ value: 'MA', label: 'MA' },
{ value: 'MD', label: 'MD' },
{ value: 'ME', label: 'ME' },
{ value: 'MI', label: 'MI' },
{ value: 'MN', label: 'MN' },
{ value: 'MO', label: 'MO' },
{ value: 'MS', label: 'MS' },
{ value: 'MT', label: 'MT' },
{ value: 'NC', label: 'NC' },
{ value: 'NE', label: 'NE' },
{ value: 'NH', label: 'NH' },
{ value: 'NJ', label: 'NJ' },
{ value: 'NM', label: 'NM' },
{ value: 'NV', label: 'NV' },
{ value: 'NY', label: 'NY' },
{ value: 'ND', label: 'ND' },
{ value: 'OH', label: 'OH' },
{ value: 'OK', label: 'OK' },
{ value: 'OR', label: 'OR' },
{ value: 'PA', label: 'PA' },
{ value: 'RI', label: 'RI' },
{ value: 'SC', label: 'SC' },
{ value: 'SD', label: 'SD' },
{ value: 'TN', label: 'TN' },
{ value: 'TX', label: 'TX' },
{ value: 'UT', label: 'UT' },
{ value: 'VT', label: 'VT' },
{ value: 'VA', label: 'VA' },
{ value: 'WA', label: 'WA' },
{ value: 'WI', label: 'WI' },
{ value: 'WV', label: 'WV' },
{ value: 'WY', label: 'WY' }];

export const LOGGER_CONSTANTS = {
    prefixApp: 'TPI-PLDS-V2: ',
    error: 'Error',
    success: 'Success',
    bulkEdit: 'Bulk edit',
    manageUser: 'Manage User',
    splunkDashboard: 'splunk-dashboard',
    company: {
        create: {
            createOrModifiedStarted: 'create company started',
            createdOrModifiedCompany: 'successfully created company',
            createOrModifiedTitle: 'add company',
            createOrModifiedFailed: 'failed to create company'
        },
        edit: {
            createOrModifiedStarted: 'modify company started',
            createdOrModifiedCompany: 'successfully modified company',
            createOrModifiedTitle: 'modify company',
            createOrModifiedFailed: 'failed to modify company'
        }
    }
};

export const SUCCESS_MESSAGE_CONSTANTS = {
    bulkSubmitSuccess: `You have successfully submitted @@x (of @@y policies) for mortgage updates. Policies will be updated within the next 5 business days.`
};

export const TIME_LIMITS = {
    mfaTimeLimit: `15 minutes`
};

export const enum CookieKeys {
    SessionUUID = 'session_uuid',
    TPIToken = 'tpi_token',
    TPITokenExp = 'tpi_token_exp'
}

export const SESSION_KEYS = {
    sessionId: 'sessionId',
    transactionId: 'transactionId'
};

export const exposedRoutes = ['/update-expired-password', '/update-expired-password/success', '/forgot-password', '/forgot-password-challenge', '/forgot-password-contact', '/forgot-password/success', '/forgot-password/error', '/forgot-password/account-locked', '/new-user-password-change', '/error', '/logout'];

export const PING_URLS = ['authorize', 'publicKeys/v1/keys', 'enterprise-user-auth/v2/userinfo'];

const paginationStart = 10;
const paginationIncrement = 20;
const paginationEnds = 30;

export const pageOptions = {
    paginationRules: [paginationStart, paginationIncrement, paginationEnds]
};

export const COMPANY_CONSTANTS = {
    deleteModal: {
        modalButtonText: 'Delete',
        modalHeading: 'Delete company',
        modalText: `Are you sure you want to delete this company? This action can’t be undone. Any associated users will be deleted and will no longer have access.`,
        modalTertiaryButtonText: 'Cancel',
        successModalText: `
        This company has been removed from our system. If you wish to add the deleted company back into the system, you will need to reenter their information manually.`
    }
};

export const KEYCODES = {
    ENTER: 13
};

export const LOGIN_LOGOUT_ERRORS = {
    RESPONSE: {
        SYSTEM_ERROR: 'System error',
        ACCOUNT_DISABLED: 'Account disabled',
        MFA_LOCKED: 'MFA Locked',
        USER_NOT_LOCATED: 'User not located'
    }
};

export const LANDING_PAGES = {
    LANDING: '/landing',
    POLICY_SEARCH: '/policy-search',
    LOGOUT: '/logout',
    TERMS_AND_CONDITIONS: '/terms-and-conditions'
};

