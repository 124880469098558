import { Component, Input } from '@angular/core';
import { BillingMappingResponse } from '../../../tpi-models/general/property-endorsement';

@Component({
    selector: 'app-property-billing-info',
    templateUrl: './property-billing-info.component.html',
    styleUrls: ['./property-billing-info.component.scss']
})
export class PropertyBillingInfoComponent {
    @Input() billingInformation: BillingMappingResponse;
}
