import { Component, Input } from '@angular/core';
import { Coverage, SelectedValue } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/coverages';

@Component({
    selector: 'app-coverage-info',
    templateUrl: './coverage-details.component.html',
    styleUrls: ['./coverage-details.component.scss']
})
export class CoverageDetailsComponent {
    @Input() coverages: Array<Coverage>;
    selectedObjValue = <SelectedValue>{};
    selectedValue: Array<SelectedValue> = [];
}
