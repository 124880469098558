import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TpiSessionConfig } from '../../tpi-models/general/tpi-session-config';
import { TPISession } from '../../tpi-models/general/tpisession';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
    @Input() session: TPISession;
    manageCompany = '3';
    manageUser = '1';
    manageWeb = '6';
    tpiSessionConfig: TpiSessionConfig = environment.tpiToggles;
}
