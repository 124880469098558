import { Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of, Subscription, timer } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CookieKeys, exposedRoutes, LANDING_PAGES } from './CONSTANTS/tpi-common-constants';
import { CompanyInfo } from './tpi-models/general/company-info';
import { TPISession } from './tpi-models/general/tpisession';
import { LoggerService } from './tpi-services/logger/logger.service';
import { LoginLogoutService } from './tpi-services/login-logout/login-logout.service';
import { ManageUserAccountsService } from './tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { TpiIdleService } from './tpi-services/shared-services/tpi-idle.service';
import { SessionService } from './tpi-services/tpi-session/session.service';
import { SelectedCompanyStateService } from './tpi-services/tpi-statemanagement/selected-company-state.service';
import { UtilsService } from './tpi-services/utility/utils-service.service';

interface Window {
    FS: {
        identify: any;
        setUserVars: any;
    };
    location: {
        pathname: string;
    };
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    deepLink = false;
    hasSetFullStoryIdentifier = false;
    hideTitle = false;
    millisecond: number = null;
    resetEuaTokenTimerSub = new Subscription();
    session: TPISession;
    sessionExpireModalShow = false;
    sessionExpireWarningModalShow = false;
    showNavigation = true;
    title = 'Nationwide Policy Inquiry';

    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        private loggerService: LoggerService,
        private tpiIdleService: TpiIdleService,
        private route: ActivatedRoute,
        private router: Router,
        private utilsService: UtilsService,
        private storeManagement: SelectedCompanyStateService,
        private loginLogoutService: LoginLogoutService,
        private oidcSecurityService: OidcSecurityService,
        private manageUserAccountsService: ManageUserAccountsService,
        @Inject('window') private window: Window
    ) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.route.firstChild.title.subscribe((title) => {
                this.loggerService.info(`Route Changed to: ${title}`);
            });
            this.route.firstChild.url.subscribe((url) => {
                this.loggerService.info(`Route url: /${url}`);
                this.utilsService.scrollToTopOfPage();
            });
            this.route.firstChild.data.subscribe((data) => {
                this.loggerService.info(`Display left-side nav: ${data['showNavigation']}`);
                this.showNavigation = data['showNavigation'];
                this.deepLink = data['deepLink'];
                this.hideTitle = data['hideTitle'] || false;
            });
        });
    }

    checkIdle(): void {
        this.loggerService.info('Start idle timer: checkIdle()');
        this.tpiIdleService.onIdleEnd().subscribe(() => {
            this.reset();
        });
        this.tpiIdleService.onTimeout().subscribe(() => {
            this.loggerService.info('TPI EUA Timed Out: start...', {
                UserID: this.session.context.session.account.shortName,
                Company:
                    this.session.context.session.account.company.companyDescription,
                Role: this.session.context.session.account.role.id,
                Page: 'Policy-Search',
                Status: 'idle-timeout'
            });
            this.loggerService.logCurrentContents();
            this.sessionExpireModalShow = true;
            this.sessionService.deleteAllCookies();
            this.resetEuaTokenTimerSub.unsubscribe();
            this.sessionService.initializeSessionStorage('');
            this.tpiIdleLogout();
        });
        this.tpiIdleService.onTimeoutWarning().subscribe(() => {
            this.loggerService.info('TPI EUA Idle Warning', {
                UserID: this.session.context.session.account.shortName,
                Company:
                    this.session.context.session.account.company.companyDescription,
                Role: this.session.context.session.account.role,
                Page: 'Policy-Search',
                Status: 'idle-warning'
            });
            this.loggerService.logCurrentContents();
            this.resetEuaTokenTimerSub.unsubscribe();
            this.sessionExpireWarningModalShow = true;
        });
        this.reset();
    }

    closeModal(): void {
        this.loggerService.info('TPI EUA Idle: Clicked Keep working', {
            UserID: this.session.context.session.account.shortName,
            Company:
                this.session.context.session.account.company.companyDescription,
            Role: this.session.context.session.account.role,
            Page: 'Policy-Search',
            Status: 'Keep-Working'
        });
        this.loggerService.logCurrentContents();
        this.resetEuaTokenTimerSub = this.resetEuaTokenTimer();
        this.sessionExpireWarningModalShow = false;
        this.reset();
    }

    getSession(uuid?: any): void {
        this.session = <TPISession>JSON.parse(sessionStorage.getItem('session'));
        if (this.session?.context?.session?.account) {
            const userName = this.session.context.session.account.shortName;
            const company = this.session.context.session.account.company;
            const companyName = company.companyDescription;
            this.storeCompany(company);
            sessionStorage.setItem('userName', userName);
            sessionStorage.setItem('companyName', companyName);
            sessionStorage.setItem('sessionId', this.sessionService.getCookie({ key: CookieKeys.SessionUUID }).toString());
        }
        this.checkIdle();
    }

    initialSession(): void {
        const pathname = this.window.location.pathname;
        this.loggerService.info(`user requested access to path: ${pathname}; exposed paths: ${exposedRoutes}`);
        if (exposedRoutes.filter((path) => pathname.startsWith(path)).length !== 0) {
            if (pathname !== LANDING_PAGES.LANDING) {
                this.sessionService.deleteAllCookies();
                this.sessionService.initializeSessionStorage(null);
                return;
            }
        }

        this.millisecond = 1000;
        const euaTokenExpire = this.sessionService.getCookie({ key: CookieKeys.TPITokenExp });
        if (parseInt(euaTokenExpire, 10) <= Date.now() / this.millisecond) {
            this.sessionExpireModalShow = true;
            this.sessionService.deleteAllCookies();
            this.tpiIdleLogout();
            return;
        }

        this.sessionService.loginSubject().subscribe({
            next: (isLoggedIn: boolean) => {
                if (isLoggedIn === false) {
                    sessionStorage.clear();
                    this.sessionService.deleteAllCookies();
                    this.resetEuaTokenTimerSub.unsubscribe();
                    this.tpiIdleService.endUserSession(true);
                } else {
                    this.getSession();
                    this.resetEuaTokenTimerSub = this.resetEuaTokenTimer();
                }
            }
        });
        this.redirectToChangePasswordOnPolicyAccessDeny();
    }

    ngAfterViewChecked(): void {
        if (this.window?.FS && !this.hasSetFullStoryIdentifier) {
            this.setFullStoryIdentifier();
        }
    }

    ngOnInit(): void {
        this.initialSession();
        this.setEnsightenBootstrap();
        this.loggerService.info('End TPI Initialization');
    }

    redirectToChangePasswordOnPolicyAccessDeny(): void {
        const company: CompanyInfo = this.sessionService.getCompanyDetails();
        const autoAccess = company?.companyAutoAuth;
        const propertyAccess = company?.companyPropertyAuth;

        if (autoAccess === propertyAccess && autoAccess === 0) {
            this.sessionService.redirectToChangePassword();
        }
    }

    redirectToLogoutPage(): void {
        this.loggerService.info('TPI EUA idle warning logout', this.session);
        this.loggerService.logCurrentContents();
        this.sessionService.deleteAllCookies();
        this.loginLogoutService.logout();
    }

    reset(): void {
        this.tpiIdleService.startWatching();
    }

    storeCompany(company: CompanyInfo): void {
        this.storeManagement.updateSelectedCompanyFields({ company });
    }

    tpiIdleLogout(): void {
        this.sessionExpireWarningModalShow = false;
        this.loggerService.info('tpiIdleLogout(): logout on idle ', this.session);
        this.loggerService.logCurrentContents();
        this.loginLogoutService.logout();
    }

    private resetEuaTokenTimer(): Subscription {
        const startTime = 0;
        const minutes = 1;
        const seconds = 5;
        const millisecond = 1000;
        const repeatInterval = minutes * seconds * millisecond;
        const delay = 180;
        return timer(startTime, repeatInterval).pipe(
            switchMap((): Observable<any> => {
                const euaExpire = parseInt(
                    this.sessionService.getCookie({ key: CookieKeys.TPITokenExp }),
                    10
                );
                const currentTimeStamp = Date.now() / this.millisecond;
                if (
                    currentTimeStamp >= euaExpire - delay &&
                    currentTimeStamp < euaExpire
                ) {
                    this.loggerService.info('Resetting Eua Token', {
                        UserID: this.session.context.session.account.shortName,
                        Company:
                            this.session.context.session.account.company.companyDescription,
                        Role: this.session.context.session.account.role,
                        Page: 'Auto',
                        Status: ''
                    });
                    return this.sessionService.resetEuaToken();
                }
                return of(null);
            })).subscribe();
    }

    private setEnsightenBootstrap(): void {
        this.loggerService.info('setEnsightenBootstrap(): Start... Ensighten Bootstrap', this.session);
        const script = window.document.createElement('script');
        script.type = 'text/javascript';
        script.src = environment.ensightenBootstrap;
        window.document.getElementsByTagName('head')[0].appendChild(script);
        this.loggerService.debug('setEnsightenBootstrap(): End... Ensighten Bootstrap', {
            script: window.document.getElementsByTagName('head')[0],
            session: this.session
        });
        this.loggerService.logCurrentContents();
    }

    private setFullStoryIdentifier(): void {
        let username = sessionStorage.getItem('userName');
        const sessionId = sessionStorage.getItem('sessionId');
        if (username) {
            username = username.toLowerCase();
            this.loggerService.info(`Updated full-story displayName to ${username}`, this.session);
            this.window.FS.identify(username, { displayName: username });
        } else {
            this.loggerService.info(`Updated full-story displayName to USER-NOT-FOUND_${sessionId}`, this.session);
            this.window.FS.setUserVars({ displayName: `USER-NOT-FOUND_${sessionId}` });
        }
        this.hasSetFullStoryIdentifier = true;
    }
}
