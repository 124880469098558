import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../tpi-services/tpi-session/session.service';

@Injectable({
    providedIn: 'root'
})
export class OnlyLoggedOutGuard {
    constructor(
        private sessionService: SessionService
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isLoggedIn = this.sessionService.isLoggedIn;
        return !isLoggedIn;
    }
}
