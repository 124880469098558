import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AdditionalInterest, Address } from '@nationwide/dgs-internet-servicing-policy-requests';
import { LienHolder } from '../../../tpi-models/general/lien-holder';
import { MortgageCompanyDetails } from '../../../tpi-models/general/mortgage-company';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
import { LoggerService } from './../../../tpi-services/logger/logger.service';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
    @ViewChild('elModal') elModal;
    @Input() lienHolder: LienHolder = this.setLienHolder();
    @Input() mortgageCompany: MortgageCompanyDetails;
    @Output() printResultEmit = new EventEmitter<boolean>();
    @Input() type: string;
    isPrintConfirmation = false;

    constructor(public utilsService: UtilsService, private loggerService: LoggerService) { }

    closeModalForm(): void {
        this.isPrintConfirmation = false;
        this.printResultEmit.emit(true);
    }

    openModal(): void {
        this.loggerService.info('Load the Confirmation Modal', {
            UserId: sessionStorage.getItem('userName'),
            PolicyNumber: this.lienHolder?.policyNumber || this.mortgageCompany?.policyNumber,
            PolicySystem: 'Policy Center',
            LOB: this.type
        });
        this.isPrintConfirmation = true;
        this.printResultEmit.emit(false);
        this.elModal.nativeElement.openModal();
    }

    printSearchEditFlow(event): void {
        this.loggerService.info('Print of Confirmation modal', {
            UserId: sessionStorage.getItem('userName'),
            PolicyNumber: this.lienHolder?.policyNumber || this.mortgageCompany?.policyNumber,
            PolicySystem: 'Policy Center',
            LOB: this.type
        });
        this.utilsService.printScreen();
        event.preventDefault();
    }

    setLienHolder(): LienHolder {
        const address = <Address>{};
        const additionalInterest = <AdditionalInterest>{ address };
        return <LienHolder>{ additionalInterest };
    }
}
