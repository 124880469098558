import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormElementCoreComponent } from '../../../../shared/components/form-element-core/form-element-core.component';
import { SubmitOptions } from '../../../../tpi-models/general/form-models';

@Component({
    selector: 'app-company-info-form',
    templateUrl: './company-info-form.component.html',
    styleUrls: ['./company-info-form.component.scss']
})
export class CompanyInfoFormComponent extends FormElementCoreComponent {
    @Input() parentForm: FormGroup;
    @Input() submitOptions: SubmitOptions;

    constructor() {
        super();
    }

    sanitizeCompany(): void {
        const companyName = this.parentForm.get('companyName').value;
        this.parentForm.get('companyName').setValue(companyName.trim());
    }

    setCheckBoxValue(formControl: string): void {
        this.parentForm.get(formControl).setValue(!this.parentForm.get(formControl).value);
    }
}
