<div id="print" aria-live="assertive">
    <div *ngIf="isPrintResult" class="noscreen">
        <div class="bolt-row border-bottom-header padding-header-md">
            <div class="bolt-col-4"></div>
            <div class="bolt-col-4 print-padding-left-3x1">
                <bolt-logo type="nw-mark"></bolt-logo>
            </div>
            <div class="bolt-col-4"></div>
        </div>
    </div>
    <div [ngClass]="{ noprint: !isPrintResult }">
        <ng-template [ngTemplateOutlet]="searchResult"></ng-template>
        <ng-template [ngTemplateOutlet]="propertyResult"></ng-template>
    </div>
</div>

<bolt-waiting-indicator #editFlowWaitIndicator id="hiddenWaitDemo" fullscreen starthidden>Please wait while we process this request...</bolt-waiting-indicator>
<app-confirmation-modal [lienHolder]="lienHolder" [mortgageCompany]="mortgageCompanyDetails" [type]="type" (printResultEmit)="enablePrintResult($event)"></app-confirmation-modal>

<ng-template #searchResult>
    <div *ngIf="showAutoResult" aria-live="assertive">
        <div class="bolt-row margin-bottom-x1">
            <div class="bolt-col">
                <app-client-info [person]="person" [policyInformationDetail]="policyInformationDetails" [isActiveTab]="'auto'"></app-client-info>
            </div>
        </div>
        <div class="bolt-row margin-bottom-x1">
            <div class="section-half-width bolt-col-12 bolt-col-sm-6 divider">
                <app-policy-info [policyInformations]="policyInformation" [policyInformationDetail]="policyInformationDetails" [cancellationDetailsForTPIs]="cancellationDetailsForTPI"
                    [autoDrivers]="autoDriver" [isActiveTab]="'auto'"></app-policy-info>
                <div class="divider-bottom"></div>
            </div>
            <div class="section-half-width bolt-col-12 bolt-col-sm-6">
                <app-agent-details [dpimContactInformation]="dpimContactInformation" [isSA360]="dpimSA360.SA360" [isActiveTab]="'auto'"></app-agent-details>
                <div class="divider-bottom"></div>
            </div>
        </div>
        <hr class="nomobile" />
        <div class="bolt-row margin-bottom-x1 margin-top-x1">
            <div class="section-half-width bolt-col-12 bolt-col-sm-6 divider">
                <app-vehicle-info [vehicles]="vehicles"> </app-vehicle-info>
                <div class="divider-bottom"></div>
            </div>
            <div class="section-half-width bolt-col-12 bolt-col-sm-6 bolt-col-sm-6">
                <app-loan-lease-details [vehicles]="vehicles" [policyInformationDetails]="policyInformationDetails" (lienHolderEmit)="editLienHolder($event)"></app-loan-lease-details>
                <div class="divider-bottom"></div>
            </div>
        </div>
        <hr class="nomobile" />
        <div class="margin-bottom-x1">
            <app-coverage-info [coverages]="coverages"> </app-coverage-info>
        </div>
        <div class="divider-bottom"></div>
        <hr class="nomobile" />
        <app-mailing-address [isSA360]="dpimSA360.SA360" [mailingAddress]="mailingAddress" [isActiveTab]="'auto'"></app-mailing-address>
    </div>
</ng-template>

<ng-template #propertyResult>
    <div *ngIf="showPropertyResult" aria-live="assertive" class=" margin-bottom-x1">
        <div class="bolt-row margin-bottom-x1">
            <div class="bolt-col">
                <app-client-info [person]="person" [policyInformationDetail]="policyInformationDetails" [isActiveTab]="'property'"></app-client-info>
            </div>
        </div>
        <div class="bolt-row margin-bottom-x1">
            <div class="section-half-width bolt-col-12 bolt-col-sm-6 divider">
                <app-policy-info [policyInformations]="policyInformation" [policyInformationDetail]="policyInformationDetails" [cancellationDetailsForTPIs]="cancellationDetailsForTPI"
                    [autoDrivers]="autoDriver" [isActiveTab]="'property'"></app-policy-info>
                <div class="divider-bottom"></div>
            </div>
            <div class="section-half-width bolt-col-12 bolt-col-sm-6">
                <app-property-endorsement [propertyEndorsement]="propertyEndorsement" [renewalInformation]="renewalInformation" [policyInformationDetail]="policyInformationDetails"
                    [billingInformation]="billingDetails"></app-property-endorsement>
                <div class="divider-bottom"></div>
            </div>
        </div>
        <hr class="nomobile" />
        <div class="bolt-row margin-bottom-x1 margin-top-x1">

            <div class="section-half-width bolt-col-12 bolt-col-sm-6 divider">
                <app-property-address [propertyAddress]="propertyLocation">
                </app-property-address>
                <div class="divider-bottom"></div>
            </div>
            <div class="section-half-width bolt-col-12 bolt-col-sm-6">
                <app-property-billing-info [billingInformation]="billingDetails"></app-property-billing-info>
                <div class="divider-bottom"></div>
            </div>
        </div>
        <hr class="nomobile" />
        <div class="bolt-row margin-bottom-x1 margin-top-x1">
            <div class="section-half-width bolt-col-12 bolt-col-sm-6 divider">
                <app-agent-details [isActiveTab]="'property'" [dpimContactInformation]="dpimContactInformation" [isSA360]="dpimSA360.SA360"></app-agent-details>
                <div class="divider-bottom"></div>
            </div>
            <div class="section-half-width bolt-col-12 bolt-col-sm-6">
                <app-property-mortgage-info [mortgageInformation]='mortgageInformation' [policyNumber]='policyNumber' [policyInformationDetails]="policyInformationDetails"
                    (mortgageCompanyEmit)="editMortgageCompany($event)" (editFlowErrorEmit)="errorEditFlow()">
                </app-property-mortgage-info>
                <div class="divider-bottom"></div>
            </div>
        </div>
        <hr class="nomobile" />
        <app-mailing-address [isSA360]="dpimSA360.SA360" [mailingAddress]="mailingAddress" [isActiveTab]="'property'"></app-mailing-address>
    </div>
</ng-template>