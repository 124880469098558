<div class="bolt-footer no-print">
    <div class="bolt-container">
        <div class="bolt-footer__head">
            <div class="bolt-footer__intro">
                <div class="bolt-footer__logo">
                    <bolt-logo color="white" type="nw-vertical"></bolt-logo>
                </div>
                <div class="bolt-footer__phone">
                    <span>1-{{nonSA360Contact}}</span>
                </div>
            </div>
        </div>
        <div class="bolt-footer__body">
            <div class="bolt-footer__content">
                <p class="bolt-text-sm bolt-footer-disclaimer">
                    Nationwide created this policy inquiry website to allow lenders - or agents of lenders - to verify
                    policyholder
                    information. All activity on this website is recorded and monitored regularly to protect against
                    unauthorized or
                    inappropriate use. Nationwide reserves the right to terminate user access at their discretion without prior
                    notice.
                </p>
                <br>
                <p class="bolt-text-sm bolt-footer-disclaimer">Users of this website understand that any information provided on
                    this site must be kept in strict confidence. Furthermore, users must not disclose or distribute any
                    policyholder
                    information to anyone other than the appropriate lender(s) - or the clients whom the lender(s) represent.
                </p>
                <span #teconsent id="teconsent" class="teconsent"></span>
            </div>
        </div>
    </div>
</div>