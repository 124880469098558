import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { tpiManageUsers } from '../../../../CONSTANTS/tpi-manage-users';
import { ManageUserAccountsAccountInfo } from '../../../../tpi-models/manage-accounts-company-web/manage-user-accounts-account-info';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';

@Component({
    selector: 'tpi-manage-user-accounts-table',
    templateUrl: './manage-user-accounts-table.component.html',
    styleUrls: ['./manage-user-accounts-table.component.scss']
})
export class ManageUserAccountsTableComponent {
    @ViewChild('selectAllCheckbox') selectAllCheckbox;
    @Input() selectedTableEntries: Array<ManageUserAccountsAccountInfo>;
    @Output() selectedTableEntriesChange: EventEmitter<Array<ManageUserAccountsAccountInfo>> = new EventEmitter<Array<ManageUserAccountsAccountInfo>>();

    selectAllLabel = tpiManageUsers.SELECT_ALL;

    private _tableEntries: Array<ManageUserAccountsAccountInfo>;
    @Input() set tableEntries(value: Array<ManageUserAccountsAccountInfo>) {
        this._tableEntries = value;
        if (this.selectedTableEntries == null) {
            this.selectedTableEntries = [];
        } else {
            this.selectedTableEntries = this.selectedTableEntries.filter((entry) => this.tableEntries.includes(entry));
        }
    }
    get tableEntries(): Array<ManageUserAccountsAccountInfo> {
        return this._tableEntries;
    }

    constructor(private loggerService: LoggerService) { }

    emitSelectedTableEntries(): void {
        this.selectedTableEntriesChange.emit(this.selectedTableEntries);
    }

    isSelected(user: ManageUserAccountsAccountInfo): boolean {
        return this.selectedTableEntries.includes(user);
    }

    resetSelection($event): void {
        if ($event) {
            this.loggerService.info('resetSelection(): Reset table selection');
            this.selectedTableEntries = [];
            this.selectAllCheckbox.nativeElement.checked = false;
            this.selectAllLabel = tpiManageUsers.SELECT_ALL;
            this.emitSelectedTableEntries();
        }
    }

    selectAll($event): void {
        this.loggerService.info('selectAll(): Entered');
        if ($event) {
            if (this.selectAllCheckbox.nativeElement.checked) {
                this.loggerService.info('selectAll(): Deselect all users in the table');
                this.selectedTableEntries = [];
                this.selectAllLabel = tpiManageUsers.SELECT_ALL;
            } else {
                this.loggerService.info('selectAll(): Select all users in the table');
                this.selectedTableEntries = this.tableEntries;
                this.selectAllLabel = tpiManageUsers.DESELCT_ALL;
            }
            this.emitSelectedTableEntries();
        }
    }

    selectUser(user: ManageUserAccountsAccountInfo): void {
        this.loggerService.info('selectUser(): Entered', { user });
        if (this.selectedTableEntries.includes(user)) {
            this.loggerService.info(`selectUser(): Unselect user`, { user });
            this.selectedTableEntries = this.selectedTableEntries.filter((entry) => entry !== user);
        } else {
            this.loggerService.info(`selectUser(): Select user`, { user });
            this.selectedTableEntries.push(user);
        }
        this.emitSelectedTableEntries();
    }
}
