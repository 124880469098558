import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { UtilsService } from '../../tpi-services/utility/utils-service.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ExpiredPasswordComponent } from './expired-password/expired-password.component';
import { LoginErrorComponent } from './expired-password/login-error/login-error.component';
import { ForgotIdComponent } from './forgot-id/forgot-id.component';
import { EmailConfirmationComponent } from './forgot-password/email-confirmation/email-confirmation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MFAChallengeComponent } from './forgot-password/mfa-challenge/mfa-challenge.component';
import { DMXErrorModalComponent } from './shared/dmx-error-modal/dmx-error-modal.component';
import { InteractiveRequirementsIconComponent } from './shared/interactive-requirements/interactive-requirements-icon/interactive-requirements-icon.component';
import { InteractiveRequirementsComponent } from './shared/interactive-requirements/interactive-requirements.component';
import { PasswordButtonBarComponent } from './shared/password-button-bar/password-button-bar.component';
import { PasswordFormComponent } from './shared/password-form/password-form.component';
import { AccountLockedFailureComponent } from './shared/password-notification/account-locked-failure/account-locked-failure.component';
import { PasswordChangeSuccessComponent } from './shared/password-notification/password-change-success/password-change-success.component';
import { PasswordNotificationComponent } from './shared/password-notification/password-notification.component';
import { TemporaryPasswordFailureComponent } from './shared/password-notification/temporary-password-failure/temporary-password-failure.component';
import { TemporaryPasswordSuccessComponent } from './shared/password-notification/temporary-password-success/temporary-password-success.component';
import { UsernameFormComponent } from './shared/username-form/username-form.component';
import { TemporaryPasswordComponent } from './temp-password/temp-password.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedComponentsModule,
        PipesModule
    ],
    declarations: [
        ChangePasswordComponent,
        TemporaryPasswordComponent,
        ExpiredPasswordComponent,
        ForgotPasswordComponent,
        ForgotIdComponent,
        EmailConfirmationComponent,
        MFAChallengeComponent,
        PasswordButtonBarComponent,
        PasswordFormComponent,
        UsernameFormComponent,
        InteractiveRequirementsComponent,
        InteractiveRequirementsIconComponent,
        PasswordNotificationComponent,
        AccountLockedFailureComponent,
        TemporaryPasswordSuccessComponent,
        TemporaryPasswordFailureComponent,
        PasswordChangeSuccessComponent,
        LoginErrorComponent,
        DMXErrorModalComponent
    ],
    exports: [
        ChangePasswordComponent,
        TemporaryPasswordComponent,
        ExpiredPasswordComponent,
        ForgotPasswordComponent,
        ForgotIdComponent,
        EmailConfirmationComponent,
        MFAChallengeComponent,
        PasswordButtonBarComponent,
        PasswordFormComponent,
        UsernameFormComponent,
        InteractiveRequirementsComponent,
        InteractiveRequirementsIconComponent,
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        PasswordNotificationComponent,
        AccountLockedFailureComponent,
        TemporaryPasswordSuccessComponent,
        TemporaryPasswordFailureComponent,
        PasswordChangeSuccessComponent,
        LoginErrorComponent,
        DMXErrorModalComponent
    ],
    providers: [UtilsService],
    bootstrap: [ChangePasswordComponent]
})
export class UserCredentialsModule { }
