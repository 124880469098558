<ng-container [formGroup]='parentForm' *ngIf="parentForm">
    <div class="padding-y-30 bolt-container bolt-row">
        <div class="bolt-col-sm-12 bolt-col-md-9">
            <bolt-textfield ngDefaultControl id="searchQuery" name="searchQuery" [label]="label" optionaltext="hide" aria-label="label" formControlName="searchQuery" maxlength="100"
                [attr.error]="checkForError('searchQuery', parentForm, appendOption(submitOptions, {label: ''}))">
            </bolt-textfield>
        </div>

        <div [ngClass]="{'bolt-col-sm-12 bolt-col-md-3': true, 'bolt-align-self-end': !error, 'bolt-align-self-center': error}">
            <bolt-button submit slot="forward" type="primary" width="full" (click)="search()">Search</bolt-button>
        </div>
    </div>
</ng-container>