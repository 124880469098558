import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { commonConstants, CookieKeys, LANDING_PAGES, LOGIN_LOGOUT_ERRORS } from '../../../CONSTANTS/tpi-common-constants';
import { UserInfoRequest, UserInfoResponse } from '../../../tpi-models/login-logout/user-info';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { LoginLogoutService } from '../../../tpi-services/login-logout/login-logout.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'tpi-login-logout-landing',
    templateUrl: './login-logout-landing.component.html'
})
export class LoginLogoutLandingComponent implements OnInit {
    // eslint-disable-next-line max-params
    constructor(private loginLogoutService: LoginLogoutService,
        private loggerService: LoggerService,
        private route: Router,
        private ngZone: NgZone,
        private oidcSecurityService: OidcSecurityService,
        private sessionService: SessionService) { }

    getUserInfo(userData): void {
        const request: UserInfoRequest = {
            userId: userData.userId
        };

        this.loginLogoutService.getUserInfo(request).subscribe({
            next: (response: UserInfoResponse) => {
                this.loggerService.info(`getUserInfo: Successfully got user info`, {
                    request,
                    response
                });
                const context = {
                    context: {
                        session: {
                            account: { ...response.user, shortName: '' }
                        }
                    }
                };
                context.context.session.account.shortName = response?.user?.userId;
                sessionStorage.setItem('session', JSON.stringify(context));
                this.sessionService.isLoggedIn = true;
                if (response?.user?.company?.autoAuthorization === 0 &&
                    response?.user?.company?.propertyAuthorization === 0) {
                    const errorResponse: UserInfoResponse = {
                        user: null,
                        systemMessage: LOGIN_LOGOUT_ERRORS.RESPONSE.ACCOUNT_DISABLED,
                        status: 'ERROR',
                        statusNum: parseInt(commonConstants.ERROR_CONSTANTS.ERROR_404, 10)
                    };
                    this.sessionService.isLoggedIn = false;
                    this.navigateToErrorPage(errorResponse);
                } else if (response?.statusNum?.toString() === commonConstants.USER_STATUS.PENDING) {
                    this.loggerService.info(`getUserInfo: Redirecting user to terms and conditions`);
                    this.route.navigate([LANDING_PAGES.TERMS_AND_CONDITIONS]);
                } else {
                    this.sessionService.updateUserLastLogin(response);
                    this.loggerService.info(`getUserInfo: Redirecting user to policy inquiry`);
                    this.sessionService.routeTo(LANDING_PAGES.POLICY_SEARCH);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.loggerService.error(`getUserInfo: System error getting user info`, {
                    error,
                    request
                });
                const errorResponse: UserInfoResponse = {
                    user: null,
                    systemMessage: LOGIN_LOGOUT_ERRORS.RESPONSE.SYSTEM_ERROR,
                    status: 'ERROR',
                    statusNum: parseInt(commonConstants.ERROR_CONSTANTS.ERROR_404, 10)
                };
                this.sessionService.isLoggedIn = false;
                if (error.status === commonConstants.HTTP_RESPONSE_CODE.STATUS_CODE_400 && error?.error?.systemMessage === 'USER_NOT_FOUND') {
                    errorResponse.systemMessage = LOGIN_LOGOUT_ERRORS.RESPONSE.USER_NOT_LOCATED;
                }
                this.navigateToErrorPage(errorResponse);
            }
        });
    }

    navigateToErrorPage(errorResponse: UserInfoResponse): void {
        this.ngZone.run(() => {
            this.route.navigate(['error'], { state: { errorResponse } }).then(() => {
                this.loggerService.info(`routeToErrorPage: Redirect to landing error page`);
                this.loggerService.logCurrentContents();
            }).catch((error) => {
                this.loggerService.error(`routeToErrorPage: Redirect to landing error page failed`, { error });
                this.loggerService.logCurrentContents();
            });
        });
    }

    ngOnInit(): void {
        const errorResponse: UserInfoResponse = {
            user: null,
            systemMessage: LOGIN_LOGOUT_ERRORS.RESPONSE.ACCOUNT_DISABLED,
            status: 'ERROR',
            statusNum: parseInt(commonConstants.ERROR_CONSTANTS.ERROR_404, 10)
        };
        this.oidcSecurityService.checkAuth().subscribe({
            next: ({ isAuthenticated, userData, accessToken, errorMessage }) => {
                if (isAuthenticated) {
                    this.sessionService.initializeSessionStorage(accessToken);
                    this.sessionService.setCookie(CookieKeys.TPIToken, accessToken);
                    this.sessionService.setCookie(CookieKeys.TPITokenExp, '1199');
                    this.sessionService.setCookie(CookieKeys.SessionUUID, userData.nonce);
                    this.sessionService.resetEuaToken().subscribe({
                        next: () => {
                            this.loggerService.info('checkAuth()-Authentication success for:', userData?.userId);
                            this.getUserInfo(userData);
                        }
                    });
                } else {
                    this.loggerService.info('checkAuth()-Authentication failed with tokens');
                    if (errorMessage) {
                        this.navigateToErrorPage(errorResponse);
                    } else {
                        this.loginLogoutService.login();
                    }
                }
            },
            error: () => {
                this.navigateToErrorPage(errorResponse);
            }
        });
    }
}
