import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PolicyConsumerData, PropertyRequestsCommonService } from '@nationwide/dgs-internet-servicing-policy-requests';
import { PropertyRequestsOptions } from '@nationwide/dgs-internet-servicing-policy-requests/services/config-interfaces/property-requests.interface';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { mortgageConstant } from '../../CONSTANTS/tpi-common-constants';
import { EditMortgageServiceRequest } from '../../tpi-models/general/edit-mortgage-services-request';
import { MortgageCompaniesResponse } from '../../tpi-models/general/mortgage-companies-response';
import { StartPolicyChangeResponse } from '../../tpi-models/general/policy-change-models';
import { LoggerService } from '../logger/logger.service';
import { RequestMapperService } from './helper/request-mapper.service';

@Injectable({
    providedIn: 'root'
})
export class EditMortgageService {
    constructor(
        private loggerService: LoggerService,
        private propertyRequestsCommonService: PropertyRequestsCommonService,
        private requestMapper: RequestMapperService
    ) { }

    addOrUpdateMortgageCompany(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        this.loggerService.info('Property Mortgage Add/Update Started.', {
            editMortgageServiceRequest
        });
        return this.startPolicyChanges(editMortgageServiceRequest).pipe(
            switchMap((startPolicyChangeResponse: StartPolicyChangeResponse) => {
                if (startPolicyChangeResponse instanceof HttpErrorResponse) {
                    return throwError(() => startPolicyChangeResponse);
                } else {
                    editMortgageServiceRequest.policyChangeId = startPolicyChangeResponse.policyChangeId;
                    return this.retrieveAllMortgagesOnPolicy(editMortgageServiceRequest);
                }
            }),

            switchMap((retrieveAllMortgagesResponse: any) => {
                if (retrieveAllMortgagesResponse instanceof HttpErrorResponse) {
                    return throwError(() => retrieveAllMortgagesResponse);
                } else {
                    for (let index = 0; index < retrieveAllMortgagesResponse?.length; index++) {
                        if (retrieveAllMortgagesResponse[index]?.type === mortgageConstant.mortgagee1) {
                            editMortgageServiceRequest.mortgageCompany.mortgageId = retrieveAllMortgagesResponse[index]?.mortgageId;
                            if (retrieveAllMortgagesResponse[index]?.mortgageId.startsWith('p-') ||
                                retrieveAllMortgagesResponse[index]?.mortgageId.startsWith('P-')) {
                                return this.deleteAndApplyMortgageOnPolicy(editMortgageServiceRequest);
                            }
                            break;
                        }
                    }
                    return this.applyMortgageOnPolicy(editMortgageServiceRequest);
                }
            }),

            switchMap((applyMortgageOnPolicyResponse: any) => {
                if (applyMortgageOnPolicyResponse instanceof HttpErrorResponse) {
                    return throwError(() => applyMortgageOnPolicyResponse);
                } else {
                    return this.ratePolicyChanges(editMortgageServiceRequest);
                }
            }),

            switchMap((ratePolicyChangeResponse: any) => {
                if (ratePolicyChangeResponse instanceof HttpErrorResponse) {
                    return throwError(() => ratePolicyChangeResponse);
                } else {
                    return this.submitPolicyChange(editMortgageServiceRequest);
                }
            }),
            switchMap((submitPolicyChangeResponse: any) => {
                if (submitPolicyChangeResponse instanceof HttpErrorResponse) {
                    return throwError(() => submitPolicyChangeResponse);
                } else {
                    const result = {
                        error: false,
                        editMortgageServiceRequest
                    };
                    return of(result);
                }
            }),

            catchError((error) =>
                this.withdrawPolicyChanges(editMortgageServiceRequest).pipe(
                    switchMap((response: any) => {
                        const result = {
                            error: true,
                            response
                        };
                        return of(result);
                    })
                )
            )
        );
    }

    applyMortgageOnPolicy(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        const consumerData: PolicyConsumerData = this.requestMapper.getConsumerData();
        if (editMortgageServiceRequest.mortgageCompany?.mortgageId) {
            const updateMortgagePropertyPolicyRequest: PropertyRequestsOptions = this.requestMapper.getApplyMortgagePropertyPolicyRequest(editMortgageServiceRequest);
            return this.propertyRequestsCommonService.updateMortgagePropertyPolicy(consumerData, updateMortgagePropertyPolicyRequest);
        } else {
            const addMortgagePropertyPolicyRequest: PropertyRequestsOptions = this.requestMapper.getApplyMortgagePropertyPolicyRequest(editMortgageServiceRequest);
            return this.propertyRequestsCommonService.addMortgagePropertyPolicy(consumerData, addMortgagePropertyPolicyRequest);
        }
    }

    deleteAndApplyMortgageOnPolicy(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        this.loggerService.info('deleteAndApplyMortgageOnPolicy() starts', editMortgageServiceRequest);
        return this.deleteMortgagePropertyPolicy(editMortgageServiceRequest).pipe(
            switchMap((deleteMortgagePropertyPolicyResponse: any) =>
                this.deleteAndApplyMortgageOnPolicyProcessor(editMortgageServiceRequest, deleteMortgagePropertyPolicyResponse))
        );
    }
    deleteAndApplyMortgageOnPolicyProcessor(editMortgageServiceRequest: EditMortgageServiceRequest,
        deleteMortgagePropertyPolicyResponse: any): Observable<any> {
        if (deleteMortgagePropertyPolicyResponse instanceof HttpErrorResponse) {
            this.loggerService.info('deleteAndApplyMortgageOnPolicyProcessor() error in response', deleteMortgagePropertyPolicyResponse);
            return throwError((): HttpErrorResponse => deleteMortgagePropertyPolicyResponse);
        } else {
            this.loggerService.info('deleteAndApplyMortgageOnPolicyProcessor() response', deleteMortgagePropertyPolicyResponse);
            editMortgageServiceRequest.mortgageCompany.mortgageId = undefined;
            return this.applyMortgageOnPolicy(editMortgageServiceRequest);
        }
    }

    deleteMortgagePropertyPolicy(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        const consumerData: PolicyConsumerData = this.requestMapper.getConsumerData();
        const deleteMortgagePropertyPolicyRequest: PropertyRequestsOptions = this.requestMapper.getDeleteMortgagePropertyPolicyRequest(editMortgageServiceRequest);
        this.loggerService.info('deleteMortgagePropertyPolicy() starts', editMortgageServiceRequest);
        return this.propertyRequestsCommonService.deleteMortgagePropertyPolicy(consumerData, deleteMortgagePropertyPolicyRequest);
    }

    getMortgageCompanies(mortgageName: string, mortgageState: string): Observable<MortgageCompaniesResponse> {
        const accessToken: string = sessionStorage.getItem('jwt');
        const consumerData: PolicyConsumerData = {
            accessToken,
            camSessionId: ''
        };
        const skipCache = true;
        return this.propertyRequestsCommonService.retrieveAllMortgageCompaniesInfo({
            consumerData,
            skipCache,
            mortgageCompanyName: mortgageName,
            state: mortgageState
        });
    }

    ratePolicyChanges(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        const consumerData: PolicyConsumerData = this.requestMapper.getConsumerData();
        const ratePolicyChangeRequest: PropertyRequestsOptions = this.requestMapper.getRateOrSubmitPropertyPolicyChangeRequest(editMortgageServiceRequest);
        return this.propertyRequestsCommonService.ratePropertyPolicyChange(consumerData, ratePolicyChangeRequest);
    }

    retrieveAllMortgagesOnPolicy(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        const consumerData: PolicyConsumerData = this.requestMapper.getConsumerData();
        const retrieveAllMortgagesRequest: PropertyRequestsOptions = this.requestMapper.getRetrieveAllMortgagesRequest(editMortgageServiceRequest);
        return this.propertyRequestsCommonService.retrieveAllMortgages(editMortgageServiceRequest.policyNumber, consumerData, retrieveAllMortgagesRequest);
    }

    startPolicyChanges(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        const consumerData: PolicyConsumerData = this.requestMapper.getConsumerData();
        const startPolicyChangeRequest: PropertyRequestsOptions = this.requestMapper.getStartPolicyChangeRequest(editMortgageServiceRequest);
        return this.propertyRequestsCommonService.startPropertyPolicyChange(consumerData, startPolicyChangeRequest);
    }

    submitPolicyChange(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        const consumerData: PolicyConsumerData = this.requestMapper.getConsumerData();
        const submitPropertyPolicyChangeRequest: PropertyRequestsOptions = this.requestMapper.getRateOrSubmitPropertyPolicyChangeRequest(editMortgageServiceRequest);
        return this.propertyRequestsCommonService.submitPropertyPolicyChange(consumerData, submitPropertyPolicyChangeRequest);
    }

    withdrawPolicyChanges(editMortgageServiceRequest: EditMortgageServiceRequest): Observable<any> {
        this.loggerService.info('withdrawPolicyChanges() property policy starts..', editMortgageServiceRequest);
        if (!editMortgageServiceRequest?.policyChangeId) {
            this.loggerService.info('withdrawPolicyChanges() policy change id is null', editMortgageServiceRequest);
            return of({ result: 'ok' });
        }
        const consumerData: PolicyConsumerData = this.requestMapper.getConsumerData();
        return this.propertyRequestsCommonService.deletePropertyPolicyChange(consumerData, +editMortgageServiceRequest.policyChangeId, editMortgageServiceRequest.policyType);
    }
}
