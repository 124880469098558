<header>
    <bolt-header breakpoint="992" class="noprint">
        <div slot="logo">
            <a href="https://www.nationwide.com/" target="_blank" rel="noopener noreferrer">
                <bolt-logo href="" class="bolt-header-desktop-only"></bolt-logo>
                <bolt-logo class="bolt-header-mobile-only" type="nw-mark"></bolt-logo>
            </a>
        </div>
        <div *ngIf="session && isLoggedIn" slot="search" class="bolt-header-desktop-only">
            <span>User ID: </span>
            <span>{{session.context.session.account.shortName}}</span>
        </div>
        <div *ngIf="session && isLoggedIn" id="logout" slot="main-actions">
            <bolt-button (click)="logout()" color="dark-mint">Logout</bolt-button>
        </div>
    </bolt-header>
</header>