import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
    AdditionalInterest,
    Address,
    PolicyInformationDetails,
    Vehicles
} from '@nationwide/dgs-internet-servicing-policy-requests';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { commonConstants } from '../../../CONSTANTS/tpi-common-constants';
import { DATE_VALIDATION_TAG, VALIDATION_REGEX } from '../../../CONSTANTS/tpi-error-tags';
import { FormElementCoreComponent } from '../../../shared/components/form-element-core/form-element-core.component';
import { ValidatorService } from '../../../shared/services/validator.service';
import { LienHolder } from '../../../tpi-models/general/lien-holder';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
@Component({
    selector: 'app-loan-lease-details',
    templateUrl: './loan-lease-details.component.html',
    styleUrls: ['./loan-lease-details.component.scss']
})
export class LoanLeaseDetailsComponent
    extends FormElementCoreComponent
    implements OnInit {
    @ViewChild('autoEdit') autoEdit;
    @Output() lienHolderEmit: EventEmitter<LienHolder> = new EventEmitter();
    @Input() policyInformationDetails: PolicyInformationDetails;
    @Input() vehicles: Array<Vehicles>;
    auto = commonConstants.AUTO_FLOW;
    expDateForm: UntypedFormGroup;
    lienHolderForm: UntypedFormGroup;
    minDate: Date;
    newLienHolder: LienHolder = this.initializeLienHolder();
    regexMap = new Map([
        [DATE_VALIDATION_TAG.ISVALID_01TO28, VALIDATION_REGEX.isValidDate01to28],
        [DATE_VALIDATION_TAG.ISVALID_29TO30, VALIDATION_REGEX.isValidDate29to30],
        [DATE_VALIDATION_TAG.ISVALID_31, VALIDATION_REGEX.isValidDate31],
        [DATE_VALIDATION_TAG.ISVALID_LEAPDAY, VALIDATION_REGEX.isValidLeapDay]
    ]);
    showAddressLine2 = false;
    showEditButton: boolean;
    showLienHolder: boolean;

    stateError: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };

    buildForm(): void {
        this.lienHolderForm = this.fh.build({
            zipCode: ['', Validators.required],
            changeEffectiveDate: [
                this.currentDateValue(),
                Validators.compose([
                    Validators.required,
                    this.validatorService.validateDate(this.regexMap),
                    this.validatorService.calculatePastDate(commonConstants.VALIDATION_CONSTANT.MIN_THIRTY_DAYS)
                ])
            ],
            companyName: ['', Validators.required],
            city: ['', Validators.required],
            addressLine1: ['', Validators.required],
            addressLine2: [''],
            loanOrLease: ['', Validators.required],
            state: ['', Validators.compose([Validators.required])],
            expirationDate: {
                where: (form: UntypedFormGroup): any =>
                    form.value.loanOrLease === commonConstants.AUTO.LOAN,
                control: [
                    '',
                    Validators.compose([
                        Validators.required,
                        this.validatorService.validateDate(this.regexMap)
                    ])
                ]
            }
        });
    }

    cancelModalForm(): void {
        this.stateError = false;
        this.clearValues();
    }

    clearValues(): void {
        this.submitOptions.submitStatus = false;
        this.lienHolderForm.reset();
        this.lienHolderForm.controls.changeEffectiveDate.setValue(
            this.currentDateValue()
        );
        this.newLienHolder = this.initializeLienHolder();
        this.minDateValue();
    }

    currentDateValue(): string {
        return this.utilsService.currentDateValue();
    }

    // eslint-disable-next-line max-params
    constructor(
        public utilsService: UtilsService,
        private loggerService: LoggerService,
        private fh: RxFormHelperService,
        private validatorService: ValidatorService
    ) {
        super();
    }

    determineEditLienHolder(): void {
        if (
            this.policyInformationDetails === undefined ||
            this.policyInformationDetails.cancellationDetails !== undefined
        ) {
            this.showEditButton = false;
        } else {
            this.showEditButton = true;
        }
    }

    initializeLienHolder(): LienHolder {
        const address = <Address>{};
        const additionalInterest = <AdditionalInterest>{ address };
        return <LienHolder>{ additionalInterest };
    }

    loadAdditionalDetails(): void {
        if (this.vehicles?.[0]?.additionalInterests?.[0]) {
            this.showLienHolder = true;
        } else {
            this.showLienHolder = false;
        }
        this.showAddressLine2 =
            !!this.vehicles?.[0]?.additionalInterests?.[0]?.address
                ?.addressLine2;
        this.determineEditLienHolder();
        this.clearValues();
    }

    minDateValue(): void {
        const nusmDays = 30;
        this.minDate = this.utilsService.minDateValue(nusmDays);
    }

    ngOnInit(): void {
        this.buildForm();
        this.minDateValue();
        this.showAddressLine2 = false;
        this.loadAdditionalDetails();
        this.stateError = false;
    }

    openEditModal(): void {
        this.loggerService.info('Open the Edit Loan or Lease Modal', {
            UserId: sessionStorage.getItem('userName'),
            PolicyNumber: this.policyInformationDetails.policyNumber,
            PolicySystem: 'Policy Center',
            LOB: 'Auto'
        });
        this.clearValues();
        this.autoEdit.nativeElement.openModal();
    }

    setErrorState(): void {
        this.stateError = false;
    }

    submitModalForm(event): void {
        if (this.lienHolderForm.valid) {
            this.loggerService.info('Save of Edit modal', {
                UserId: sessionStorage.getItem('userName'),
                PolicyNumber: this.policyInformationDetails.policyNumber,
                PolicySystem: 'Policy Center',
                LOB: 'Auto'
            });
            this.newLienHolder.additionalInterest.companyName =
                this.lienHolderForm.value.companyName;
            this.newLienHolder.additionalInterest.address.addressLine1 =
                this.lienHolderForm.value.addressLine1;
            this.newLienHolder.additionalInterest.address.addressLine2 =
                this.lienHolderForm.value.addressLine2;
            this.newLienHolder.additionalInterest.address.city =
                this.lienHolderForm.value.city;
            this.newLienHolder.additionalInterest.address.state =
                this.lienHolderForm.value.state;
            this.newLienHolder.additionalInterest.address.postalCode =
                this.lienHolderForm.value.zipCode;
            this.newLienHolder.changeEffectiveDate =
                this.lienHolderForm.value.changeEffectiveDate;
            this.newLienHolder.additionalInterest.expirationDate =
                this.lienHolderForm.get('loanOrLease').value === commonConstants.AUTO.LOAN ?
                    this.lienHolderForm.value.expirationDate :
                    '';
            this.newLienHolder.loanOrLeaseDetails =
                this.lienHolderForm.value.loanOrLease;
            this.lienHolderEmit.emit(this.newLienHolder);
            this.submitOptions.submitStatus = false;
        } else {
            event.preventDefault();
            this.submitOptions.submitStatus = true;
            this.stateError = this.lienHolderForm.controls.state.invalid;
        }
    }
}
