<ng-container [formGroup]='parentForm' *ngIf="parentForm">
    <div class="bolt-row bolt-space-bottom-lg">
        <div class="bolt-col">
            <bolt-textfield ngDefaultControl label="First name" id="firstName" name="firstName" formControlName="firstName" maxlength="25"
                [attr.error]="checkForError('firstName', parentForm, appendOption(submitOptions, {label: 'First name'}))" required>
            </bolt-textfield>
        </div>
        <div class="bolt-col">
            <bolt-textfield ngDefaultControl label="Last name" id="lastName" name="lastName" formControlName="lastName" maxlength="25"
                [attr.error]="checkForError('lastName', parentForm, appendOption(submitOptions, {label: 'Last name'}))" required>
            </bolt-textfield>
        </div>
    </div>
    <div class="bolt-row bolt-space-bottom-lg">
        <div class="bolt-col">
            <bolt-textfield ngDefaultControl label="Company email address" id="companyEmailAddress" name="companyEmailAddress" formControlName="companyEmailAddress" maxlength="100"
                [attr.error]="checkForError('companyEmailAddress', parentForm, appendOption(submitOptions, {label: 'Company email address'}))" required>
                <bolt-contextual-help slot="help" type="push">
                    This will serve as their username and cannot be a personal email. Please double check for typos
                    before submitting this form.
                    <br><br>
                    Allowed special characters: @ . - _
                    <br><br>
                    Tip: to replace the username with the user's email address, you must first delete the user
                    and then add them back in as a new user.
                </bolt-contextual-help>
            </bolt-textfield>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col">
            <bolt-radio-group ngDefaultControl label="User role" name="userRole" formControlName="userRole"
                [attr.error]="checkForError('userRole', parentForm, appendOption(submitOptions, {label: 'User role'}))" required>
                <bolt-contextual-help slot="help" type="push">
                    Select Admin if you want the user to be able to add or edit users for your company. Most
                    user roles are Agents.
                </bolt-contextual-help>
                <bolt-radio *ngFor="let role of roleOptions" [value]="role.id">
                    <div>
                        {{role.roleDescription}}
                    </div>
                </bolt-radio>
            </bolt-radio-group>
        </div>
    </div>
</ng-container>