import { AfterViewInit, Component, Inject, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { LOGGER_CONSTANTS, pageOptions } from '../../../CONSTANTS/tpi-common-constants';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { CompanyInfo, UserCompany, WebSupportCompanyPayload } from '../../../tpi-models/general/company-info';
import { DataTableSettings } from '../../../tpi-models/general/data-table';
import { DataTableFilter } from '../../../tpi-models/general/filter-data';
import { Window } from '../../../tpi-models/general/model-window';
import { AuthorDetails } from '../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { SelectedCompanyStateService } from '../../../tpi-services/tpi-statemanagement/selected-company-state.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
import { DeleteCompanyComponent } from './delete-company/delete-company.component';
import { ModifyCompanyComponent } from './modify-company/modify-company.component';
@Component({
    selector: 'tpi-manage-company-list',
    templateUrl: './manage-company-list.component.html',
    styleUrls: ['./manage-company-list.component.scss']
})
export class ManageCompanyListComponent implements OnInit, AfterViewInit {
    @ViewChild(DeleteCompanyComponent) deleteCompany: DeleteCompanyComponent;
    @ViewChild(ModifyCompanyComponent) modifyCompany: ModifyCompanyComponent;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Output() showResult: boolean;

    authorDetails: AuthorDetails;
    companyForm: FormGroup;
    companyName: string;
    companyTableEntries: any;
    dataSource = new MatTableDataSource<any>([]);
    dataTableSettings: DataTableSettings = {
        paginationSettings: {
            pageSizeOptions: pageOptions.paginationRules,
            arialLabelValue: 'Company user list pagination'
        },
        columnDefinition: [
            'companyDescription',
            'companyPropertyAuth',
            'companyAutoAuth',
            'delete',
            'edit']
    };
    dataToFilter: DataTableFilter = {
        data: {},
        filteredData: {},
        fields: ['companyDescription']
    };
    deleteCompanyInfo: WebSupportCompanyPayload;
    errorMessage: string;
    filterOptions = {
        label: 'Filter by company name',
        required: 'false',
        formControl: 'filter',
        listCountLabel: '@x@ companies',
        totalArray: 0,
        loggerInfoFocusOut: `${LOGGER_CONSTANTS.splunkDashboard}: filter by company unfocused`
    };
    hasError: boolean;
    lienForm: FormGroup;
    modifyFormInfo: UserCompany;
    showSuccessMessage: boolean;
    submitOptionsCompany = {
        onSubmit: true,
        submitStatus: false
    };
    submitOptionsLien = {
        onSubmit: true,
        submitStatus: false
    };
    successMessage: string;

    // eslint-disable-next-line max-params
    constructor(public formHelper: RxFormHelperService,
        private logger: LoggerService,
        public utilsService: UtilsService,
        private manageAccounts: ManageUserAccountsService,
        private storeManagement: SelectedCompanyStateService,
        private route: Router,
        private session: SessionService,
        private ngZone: NgZone,
        @Inject('window') private window: Window) { }

    filterByNameOrEmail(filteredResults: any): void {
        this.dataSource.data = filteredResults.filteredData;
        setTimeout(() => this.dataSource.paginator = this.paginator);
    }

    getCompanyList(): void {
        const userDetails = {
            userId: this.authorDetails.userName,
            userNumber: this.authorDetails.userId
        };
        this.showWaitIndicator(true);
        this.manageAccounts.retrieveCompanies(userDetails).subscribe({
            next: (response: Array<CompanyInfo>) => {
                this.dataSource.data = response;
                this.dataToFilter.data = response;
                this.filterOptions.totalArray = response.length;
                setTimeout(() => this.dataSource.paginator = this.paginator);
                this.showWaitIndicator(false);
                this.logger.info(`${LOGGER_CONSTANTS.splunkDashboard}: getCompanyList: read company list success`);
            },
            error: (error: any) => {
                this.showWaitIndicator(false);
                this.hasError = true;
                this.errorMessage = tpiSearchError.UNABLE_PROCESS_ERROR;
                this.logger.info(`${LOGGER_CONSTANTS.splunkDashboard}: getCompanyList: read company list failed`, error);
            }
        });
    }

    handlePage(event: any): void {
        this.utilsService.scrollToTopOfPage();
    }

    navigateToUserList(company: any): void {
        this.storeManagement.updateSelectedCompanyFields({
            company: {
                companyNumber: company.id,
                companyAutoAuth: company.autoAuthorization,
                companyPropertyAuth: company.propertyAuthorization,
                companyDescription: company.companyDescription
            }
        });
        this.ngZone.run(() => {
            this.route.navigate(['manage-users']).then(() => {
                this.logger.info(`${LOGGER_CONSTANTS.splunkDashboard}: navigateToUserList: Redirect to user list page`);
            }).catch((error) => {
                this.showResultsError(tpiSearchError.UNABLE_PROCESS_ERROR);
                this.logger.error(`${LOGGER_CONSTANTS.splunkDashboard}: navigateToUserList: Redirect to user list page failed`, { error });
            });
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.authorDetails = this.session.getAuthorDetails();
        this.setVariables();
        this.getCompanyList();
    }

    onDeleteCompanyClick(company: UserCompany): void {
        this.deleteCompany.showModal = true;
        this.hasError = false;
        this.errorMessage = '';
        this.deleteCompanyInfo = {
            userId: this.authorDetails.userName,
            userNumber: this.authorDetails.userId,
            company
        };

        this.logger.info(`${LOGGER_CONSTANTS.splunkDashboard}: onDeleteCompanyClick: ${this.authorDetails.userName} opened delete company modal ${this.deleteCompanyInfo.company.companyDescription}`, {
            company,
            authorDetails: this.authorDetails
        });
    }

    onModifyCompanyClick($event, element: UserCompany): void {
        this.hasError = false;
        this.errorMessage = '';
        this.modifyFormInfo = element;
        this.logger.info(`${LOGGER_CONSTANTS.splunkDashboard}: onModifyCompanyClick: ${this.authorDetails.userName} opened edit company modal ${this.modifyFormInfo.companyDescription}`, this.modifyFormInfo);
        this.modifyCompany.openModal(true, this.modifyFormInfo);
        $event.preventDefault();
    }

    reload(): void {
        this.getCompanyList();
    }

    setVariables(): void {
        this.submitOptionsCompany = {
            submitStatus: false,
            onSubmit: true
        };
    }

    showResultsError(errorMessage: string): void {
        this.hasError = true;
        this.errorMessage = errorMessage;
        this.utilsService.scrollToTopOfPage();
    }

    showWaitIndicator($show: boolean): void {
        if ($show) {
            this.window.manageCompanyWaitingIndicator.show();
        } else {
            this.window.manageCompanyWaitingIndicator.hide();
        }
    }
}
