<app-ping-iframe></app-ping-iframe>
<div>
    <h2 class="bolt-heading-md">Verify Your Identity</h2>

    <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>

    <h2 class="bolt-heading-sm">Contact Method</h2>

    <div class="bolt-row">
        <div class="bolt-col">
            <p>If the contact information below is incorrect, please contact your admin.</p>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col">
            <p>We'll send your verification code here:</p>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col">
            <p><strong>{{userFields?.emailAddress | maskEmail}}</strong></p>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col bolt-space-bottom-lg">
            <bolt-notification type="info">Please allow several minutes for the code to arrive.</bolt-notification>
        </div>
    </div>
</div>

<app-password-button-bar [secondaryLabel]="'Cancel'" (secondaryEmit)="redirectToLegacy()" [primaryLabel]="'Continue'" (primaryEmit)="requestMFAChallenge()">
</app-password-button-bar>

<app-wait-indicator></app-wait-indicator>