<ng-container>
    <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>

    <app-search-success-panel [showSuccessMessage]="showSuccessMessage" [successMessage]="successMessage"></app-search-success-panel>

    <h2 class="bolt-heading-md">Change Password</h2>

    <app-password-form [submitOptions]="submitOptions" [parentForm]="changePasswordForm"></app-password-form>

</ng-container>

<app-password-button-bar [secondaryLabel]="'Cancel'" (secondaryEmit)="resetForm($event)" [primaryLabel]="'Save'" (primaryEmit)="performValidation($event)">
</app-password-button-bar>

<app-wait-indicator></app-wait-indicator>

<app-dmx-error-modal (clickOKButtonEmit)="redirectToLegacy($event)" [showModal]="hasDMXError">
</app-dmx-error-modal>