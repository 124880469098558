<app-ping-iframe></app-ping-iframe>
<div>
    <h2 class="bolt-heading-md">Change Password</h2>

    <div class="margin-bottom">
        <bolt-notification type="info">
            Your password has expired. Please create a new password.</bolt-notification>
    </div>

    <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>

    <app-username-form [submitOptions]="submitOptions" [parentForm]="expiredPasswordForm"></app-username-form>

    <app-password-form [submitOptions]="submitOptions" [parentForm]="expiredPasswordForm"></app-password-form>
</div>

<app-password-button-bar [secondaryLabel]="'Cancel'" (secondaryEmit)="redirectToLegacy($event)" [primaryLabel]="'Save'" (primaryEmit)="performValidation($event)">
</app-password-button-bar>

<app-wait-indicator></app-wait-indicator>

<tpi-notification-modal *ngIf="showErrorModal">
    <ng-container modalFailure *ngIf="showErrorModal">
        <bolt-modal primarybutton="Ok" (bolt-modal-primary-button-click)="closeErrorModal()" id="errorModal" type="error" heading="Password update failed" open=true>
            <p>We are unable to update the password at this time. Try again or call 800-240-1135.</p>
        </bolt-modal>
    </ng-container>
</tpi-notification-modal>

<app-dmx-error-modal (clickOKButtonEmit)="redirectToLegacy($event)" [showModal]="hasDMXError">
</app-dmx-error-modal>