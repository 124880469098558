import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MaskEmailPipe } from './mask-email.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule],
    exports: [
        SanitizeHtmlPipe,
        SafeUrlPipe,
        MaskEmailPipe
    ],
    declarations: [
        SanitizeHtmlPipe,
        SafeUrlPipe,
        MaskEmailPipe
    ],
    providers: []
})
export class PipesModule { }
