import { Component, OnInit } from '@angular/core';
import { StoreUserFields } from '../../../../../tpi-models/store/store-user-fields.model';
import { SessionService } from '../../../../../tpi-services/tpi-session/session.service';
import { UserFieldsStateService } from '../../../../../tpi-services/tpi-statemanagement/user-fields-state.service';

@Component({
    selector: 'app-temporary-password-success',
    templateUrl: './temporary-password-success.component.html'
})
export class TemporaryPasswordSuccessComponent implements OnInit {
    email: string;

    constructor(
        private sessionService: SessionService,
        private storeUserFieldsService: UserFieldsStateService
    ) { }

    ngOnInit(): void {
        this.sessionService.deleteAllCookies();
        this.storeUserFieldsService.getUserFields().subscribe((searchFields: StoreUserFields) => {
            this.email = searchFields.emailAddress;
        });
        if (this.email == null) {
            this.email = 'your email';
        }
    }
}
