<h1 class="bolt-heading-md bolt-typography margin-bottom-auto">
    Client
</h1>
<div class="noprint bolt-container all-border bolt-row bolt-body-copy-lg">
    <div class="bolt-col-md-2 bolt-col-3 client-image-padding-0 flex-center">
        <img src="../../../assets/image/person-circle-64.svg" class="userimg" alt="user image" />
    </div>
    <div class="bolt-col-md-10 bolt-col-9 flex-center content-padding-left-0">
        <div *ngIf="!policyInformationDetail || !person">
            <ng-template [ngTemplateOutlet]="noResultFound"></ng-template>
        </div>
        <div *ngIf="policyInformationDetail && person">
            <ng-template [ngTemplateOutlet]="resultFound"></ng-template>
        </div>
    </div>
</div>
<div class="noscreen bolt-container all-border bolt-row bolt-body-copy-lg">
    <div class="bolt-col-2 client-image-padding-0 flex-center">
        <img src="../../../assets/image/person-circle-64.svg" class="userimg" alt="user image" />
    </div>
    <div class="bolt-col-10 flex-center content-padding-left-0">
        <div *ngIf="!policyInformationDetail || !person">
            <ng-template [ngTemplateOutlet]="noResultFound"></ng-template>
        </div>
        <div *ngIf="policyInformationDetail && person">
            <ng-template [ngTemplateOutlet]="resultFound"></ng-template>
        </div>
    </div>
</div>

<ng-template #noResultFound>
    Client Details are not available at this time.
</ng-template>
<ng-template #resultFound>
    <span class="nwpii">{{person.firstName|titlecase}} {{person.middleName|titlecase}}
        {{person.lastName|titlecase}}</span>
    <br />
    <span class="nwpii">
        {{policyInformationDetail.policyAddress.addressLine1|titlecase}}
        <br />
        {{policyInformationDetail.policyAddress.city|titlecase}}, {{policyInformationDetail.policyAddress.state}}</span>
    {{policyInformationDetail.policyAddress.postalCode|titlecase}}
</ng-template>