import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { StoreUserFields } from '../../tpi-models/store/store-user-fields.model';
import { AppState } from '../../tpi-states/app.state';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root'
})
export class UserFieldsStateService {
    constructor(private loggerService: LoggerService,
        private storeUserFields: Store<AppState>
    ) { }

    getUserFields(): Observable<StoreUserFields> {
        const userFields = this.storeUserFields.select((state) => state.storeUserFields);
        userFields.subscribe((value) => {
            this.loggerService.info('getUserFields(): retrieve email and usernamefor in UserFieldsStateService',
                {
                    userFields: value
                });
        });
        return userFields;
    }

    updateUserFields(storeUserFields: StoreUserFields): void {
        this.loggerService.info('updateUserFields(): update email and usernamefor in UserFieldsStateService',
            {
                type: commonConstants.STORE.MODIFY_USERFIELDS,
                userFields: storeUserFields
            });
        this.storeUserFields.dispatch({
            type: commonConstants.STORE.MODIFY_USERFIELDS,
            payload: storeUserFields
        });
    }
}
