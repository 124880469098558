<div>
    <bolt-button slot="cancel" type="secondary" class="buttons" (click)="openModal($event)">Add new user</bolt-button>
</div>

<div [formGroup]='parentForm' *ngIf="parentForm" class="align-left">
    <tpi-notification-modal *ngIf="showModal">
        <bolt-modal primarybutton="Create user" tertiarybutton="Cancel" (bolt-modal-primary-button-click)="performValidation($event)" (bolt-modal-tertiary-button-click)="closeModal($event)"
            id="addNewUserModal" heading="Create new user" [open]="showModal">
            <p>A confirmation of these changes will be sent to the email you enter, along with information about
                how to log in. All fields are required.</p>
            <tpi-user-info-form [parentForm]="parentForm" [submitOptions]="submitOptions" [roleOptions]="roleOptions">
            </tpi-user-info-form>
        </bolt-modal>
    </tpi-notification-modal>

    <tpi-success-modal
        [modalText]="'A new user profile has been created. They will receive two emails, one with their new username and one with a temporary password. The temporary password will expire in 48 hours.'">
    </tpi-success-modal>
</div>