import { Component, Input } from '@angular/core';
import { Address } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/address';

@Component({
    selector: 'app-property-address',
    templateUrl: './property-address.component.html',
    styleUrls: ['./property-address.component.scss']
})
export class PropertyAddressComponent {
    @Input() propertyAddress: Address;
}
