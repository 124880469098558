<div class="bolt-row scroll-box-border">
    <div class="bolt-col" aria-live="assertive" tabindex="1">
        <p>
            Nationwide Mutual Insurance Company, its subsidiaries and affiliates, including, without limitation, Allied
            Group, Inc. and Harleysville Group, Inc. (collectively &ldquo;Nationwide&rdquo; or &ldquo;We&rdquo;), allows
            business entities to grant online access to its employees/contractors to the Nationwide Third Party Inquiry
            website (&ldquo;Website&rdquo;). Nationwide created this Website to allow lenders, or agents of lenders
            (&ldquo;Third Party&rdquo;), to verify and edit specific policyholder information. The Third Party must
            acknowledge and agree that it understands and will comply with the following terms and conditions of the
            Website, as set forth below.
        </p>
        <p>
            When you access the Website, you are agreeing to these terms and conditions of use, plus any additional
            terms or
            conditions posted on
            <a href="https://www.nationwide.com/terms-conditions.jsp" target="_blank" rel="noopener">Nationwide.com</a>
            (collectively &ldquo;Terms and Conditions&rdquo;). The Terms and
            Conditions are in addition to any other agreements between you and Nationwide.
        </p>
        <p>
            Nationwide reserves the right to modify, change, suspend or discontinue the Website, or any part of it at
            any time without notice.&nbsp; We may amend any of the Terms and Conditions at our sole discretion at any
            time without prior notice to you. Such modification or change shall be effective upon posting to the Website
            or
            <a href="https://www.nationwide.com/terms-conditions.jsp" target="_blank" rel="noopener">Nationwide.com</a>. You agree to be bound to any changes to these Terms and Conditions
            when you use the Website after any such change is posted.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Important Information about the Website</strong>
        </h2>
        <ul class="bolt-body-copy-sm">
            <li>Third Party certifies that it has a right to access and edit the information.
            </li>
            <li>Third Party warrants that any information it edits on the Website is accurate and free from errors.</li>
            <li>Third Party certifies that the information will only be used to obtain insurance-related information
                (including to verify coverage or obtain premium amounts) in connection with the insurance tracking
                services
                Third Party provides to its lender-clients.</li>
            <li>Third Party certifies that the information will be kept in strict confidence and will not be shared with
                anyone except to those who have a &ldquo;need to know&rdquo; such information. This includes the clients
                of
                Third Party for which the Third Party provides insurance-tracking services on properties within the
                lender's
                mortgage and/or loan portfolios (including loans being sub-serviced by such lender-client).</li>
            <li>Third Party certifies that it will notify Nationwide promptly if it believes there has been any misuse
                of
                the Website, including any inappropriate access to the Website.</li>
            <li>Third Party certifies that it will turn off individual IDs/Passwords promptly when necessary (e.g. when
                an
                employee/contractor leaves the Third Party) and will notify Nationwide of this action promptly.</li>
            <li>Nationwide will monitor and audit Third Party&rsquo;s access.</li>
            <li>Nationwide reserves the right to terminate Third Party&rsquo;s access at any time and for any reason.
            </li>
            <li>Third Party is solely responsible for the use of IDs and passwords that Nationwide assigns to it.</li>
        </ul>
        <h2 class="bolt-body-copy-md">
            <strong>Privacy and Security</strong>
        </h2>
        <p>
            Please review Nationwide's
            <a href="https://www.nationwide.com/privacy.jsp" target="_blank" rel="noopener">Privacy Policy</a>
            that explains how we collect, use and share user information.
        </p>
        <p class="bolt-body-copy-md">
            <strong>Intellectual Property</strong>
        </p>
        <p>
            Intellectual property is protected by copyrights, trademarks, service marks, international treaties or other
            proprietary rights and laws of the United States and other applicable countries. You agree to abide by all
            applicable proprietary rights and laws as well as any additional trademark or copyright notices or
            restrictions
            contained in the Terms and Conditions. The Terms and Conditions grant you no right, title or interest in
            Nationwide's intellectual property and create no relationship between you and Nationwide. You may address
            all
            inquiries concerning use of Nationwide's intellectual property to: Nationwide&rsquo;s Office of the Chief
            Legal
            Officer, Intellectual Property Department, One Nationwide Plaza, Columbus, OH 43215-2220 (email address:
            <a href="mailto:trademark@nationwide.com">trademark@nationwide.com</a>).
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Trademark Notice</strong>
        </h2>
        <p>
            The trademarks, service marks, slogans, logos, trade dress and other identifiers ("Marks") displayed on the
            Website are the property of Nationwide, unless otherwise disclosed. You are prohibited from using any Marks
            for
            any purpose including, but not limited to, use on other materials, in presentations, as domain names, or as
            metatags, without the written permission of Nationwide.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Designated Agent (Digital Millennium Copyright Act)</strong>
        </h2>
        <p>
            If you think that a work protected by a United States copyright has been linked to, posted or stored on the
            Website without authorization, please contact the following agent designated to receive notification of
            claimed
            copyright infringement: Copyright Attorney, Nationwide&rsquo;s Office of the Chief Legal Officer,
            Intellectual
            Property Department, One Nationwide Plaza, Columbus, OH 43215-2220 or (email address:
            <a href="mailto:copyright@nationwide.com">copyright@nationwide.com</a>).
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>License and Reproduction</strong>
        </h2>
        <p>
            Any commercial or public use of the Website or any of its Content (as defined below) is strictly prohibited
            without written permission from Nationwide.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Disclaimer of Warranties</strong>
        </h2>
        <p>
            ALL INFORMATION AND OTHER MATERIALS PRESENT ON THE WEBSITE ("CONTENT"), INCLUDING, WITHOUT LIMITATION,
            PRODUCTS
            AND SERVICES ARE PROVIDED "AS IS," WITHOUT ANY WARRANTIES ABOUT THE CONTENT'S NATURE OR ACCURACY (EITHER
            WHEN
            POSTED OR AS A RESULT OF THE PASSAGE OF TIME) AND WITHOUT ANY REPRESENTATIONS OR GUARANTEES.
        </p>
        <p>
            WITHOUT LIMITING THE ABOVE, NATIONWIDE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED
            TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, SECURITY OR
            ACCURACY WITH REGARD TO ANY THIRD PARTY WEBSITE TO WHICH OR FROM WHICH THE WEBSITE IS LINKED ("LINKED
            WEBSITE").
            NATIONWIDE DOES NOT ENDORSE AND IS NOT RESPONSIBLE FOR THE CAPABILITIES OR RELIABILITY OF ANY PRODUCT OR
            SERVICE
            OBTAINED FROM A LINKED WEBSITE.
        </p>
        <p>
            Nationwide makes no representations, warranties or guarantees that the Website will be accessible
            continuously
            and without interruption, or error free.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Limitation of Liabilities</strong>
        </h2>
        <p>
            IN NO EVENT WILL NATIONWIDE, ITS RESPECTIVE SERVICE PROVIDERS, EMPLOYEES, AGENTS,
            OFFICERS AND DIRECTORS BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, DIRECT, SPECIAL, INCIDENTAL OR
            CONSEQUENTIAL
            DAMAGES, EVEN IF YOU HAVE NOTIFIED NATIONWIDE ABOUT THE POSSIBILITY OF SUCH DAMAGES, OR FOR ANY CLAIMS BY
            ANY
            THIRD PARTIES ARISING OUT OF OR IN ANY WAY RELATED TO THE ACCESS, USE OR CONTENT OF THE WEBSITE OR A LINKED
            WEB
            SITE WHETHER SUCH CLAIMS ARE BROUGHT UNDER ANY THEORY OF LAW OR EQUITY.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Your liabilities</strong>
        </h2>
        <p>
            Nationwide has the right to deny you access and use of the Website and its Content if you
            violate (as Nationwide may determine in its sole and absolute discretion) any provision of the Terms and
            Conditions. Nationwide reserves the right to seek all other remedies available at law and in equity. You
            agree,
            at your own expense, to defend, indemnify and hold Nationwide harmless from any claim or demand, including
            reasonable attorneys' fees, made by a third party in connection with or arising out of your access to, or
            use
            of, the Website or any of its Content in a manner other than as expressly authorized by the Terms and
            Conditions, your breach of the Terms and Conditions or your violation of applicable laws or any rights of
            any
            third party.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Governing Law/Venue These</strong>
        </h2>
        <p>
            Terms and Conditions shall be governed by and construed in accordance with the laws of the United States and
            the
            State of Ohio. You consent to exclusive jurisdiction and venue in Franklin County, Ohio and waive the
            defense of
            forum non conveniens.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Entire Agreement</strong>
        </h2>
        <p>
            Except to the extent otherwise set forth in the Terms and Conditions, these Terms and Conditions set forth
            the
            entire understanding between Nationwide and you with respect to your access to, and use of, the Website and
            its
            Content and supersedes all prior or contemporaneous understandings regarding access and use.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Severability</strong>
        </h2>
        <p>
            If any portion of these Terms and Conditions is found to be unenforceable, the remaining sections of the
            Terms
            and Conditions will remain in effect.
        </p>
        <h2 class="bolt-body-copy-md">
            <strong>Waiver</strong>
        </h2>
        <p>
            Failure by Nationwide, in any instance, to exercise any of its rights under the Terms and Conditions will
            not
            constitute waiver of such right or any other rights under the Terms and Conditions.
        </p>
        <h class="bolt-body-copy-md">
            <strong>Effective 1/3/2019</strong>
        </h>
    </div>
</div>