import { Component } from '@angular/core';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { LOGGER_CONSTANTS } from '../../../../CONSTANTS/tpi-common-constants';
import { WebSupportActionsEnum } from '../../../../CONSTANTS/web-support-actions';
import { UserCompany } from '../../../../tpi-models/general/company-info';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';
import { AddModifyCompanyCoreComponent } from '../add-modify-company-core/add-modify-company-core.component';

@Component({
    selector: 'tpi-modify-company',
    templateUrl: './modify-company.component.html'
})
export class ModifyCompanyComponent extends AddModifyCompanyCoreComponent {
    // eslint-disable-next-line max-params
    constructor(
        public loggerService: LoggerService,
        public formHelper: RxFormHelperService,
        public accountService: ManageUserAccountsService,
        public sessionService: SessionService) {
        super(sessionService, loggerService, formHelper, accountService); this.action = 'edit'; this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
        this.action = WebSupportActionsEnum.EDIT;
    }

    openModal($event, formValues: UserCompany): void {
        if ($event) {
            this.buildCompanyForm();
            this.showModal = true;
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: openModal: opened modify company modal`);
            this.companyNumber = formValues.id;
            this.companyForm.get('companyName').setValue(formValues.companyDescription);
            this.companyForm.get('policyAccessAutoAuth').setValue(formValues.autoAuthorization === 1);
            this.companyForm.get('policyAccessPropertyAuth').setValue(formValues.propertyAuthorization === 1);
        }
    }
}
