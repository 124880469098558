import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PolicyNumberFormatterPipe } from '@nationwide/internet-servicing-angular-pipes';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AutoAddressSearchRequest } from '../tpi-models/general/auto-sddress-search-request';
import { AddressVinValidationResponse, PolicyVinRequest, PolicyVinResponse } from '../tpi-models/general/auto-validation-request-responses';
import {
    EcifAddressRequest,
    EcifAddressResponse
} from '../tpi-models/general/ecif-address';
import { EcifCustomerResponse } from '../tpi-models/general/ecifResponse';
import { LoggerService } from '../tpi-services/logger/logger.service';
import { UtilsService } from '../tpi-services/utility/utils-service.service';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EcifService {
    // eslint-disable-next-line max-params
    constructor(
        private http: HttpClient,
        private loggerService: LoggerService,
        private policyNumberFormatterPipe: PolicyNumberFormatterPipe,
        private utilsService: UtilsService
    ) { }

    getEcifCustomer(
        customerNumber: string,
        euaToken: string
    ): Observable<EcifCustomerResponse> {
        const httpOptions = this.utilsService.getHttpOptions(euaToken);
        const url = `${environment.apigeeEnvironment}${environment.ecif}customers/${customerNumber}`;
        return this.http.get<EcifCustomerResponse>(url, {
            headers: httpOptions
        });
    }

    getEcifCustomerNumber(
        address: EcifAddressRequest,
        euaToken: string
    ): Observable<EcifAddressResponse[]> {
        const httpOptions = this.utilsService.getHttpOptions(euaToken);
        const url = `${environment.apigeeEnvironment}${environment.ecif}customersearch/address?customerType=${environment.ecifCustomerType}&depth=${environment.ecifDepth}&returnEmail=${environment.ecifReturnEmail}`;
        return this.http.post<EcifAddressResponse[]>(url, address, {
            headers: httpOptions
        });
    }

    getEcifCustomerwithECN(ecn: string, euaToken: string): Observable<any> {
        const httpOptions = this.utilsService.getHttpOptions(euaToken);
        const url = `${environment.apigeeEnvironment}${environment.ecif}customers/${ecn}`;
        return this.http.get<EcifCustomerResponse>(url, {
            headers: httpOptions
        });
    }

    getEcifPolicyAgreement(
        polNumber: string,
        euaToken: string
    ): Observable<any> {
        const httpOptions: HttpHeaders = this.utilsService.getHttpOptions(euaToken);
        const policyNumber = polNumber;
        return this.http.get(
            `${environment.apigeeEnvironment}${environment.ecif}agreements/${environment.APIs.sourceSystem}/${policyNumber}`,
            { headers: httpOptions }
        );
    }

    validateAutoAddressEcn(autoAddressSearchRequest: AutoAddressSearchRequest): Observable<string[]> {
        this.loggerService.debug('validateAutoAddressEcn() - Enter', autoAddressSearchRequest);
        const euaToken = sessionStorage.getItem('jwt');
        const httpOptions: HttpHeaders = this.utilsService.getHttpOptions(euaToken);
        const body = autoAddressSearchRequest;
        const url = `${environment.apigeeEnvironment}${environment.ecifValidateAutoAddressEcn}`;
        return this.http.post<string[]>(url, body, { headers: httpOptions }).pipe(
            catchError((error) => {
                this.loggerService.error('validateAutoAddressEcn() - exit error failed to fetch ecif customer numbers', error);
                return throwError(() => error);
            })
        );
    }

    validateAutoAddressVin(policyNumber: string, fullVin: string): Observable<AddressVinValidationResponse | any> {
        this.loggerService.debug('validateAutoAddressVin() - Enter', { policyNumber, fullVin });
        const euaToken = sessionStorage.getItem('jwt');
        let httpOptions: HttpHeaders = this.utilsService.getHttpOptions(euaToken);
        policyNumber = policyNumber.replace(' ', '%20');
        this.loggerService.debug('validateAutoAddressVin() - Policynumber formatted', { policyNumber });
        httpOptions = httpOptions.set('X-NW-Target-Env', environment.policyCenterShortName);
        const body: PolicyVinRequest = { policyNumber, vin: fullVin };
        const url = `${environment.apigeeEnvironment}${environment.ecifvalidateAutoAddressVin.replace(':policyNumber', policyNumber)}`;
        return this.http.post<AddressVinValidationResponse | any>(url, body, { headers: httpOptions }).pipe(
            catchError((error) => {
                this.loggerService.error('validateAutoAddressVin() - exit error failed to validate address vin', error);
                const systemErrorCode = 500;
                if (error.status !== systemErrorCode) {
                    return of(error);
                }
                return throwError(() => error);
            })
        );
    }

    validateAutoPolicyVin(policyNumber: string, sixDigitsVin: string): Observable<PolicyVinResponse | any> {
        this.loggerService.debug('validateAutoPolicyVin() - Enter', { policyNumber, sixDigitsVin });
        const euaToken = sessionStorage.getItem('jwt');
        let httpOptions: HttpHeaders = this.utilsService.getHttpOptions(euaToken);
        policyNumber = this.policyNumberFormatterPipe.transform(policyNumber);
        this.loggerService.debug('validateAutoPolicyVin() - Policynumber formatted', { policyNumber });
        httpOptions = httpOptions.set('X-NW-Target-Env', environment.policyCenterShortName);
        this.loggerService.debug('validateAutoPolicyVin() - Adding PolicyCenter Env header', { httpOptions });
        const body: PolicyVinRequest = {
            policyNumber,
            vin: sixDigitsVin
        };
        const url = `${environment.apigeeEnvironment}${environment.ecifValidateAutoPolicyVin}`;
        return this.http.post<PolicyVinResponse | any>(url, body, { headers: httpOptions }).pipe(
            catchError((error) => {
                this.loggerService.error('validateAutoPolicyVin() - exit error failed to validate policy number and vin', error);
                return throwError(() => error);
            })
        );
    }

    validatePolicyWithEcifAgreement(
        policyNumber: string,
        zipCode: string,
        euaToken: string
    ): Observable<any> {
        const httpOptions: HttpHeaders = this.utilsService.getHttpOptions(euaToken);
        const body = {
            policyNumber,
            systemType: environment.APIs.sourceSystem,
            zipCode
        };
        return this.http.post(
            `${environment.apigeeEnvironment}${environment.ecif}validation/search/property`,
            body,
            { headers: httpOptions }
        );
    }

    validationAutoAddressEcnPolicyNumber(ecn: string): Observable<string[]> {
        this.loggerService.debug('validationAutoAddressEcnPolicyNumber() - Enter', { ecn });
        const euaToken = sessionStorage.getItem('jwt');
        const httpOptions: HttpHeaders = this.utilsService.getHttpOptions(euaToken);
        const body = {};
        const url = `${environment.apigeeEnvironment}${environment.ecifValidateAutoAddressEcnpolicyNumber.replace(':ecn', ecn)}`;
        return this.http.post<string[]>(url, body, { headers: httpOptions }).pipe(
            catchError((error) => {
                this.loggerService.error('validationAutoAddressEcnPolicyNumber() - exit error failed to fetch policy numbers', error);
                return throwError(() => error);
            })
        );
    }
}
