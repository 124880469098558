import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PolicyConsumerData } from '@nationwide/dgs-internet-servicing-policy-requests';
import { MortgageCompany, PropertyChangeRequest, PropertyRequestsOptions } from '@nationwide/dgs-internet-servicing-policy-requests/services/config-interfaces/property-requests.interface';
import { AwdWorkflowServiceRequest } from '../../../tpi-models/general/awd-workflow-service-request';
import { EditMortgageServiceRequest, Mortgage, MortgageAddress } from '../../../tpi-models/general/edit-mortgage-services-request';
import { LienHolder } from '../../../tpi-models/general/lien-holder';
import { MortgageCompanyDetails } from '../../../tpi-models/general/mortgage-company';

@Injectable({
    providedIn: 'root'
})
export class RequestMapperService {
    constructor(
        private datepipe: DatePipe
    ) { }

    buildAwdWorkflowAutoServiceRequest(lienholderRequest: LienHolder, vin: string): AwdWorkflowServiceRequest {
        const request: AwdWorkflowServiceRequest = {};
        request.policyNumber = lienholderRequest.policyNumber;
        request.vin = vin;
        request.changeEffectiveDate = lienholderRequest.changeEffectiveDate;
        request.loanOrLeaseDetails = lienholderRequest.loanOrLeaseDetails;
        request.lienholderDetails = lienholderRequest.additionalInterest;
        request.policyType = 'Auto';
        return request;
    }

    buildAwdWorkflowServiceRequest(editMortgageServiceRequest: EditMortgageServiceRequest): AwdWorkflowServiceRequest {
        const request: AwdWorkflowServiceRequest = {};
        request.loanNumber = editMortgageServiceRequest.loanNumber;
        request.policyNumber = editMortgageServiceRequest.policyNumber;
        request.policyType = editMortgageServiceRequest.lineOfBusiness;
        request.policyZipCode = editMortgageServiceRequest.policyZipCode;
        request.mortgageCompany = editMortgageServiceRequest.mortgageCompany;
        return request;
    }

    buildEditMortgageServiceRequqest(mortgageCompanyDetails: MortgageCompanyDetails): EditMortgageServiceRequest {
        const request: EditMortgageServiceRequest = {};
        request.policyNumber = mortgageCompanyDetails.policyNumber;
        request.effectiveDate = this.datepipe.transform(
            mortgageCompanyDetails.changeEffectiveDate,
            'YYYY-MM-dd'
        );
        request.policyZipCode = mortgageCompanyDetails.zipCode;
        request.lineOfBusiness = 'PolicyCenter';
        request.policyType = mortgageCompanyDetails.propertyType;
        request.loanNumber = mortgageCompanyDetails.loanNumber;
        request.companyId = mortgageCompanyDetails?.mortgageCompanyAddress?.companyId;

        const mortgageCompany: Mortgage = {};
        mortgageCompany.companyName = mortgageCompanyDetails?.mortgageCompanyAddress?.companyName || mortgageCompanyDetails?.mortgageCompanyAddress?.name;
        mortgageCompany.companyId = mortgageCompanyDetails?.mortgageCompanyAddress?.companyId;
        mortgageCompany.ediNumber = mortgageCompanyDetails?.mortgageCompanyAddress?.ediNumber;
        mortgageCompany.name = mortgageCompanyDetails?.mortgageCompanyAddress?.name;

        const address: MortgageAddress = {};
        address.addressLine1 = mortgageCompanyDetails?.mortgageCompanyAddress?.address?.addressLine1;
        address.city = mortgageCompanyDetails?.mortgageCompanyAddress?.address?.city;
        address.postalCode = mortgageCompanyDetails?.mortgageCompanyAddress?.address?.postalCode;
        address.state = mortgageCompanyDetails?.mortgageCompanyAddress?.address?.state;
        mortgageCompany.address = address;
        request.mortgageCompany = mortgageCompany;

        return request;
    }

    getApplyMortgagePropertyPolicyRequest(editMortgageServiceRequest: EditMortgageServiceRequest): PropertyRequestsOptions {
        const mortgage: MortgageCompany = {
            mortgageType: 'Mortgagee_Ext',
            type: 'Mortgagee1',
            loanNumber: editMortgageServiceRequest.loanNumber,
            companyId: editMortgageServiceRequest.companyId,
            companyName: editMortgageServiceRequest.mortgageCompany.companyName
        };
        const request: PropertyChangeRequest = {
            policyNumber: editMortgageServiceRequest.policyNumber,
            policyChangeId: editMortgageServiceRequest.policyChangeId,
            mortgageId: editMortgageServiceRequest.mortgageCompany.mortgageId,
            mortgage
        };
        return this.getPropertyRequestAction(editMortgageServiceRequest.policyType, request, true);
    }

    getConsumerData(): PolicyConsumerData {
        const consumerData: PolicyConsumerData = {
            accessToken: sessionStorage.getItem('jwt'),
            camSessionId: ''
        };
        return consumerData;
    }

    getDeleteMortgagePropertyPolicyRequest(editMortgageServiceRequest: EditMortgageServiceRequest): PropertyRequestsOptions {
        const request: PropertyChangeRequest = {
            policyNumber: editMortgageServiceRequest.policyNumber,
            policyChangeId: editMortgageServiceRequest.policyChangeId,
            mortgageId: editMortgageServiceRequest.mortgageCompany.mortgageId
        };
        return this.getPropertyRequestAction(editMortgageServiceRequest.policyType, request, true);
    }

    getRateOrSubmitPropertyPolicyChangeRequest(editMortgageServiceRequest: EditMortgageServiceRequest): PropertyRequestsOptions {
        const request: PropertyChangeRequest = {
            policyNumber: editMortgageServiceRequest.policyNumber,
            policyChangeId: editMortgageServiceRequest.policyChangeId
        };
        return this.getPropertyRequestAction(editMortgageServiceRequest.policyType, request, true);
    }

    getRetrieveAllMortgagesRequest(editMortgageServiceRequest: EditMortgageServiceRequest): PropertyRequestsOptions {
        const request: PropertyChangeRequest = {
            policyNumber: editMortgageServiceRequest.policyNumber
        };
        return this.getPropertyRequestAction(editMortgageServiceRequest.policyType, request, true);
    }

    getStartPolicyChangeRequest(editMortgageServiceRequest: EditMortgageServiceRequest): PropertyRequestsOptions {
        const request: PropertyChangeRequest = {
            policyNumber: editMortgageServiceRequest.policyNumber,
            effectiveDate: editMortgageServiceRequest.effectiveDate
        };
        return this.getPropertyRequestAction(editMortgageServiceRequest.policyType, request, true);
    }

    getwithdrawPolicyChangeRequest(editMortgageServiceRequest: EditMortgageServiceRequest): PropertyRequestsOptions {
        const request: PropertyChangeRequest = {
            policyChangeId: editMortgageServiceRequest.policyChangeId
        };
        return this.getPropertyRequestAction(editMortgageServiceRequest.policyType, request, true);
    }

    private getPropertyRequestAction(propertyType?: string, request?: PropertyChangeRequest, skipCache = true): PropertyRequestsOptions {
        const propertyRequestsOptions: PropertyRequestsOptions = {
            propertyType,
            request,
            skipCache: true
        };
        return propertyRequestsOptions;
    }
}
