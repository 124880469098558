import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmitOptions } from '../../../../tpi-models/general/form-models';
import { UtilsService } from '../../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'app-edit-lien-form',
    templateUrl: './edit-lien-form.component.html'
})
export class EditLienFormComponent {
    @Input() parentForm: FormGroup;
    @Input() submitOptions: SubmitOptions;
    @Output() updateLienEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public utilsService: UtilsService) {
    }

    update(): void {
        this.updateLienEmit.emit(true);
    }
}
