import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { LoginLogoutErrorsComponent } from './login-logout-errors/login-logout-errors.component';
import { LoginLogoutLandingComponent } from './login-logout-landing/login-logout-landing.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        LoginLogoutErrorsComponent,
        LoginLogoutLandingComponent,
        LogoutComponent
    ],
    imports: [
        CommonModule,
        SharedComponentsModule
    ],
    exports: [
        LoginLogoutErrorsComponent,
        LoginLogoutLandingComponent,
        LogoutComponent
    ]
})
export class LoginLogoutModule { }
