<ng-container [formGroup]='parentForm' *ngIf="parentForm">

    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-textfield ngDefaultControl id="lien" name="lien" label="Edit Lien Threshold" aria-label="Edit Lien Threshold" formControlName="lien" required>
            </bolt-textfield>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-button submit slot="forward" type="primary" (click)="update()">Update</bolt-button>
        </div>
    </div>

</ng-container>