import { Injectable } from '@angular/core';
import { commonConstants } from '../../../../CONSTANTS/tpi-common-constants';
import { CoveragesSelectedValue } from '../../../../tpi-models/general/coverages-models';

@Injectable({
    providedIn: 'root'
})

export class SearchResultsAdapterService {
    isPolicyLevelCoverageAndSelected(coverage: any, vehicleId: number): boolean {
        const selectedValueFiltered = [];
        for (const selectedValue of coverage.selectedValue) {
            if (selectedValue.value !== commonConstants.FALSE_STRING && (
                coverage.coverageLevel === commonConstants.POLICY ||
                coverage.coverageLevel === commonConstants.VEHICLE &&
                coverage.coverableId === vehicleId
            )) {
                selectedValueFiltered.push(this.mapSelectedValueDescription(selectedValue));
            }
        }
        if (selectedValueFiltered.length > 0) {
            coverage.selectedValue = selectedValueFiltered;
            return true;
        }
        return false;
    }

    mapSelectedValueDescription(selectedValue: CoveragesSelectedValue): CoveragesSelectedValue {
        if (selectedValue.code === commonConstants.COVERAGES.FULL_SAFTY_GLASS_CODE) {
            selectedValue.description = commonConstants.COVERAGES.FULL_SAFTY_GLASS_DESCRIPTION;
        }
        return selectedValue;
    }
}
