<div>
    <h2 class="bolt-heading-md">Verify Your Identity</h2>

    <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>

    <h2 class="bolt-heading-sm">Verification Code</h2>

    <div class="bolt-row">
        <div class="bolt-col">
            <ul class="margin-bottom">
                <li>This code will be valid for {{mfaTimeLimit}} after you request it.</li>
                <li><strong>Do not close this window</strong> or you will have to request a new code.</li>
                <li>If a code doesn't arrive, check your spam folder before requesting a new one.</li>
            </ul>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col bolt-space-bottom-lg">
            <p>Please allow several minutes for the code to arrive.</p>
        </div>
    </div>

    <ng-container [formGroup]='mfaForm' *ngIf="mfaForm">
        <div class="bolt-row">
            <div class="bolt-col-sm-6 bolt-space-bottom-lg">
                <bolt-textfield ngDefaultControl id="verificationCode" name="verificationCode" label="Verification Code" required formControlName="verificationCode"
                    [attr.error]="checkForError('verificationCode', mfaForm, appendOption(submitOptions, {label: 'Verification Code'}))">
                </bolt-textfield>
            </div>
        </div>
    </ng-container>

    <div class="bolt-row">
        <div class="bolt-col bolt-space-bottom-lg">
            <bolt-notification type="info">
                After entering the verification code, please check your email for the temporary password.
                <br>
                If you do not receive a code, select 'Request New Code' or contact your
                admin.
            </bolt-notification>
        </div>
    </div>
</div>

<app-password-button-bar [secondaryLabel]="'Request New Code'" (secondaryEmit)="openModal($event)" [primaryLabel]="'Submit'" (primaryEmit)="performValidation($event)">
</app-password-button-bar>

<app-wait-indicator></app-wait-indicator>

<tpi-notification-modal *ngIf="showRequestNewCodeModal">
    <ng-container modalSuccess *ngIf="showRequestNewCodeModal">
        <bolt-modal primarybutton="Yes, leave the page" (bolt-modal-primary-button-click)="redirectToEmailConfirmationPage($event)" secondarybutton="No, stay on this page"
            (bolt-modal-secondary-button-click)="closeModal($event)" id="requestNewCodeModal" type="question" heading="You are leaving this page" open=true>
            <p>Are you sure you want to leave this page? Leaving this page will deactivate any previous activation
                codes.</p>
        </bolt-modal>
    </ng-container>
</tpi-notification-modal>