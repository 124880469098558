import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PolicyHolders } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/policyInformationDetails';
import { DpimRoleInfoResponse } from '@nationwide/internet-servicing-dpim-requests/api-response-types/distribution-partner-information-api';
import {
    BILLING_CONSTANT,
    CancellationDetailsReason,
    commonConstants,
    mortgageConstant,
    propertyEndorsementConstants,
    retrieveMailingAddress
} from '../../CONSTANTS/tpi-common-constants';
import { CoveredLocationResponse } from '../../tpi-models/covered-locations';
import { BillingBalance } from '../../tpi-models/general/billing-account-balances';
import { Bill, BillingAppliedPayment, BillingPolicyAmount } from '../../tpi-models/general/billing-account-bills';
import { Coverage, CoveragesResponse, CoveragesSelectedValue } from '../../tpi-models/general/coverages-models';
import { MortgageInformation } from '../../tpi-models/general/mortgage-company';
import { PolicyInformationResponse } from '../../tpi-models/general/policy-information';
import { PropertyPolicyInformation } from '../../tpi-models/general/property-details';
import {
    BillingMappingResponse,
    PropertyEndorsement,
    PropertyEndorsementAttributes
} from '../../tpi-models/general/property-endorsement';
import { PropertySearchValues } from '../../tpi-models/general/search-values';
import { LoggerService } from '../logger/logger.service';
import { UtilsService } from '../utility/utils-service.service';

@Injectable({
    providedIn: 'root'
})
export class PolicySearchResultsAdapterService {
    constructor(private loggerService: LoggerService, private utilsService: UtilsService) { }
    buildMortgageResponse(mappedMortgageResponse: any): any {
        if (mappedMortgageResponse.length > 0) {
            return this.sortMortgageeInformation(mappedMortgageResponse);
        }
        return [{
            isEmpty: true,
            isError: false,
            isEdit: true
        }];
    }
    // eslint-disable-next-line max-params, complexity
    filterBills(
        bills: Array<Bill>,
        balances: Array<BillingBalance>,
        policyNumber: string,
        billingMappingResponse: BillingMappingResponse
    ): BillingMappingResponse {
        let billingMapping = null;
        let bill: Bill = bills[0];
        let appliedBill: Bill = bills[0];
        let policyAmount: BillingPolicyAmount;
        let appliedPayment: BillingAppliedPayment;
        for (const latestBill of bills) {
            const dueDatePrevious = new Date(bill?.dueDate).getTime();
            const dueDateNext = new Date(latestBill?.dueDate).getTime();
            if (bill && dueDateNext > dueDatePrevious) {
                bill = latestBill;
            }
        }
        for (const latestAppliedBill of bills) {
            const dueDatePrevious = new Date(appliedBill?.dueDate).getTime();
            const dueDateNext = new Date(latestAppliedBill?.dueDate).getTime();
            if (latestAppliedBill?.appliedPayments && dueDateNext > dueDatePrevious) {
                appliedBill = latestAppliedBill;
            }
        }

        const balanceDue = balances.filter((balance) => balance.productKey === policyNumber);
        billingMappingResponse.currentAmountDue = balanceDue?.[0]?.minAmtDueMinusPendPmtZeroIfNeg.toFixed(2);
        billingMappingResponse.unPaidBalance = balanceDue?.[0]?.fullPayAmtMinusPendPmtZerIfNeg.toFixed(2);

        if (appliedBill?.appliedPayments) {
            appliedPayment = this.getAppliedPayment(appliedBill, policyNumber);
            if (appliedPayment) {
                billingMappingResponse.lastDayPaymentReceivedAmount = appliedPayment?.paidAmount?.toFixed(2);
                billingMappingResponse.lastDayPaymentReceivedDate = this.formatDate(appliedPayment?.receivedDate);
                billingMapping = billingMappingResponse;
            }
        }
        if (bill?.policyAmounts) {
            policyAmount = this.getPolicyAmount(bill, policyNumber);
            if (policyAmount) {
                billingMappingResponse.lastDayPayment = this.formatDate(policyAmount?.lastDayToPay);
                billingMappingResponse.billDueDate = this.formatDate(bill?.dueDate);
                billingMapping = billingMappingResponse;
            }
        }

        return billingMapping;
    }

    formatDate(date: string): string {
        if (date?.length > 0) {
            return this.utilsService.formatedDate(date);
        }
        return null;
    }

    getAppliedPayment(bill: Bill, policyNumber: string): BillingAppliedPayment {
        let appliedPayment = null;
        for (const appliedPaymt of bill.appliedPayments) {
            const receivedDatePrevious = new Date(appliedPayment?.receivedDate).getTime();
            const receivedDateNext = new Date(appliedPaymt?.receivedDate).getTime();
            if (
                appliedPaymt?.status === BILLING_CONSTANT.RECONCILED && appliedPaymt?.relatedPolicies?.policyNumber === policyNumber && !appliedPayment ||
                appliedPaymt?.status === BILLING_CONSTANT.RECONCILED && appliedPaymt?.relatedPolicies?.policyNumber === policyNumber && receivedDatePrevious < receivedDateNext
            ) {
                appliedPayment = appliedPaymt;
            }
        }
        return appliedPayment;
    }

    getPolicyAmount(bill: Bill, policyNumber): BillingPolicyAmount {
        let policyAmount: BillingPolicyAmount = null;
        try {
            for (const paymentAmt of bill.policyAmounts) {
                if (paymentAmt?.policyNumber === policyNumber) {
                    policyAmount = paymentAmt;
                }
            }
        } catch (error) {
            return policyAmount;
        }
        return policyAmount;
    }

    mapAgentInformation(agentResponse: DpimRoleInfoResponse | HttpErrorResponse, searchValues: PropertySearchValues): DpimRoleInfoResponse {
        let mappedAgentResponse = null;
        if (!(agentResponse instanceof HttpErrorResponse)) {
            this.loggerService.info('Agent API Success response - property fetchDistributionPartnerIndividualInfo()', {
                agentInformation: agentResponse,
                searchValues
            });
            mappedAgentResponse = agentResponse;
        }
        return mappedAgentResponse;
    }

    mapBillingResponse(response: any[]): BillingMappingResponse {
        let mappingResponse: BillingMappingResponse = null;
        const balancesIndex = 1;
        const billsIndex = 0;
        const policeNumberIndex = 2;
        const arraySize = 3;
        if (response?.length === arraySize) {
            mappingResponse = {
                billTo: null,
                billDueDate: null,
                currentAmountDue: null,
                unPaidBalance: null,
                lastDayPayment: null,
                lastDayPaymentReceivedAmount: null,
                lastDayPaymentReceivedDate: null
            };
            const policyNumber = response[policeNumberIndex]?.policyNumber;
            const bills: Array<Bill> = response[billsIndex][0]?.bills?.filter((b: Bill) => b?.billType !== 'Next');
            const balances: Array<BillingBalance> = response[balancesIndex]?.balances;
            mappingResponse = this.filterBills(bills, balances, policyNumber, mappingResponse);
        }
        this.loggerService.info('mapBillingResponse(() - billing mapped object response', mappingResponse);
        return mappingResponse;
    }
    mapCoveredLocation(coveredLocationResponse: CoveredLocationResponse | HttpErrorResponse): CoveredLocationResponse {
        this.loggerService.info('Covered location API Success response - property retrieveCoveredLocations()', {
            coveredLocationResponse
        });
        if (!coveredLocationResponse || coveredLocationResponse instanceof HttpErrorResponse) {
            return null;
        }
        return coveredLocationResponse;
    }

    mapDwellingReplacementCost(coverages: Array<Coverage>): PropertyEndorsementAttributes {
        let dwellingReplacementCost = null;
        try {
            const replacementCostNode = coverages.filter(
                (coveragesResponse: any) =>
                    (coveragesResponse.coverageId.includes(propertyEndorsementConstants.DwellingReplacementCostId) ||
                        coveragesResponse.coverageId.includes(propertyEndorsementConstants.GuaranteedReplacementCostId)) &&
                    coveragesResponse.selectedValue &&
                    coveragesResponse.selectedValue[0].value !== 'false'
            );
            if (replacementCostNode.length > 0) {
                dwellingReplacementCost = this.mapDwellingReplacementCostFields(replacementCostNode[0]);
            }
        } catch (error: any) {
            dwellingReplacementCost = null;
        }
        return dwellingReplacementCost;
    }

    mapDwellingReplacementCostFields(node: Coverage): PropertyEndorsementAttributes {
        if (node.name.toLowerCase().includes(propertyEndorsementConstants.GUARANTEED)) {
            return {
                label: propertyEndorsementConstants.GuaranteedReplacementCost,
                value: 'Guaranteed'
            };
        }
        return {
            label: propertyEndorsementConstants.DwellingReplacementCost,
            value: node.name.split('-')[1]
        };
    }

    mapEndorsementAttributes(selectedValue: Array<CoveragesSelectedValue>, codeValue: string): PropertyEndorsementAttributes {
        let propertyAttributes: PropertyEndorsementAttributes = null;
        if (selectedValue?.length > 0) {
            const selectedAttributes = selectedValue.filter(
                (selectedValueResponse) =>
                    selectedValueResponse.value !== commonConstants.FALSE_STRING && selectedValueResponse.code === codeValue
            );
            if (selectedAttributes?.length > 0) {
                propertyAttributes = this.mapLabelAndValue(
                    propertyEndorsementConstants[codeValue],
                    selectedAttributes[0]?.description || 'Included'
                );
            }
        }
        return propertyAttributes;
    }

    mapEndorsementValues(coverageEndorsementProperties: {
        coverageConstant: string;
        coverages: Array<Coverage>;
        coverageLevel: string;
        code?: string;
    }): PropertyEndorsementAttributes {
        const rawMappingEndorsement = coverageEndorsementProperties.coverages.filter(
            (coveragesFilterResponse) =>
                coveragesFilterResponse.coverageId === coverageEndorsementProperties.coverageConstant &&
                coveragesFilterResponse.coverageLevel === coverageEndorsementProperties.coverageLevel
        );
        if (rawMappingEndorsement?.length > 0) {
            return this.mapEndorsementAttributes(
                rawMappingEndorsement[0].selectedValue,
                coverageEndorsementProperties.code || null
            );
        }
        return null;
    }

    mapLabelAndValue(label: string, value: string): {
        label: string;
        value: string;
    } {
        return {
            label,
            value
        };
    }

    mapMortgageInformation(mortgageResponse: any, searchValues: PropertySearchValues): MortgageInformation {
        let mappedMortgageResponse = null;
        if (!mortgageResponse || mortgageResponse instanceof HttpErrorResponse) {
            mappedMortgageResponse = [
                {
                    isError: true,
                    isEmpty: false
                }
            ];
        } else {
            this.loggerService.info('Agent API Success response - property retrieveAllMortgages()', {
                mortgageInformation: mortgageResponse,
                searchValues
            });

            if (mortgageResponse?.length > 0) {
                mappedMortgageResponse = [];
                for (const mortgageResponseValues of mortgageResponse) {
                    if (mortgageResponseValues.type && mortgageResponseValues.mortgageType === mortgageConstant.mortgageeExtn) {
                        const { legalInformation, address, companyName, loanNumber, type } = mortgageResponseValues;
                        mappedMortgageResponse.push({
                            loanNumber,
                            address,
                            legalInformationName: legalInformation?.name || null,
                            type,
                            companyName,
                            isEmpty: false,
                            isError: false
                        });
                    }
                }
                mappedMortgageResponse = this.buildMortgageResponse(mappedMortgageResponse);
            } else {
                mappedMortgageResponse = [
                    {
                        isEmpty: true,
                        isError: false
                    }
                ];
            }
        }
        return mappedMortgageResponse;
    }

    mapPropertyEndorsements(coveragesResponse: CoveragesResponse | HttpErrorResponse, productType: string): PropertyEndorsement {
        if (!coveragesResponse || coveragesResponse instanceof HttpErrorResponse) {
            return null;
        }
        let propertyEndorsement: PropertyEndorsement = {
            currentDetectibleAmount: null,
            currentDwellingAmount: null,
            dwellingReplacementCost: null,
            currentPremium: null,
            renewelPremium: null
        };
        this.loggerService.info('Coverage API Success response - property retrievePropertyCoverages()', {
            mortgageInformation: coveragesResponse,
            productType
        });
        if (coveragesResponse) {
            const coverages: Array<Coverage> = coveragesResponse.coverages;
            if (productType === propertyEndorsementConstants.DWELLING_FIRE_CONSTANT) {
                propertyEndorsement.currentDwellingAmount = this.mapEndorsementValues({
                    coverageConstant: propertyEndorsementConstants.FireBuildingId,
                    coverages,
                    coverageLevel: propertyEndorsementConstants.Dwelling,
                    code: propertyEndorsementConstants.FireBuildingLimitId
                });
                propertyEndorsement.currentDetectibleAmount = this.mapEndorsementValues({
                    coverageConstant: propertyEndorsementConstants.SectionIDeductibleId,
                    coverages,
                    coverageLevel: propertyEndorsementConstants.Dwelling,
                    code: propertyEndorsementConstants.AllPerilDeductibleId
                });
            } else {
                if (productType === propertyEndorsementConstants.CONDO_CONSTANT) {
                    propertyEndorsement.currentDwellingAmount = this.mapEndorsementValues({
                        coverageConstant: propertyEndorsementConstants.BuildingAdditionsAlterationsId,
                        coverages,
                        coverageLevel: propertyEndorsementConstants.Dwelling,
                        code: propertyEndorsementConstants.BuildingAdditionsAlterationsLimitId
                    });
                } else {
                    propertyEndorsement.currentDwellingAmount = this.mapEndorsementValues({
                        coverageConstant: propertyEndorsementConstants.BasicCoverageId,
                        coverages,
                        coverageLevel: propertyEndorsementConstants.Dwelling,
                        code: propertyEndorsementConstants.BasicCoverageLimitId
                    });
                }
                propertyEndorsement.currentDetectibleAmount = this.mapEndorsementValues({
                    coverageConstant: propertyEndorsementConstants.BasicCoverageId,
                    coverages,
                    coverageLevel: propertyEndorsementConstants.Dwelling,
                    code: propertyEndorsementConstants.BasicCoverageDeductibleId
                });
            }
            propertyEndorsement.windHailDedectible = this.mapEndorsementValues({
                coverageConstant: propertyEndorsementConstants.SectionIDeductibleId,
                coverages,
                coverageLevel: propertyEndorsementConstants.Dwelling,
                code: propertyEndorsementConstants.WindHailPerilDeductibleId
            });
            propertyEndorsement.hurricanceDedectible = this.mapEndorsementValues({
                coverageConstant: propertyEndorsementConstants.SectionIDeductibleId,
                coverages,
                coverageLevel: propertyEndorsementConstants.Dwelling,
                code: propertyEndorsementConstants.HurricanePerilDeductibleId
            });
            propertyEndorsement.dwellingReplacementCost = this.mapDwellingReplacementCost(coverages);
            propertyEndorsement.currentPremium = {
                ...coveragesResponse?.premium?.previousPolicyFullTermPremium,
                label: propertyEndorsementConstants.CurrentPremium
            };
        } else {
            propertyEndorsement = null;
        }
        return propertyEndorsement;
    }

    mapPropertyPolicyInformation(policyInformationResponse: PolicyInformationResponse | HttpErrorResponse, policySearchValues: PropertySearchValues): PropertyPolicyInformation {
        const policyInformation = {
            policyInformationDetails: null,
            policyInformation: null,
            cancellationDetailsForTPI: null,
            mailingAddress: null,
            productType: null
        };
        const productTypeDisplayName = commonConstants.getPropertyTypeDisplayName(policySearchValues.productType);
        if (!(policyInformationResponse instanceof HttpErrorResponse)) {
            this.loggerService.info('Policy Information API response - retrievePolicyInformation()', {
                Policy: policySearchValues.policyNumber,
                ZIPCode: policySearchValues.zipCode,
                policyInformation: policyInformationResponse
            });
            policyInformation.policyInformationDetails = policyInformationResponse;
            if (policyInformation.policyInformationDetails.cancellationDetails == null) {
                policyInformation.policyInformation = {
                    policyStatus: commonConstants.POLICY_STATUS.ACTIVE,
                    policyType: productTypeDisplayName
                };
            } else {
                policyInformation.cancellationDetailsForTPI =
                    policyInformation.policyInformationDetails.cancellationDetails;
                policyInformation.cancellationDetailsForTPI.reason =
                    CancellationDetailsReason[policyInformation.policyInformationDetails.cancellationDetails?.reason];
                policyInformation.policyInformation = {
                    policyStatus: commonConstants.POLICY_STATUS.CANCELLED,
                    policyType: productTypeDisplayName
                };
            }

            policyInformation.mailingAddress =
                retrieveMailingAddress[policyInformation.policyInformationDetails.policyAddress.state];
            policyInformation.productType = productTypeDisplayName;
            this.loggerService.info('Mailing address Details', {
                policyNumber: policySearchValues.policyNumber,
                zipCode: policySearchValues.zipCode,
                productType: policySearchValues.productType,
                mailingAddress: policyInformation.mailingAddress
            });
        }
        return policyInformation;
    }

    mapPropertyPolicyInsured(policyInsured: Array<PolicyHolders> | HttpErrorResponse, searchValues: PropertySearchValues): Readonly<PolicyHolders> {
        let mappedInsured = null;
        if (!(policyInsured instanceof HttpErrorResponse)) {
            this.loggerService.info('Policy Holder API response - retrievePolicyHolders()', {
                policyNumber: searchValues.policyNumber,
                zipCode: searchValues.zipCode,
                policyInformation: policyInsured
            });
            mappedInsured = policyInsured.filter(
                (policyHolderType: PolicyHolders) => policyHolderType.policyHolderType === 'PolicyPriNamedInsured'
            )[0].person;
        }
        return mappedInsured;
    }

    sortMortgageeInformation(mappedMortgageResponse: any): any {
        return mappedMortgageResponse.sort((elementPrevious, elementNext) => {
            if (elementPrevious.type === elementNext.type) {
                return 0;
            } else if (elementPrevious.type === mortgageConstant.mortgagee1) {
                return -1;
            } else if (elementNext.type === mortgageConstant.mortgagee1) {
                return 1;
            } else {
                return 0;
            }
        });
    }
}
