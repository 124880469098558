import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-search-success-panel',
    templateUrl: './search-success-panel.component.html'
})
export class SearchSuccessPanelComponent implements OnInit {
    @Input() showSuccessMessage: boolean;
    @Input() successMessage: string;

    ngOnInit(): void {
        this.showSuccessMessage = false;
    }
}
