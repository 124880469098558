<ng-container>
    <tpi-notification-modal *ngIf="showModal">
        <bolt-modal primarybutton="Save" tertiarybutton="Cancel" (bolt-modal-primary-button-click)="saveCompany($event)" (bolt-modal-tertiary-button-click)="closeModal($event)" id="modifyCompanyModal"
            heading="Edit company" [open]="showModal">
            <p><strong>All fields are required.</strong></p>
            <app-company-info-form [parentForm]="companyForm" [submitOptions]="submitOptions">
            </app-company-info-form>
        </bolt-modal>
    </tpi-notification-modal>
</ng-container>
<tpi-success-modal (isOpenedEmit)="reloadPage($event)" [modalText]="'The company information you entered has been updated in our system.'">
</tpi-success-modal>