import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { SessionService } from '../../../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'tpi-password-change-success',
    templateUrl: './password-change-success.component.html'
})
export class PasswordChangeSuccessComponent implements OnInit {
    pingLogoutUrl = environment.pingLogoutUrl;
    constructor(
        private sessionService: SessionService
    ) { }

    ngOnInit(): void {
        this.sessionService.isLoggedIn = false;
        this.sessionService.deleteAllCookies();
    }
}
