import { Injectable } from '@angular/core';
import { AbstractLoggerService, LoggingJSON, LoggingLevel, SplunkService } from '@nationwide/client-logging';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoggerService extends AbstractLoggerService {
    SERVICE_ID = 'service';
    TRANSACTION_ID = 'transactionId';

    // Required settings for AbstractLoggerService
    appName = environment.splunkProps.appName;
    clientId = environment.authorize.client_id;
    clientLoggingEnabled = true;
    endpoint = environment.CLIENT_LOGGING_API.ENDPOINT;
    envName = environment.envName;
    logToConsole = environment.LOGGING.logToConsole;
    loggingJson = <LoggingJSON>environment.LOGGING.logJSON;
    loggingLevel = <LoggingLevel>environment.LOGGING.level;
    maxLogLength = environment.CLIENT_LOGGING_API.maxLogLength;
    maxRetryAttempts = environment.CLIENT_LOGGING_API.maxRetryAttempts;
    pushLength = environment.CLIENT_LOGGING_API.PUSH_LENGTH;
    retryDelay = 0;
    retryLogCurrentContentsTime: number;
    splunkToken = environment.splunkProps.token;

    // forLocal
    userName: string;

    constructor(
        splunkService: SplunkService
    ) {
        super(splunkService);
    }

    debug(statement: string, json?: object, additionalParameters?: object): void {
        additionalParameters = this.setTransactionIdAndService(additionalParameters);
        super.debug(statement, json, additionalParameters);
    }

    error(statement: string, json: object = {}, additionalParameters?: object): void {
        additionalParameters = this.setTransactionIdAndService(additionalParameters);
        super.error(statement, json, additionalParameters);
    }

    getAuthToken(): string {
        const oauthToken = sessionStorage.getItem('tokenDetails');
        return oauthToken?.split(',')[0] || '';
    }

    getSessionId(): string {
        return sessionStorage.getItem('sessionId');
    }

    getUser(): string {
        return sessionStorage.getItem('userName');
    }

    info(statement: string, json?: object, additionalParameters?: object): void {
        additionalParameters = this.setTransactionIdAndService(additionalParameters);
        super.info(statement, json, additionalParameters);
    }

    setUser(userName): void {
        this.userName = userName;
    }

    private setTransactionIdAndService(additionalParameters: object): {} {
        const transactionId = sessionStorage.getItem(this.TRANSACTION_ID);
        if (!additionalParameters) {
            additionalParameters = {};
        }
        additionalParameters[this.TRANSACTION_ID] = transactionId;
        additionalParameters[this.SERVICE_ID] = 'frontend';
        return additionalParameters;
    }
}
