import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { EVENTS } from '../../CONSTANTS/tpi-common-constants';

@Directive({
    selector: '[tpiToUppercaseDirective]'
})
export class ToUppercaseDirective {
    constructor(public element: ElementRef, private ngControl: NgControl) { }
    @HostListener(EVENTS.INPUT, ['$event'])
    onInput(event): void {
        const uppercaseInput = this.element.nativeElement.value.toUpperCase();
        this.element.nativeElement.value = uppercaseInput;
        this.ngControl.control.setValue(uppercaseInput);
    }
}
