import { Injectable } from '@angular/core';
import { PropertyCoveragesRequestsCommonService, PropertyRequestsCommonService } from '@nationwide/dgs-internet-servicing-policy-requests';
import { PolicyHolders } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/policyInformationDetails';
import { DpimRoleInfoResponse } from '@nationwide/internet-servicing-dpim-requests/api-response-types/distribution-partner-information-api';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { DistributionPartnerIndividualInfoService } from '../../shared/services/distribution-partner-individual-info/distribution-partner-individual-info.service';
import { CoveredLocationResponse } from '../../tpi-models/covered-locations';
import { BillingAccountRelatedAccounts } from '../../tpi-models/general/billing-account-related-accounts';
import { CoveragesResponse } from '../../tpi-models/general/coverages-models';
import { MortgageInformationResponse } from '../../tpi-models/general/mortgage-company';
import { PolicyInformationResponse } from '../../tpi-models/general/policy-information';
import { BillingMappingResponse } from '../../tpi-models/general/property-endorsement';
import { PropertySearchValues } from '../../tpi-models/general/search-values';
import { LoggerService } from '../logger/logger.service';
import { BillingService } from './billing.service';
import { PolicySearchResultsAdapterService } from './policy-search-results-adapter.service';
import { RenewalsService } from './renewals.service';

@Injectable({
    providedIn: 'root'
})
export class PolicySearchResultsService {
    // eslint-disable-next-line max-params
    constructor(
        private propertyRequestsCommon: PropertyRequestsCommonService,
        private propertyCoveragesCommon: PropertyCoveragesRequestsCommonService,
        private loggerService: LoggerService,
        private searchResultsAdapter: PolicySearchResultsAdapterService,
        private distributionPartnerIndividualInfoService: DistributionPartnerIndividualInfoService,
        private billingServices: BillingService,
        private renewalsService: RenewalsService
    ) { }

    getPropertySearchResults(searchValues: PropertySearchValues, consumerData: any, euaToken: any): Observable<any> {
        const policyNumber = searchValues.policyNumber;
        const propertyOptions = {
            propertyType: searchValues.productType
        };
        consumerData.isDirectCallToPC = true;

        const zipCode = searchValues.zipCode;
        const initApiCalls = [
            this.propertyRequestsCommon.retrievePropertyPolicyInformation(policyNumber, consumerData, propertyOptions).pipe(
                switchMap((policyInformationResponse: PolicyInformationResponse) => {
                    const policyInformationMapping = this.searchResultsAdapter.mapPropertyPolicyInformation(policyInformationResponse, {
                        policyNumber,
                        zipCode,
                        productType: searchValues.productType
                    });
                    return of(policyInformationMapping);
                }),
                catchError((error) => {
                    this.loggerService.error(
                        'Error In  Retrieve Policy Information API - retrievePolicyInformation()',
                        {
                            policyNumber,
                            error,
                            productType: searchValues.productType
                        }
                    );
                    return of(error);
                })
            ),
            this.propertyRequestsCommon.retrieveAllPolicyHolders(policyNumber, consumerData, propertyOptions).pipe(switchMap(
                (policyInsured: Array<PolicyHolders>) => of(this.searchResultsAdapter.mapPropertyPolicyInsured(policyInsured, { policyNumber, zipCode, productType: searchValues.productType }))
            ), catchError((error) => {
                this.loggerService.error(
                    'Error In  Retrieve Policy Holder API - retrieveAllPolicyHolders()',
                    {
                        policyNumber,
                        error
                    }
                );
                return of(error);
            })),
            this.propertyRequestsCommon.retrieveAllMortgages(policyNumber, consumerData, propertyOptions).pipe(switchMap(
                (mortgageResponse: Array<MortgageInformationResponse>) => of(this.searchResultsAdapter.mapMortgageInformation(mortgageResponse, { policyNumber, zipCode, productType: searchValues.productType }))
            ), catchError((error) => {
                this.loggerService.error(
                    'Error In  Fetch  API - retrieveAllMortgages()',
                    {
                        policyNumber,
                        error,
                        productType: searchValues.productType
                    }
                );
                return of(error);
            })),
            this.propertyCoveragesCommon.retrievePropertyCoverages({ consumerData }, searchValues.productType).pipe(switchMap((coveragesResponse: CoveragesResponse) => of(this.searchResultsAdapter.mapPropertyEndorsements(coveragesResponse, searchValues.productType))
            ), catchError((error) => {
                this.loggerService.error(
                    'Error In  Fetch  API - retrievePropertyCoverages()',
                    {
                        policyNumber,
                        error,
                        productType: searchValues.productType
                    }
                );
                return of(error);
            })),
            this.distributionPartnerIndividualInfoService.fetchDistributionPartnerIndividualInfo(policyNumber, euaToken).pipe(switchMap(
                (dpiResponse: DpimRoleInfoResponse) => of(this.searchResultsAdapter.mapAgentInformation(dpiResponse, { policyNumber, zipCode, productType: searchValues.productType }))
            ), catchError((error) => {
                this.loggerService.error(
                    'Error In  Fetch  API - fetchDistributionPartnerIndividualInfo()',
                    {
                        policyNumber,
                        error,
                        productType: searchValues.productType
                    }
                );
                return of(error);
            })),
            this.propertyRequestsCommon.retrieveCoveredLocations(policyNumber, consumerData, propertyOptions).pipe(
                switchMap((coveredLocationResponse: CoveredLocationResponse) => of(this.searchResultsAdapter.mapCoveredLocation(coveredLocationResponse))), catchError((error) => {
                    this.loggerService.error(
                        'Error In  Fetch  API - retrieveCoveredLocations()',
                        {
                            policyNumber,
                            error,
                            productType: searchValues.productType
                        }
                    );
                    return of(error);
                })),
            this.billingServices.getBillingRelatedAccounts(policyNumber).pipe(
                switchMap((response: BillingAccountRelatedAccounts) => {
                    const billingAccountNumber: string = this.billingServices.getBillingNumberFromRelatedAccountsResponse(response);
                    this.loggerService.info(`Billing Related Accounts for ${policyNumber} :  ${billingAccountNumber}`);
                    if (billingAccountNumber) {
                        const billingcalls = [
                            this.billingServices.getBillingAcountBills(billingAccountNumber),
                            this.billingServices.getBillingAcountBalances(billingAccountNumber)
                        ];
                        return forkJoin(billingcalls);
                    }
                    return of(null);
                }),
                switchMap((response: any[]): Observable<BillingMappingResponse> => {
                    if (response?.length > 0) {
                        response.push({ policyNumber });
                    }
                    return of(this.searchResultsAdapter.mapBillingResponse(response));
                }),
                catchError((error) => {
                    this.loggerService.error('Error In Billing Related Accounts API - getBillingRelatedAccounts()', { policyNumber, error });
                    return of(error);
                })),
            this.renewalsService.getPropertyRenewals(policyNumber, consumerData, propertyOptions).pipe(
                switchMap((response) => of(response)),
                catchError((error) => {
                    this.loggerService.error('Error In getPropertyRenewals API - getPropertyRenewals()', { policyNumber, error });
                    return of(error);
                }))
        ];
        return forkJoin(initApiCalls).pipe(
            switchMap((response) => of(response)));
    }
}
