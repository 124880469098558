<ng-container [formGroup]='parentForm' *ngIf="parentForm">
    <div *ngIf="!disableCurrentPassword" class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg nwpii">
            <bolt-password ngDefaultControl id="currentPassword" name="currentPassword" label="Current password" required formControlName="currentPassword" showvisibilitytoggle
                [attr.error]="checkForError('currentPassword', parentForm, appendOption(submitOptions, {label: 'Current password'}))">
            </bolt-password>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg nwpii">
            <bolt-password ngDefaultControl id="newPassword" name="newPassword" label="New password" [instructionaltext]="getNewPasswordInstructionaltext()" minlength="12" required
                formControlName="newPassword" showvisibilitytoggle (focus)="onNewPasswordFocus()" (focusout)="onNewPasswordFocusOut()"
                [attr.error]="checkForError('newPassword', parentForm, appendOption(submitOptions, {label: 'New password'}))">
                <bolt-contextual-help slot="help">
                    <p>Here are some helpful tips for creating a secure password: </p>
                    <ul>
                        <li>Avoid commonly used words.</li>
                        <li>Steer clear of repeating characters (e.g., 111, !!!).</li>
                        <li>Refrain from using numbers or letters in sequence (e.g., abc, 123).</li>
                        <li>Make sure it's not a previously used password.</li>
                    </ul>
                </bolt-contextual-help>
            </bolt-password>
        </div>
        <div *ngIf="doesNewPasswordsMatch()" class="new-passwords-match-checkmark bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-icon *ngIf=true name="checkmark-circle-filled" color="dark-mint"></bolt-icon>
        </div>
    </div>

    <app-interactive-requirements [parentForm]="parentForm" [showInteractiveRequirements]="showInteractiveRequirements"></app-interactive-requirements>

    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg nwpii">
            <bolt-password ngDefaultControl id="confirmNewPassword" name="confirmNewPassword" label="Confirm new password" minlength="12" required formControlName="confirmNewPassword"
                showvisibilitytoggle [attr.error]="checkForError('confirmNewPassword', parentForm, appendOption(submitOptions, {label: 'Confirm new password'}))">
            </bolt-password>
        </div>
        <div *ngIf="doesNewPasswordsMatch()" class="new-passwords-match-checkmark bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-icon *ngIf=true name="checkmark-circle-filled" color="dark-mint"></bolt-icon>
        </div>
    </div>
</ng-container>