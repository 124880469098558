import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormElementCoreComponent } from '../../../../shared/components/form-element-core/form-element-core.component';
import { UtilsService } from '../../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'app-username-form',
    templateUrl: './username-form.component.html'
})
export class UsernameFormComponent extends FormElementCoreComponent {
    @Input() parentForm: FormGroup;
    @Input() submitOptions: any;

    constructor(public utilsService: UtilsService) {
        super();
    }
}
