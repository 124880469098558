import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/core-component-library-base';

@Injectable({
    providedIn: 'root'
})
export class SetupTransactionService {
    private TRANSACTION_ID = 'transactionId';

    constructor(
        private apiCommonService: APICommonService
    ) { }

    getTransactionId(): string {
        if (sessionStorage.getItem(this.TRANSACTION_ID)) {
            return sessionStorage.getItem(this.TRANSACTION_ID);
        } else {
            this.setupTransactionId();
            return sessionStorage.getItem(this.TRANSACTION_ID);
        }
    }

    setupTransactionId(): void {
        sessionStorage.setItem(this.TRANSACTION_ID, this.apiCommonService.generateTransactionId());
    }
}
