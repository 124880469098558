<ng-container>
    <tpi-notification-modal *ngIf="showModal">
        <bolt-modal [primarybutton]="modifyUserInput?.modalButtonText" tertiarybutton="Cancel" (bolt-modal-primary-button-click)="tryModifyUser($event)"
            (bolt-modal-tertiary-button-click)="closeModal($event)" id="modifyUserModal" [heading]="modifyUserInput?.modalHeading" [open]="showModal">
            <p>{{modifyUserInput?.modalText}}</p>
        </bolt-modal>
    </tpi-notification-modal>

    <tpi-success-modal [modalText]="modifyUserInput?.successModalText">
    </tpi-success-modal>
</ng-container>