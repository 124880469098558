<div [formGroup]='parentForm' *ngIf="parentForm" class="bolt-space-bottom-lg" (keydown)="searchMortgageCompany($event)">
    <div class="bolt-row bolt-space-bottom-md">
        <div class="bolt-col-12">
            <h2 class="bolt-body-copy-md"><strong>Select new mortgage company by state and name</strong></h2>
        </div>
    </div>
    <div class="bolt-row bolt-space-bottom-lg">
        <div class="bolt-col-sm bolt-col-lg-3">
            <bolt-select ngDefaultControl formControlName='state' arialabel="Please select state" required placeholder="Select state"
                [attr.error]="checkForError('state', parentForm, appendOption(submitOptions, {label: 'State'}))">
                <option *ngFor='let stateValue of states' [value]='stateValue.value'>{{stateValue.label}}</option>
            </bolt-select>
        </div>
        <div class="bolt-col-sm bolt-col-lg-5">
            <bolt-textfield ngDefaultControl formControlName="companyName" arialabel="Please enter company name" maxlength="100"
                [attr.error]="checkForError('companyName', parentForm, appendOption(submitOptions, {label: 'Company Name'}))" required>
            </bolt-textfield>

        </div>
        <div class="bolt-col-sm bolt-col-lg-4">
            <bolt-button slot="forward" type="primary" width="full-sm" class="button-margin-space" (click)="searchMortgageCompany(true)">Search</bolt-button>
            <bolt-button slot="back" class="buttons" width="full-sm" (click)="resetMortgageCompany()">Reset</bolt-button>
        </div>
    </div>
</div>