import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { APICommonService, HeaderTypes } from '@nationwide/core-component-library-base';
import { environment } from '../../../environments/environment';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { TpiManageUsersPagePaths, tpiManageUsers } from '../../CONSTANTS/tpi-manage-users';
import { BulkGridSantized } from '../../tpi-models/general/mortgage-company';
import { SearchFields } from '../../tpi-models/general/search-forms';
import { ManageUserAccountsAccountInfo } from '../../tpi-models/manage-accounts-company-web/manage-user-accounts-account-info';
import { ModifyUserAccountInfo } from '../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { ModifyUserErrorResponse } from '../../tpi-models/manage-accounts-company-web/modify-user-error-response';
import { ModifyUserRequest } from '../../tpi-models/manage-accounts-company-web/modify-user-request';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    constructor(private apiCommonService: APICommonService) { }

    addToValidGrid(rows: Array<Array<string>>, invalidRowNumbers: Array<number>, returnValue: BulkGridSantized): BulkGridSantized {
        const rowsToValid = [];
        let row = 1;
        if (invalidRowNumbers.length > 0) {
            for (const eachRow of rows) {
                if (!invalidRowNumbers.includes(row)) {
                    rowsToValid.push(eachRow);
                }
                row++;
            }
            returnValue.validGrid = this.getNonEmptyValuesForGrid(rowsToValid);
        }
        return returnValue;
    }

    checkDuplicate(formControl: AbstractControl, sanitizedValue: BulkGridSantized): any | [] {
        if (formControl?.errors?.duplicatePolicyNumber) {
            const duplicates = formControl?.errors.duplicatePolicyNumber.split(',');
            const nonEmpties = this.getNonEmptyValuesForGrid(formControl.value);
            for (const duplicate of duplicates) {
                for (const nonEmpty of nonEmpties) {
                    if (duplicate === nonEmpty[1]) {
                        sanitizedValue.invalidGrid.push(nonEmpty);
                        sanitizedValue.duplicates.push(nonEmpty);
                    }
                }
            }
            sanitizedValue = this.removeDuplicateFromValid(duplicates, sanitizedValue);
        }
        return sanitizedValue;
    }

    checkKeyAllow(exp, event): boolean {
        const charval = this.getKeyPressKey(event);
        return this.checkTextAllowed(exp, charval, event);
    }

    checkTextAllowToPaste(exp, event): boolean {
        const pastedData = this.getPasteText(event);
        return this.checkTextAllowed(exp, pastedData, event);
    }

    checkTextAllowed(exp, data, event): boolean {
        if (exp.test(data)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    currentDateValue(): string {
        const today = new Date();
        return this.formatedDate(today);
    }

    formatedDate(dateValue): string {
        return formatDate(dateValue, 'MM/dd/yyyy', 'en_US');
    }

    getDaysBetweenDates(startDate: Date, endDate: Date): string {
        const second = 1000;
        const secondsInDay = 86400;
        const secondsInStartDate = startDate.getTime() / second;
        const secondsInEndDate = endDate.getTime() / second;
        const days = Math.floor((secondsInEndDate - secondsInStartDate) / secondsInDay);
        return days.toString();
    }

    getHtmlList(items: Array<string>): string {
        const list = items.map((item) => `<li>${item}</li>`).join('');
        return `<ul class="error-list">${list}</ul>`;
    }

    getHttpOptions(euaToken: string, headerType?: HeaderTypes): HttpHeaders {
        return this.apiCommonService.buildHeader({
            appClientId: environment.authorize.client_id,
            authToken: euaToken,
            nwAppId: '',
            contentType: 'application/json',
            headerType: headerType || 'X-NW-MessageID'
        });
    }

    getKeyPressKey(event): string {
        return String.fromCharCode(event.charCode || event.which);
    }

    getNonEmptyValuesForGrid(gridValue: any): any {
        return gridValue.filter((item) => item[0] !== '' || item[1] !== '' || item[2] !== '');
    }

    getPagePathForUserRole(roleNumber: number): string {
        let path;
        switch (roleNumber) {
            case tpiManageUsers.WEB_SUPPORT_ACCOUNT.id:
                path = TpiManageUsersPagePaths.Websupport;
                break;
            case tpiManageUsers.SUPER_USER_ACCOUNT.id:
                path = TpiManageUsersPagePaths.CompanyList;
                break;
            case tpiManageUsers.ADMIN_ACCOUNT.id:
                path = TpiManageUsersPagePaths.ManageUsers;
                break;
            default:
                path = TpiManageUsersPagePaths.Default;
        }
        return path;
    }

    getPasteText(event): string {
        const clipboardData = event.clipboardData;
        return clipboardData.getData('Text');
    }

    getUserIdsFromModifyUserAccountInfo(users: Array<ModifyUserAccountInfo>): Array<string> {
        return users?.map((user) => user.userId);
    }

    getUsersFailedFromResponse(response: Array<ModifyUserErrorResponse>): Array<ModifyUserAccountInfo> {
        return response?.map((errorResponse) => errorResponse.user);
    }

    getUsersSucceed(usersFailed: Array<ModifyUserAccountInfo>, request: ModifyUserRequest): Array<ModifyUserAccountInfo> {
        return request.users.filter((user) => usersFailed.find((userFailed) => userFailed.userId === user.userId) == null);
    }

    gridRemoveWhiteSpace(grid: Array<Array<string>>): Array<Array<string>> {
        for (const [rowIndex, row] of grid.entries()) {
            for (const [colIndex, cell] of row.entries()) {
                grid[rowIndex][colIndex] = cell.replace(/\s/g, '');
            }
        }
        return grid;
    }

    gridReset(): Array<Array<string>> {
        const data = [];
        for (let index = 0; index < commonConstants.BULK_CONSTANTS.MAX_UPLOAD; index++) {
            data.push(['', '', '']);
        }
        return data;
    }

    isCityValid(city): boolean {
        return !this.isFieldEmpty(city);
    }

    isFieldEmpty(field): boolean {
        return field === '';
    }

    isFullVinFormatValid(fullVin): boolean {
        const fullVinRegex = /^[0-9a-zA-Z]+$/;
        const fullVinExRegex = /^[^iIoO]+$/;
        const nineRegex = /^\d$/;
        const indexValue = 8;
        const ninethchar = fullVin.charAt(indexValue);
        const vinLength = 17;

        return !(
            !fullVinRegex.test(fullVin) ||
            fullVin.length !== vinLength ||
            !fullVinExRegex.test(fullVin) ||
            ninethchar !== commonConstants.POLICY_NUMBER_CONSTANTS.X_DIGIT &&
            ninethchar !== commonConstants.POLICY_NUMBER_CONSTANTS.X_DIGIT.toLowerCase() &&
            !nineRegex.test(ninethchar)
        );
    }

    isFullVinValid(fullVin): boolean {
        if (this.isFieldEmpty(fullVin)) {
            return false;
        } else {
            return !!this.isFullVinFormatValid(fullVin);
        }
    }

    isNoSearchFieldValid(searchFields): boolean {
        return !!(
            this.isStreetAddressValid(searchFields.streetAddress) &&
            this.isCityValid(searchFields.city) &&
            this.isStateValid(searchFields.state) &&
            this.isZipValid(searchFields.zipCode) &&
            this.isFullVinValid(searchFields.fullVin)
        );
    }

    isPolicyNumberFormatValid(policyNumber): boolean {
        return !!(
            policyNumber.length > 0 && this.validatePolicyNumber(policyNumber)
        );
    }

    isPolicyNumberValid(policyNumber): boolean {
        if (this.isFieldEmpty(policyNumber)) {
            return false;
        } else {
            return !!this.isPolicyNumberFormatValid(policyNumber);
        }
    }

    isSearchFieldsValid(searchFields: SearchFields): boolean {
        if (searchFields.isYesSearch) {
            return this.isYesSearchFieldsValid(searchFields);
        } else {
            return this.isNoSearchFieldValid(searchFields);
        }
    }

    isStateValid(state): boolean {
        return !this.isFieldEmpty(state);
    }

    isStreetAddressValid(streetAddress): boolean {
        return !this.isFieldEmpty(streetAddress);
    }

    isVinFormatValid(vin): boolean {
        return !!(vin.length > 0 && this.validateVin(vin));
    }

    isVinValid(vin): boolean {
        if (this.isFieldEmpty(vin)) {
            return false;
        } else {
            return !!this.isVinFormatValid(vin);
        }
    }

    isYesSearchFieldsValid(serchFields): boolean {
        return (
            this.isPolicyNumberValid(serchFields.policyNumber) &&
            this.isVinValid(serchFields.vin)
        );
    }

    isZipFormatValid(zip): boolean {
        const zipCodeRegex = /^\d{5}$/;
        return !!zipCodeRegex.test(zip);
    }

    isZipValid(zip): boolean {
        if (this.isFieldEmpty(zip)) {
            return false;
        } else {
            return !!this.isZipFormatValid(zip);
        }
    }

    keyPressAlphanumeric(event): boolean {
        const exp = /^[a-zA-Z0-9]$/;
        return this.checkKeyAllow(exp, event);
    }

    keyPressAlphanumericAndSpace(event): boolean {
        const exp = /^[a-zA-Z0-9\s]$/;
        return this.checkKeyAllow(exp, event);
    }

    keyPressCompanyName(event): boolean {
        const exp = /[><?@'`~^%[\]{}.!#|"$;:=/(),\-\w\s+]*/;
        return this.checkKeyAllow(exp, event);
    }

    keyPressDate(event): boolean {
        const exp = /^[0-9/]$/;
        return this.checkKeyAllow(exp, event);
    }

    keyPressLetters(event): boolean {
        const exp = /^[a-zA-Z]$/;
        return this.checkKeyAllow(exp, event);
    }

    keyPressNumbers(event): boolean {
        const exp = /^\d$/;
        return this.checkKeyAllow(exp, event);
    }

    mapManageUserAccountInfo(accounts: Array<ManageUserAccountsAccountInfo>): Array<ModifyUserAccountInfo> {
        return accounts.map((account) => ({
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.email,
            userId: account.userId,
            role: account.role.id,
            userNumber: account.id,
            companyNumber: account.company.id
        }));
    }

    minDateValue(numberDays): Date {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - numberDays);
        return minDate;
    }

    pasteAlphanumeric(event): boolean {
        const exp = /^\w+$/;
        return this.checkTextAllowToPaste(exp, event);
    }

    pasteAlphanumericAndSpace(event): boolean {
        const exp = /^[\w\s]+$/;
        return this.checkTextAllowToPaste(exp, event);
    }

    pasteCompanyName(event): boolean {
        const exp = /[><?@'`~^%[\]{}.!#|"$;:=/(),\-\w\s+]*/;
        return this.checkTextAllowToPaste(exp, event);
    }

    pasteDate(event): boolean {
        const exp = /^[\d/]+$/;
        return this.checkTextAllowToPaste(exp, event);
    }

    pasteLetters(event): boolean {
        const exp = /^[a-zA-Z]+$/;
        return this.checkTextAllowToPaste(exp, event);
    }

    pasteNumbers(event): boolean {
        const exp = /^\d+$/;
        return this.checkTextAllowToPaste(exp, event);
    }

    printScreen(): void {
        window.print();
    }

    removeDuplicateFromValid(duplicates: Array<string>, returnValue: BulkGridSantized): BulkGridSantized {
        const updatedValidGrid = [];
        for (const valid of returnValue.validGrid) {
            if (!duplicates.includes(valid[1])) {
                updatedValidGrid.push(valid);
            }
        }
        returnValue.validGrid = updatedValidGrid;
        return returnValue;
    }

    sanitizedValueAgainstInvalid(formValue: AbstractControl): BulkGridSantized {
        let returnValue: BulkGridSantized = {
            validGrid: [],
            invalidGrid: [],
            duplicates: []
        };
        const rows: Array<Array<string>> = formValue.value;
        if (formValue?.errors?.bulkError) {
            const invalidRowNumbers = [];
            for (const gridError of <Array<any>>formValue.errors.bulkError) {
                const gridRowNumber = Number(gridError.rowNo);
                invalidRowNumbers.push(gridRowNumber);
                returnValue.invalidGrid.push(rows[gridRowNumber - 1]);
            }
            returnValue = this.addToValidGrid(rows, invalidRowNumbers, returnValue);
        } else {
            returnValue.validGrid = this.getNonEmptyValuesForGrid(rows);
        }
        returnValue = this.checkDuplicate(formValue, returnValue);
        return returnValue;
    }

    scrollToTopOfPage(): void {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });
    }

    setEmptyValuesForGrid(gridValue: Array<Array<string>>): Array<Array<any>> {
        const totalValue = commonConstants.BULK_CONSTANTS.MAX_UPLOAD - gridValue.length;
        if (totalValue < commonConstants.BULK_CONSTANTS.MAX_UPLOAD) {
            for (let gridIndex = 0; gridIndex < totalValue; gridIndex++) {
                gridValue.push(['', '', '']);
            }
        }
        return gridValue;
    }

    validateDate(dateValue): boolean {
        const isValidDate01to28 = /^(0\d|1[0-2])\/(0\d|1\d|2[0-8])\/(19|20)\d{2}$/;
        const isValidDate29to30 = /^(01|0[3-9]|1[0-2])\/(29|30)\/(19|20)\d{2}$/;
        const isValidDate31 = /^(0[13578]|1[02])\/31\/(19|20)\d{2}$/;
        const isValidLeapDay = /^(02)\/29\/(((19|20)(04|08|[2468][048]|[13579][26]))|2000)$/;
        return isValidDate01to28.test(dateValue) || isValidDate29to30.test(dateValue) || isValidDate31.test(dateValue) || isValidLeapDay.test(dateValue);
    }

    validatePolicyNumber(polNumber): boolean {
        const policyCenterExp = /^\d{4}[a-zA-Z\s]{0,2}\d{6}$/;
        polNumber = polNumber.replace(/\s/g, '');
        return !!polNumber.match(policyCenterExp);
    }

    validateVin(vinValue): boolean {
        vinValue = vinValue.replace(/\s/g, '');
        const numericExpression = /^[0-9a-zA-Z]{6}$/;
        return !!vinValue.match(numericExpression);
    }
}
