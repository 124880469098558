import { Component, Input, OnInit } from '@angular/core';
import { DpimContactInformation } from '@nationwide/internet-servicing-dpim-requests/api-response-types/distribution-partner-information-api';
import { environment } from '../../../../environments/environment';
import { AgentDetails } from '../../../tpi-models/general/agent-information';
@Component({
    selector: 'app-agent-details',
    templateUrl: './agent-details.component.html',
    styleUrls: ['./agent-details.component.scss']
})
export class AgentDetailsComponent implements OnInit {
    @Input() dpimContactInformation: DpimContactInformation;
    @Input() isActiveTab: string;
    @Input() isSA360: boolean;

    agent = <AgentDetails>{};
    sa360Contact = environment.sa360Contact;

    dpimContactInformationIsError(): boolean {
        return this.dpimContactInformation instanceof Error;
    }

    formatZip(zip): string {
        const zipLength = 5;
        return zip.length > zipLength ? `${zip.slice(0, Math.max(0, zipLength))}-${zip.slice(Math.max(0, zipLength))}` : zip;
    }

    getContactPoints(): void {
        if (this.dpimContactInformation?.contactPoints?.length > 0) {
            this.agent.phones = [];
            for (const contactPoint of this.dpimContactInformation.contactPoints) {
                if (contactPoint?.contactPoint?.postalAddress) {
                    this.agent.addressLineOne = contactPoint.contactPoint.postalAddress.addressLineOne;
                    this.agent.city = contactPoint.contactPoint.postalAddress.city;
                    this.agent.state = contactPoint.contactPoint.postalAddress.stateOrProvince;
                    this.agent.zip = this.formatZip(contactPoint.contactPoint.postalAddress.postalCode);
                } else if (contactPoint?.contactPoint?.email?.emailAddress) {
                    this.agent.email = contactPoint.contactPoint.email.emailAddress;
                } else if (contactPoint?.contactPoint.phone?.fullPhoneNumber) {
                    this.agent.phones.push(contactPoint.contactPoint.phone.fullPhoneNumber);
                }
            }
        }
    }

    getNameDetails(): void {
        this.agent.firstName =
            this.dpimContactInformation.contactName?.personName?.firstName;
        this.agent.lastName =
            this.dpimContactInformation.contactName?.personName?.lastName;
        this.agent.middleName =
            this.dpimContactInformation.contactName?.personName?.middleName;
    }

    getOrganization(): void {
        this.agent.organization =
            this.dpimContactInformation.contactName.organizationName.orgName;
    }

    ngOnInit(): void {
        if (this.dpimContactInformation && !this.dpimContactInformationIsError()) {
            if (this.dpimContactInformation.contactName.personName) {
                this.getNameDetails();
            } else {
                this.getOrganization();
            }
            this.getContactPoints();
        }
    }
}
