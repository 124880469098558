<ng-container [formGroup]='parentForm' *ngIf="parentForm">
    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg nwpii">
            <bolt-textfield ngDefaultControl id="streetAddress" name="streetAddress" label="Street Address" required maxlength="100" formControlName="streetAddress"
                [attr.error]="checkForError('streetAddress', parentForm, appendOption(submitOptions, {label: 'Street Address'}))">
            </bolt-textfield>
        </div>
        <div class="bolt-col-sm-6 bolt-space-bottom-lg nwpii">
            <bolt-textfield ngDefaultControl label="City" required maxlength=25 formControlName="city" [attr.error]="checkForError('city', parentForm, appendOption(submitOptions, {label: 'City'}))">
            </bolt-textfield>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg nwpii">
            <bolt-select label="State" ngDefaultControl width="10" formControlName="state" placeholder="Select" required (change)="setState($event)" [(value)]="autoSearchFields.state"
                [attr.error]="checkForError('state', parentForm, appendOption(submitOptions, {label: 'State'}))">
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
            </bolt-select>
        </div>
        <div class="bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-textfield label="ZIP Code" required ngDefaultControl formControlName="zipCode" inputsize="5" maxlength="5" (keypress)="utilsService.keyPressNumbers($event)"
                (paste)="utilsService.pasteNumbers($event)" [attr.error]="checkForError('zipCode', parentForm, appendOption(submitOptions, {label: 'ZIP Code'}))">
            </bolt-textfield>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-textfield label="Full VIN" required ngDefaultControl tpiToUppercaseDirective formControlName="fullVin" inputsize="17" maxlength="17"
                (keypress)="utilsService.keyPressAlphanumeric($event)" (paste)="utilsService.pasteAlphanumeric($event)"
                [attr.error]="checkForError('fullVin', parentForm, appendOption(submitOptions, {label: 'Full VIN'}))">
            </bolt-textfield>
        </div>
    </div>
</ng-container>