import { Component, EventEmitter, Output } from '@angular/core';
@Component({
    selector: 'app-bulk-button-bar',
    templateUrl: './bulk-button-bar.component.html'
})

export class BulkButtonBarComponent {
    @Output() onReset: EventEmitter<any> = new EventEmitter();
    @Output() onSubmit: EventEmitter<any> = new EventEmitter();

    resetSearch(): void {
        this.onReset.emit();
    }

    submitSearch(): void {
        this.onSubmit.emit();
    }
}
