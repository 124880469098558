import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ACCEPT_REGEX, EVENTS } from '../../CONSTANTS/tpi-common-constants';

@Directive({
    selector: '[tpiNumbersOnlyDirective]'
})
export class NumbersOnlyDirective {
    constructor(private element: ElementRef, private ngControl: NgControl) { }

    @HostListener(EVENTS.INPUT, ['$event']) onInputChange(event): void {
        const initalValue = this.element.nativeElement.value;
        const initalValueReplace = initalValue.replace(ACCEPT_REGEX.ONLY_DIGITS, '');
        this.element.nativeElement.value = initalValueReplace;
        this.ngControl.control.setValue(initalValueReplace);
        if (initalValue !== this.element.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
