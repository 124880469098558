import { Component, Input } from '@angular/core';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'app-password-notification',
    templateUrl: './password-notification.component.html',
    styleUrls: ['./password-notification.component.scss']
})
export class PasswordNotificationComponent {
    @Input() isError: boolean;
    @Input() message: string;

    constructor(
        private sessionService: SessionService
    ) { }

    login(): void {
        this.sessionService.redirectToLegacy();
    }
}
