<div *ngIf="manageUserAccountsAccountInfo && manageUserAccountsAccountInfo.id" class="bolt-container">
    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-col-md-4">
            <h3>NAME</h3>
            <p>{{manageUserAccountsAccountInfo.firstName}} {{manageUserAccountsAccountInfo.lastName}}</p>
        </div>
        <div class="bolt-col-sm-6 bolt-col-md-5">
            <h3>USERNAME</h3>
            <p>{{manageUserAccountsAccountInfo.userId}} </p>
            <h3>EMAIL ADDRESS</h3>
            <p>{{manageUserAccountsAccountInfo.email}}</p>
        </div>
        <div class="bolt-col-sm-12 bolt-col-md-3 bolt-container">
            <div class="bolt-row">
                <bolt-button class="bolt-col-12" width="full" size="sm" (click)="open('UNLOCK')">Unlock user</bolt-button>
            </div>
            <div class="bolt-row">
                <bolt-button class="bolt-col-12" width="full" size="sm" (click)="open('RESET')">Reset password</bolt-button>
            </div>
        </div>
    </div>
    <hr>
    <tpi-notification-modal>
        <ng-container modalInfo>
            <bolt-modal primarybutton="Unlock" tertiarybutton="Cancel" (bolt-modal-primary-button-click)="passwordMFAUnlock()" (bolt-modal-tertiary-button-click)="close()" id="unlockComfirmation"
                #unlockComfirmation heading="Unlock user access">
                <p class="update-font">
                    {{webSupportModalTextUnlock}}
                </p>
            </bolt-modal>
        </ng-container>
        <ng-container modalInfo>
            <bolt-modal primarybutton="Reset Password" tertiarybutton="Cancel" (bolt-modal-primary-button-click)="passwordReset()" (bolt-modal-tertiary-button-click)="close()" id="resetComfirmation"
                #resetComfirmation heading="Reset user password">
                <p class="update-font">
                    {{webSupportModalTextReset}}
                </p>
            </bolt-modal>
        </ng-container>
        <ng-container modalSuccess>
            <bolt-modal primarybutton="Close" (bolt-modal-primary-button-click)="close()" id="modalSuccess" #modalSuccess heading="Success!" type="confirm">
                <p class="update-font" *ngIf="successUnlockMessage">
                    {{webSupportSuccessModalUnlock}}
                </p>
                <p class="update-font" *ngIf="!successUnlockMessage">
                    {{webSupportSuccessModalReset}}
                </p>
            </bolt-modal>
        </ng-container>
    </tpi-notification-modal>