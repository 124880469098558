<div *ngIf="mailingAddress">
    <h1 class="bolt-heading-md">
        Mailing Addresses
    </h1>
    <div class="bolt-row">
        <div class="section-one-third bolt-col-sm-4 bolt-col-12 divider">
            <div class="bot-margin-3">
                <h2 class="element-text bolt-body-copy-sm bolt-heading-sm">
                    mail payments to:
                </h2>
                <p class="bolt-body-copy-sm">

                    {{ mailingAddress.mailPaymentTo.to }}
                    <br />
                    {{ mailingAddress.mailPaymentTo.address.addressLine1 }}

                    {{ mailingAddress.mailPaymentTo.address.addressLine2 }}

                    <br />
                    {{ mailingAddress.mailPaymentTo.address.city }},

                    {{ mailingAddress.mailPaymentTo.address.state }}

                    {{ mailingAddress.mailPaymentTo.address.postalCode }}
                </p>
            </div>
        </div>

        <div class="section-one-third bolt-col-sm-4 bolt-col-12 divider">
            <div class="bot-margin-3">
                <h2 class="element-text bolt-body-copy-sm bolt-heading-sm">
                    overnight payments to:
                </h2>
                <p class="bolt-body-copy-sm">
                    {{ mailingAddress.overnightPaymentsTo.to }}
                    <br />
                    <span *ngIf="
                            mailingAddress.overnightPaymentsTo.address
                                .addressLine1
                        ">{{
                        mailingAddress.overnightPaymentsTo.address
                        .addressLine1
                        }}<br /></span>
                    <span *ngIf="
                            mailingAddress.overnightPaymentsTo.address
                                .addressLine2
                        ">{{
                        mailingAddress.overnightPaymentsTo.address
                        .addressLine2
                        }}<br /></span>

                    <span *ngIf="
                            mailingAddress.overnightPaymentsTo.address
                                .addressLine3
                        ">{{
                        mailingAddress.overnightPaymentsTo.address
                        .addressLine3
                        }}<br /></span>

                    <span *ngIf="
                            mailingAddress.overnightPaymentsTo.address
                                .addressLine4
                        ">{{
                        mailingAddress.overnightPaymentsTo.address
                        .addressLine4
                        }}<br /></span>

                    {{ mailingAddress.overnightPaymentsTo.address.city }},
                    {{ mailingAddress.overnightPaymentsTo.address.state }}
                    {{ mailingAddress.overnightPaymentsTo.address.postalCode }}
                    <br />
                    {{ mailingAddress.overnightPaymentsTo.address.attn }}
                </p>
            </div>
        </div>
        <div class="section-one-third bolt-col-sm-4 bolt-col-12">
            <div class="bot-margin-3">
                <h2 class="element-text bolt-body-copy-sm bolt-heading-sm">
                    general correspondence:
                </h2>
                <p class="bolt-body-copy-sm">
                    {{ mailingAddress.generalCorrespondence.to }}
                    <br />
                    {{
                    mailingAddress.generalCorrespondence.address
                    .addressLine1
                    }}
                    {{
                    mailingAddress.generalCorrespondence.address
                    .addressLine2
                    }}
                    <br />
                    {{ mailingAddress.generalCorrespondence.address.city }},
                    {{ mailingAddress.generalCorrespondence.address.state }}
                    {{
                    mailingAddress.generalCorrespondence.address.postalCode
                    }}
                </p>
            </div>
        </div>
    </div>
    <br />
    <bolt-notification type="info" class="bolt-body-copy-sm noprint">
        Make checks payable to: Nationwide Insurance
    </bolt-notification>
    <p class="bolt-body-copy-sm noscreen">
        Make checks payable to: Nationwide Insurance
    </p>
</div>
<div *ngIf="mailingAddress == null" class="bolt-row padding-top-lg bolt-body-copy-sm">
    <div class="bolt-col">
        Mailing address information is not available at this time.
    </div>
</div>
<br class="noprint" />
<hr />
<div>
    <h2 class="bolt-body-copy-sm">
        To verify policy information by phone, please call {{ isSA360 ?
        sa360Contact : nonSA360Contact }}.
    </h2>
</div>