<h1 class="bolt-heading-md margin-bottom-auto">
    Agent Details
</h1>
<div *ngIf="!dpimContactInformation || dpimContactInformationIsError() || !agent" class="bolt-row padding-top-lg bolt-body-copy-sm">
    <div class="bolt-col-3">
        <img src="../../../assets/image/person-circle-64.svg" class="userimg" alt="user image" />
    </div>
    <div class="bolt-col">Agent information is not available.</div>
</div>
<div class="bolt-row margin-top-x1 mobile-padding-top-lg mobile-padding-bottom-lg" *ngIf="dpimContactInformation && !dpimContactInformationIsError() && agent">
    <div class="bolt-col-3">
        <img src="../../../assets/image/person-circle-64.svg" class="userimg" alt="user image" />
    </div>
    <div class="bolt-col-9" *ngIf="!isSA360">
        <div class="bolt-row bolt-body-copy-sm line-space">
            <span *ngIf="agent.firstName">{{agent.firstName}}&nbsp;</span>
            <span *ngIf="agent.middleName">{{agent.middleName}}&nbsp;</span>
            <span *ngIf="agent.lastName">{{agent.lastName}}</span>
        </div>
        <div *ngIf="agent.organization" class="bolt-row bolt-body-copy-sm line-space">
            {{agent.organization}}
        </div>
        <div *ngIf="dpimContactInformation.contactPoints">
            <div *ngIf="dpimContactInformation.contactPoints.length>0">
                <div class="bolt-row bolt-body-copy-sm line-space">
                    {{ agent.addressLineOne }}
                </div>

                <div class="bolt-row bolt-body-copy-sm line-space">
                    {{ agent.city }},
                    {{ agent.state }}
                    {{ agent.zip }}
                </div>
                <div *ngIf="agent.phones" class="bolt-body-copy-sm line-space">
                    <div *ngFor="let phone of agent.phones" class="bolt-row">
                        {{ phone }}
                    </div>
                </div>
                <div *ngIf="agent.email" class="bolt-row bolt-body-copy-sm line-space wrap-word">
                    <a href="mailto:{{ agent.email }}">{{ agent.email }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="bolt-col-9" *ngIf="isSA360">
        <div class="bolt-row bolt-body-copy-sm line-space">
            <span *ngIf="agent.firstName">{{agent.firstName}}&nbsp;</span>
            <span *ngIf="agent.middleName">{{agent.middleName}}&nbsp;</span>
            <span *ngIf="agent.lastName">{{agent.lastName}}</span>
        </div>
        <div *ngIf="agent.organization" class="bolt-row bolt-body-copy-sm line-space">
            {{agent.organization}}
        </div>
        <div class="bolt-row bolt-body-copy-sm line-space">
            <p>The agent listed is using our Service Advantage 360 program. Any
                policy updates or questions can be directed to the SA360 specialists at
                <strong>{{sa360Contact}}</strong>.
            </p>
        </div>
    </div>
</div>