import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from '../tpi-services/logger/logger.service';

@Injectable({
    providedIn: 'root'
})
export class LogOnLoadAndRefreshGuard {
    constructor(private router: Router, private loggerService: LoggerService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.isPageRefreshed()) {
            this.loggerService.info(`/${route.url}: Page Refreshed `, {
                data: route.data,
                url: route.url,
                queryParam: route.queryParams,
                title: route.title
            });
        } else {
            this.loggerService.info(`/${route.url}: Page Loaded `, {
                data: route.data,
                url: route.url,
                queryParam: route.queryParams,
                title: route.title
            });
        }
        this.loggerService.logCurrentContents();
        return true;
    }

    private isPageRefreshed(): boolean {
        return !this.router.navigated;
    }
}
