<h2 class="bolt-heading-sm bolt-space-bottom-lg">Vehicle Details</h2>
<div class="bolt-row" *ngIf="vehicles != null">
    <div class="bolt-col-3">
        <img src="../../../assets/image/car-circle-64.svg" class="vehicleimg" alt="car-icon image" />
    </div>
    <div class="bolt-col-9 print-padding-left-20">
        <div class="bolt-row bolt-body-copy-sm">
            {{vehicles[0].year}} {{vehicles[0].make}} {{vehicles[0].model}}
        </div>
        <div class="bolt-row margin-top-n-md">
            <h3 class="element-text bolt-body-copy-sm bolt-heading-sm">vin:</h3>
        </div>
        <div class="bolt-row bolt-body-copy-sm">
            {{vehicles[0].vin}}
        </div>
        <div class="bolt-row">
            <h3 class="element-text bolt-heading-sm bolt-body-copy-sm">insured on this policy since:</h3>
        </div>
        <div class="bolt-row">
            <span>{{vehicles[0].vehicleOnPolicySince| date: 'MM/dd/yyyy' || 'Not
                available'}}</span>
        </div>
    </div>
</div>
<div *ngIf="vehicles == null" class="bolt-row padding-top-lg bolt-body-copy-sm">
    <div class="bolt-col">
        Vehicle information is not available at this time.
    </div>
</div>