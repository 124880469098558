import { Component, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { commonConstants, SESSION_KEYS, TIME_LIMITS } from '../../../../CONSTANTS/tpi-common-constants';
import { CONST_SIZES, tpiErrorTags, VALIDATION_REGEX } from '../../../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../../../CONSTANTS/tpi-search-errors';
import { FormElementCoreComponent } from '../../../../shared/components/form-element-core/form-element-core.component';
import { WaitIndicatorComponent } from '../../../../shared/components/wait-indicator/wait-indicator.component';
import { ValidatorService } from '../../../../shared/services/validator.service';
import { StoreUserFields } from '../../../../tpi-models/store/store-user-fields.model';
import { ForgotPasswordVerifyMFARequest } from '../../../../tpi-models/user-credentials/forgot-password-verify-mfa-request';
import { ForgotPasswordVerifyMFAResponse } from '../../../../tpi-models/user-credentials/forgot-password-verify-mfa-response';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';
import { SessionService } from '../../../../tpi-services/tpi-session/session.service';
import { UserFieldsStateService } from '../../../../tpi-services/tpi-statemanagement/user-fields-state.service';
import { PasswordService } from '../../../../tpi-services/user-credential-services/password.service';
import { UtilsService } from '../../../../tpi-services/utility/utils-service.service';
@Component({
    selector: 'app-mfa-challenge',
    templateUrl: './mfa-challenge.component.html',
    styleUrls: ['./mfa-challenge.component.scss']
})
export class MFAChallengeComponent extends FormElementCoreComponent {
    @ViewChild(WaitIndicatorComponent) waitIndicator;

    errorMessage: string;
    hasError: boolean;
    mfaForm: FormGroup;
    mfaTimeLimit = TIME_LIMITS.mfaTimeLimit;
    showRequestNewCodeModal: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };
    userFields: StoreUserFields;

    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        private loggerService: LoggerService,
        private route: Router,
        public formHelper: RxFormHelperService,
        public utilsService: UtilsService,
        private validatorService: ValidatorService,
        private passwordService: PasswordService,
        private storeUserFieldsService: UserFieldsStateService
    ) {
        super();
    }

    buildForm(): void {
        this.mfaForm = this.formHelper.build({
            verificationCode: [
                '',
                Validators.compose([
                    Validators.required,
                    this.validatorService.validateMFAWithSpecifiedDigit(CONST_SIZES.MFA_LENGTH),
                    this.validatorService.validateMFAFormat(
                        VALIDATION_REGEX.isNumeric
                    )
                ])
            ]
        });
        this.resetFormValues();
    }

    closeModal($event): void {
        if ($event) {
            this.showRequestNewCodeModal = false;
        }
    }

    ngOnInit(): void {
        this.sessionService.deleteAllCookies();
        this.storeUserFieldsService.getUserFields().subscribe((searchFields: StoreUserFields) => {
            this.userFields = searchFields;
        });
        this.setVariables();
        this.buildForm();
    }

    openModal($event): void {
        if ($event) {
            this.showRequestNewCodeModal = true;
        }
    }

    performValidation($event?): void {
        if ($event) {
            this.submitOptions = {
                submitStatus: true,
                onSubmit: true
            };
            this.hasError = false;
            this.showResults();
        }
    }

    redirectToEmailConfirmationPage($event): void {
        if ($event) {
            this.waitIndicator.hideWaitIndicator();
            this.utilsService.scrollToTopOfPage();
            this.route.navigate(['forgot-password-contact']).then(() => {
                this.loggerService.info('redirectToEmailConfirmationPage(): requested a new code on forgot password mfa challenge page; redirecting to /forgot-password-contact');
                this.loggerService.logCurrentContents();
            }).catch((error) => {
                this.loggerService.error('redirectToEmailConfirmationPage(): requested a new code on forgot password mfa challenge page; failed to redirect to /forgot-password-contact',
                    {
                        redirectError: error
                    });
                this.loggerService.logCurrentContents();
            });
        }
    }

    redirectToFailurePage(): void {
        this.waitIndicator.hideWaitIndicator();
        this.utilsService.scrollToTopOfPage();
        this.route.navigate(['forgot-password', 'error']).then(() => {
            this.loggerService.info('redirectToFailurePage(): failed to generate temporary password; redirecting to /forgot-password/error');
            this.loggerService.logCurrentContents();
        }).catch((error) => {
            this.loggerService.error('redirectToFailurePage(): failed to generate temporary password; failed to redirect[/forgot-password/error] on unsuccessful mfa verification',
                {
                    redirectError: error
                });
            this.loggerService.logCurrentContents();
        });
    }

    redirectToSuccessPage(): void {
        this.waitIndicator.hideWaitIndicator();
        this.utilsService.scrollToTopOfPage();
        this.route.navigate(['forgot-password', 'success']).then(() => {
            this.loggerService.info('redirectToSuccessPage(): generated temporary password successfully; redirecting to /forgot-password/success');
            this.loggerService.logCurrentContents();
        }).catch((error) => {
            this.loggerService.error('redirectToSuccessPage(): generated temporary password successfully; failed to redirect[/forgot-password/success] on successful mfa verification',
                {
                    redirectError: error
                });
            this.loggerService.logCurrentContents();
        });
    }

    resetForm($event?): void {
        if ($event) {
            this.mfaForm.updateValueAndValidity();
            this.resetFormValues();
            this.submitOptions.submitStatus = false;
            this.hasError = false;
        }
    }

    resetFormControllerError(): void {
        this.mfaForm.updateValueAndValidity();
        this.mfaForm.get('verificationCode').updateValueAndValidity();
    }

    resetFormValues(): void {
        this.mfaForm.get('verificationCode').setValue('');
        this.hasError = false;
    }

    setVariables(): void {
        this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
    }

    showResults(): void {
        this.resetFormControllerError();

        if (this.mfaForm.valid) {
            this.verifyMFAChallenge(this.mfaForm.get('verificationCode').value);
        }
        this.utilsService.scrollToTopOfPage();
    }

    showResultsError(errorMessage: string): void {
        this.loggerService.info(`showResultsError(): message: ${errorMessage}`);
        this.hasError = true;
        this.errorMessage = errorMessage;
        this.loggerService.logCurrentContents();
    }

    verifyMFAChallenge(verificationCode: string): void {
        this.waitIndicator.openWaitIndicator();

        const mfaRequest: ForgotPasswordVerifyMFARequest = {
            user: this.userFields.username,
            email: this.userFields.emailAddress,
            device: {
                channel: commonConstants.WEB,
                deviceToken: commonConstants.PLACEHOLDER,
                transactionId: sessionStorage.getItem(SESSION_KEYS.transactionId),
                sessionId: sessionStorage.getItem(SESSION_KEYS.sessionId),
                confirmationCode: verificationCode
            }
        };

        this.passwordService.doVerifyMFAChallenge(mfaRequest).subscribe(
            {
                next: (forgotPasswordVerifyMFAResponse: ForgotPasswordVerifyMFAResponse) => {
                    if (forgotPasswordVerifyMFAResponse.result.toLowerCase() === commonConstants.RESPONSE_STATUS.SUCCESS.toLowerCase()) {
                        this.loggerService.info('verifyMFAChallenge: successful verify mfa challenge for forgot password', forgotPasswordVerifyMFAResponse);
                        this.sessionService.changedPassword = true;
                        this.redirectToSuccessPage();
                    } else if (forgotPasswordVerifyMFAResponse.exception === tpiErrorTags.EXPIRED_VERIFICATION_CODE) {
                        this.waitIndicator.hideWaitIndicator();
                        this.loggerService.error('verifyMFAChallenge: failed verify mfa challenge for forgot password due to expired verification code', forgotPasswordVerifyMFAResponse);
                        this.showResultsError(tpiSearchError.EXPIRED_MFA_ERROR);
                    } else {
                        this.waitIndicator.hideWaitIndicator();
                        this.loggerService.error('verifyMFAChallenge: failed verify mfa challenge for forgot password', forgotPasswordVerifyMFAResponse);
                        this.showResultsError(tpiSearchError.INVALID_MFA_ERROR);
                    }
                },

                error: (errorResponse) => {
                    this.waitIndicator.hideWaitIndicator();
                    this.loggerService.error('verifyMFAChallenge: error verify mfa challenge for forgot password', errorResponse);
                    this.sessionService.changedPassword = true;
                    this.redirectToFailurePage();
                }
            }
        );
    }
}
