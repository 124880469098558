import { Component, Input } from '@angular/core';
import { PolicyInformationDetails } from '@nationwide/dgs-internet-servicing-policy-requests';
import { Address } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/address';
import { Person } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/person';
import { PolicyHolders } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/policyInformationDetails';

@Component({
    selector: 'app-client-info',
    templateUrl: './client-info.component.html',
    styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent {
    @Input() isActiveTab: string;
    @Input() person: Person;
    @Input() policyInformationDetail: PolicyInformationDetails;
    address = <Address>{};
    policyHolders = <PolicyHolders>{};
}
