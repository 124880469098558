<h1 class="bolt-heading-md margin-bottom-auto">Billing Details</h1>
<div *ngIf="billingInformation">
    <div class="bolt-row text-padding-top">
        <div class="bolt-col-6">
            <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">bill to:</h2>
            <span class="bolt-body-copy-sm">Not Available</span>
        </div>
        <div class="bolt-col-6" *ngIf="billingInformation?.billDueDate">
            <h2 class="bolt-heading-sm bolt-body-copy-sm element-text">bill due date:</h2>
            <span class="bolt-body-copy-sm">{{billingInformation?.currentAmountDue === '0.00'?'Not
                Available':billingInformation.billDueDate}}</span>
        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-6" *ngIf="billingInformation?.currentAmountDue">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">current amount due:</h2>
            <span class="bolt-body-copy-sm">${{billingInformation.currentAmountDue}}</span>
        </div>
        <div class="bolt-col-6" *ngIf="billingInformation?.unPaidBalance">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">unpaid balance:</h2>
            <span class="bolt-body-copy-sm">${{billingInformation.unPaidBalance}}</span>

        </div>
    </div>
    <div class="bolt-row">
        <div class="bolt-col-6">
            <h2 class="element-text bolt-heading-sm bolt-body-copy-sm">Last Day For Payment:</h2>
            <span class="bolt-body-copy-sm" *ngIf="billingInformation?.lastDayPayment">{{billingInformation?.lastDayPayment}}</span>
            <span class="bolt-body-copy-sm" *ngIf="!billingInformation?.lastDayPayment">Not Available</span>
        </div>
        <div class="bolt-col-6">
            <h2 class="bolt-heading-sm element-text bolt-body-copy-sm">Last Payment Received</h2>
            <p class="bolt-body-copy-sm" *ngIf="billingInformation?.lastDayPaymentReceivedAmount && billingInformation?.lastDayPaymentReceivedDate">
                ${{billingInformation.lastDayPaymentReceivedAmount}} on
                <br />
                {{billingInformation?.lastDayPaymentReceivedDate}}
            </p>
            <p class="bolt-body-copy-sm" *ngIf="!billingInformation?.lastDayPaymentReceivedAmount || !billingInformation?.lastDayPaymentReceivedDate">
                Not Available
            </p>
        </div>
    </div>
</div>
<div *ngIf="!billingInformation">
    Billing information is not available at this time.
</div>