import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { EXPIRED_PASSWORD_RETURN_CODE } from '../../../CONSTANTS/change-password-return-codes';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { WaitIndicatorComponent } from '../../../shared/components/wait-indicator/wait-indicator.component';
import { ChangePasswordRequest } from '../../../tpi-models/user-credentials/change-password-request';
import { UpdateExpiredPasswordResponse } from '../../../tpi-models/user-credentials/update-expired-password-response';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { PasswordService } from '../../../tpi-services/user-credential-services/password.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
import { PasswordFormComponent } from '../shared/password-form/password-form.component';

@Component({
    selector: 'app-expired-password',
    templateUrl: './expired-password.component.html',
    styleUrls: ['./expired-password.component.scss']
})
export class ExpiredPasswordComponent implements AfterViewInit, OnInit {
    @ViewChild(PasswordFormComponent) passwordFormComponent: PasswordFormComponent;
    @ViewChild(WaitIndicatorComponent) waitIndicator;

    errorMessage: string;
    expiredPasswordForm: FormGroup;
    hasDMXError = false;
    hasError: boolean;
    showErrorModal: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };

    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        private loggerService: LoggerService,
        private route: Router,
        private passwordService: PasswordService,
        public formHelper: RxFormHelperService,
        public utilsService: UtilsService
    ) { }

    buildForm(): void {
        this.expiredPasswordForm = this.formHelper.build({
            username: [
                '',
                Validators.compose([
                    Validators.required
                ])
            ],
            currentPassword: [
                ''
            ],
            newPassword: [
                ''
            ],
            confirmNewPassword: [
                ''
            ]
        });

        this.resetFormValues();
    }

    closeErrorModal(): void {
        this.showErrorModal = false;
    }

    mapErrorResponse(expirePasswordErrorResponse: UpdateExpiredPasswordResponse): void {
        switch (expirePasswordErrorResponse.exception) {
            case EXPIRED_PASSWORD_RETURN_CODE.REUSE_PASSWORD:
                this.setPreviousUsedPasswordFormControlFieldError();
                this.showResultsError(tpiSearchError.PASSWORD_MATCH_PREVIOUS_ERROR);
                break;
            case EXPIRED_PASSWORD_RETURN_CODE.PASSWORD_TOO_NEW:
                this.showResultsError(tpiSearchError.PASSWORD_TOO_NEW_ERROR);
                break;
            case EXPIRED_PASSWORD_RETURN_CODE.PASSWORD_NOT_COMPLIANT:
                this.showResultsError(tpiSearchError.PASSWORD_WEAK_ERROR);
                break;
            default:
                this.showResultsError(tpiSearchError.WRONG_PASSWORD_ERROR);
                break;
        }
    }

    ngAfterViewInit(): void {
        this.setFormValidation();
    }

    ngOnInit(): void {
        this.sessionService.isLoggedIn = false;
        this.sessionService.deleteAllCookies();
        this.setVariables();
        this.buildForm();
    }

    performValidation($event?): void {
        if ($event) {
            this.submitOptions = {
                submitStatus: true,
                onSubmit: true
            };
            this.hasError = false;
            this.showResults();
        }
    }

    redirectToLegacy($event): void {
        if ($event) {
            this.sessionService.redirectToLegacy();
        }
    }

    redirectToSuccessPage(): void {
        this.waitIndicator.hideWaitIndicator();
        this.utilsService.scrollToTopOfPage();
        this.route.navigate(['update-expired-password', 'success']).then(() => {
            this.loggerService.info('redirectToSuccessPage(): updated expired password successfully; redirecting to /update-expired-password/success');
            this.loggerService.logCurrentContents();
        }).catch((error) => {
            this.loggerService.error('redirectToSuccessPage(): updated expired password successfully; failed to redirect[/update-expired-password/success] on successful password update',
                {
                    redirectError: error
                });
            this.loggerService.logCurrentContents();
        });
    }

    resetForm($event?): void {
        if ($event) {
            this.expiredPasswordForm.updateValueAndValidity();
            this.resetFormValues();
            this.submitOptions.submitStatus = false;
            this.hasError = false;
            this.passwordFormComponent.resetValidation();
        }
    }

    resetFormControllerError(): void {
        this.expiredPasswordForm.updateValueAndValidity();
        this.expiredPasswordForm.get('currentPassword').updateValueAndValidity();
        this.expiredPasswordForm.get('newPassword').updateValueAndValidity();
        this.expiredPasswordForm.get('confirmNewPassword').updateValueAndValidity();
        this.expiredPasswordForm.get('username').updateValueAndValidity();
    }

    resetFormValues(): void {
        this.expiredPasswordForm.get('username').setValue('');
        this.expiredPasswordForm.get('currentPassword').setValue('');
        this.expiredPasswordForm.get('newPassword').setValue('');
        this.expiredPasswordForm.get('confirmNewPassword').setValue('');
        this.hasError = false;
    }

    setFormValidation(): void {
        this.passwordFormComponent.setPasswordValidation(this.expiredPasswordForm.get('username').value);

        this.expiredPasswordForm.get('username').valueChanges.subscribe(() => {
            if (this?.passwordFormComponent) {
                this.passwordFormComponent.setNewPasswordValidation(this.expiredPasswordForm.get('username').value);
                this.passwordFormComponent.setConfirmNewPasswordValidation(this.expiredPasswordForm.get('username').value);
            }
        });
    }

    setPreviousUsedPasswordFormControlFieldError(): void {
        const newPasswordFormController = this.expiredPasswordForm.get('newPassword');
        const confirmNewPasswordFormController = this.expiredPasswordForm.get('confirmNewPassword');
        newPasswordFormController.setErrors({ passwordPreviousUsedPasswordFieldError: true });
        confirmNewPasswordFormController.setErrors({ passwordPreviousUsedPasswordFieldError: true });
    }

    setVariables(): void {
        this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
    }

    showResults(): void {
        this.resetFormControllerError();

        if (!this.passwordFormComponent.newPasswordsMatch()) {
            this.loggerService.error('showResults: new Passwords Match error');
            this.showResultsError(tpiSearchError.NEW_PASSWORD_NOT_MATCH_ERROR);
            this.utilsService.scrollToTopOfPage();
        } else if (this.expiredPasswordForm.valid) {
            this.waitIndicator.openWaitIndicator();
            this.loggerService.info('showResults: valid expire password form, updating password ...');
            const username = this.expiredPasswordForm.get('username').value;
            const currentPassword = this.expiredPasswordForm.get('currentPassword').value;
            const newPassword = this.expiredPasswordForm.get('newPassword').value;
            this.updateExpiredPassword(username, currentPassword, newPassword);
        }
    }

    showResultsError(errorMessage: string): void {
        this.loggerService.info(`showResultsError(): message: ${errorMessage}`);
        this.hasError = true;
        this.errorMessage = errorMessage;
        this.loggerService.logCurrentContents();
    }

    updateExpiredPassword(username: string, currentPassword: string, newPassword): void {
        const expirePasswordRequest: ChangePasswordRequest = {
            username,
            currentPassword,
            newPassword,
            firstName: '',
            email: ''
        };
        this.passwordService.doUpdateExpiredPassword(expirePasswordRequest).subscribe(
            {
                next: (updateExpiredPasswordResponse: UpdateExpiredPasswordResponse) => {
                    this.loggerService.info('updateExpiredPassword: successful expired password updated', updateExpiredPasswordResponse);
                    this.sessionService.changedPassword = true;
                    this.redirectToSuccessPage();
                },

                error: (errorResponse) => {
                    this.waitIndicator.hideWaitIndicator();
                    this.loggerService.error('updateExpiredPassword: failed expired password updated', errorResponse);
                    const dmxRelatedErrors = [EXPIRED_PASSWORD_RETURN_CODE.EMAIL_RESOLUTION_FAILURE, EXPIRED_PASSWORD_RETURN_CODE.DMX_DB_FAILURE];
                    if (errorResponse?.error?.user && dmxRelatedErrors.includes(errorResponse?.error?.exception)) {
                        this.hasDMXError = true;
                    } else if (errorResponse?.error?.user || errorResponse?.error?.validationError) {
                        this.mapErrorResponse(errorResponse.error);
                    } else {
                        this.showResultsError(tpiSearchError.UNABLE_CHANGE_PASSWORD_ERROR);
                    }
                    this.utilsService.scrollToTopOfPage();
                },

                complete: () => {
                    this.loggerService.logCurrentContents();
                }
            }
        );
    }
}
