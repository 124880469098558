export const ECIF = {
    PRODUCER_VALUES: {
        RESIDENT_PRODUCER: 'Resident Producer',
        AMF: 'AMF',
        PDS: 'PDS',
        INDEPENDENT_CHANNEL_AGENT: 'Independent Channel Agent',
        EXCLUSIVE_CHANNEL_AGENT: 'Exclusive Channel Agent',
        IA: 'IA',
        EA: 'EA',
        PRODUCER: 'Producer'
    }
};
