import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[tpiAlphanumericSpacesDirective]'
})
export class AlphanumericSpacesDirective {
    constructor(private element: ElementRef, private ngControl: NgControl) { }

    @HostListener('input', ['$event']) onInputChange(event): void {
        const initalValue = this.element.nativeElement.value;
        const initalValueReplace = initalValue.replace(/[^a-zA-Z0-9\s]*/g, '');
        this.element.nativeElement.value = initalValueReplace;
        this.ngControl.control.setValue(initalValueReplace);
        if (initalValue !== this.element.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
