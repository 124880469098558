<h1 class="bolt-heading-md margin-bottom-lg">
    Coverage Details & Limits
</h1>
<div class="bolt-row" *ngIf="coverages !== null || coverages.length > 0">
    <div class="section-one-third section-full bolt-col-sm-4 bolt-col-6" *ngFor="let coverage of coverages ;let j=index" [ngClass]="((j+1)%3 != 0 )? 'divider' :''">
        <h4 class="element-text bolt-body-copy-sm bolt-heading-sm">{{coverage.name}}:</h4>
        <div *ngIf="coverage?.selectedValue?.length>0">
            <div class="bolt-body-copy-sm" *ngFor="let selectedValue of coverage.selectedValue">
                {{selectedValue?.description?.split('|')[0]|| 'Included'}}
            </div>
        </div>
    </div>
</div>
<div class="bolt-row padding-top-lg bolt-body-copy-sm" *ngIf="coverages === null || coverages.length === 0">
    <div class="bolt-col">
        Coverage information is not available at this time.
    </div>
</div>