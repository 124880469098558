import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/core-component-library-base';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GisResponse } from '../../tpi-models/general/gisResponse';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root'
})
export class GisService {
    // eslint-disable-next-line max-params
    constructor(
        private http: HttpClient,
        private apiCommonService: APICommonService,
        private loggerService: LoggerService,
        @Inject('policyRequestsConfig') private environment
    ) { }

    getPlace(fullAddress: string): Observable<GisResponse> {
        this.loggerService.info('Fetch address start - getPlace()', {
            fullAddress
        });
        fullAddress = fullAddress.split(' ').join('%20');
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.apiCommonService.buildHeader({
            appClientId: this.environment.authorize.client_id,
            authToken: euaToken,
            nwAppId: '',
            contentType: 'application/json',
            headerType: 'X-NW-Message-ID'
        });
        const url = `${this.environment.gisEndPoint}?apikey=${this.environment.authorize.client_id}&addressLimit=1&street=${fullAddress}`;
        return this.http.get<GisResponse>(url, { headers }).pipe(
            tap((gisResponse: GisResponse) => {
                this.loggerService.info('Fetch address End - getPlace()', {
                    gisResponse
                });
            })
        );
    }
}
