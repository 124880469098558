import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { environment } from '../../../environments/environment';
import { commonConstants, KEYCODES } from '../../CONSTANTS/tpi-common-constants';
import { CONST_SIZES, customErrorFlags, VALIDATION_REGEX } from '../../CONSTANTS/tpi-error-tags';
import { ValidatorService } from '../../shared/services/validator.service';
import { EditMortgageServiceRequest } from '../../tpi-models/general/edit-mortgage-services-request';
import { SearchFields } from '../../tpi-models/general/search-forms';
import { TpiSessionConfig } from '../../tpi-models/general/tpi-session-config';
import { UserSession } from '../../tpi-models/general/tpisession';
import { LoggerService } from '../../tpi-services/logger/logger.service';
import { PolicySearchStateServiceService } from '../../tpi-services/tpi-statemanagement/policy-search-state-service.service';
import { AutoNoSearchComponent } from './auto-no-search/auto-no-search.component';
import { AutoSearchComponent } from './auto-search/auto-search.component';
import { ButtonBarComponent } from './button-bar/button-bar.component';
import { SearchResultsComponent } from './shared-components/search-results/search-results.component';
interface Window {
    policyRedirectWait: {
        show: (timeOut: number) => void;
    };
}
@Component({
    selector: 'tpi-policy-inquiry',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './policy-inquiry.component.html'
})
export class PolicyInquiryComponent implements OnInit {
    @ViewChild(AutoNoSearchComponent) autoNoSearch: AutoNoSearchComponent;
    @ViewChild(AutoSearchComponent) autoYesSearch: AutoSearchComponent;
    @ViewChild('buttonBar') buttonBar;
    @ViewChild(ButtonBarComponent) buttonBarComponent: ButtonBarComponent;
    @ViewChild('policyInquiryForm', { static: false }) policyInquiryForm: ElementRef;
    @ViewChild(SearchResultsComponent) resultComponent: SearchResultsComponent;
    @Output() showAutoResult: boolean;
    @Output() showPropertyResult: boolean;
    activeTab = 0;
    authorizationAuto: number;
    authorizationProperty: number;
    autoSearchFields: SearchFields;
    disablePrint: boolean;
    editMortgageServiceRequest: EditMortgageServiceRequest = {};
    errorMessage: string;
    hasError: boolean;
    propertySearchForm: UntypedFormGroup;
    searchFields: SearchFields = {
        policyNumber: '',
        vin: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        fullVin: '',
        hasError: false,
        errorMessage: '',
        invalidForm: true,
        isYesSearch: true,
        isNoSearch: false,
        action: {
            displayResults: false,
            vin: '',
            policyNumber: ''
        },
        isPropertySearch: false
    };
    searchForm: UntypedFormGroup;
    selectedTab = 'auto';
    showAutoYesSearch: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };
    tpiSessionConfig: TpiSessionConfig = environment.tpiToggles;

    // eslint-disable-next-line max-params
    constructor(
        public formHelper: RxFormHelperService,
        private validatorService: ValidatorService,
        private reference: ChangeDetectorRef,
        private titleService: Title,
        private loggerService: LoggerService,
        @Inject('window') private window: Window,
        @Inject(PLATFORM_ID) private platformId: Object,
        private storeSearchFields: PolicySearchStateServiceService
    ) { }

    buildForm(): void {
        this.searchForm = this.formHelper.build({
            havingPolicyNumber: {
                where: (form: UntypedFormGroup): any => this.checkIsAuto(),
                control: ['1', Validators.compose([Validators.required])]
            },
            policyNumber: {
                where: (form: UntypedFormGroup): any => this.checkIsAutoYes(form) || this.checkIsProperty(),
                control: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(CONST_SIZES.POLICYNUMBER_MAX_LENGTH),
                        this.validatorService.validatePolicy(VALIDATION_REGEX.isPolicyNumber)
                    ])
                ]
            },
            sixDigitVinNumber: {
                where: (form: UntypedFormGroup): any => this.checkIsAutoYes(form),
                control: [
                    '',
                    Validators.compose([
                        Validators.required,
                        this.validatorService.validateVinWithSpecifiedDigit(CONST_SIZES.VIN_SHORT_MIN_LENGTH),
                        Validators.pattern(VALIDATION_REGEX.isAlphaNumeric)
                    ])
                ]
            },
            streetAddress: {
                where: (form: UntypedFormGroup): any => this.checkIsAutoNo(form),
                control: ['', Validators.compose([Validators.required])]
            },
            city: {
                where: (form: UntypedFormGroup): any => this.checkIsAutoNo(form),
                control: ['', Validators.compose([Validators.required])]
            },
            state: {
                where: (form: UntypedFormGroup): any => this.checkIsAutoNo(form),
                control: ['', Validators.compose([Validators.required])]
            },
            zipCode: {
                where: (form: UntypedFormGroup): any => this.checkIsAutoNo(form) || this.checkIsProperty(),
                control: ['', Validators.compose([Validators.required, Validators.pattern(VALIDATION_REGEX.isZipCode)])]
            },
            fullVin: {
                where: (form: UntypedFormGroup): any => this.checkIsAutoNo(form),
                control: [
                    '',
                    Validators.compose([
                        Validators.required,
                        this.validatorService.validateFullVin(VALIDATION_REGEX.isAlphaNumeric)
                    ])
                ]
            }
        });

        this.searchForm.get('havingPolicyNumber').valueChanges.subscribe(() => {
            this.submitOptions.submitStatus = false;
            this.resetAutoValues();
            this.showAutoResult = false;
        });
        this.searchForm.markAsTouched();
    }

    buildPropertyForm(): void {
        this.propertySearchForm = this.formHelper.build({
            policyNumber: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(CONST_SIZES.POLICYNUMBER_MAX_LENGTH),
                    this.validatorService.validatePolicy(VALIDATION_REGEX.isPolicyNumber)
                ])
            ],
            zipCode: [
                '',
                Validators.compose([
                    Validators.required,
                    this.validatorService.validateWithCustomError(
                        VALIDATION_REGEX.isZipCode,
                        customErrorFlags.ZIP_CODE_PROPERTY_ERROR
                    )
                ])
            ]
        });
        this.propertySearchForm.markAsTouched();
    }

    checkIsAuto(): boolean {
        return this.selectedTab === 'auto';
    }

    checkIsAutoNo(form: UntypedFormGroup): boolean {
        return this.selectedTab === commonConstants.AUTO_FLOW && form?.get('havingPolicyNumber')?.value === '2';
    }

    checkIsAutoYes(form: UntypedFormGroup): boolean {
        return this.selectedTab === commonConstants.AUTO_FLOW && form?.get('havingPolicyNumber')?.value === '1';
    }

    checkIsProperty(): boolean {
        return this.selectedTab === commonConstants.PROPERTY_FLOW;
    }

    displayResult(event: boolean): void {
        this.showAutoResult = event;
        if (this.showAutoResult) {
            this.buttonBar.hideWaitIndicator();
        }
    }

    displayResultProperty(event: boolean): void {
        this.showPropertyResult = event;
    }

    editLienHolderFailure(eventLienFeedback): void {
        this.hasError = eventLienFeedback.hasError;
        this.errorMessage = eventLienFeedback.errorMessage;
        this.focusError();
    }

    focusError(): void {
        if (isPlatformBrowser(this.platformId) && this.hasError) {
            this.policyInquiryForm.nativeElement.scrollIntoView();
        }
    }

    focusedTab(): void {
        if (this.isPropertyAndAuto()) {
            this.selectedTab = this.activeTab === 0 ? commonConstants.PROPERTY_FLOW : commonConstants.AUTO_FLOW;
        } else if (this.isPropertyTabOnly()) {
            this.selectedTab = commonConstants.PROPERTY_FLOW;
        } else {
            this.selectedTab = commonConstants.AUTO_FLOW;
        }
    }

    getSearchForm(): UntypedFormGroup {
        if (this.isPropertyAndAuto()) {
            if (this.activeTab === 0) {
                this.titleService.setTitle('Property Policy Search - Nationwide');
                return this.propertySearchForm;
            } else {
                this.titleService.setTitle('Auto Policy Search - Nationwide');
                return this.searchForm;
            }
        } else if (this.isPropertyTabOnly()) {
            this.titleService.setTitle('Property Policy Search - Nationwide');
            return this.propertySearchForm;
        } else {
            this.titleService.setTitle('Auto Policy Search - Nationwide');
            return this.searchForm;
        }
    }

    isAutoTabOnly(): boolean {
        return this.authorizationAuto === 1 && this.authorizationProperty !== 1;
    }

    isPropertyAndAuto(): boolean {
        return this.authorizationAuto === 1 && this.authorizationProperty === 1;
    }

    isPropertyTabOnly(): boolean {
        return this.authorizationAuto !== 1 && this.authorizationProperty === 1;
    }

    loadVariables(): void {
        this.submitOptions = {
            onSubmit: true,
            submitStatus: false
        };
    }

    navigateToLegacyTpi(tab: string): void {
        this.resultComponent.resetResultData();
        this.submitOptions.submitStatus = false;
        this.disablePrint = true;
        this.selectedTab = tab;
        this.resetForm(true);
        if (tab === commonConstants.PROPERTY_FLOW) {
            this.showPropertyResult = false;
            this.activeTab = 0;
            this.buildPropertyForm();
        } else {
            this.showAutoResult = false;
            this.activeTab = 1;
            this.buildForm();
        }
        this.focusedTab();
        this.storeSearchFields.updateSearchFields(this.autoSearchFields);
        if (tab === commonConstants.AUTO_FLOW && this.tpiSessionConfig.tpiAutoToggle) {
            window.open(`${this.tpiSessionConfig.tpiLegacyDomain}policyinquiry/`, commonConstants.WINDOWS.SELF);
            return;
        }
        if (tab === commonConstants.PROPERTY_FLOW && this.tpiSessionConfig.tpiPropertyToggle) {
            this.window.policyRedirectWait.show(commonConstants.DEFAULT_WAIT_INDICATOR_DURATION);
            window.open(`${this.tpiSessionConfig.tpiLegacyDomain}policyinquiry/`, commonConstants.WINDOWS.SELF);
        }
    }

    ngOnInit(): void {
        const session: UserSession = JSON.parse(sessionStorage.getItem('session'));
        this.authorizationAuto = session?.context.session.account.company.autoAuthorization;
        this.authorizationProperty = session?.context.session.account.company.propertyAuthorization;
        this.searchFieldsUpdate();
        this.setTabInformation();
        this.focusedTab();
        this.setVariables();
        this.showAutoResult = false;
        this.showPropertyResult = false;
        this.buildPropertyForm();
        this.buildForm();
    }

    performValidation(event?): void {
        this.submitOptions = {
            submitStatus: true,
            onSubmit: true
        };
    }

    resetAutoValues(): void {
        this.searchForm.get('policyNumber').setValue('');
        this.searchForm.get('sixDigitVinNumber').setValue('');
        this.searchForm.get('streetAddress').setValue('');
        this.searchForm.get('city').setValue('');
        this.searchForm.get('state').setValue('');
        this.searchForm.get('zipCode').setValue('');
        this.searchForm.get('fullVin').setValue('');
        this.hasError = false;
    }

    resetForm($event?): void {
        if ($event) {
            this.showPropertyResult = false;
            if (this.checkIsAuto()) {
                this.resultComponent.resetResultData();
                this.showAutoResult = false;
                this.searchForm.updateValueAndValidity();
                this.resetAutoValues();
            } else {
                this.showPropertyResult = false;
                this.resultComponent.resetResultPropertyData();
                this.propertySearchForm.updateValueAndValidity();
                this.resetPropertyValues();
            }
            this.submitOptions.submitStatus = false;
            this.hasError = false;
        }
    }

    resetPropertyValues(): void {
        this.propertySearchForm.get('policyNumber').setValue('');
        this.propertySearchForm.get('zipCode').setValue('');
        this.hasError = false;
    }

    searchFieldsUpdate(): void {
        this.storeSearchFields.updateSearchFields(this.searchFields);
        this.storeSearchFields.getSearchFields().subscribe((searchFields: SearchFields) => {
            this.autoSearchFields = searchFields;
        });
    }

    selectionChange(): void {
        this.buttonBarComponent.disablePrint = true;
    }

    setSearch(): void {
        this.hasError = this.autoSearchFields.hasError;
        this.errorMessage = this.autoSearchFields.errorMessage;
        if (this.hasError) {
            this.focusError();
            return;
        }
        if (this.checkIsAuto()) {
            this.showAutoResults();
        } else if (this.checkIsProperty() && this.propertySearchForm?.valid) {
            this.showResultsProperty();
        } else {
            return;
        }
    }

    setTabInformation(): void {
        this.activeTab = 0;
        this.loggerService.info(`User policy access: Property - ${this.authorizationProperty}, Auto - ${this.authorizationAuto}`);
        this.loggerService.info(`Current active Policy Inquiry tab (0: property, 1: auto): ${this.activeTab}`);
        if (this.isPropertyAndAuto() && this.tpiSessionConfig.tpiPropertyToggle || this.isAutoTabOnly()) {
            this.activeTab = 1;
            this.loggerService.info(`Active Policy Inquiry tab (0: property, 1: auto) has changed to ${this.activeTab}`);
        } else if (!this.isPropertyAndAuto() && !this.isAutoTabOnly() && !this.isPropertyTabOnly()) {
            this.loggerService.error(`User does not have access to Auto and Property`);
            this.loggerService.logCurrentContents();
        }
    }

    setVariables(): void {
        this.submitOptions = {
            submitStatus: false,
            onSubmit: true
        };
    }

    showAutoResults(): void {
        if (!this.resultComponent) {
            this.showAutoResult = false;
            return;
        }
        if (this.autoSearchFields.action.displayResults) {
            this.buttonBarComponent.disablePrint = false;
            this.resultComponent.resetResultData();
            this.resultComponent.showAutoResults(this.autoSearchFields);
        }
    }

    showResults(event: boolean): void {
        if (this.selectedTab === commonConstants.AUTO_FLOW) {
            this.showAutoResult = event;
            if (this.showAutoResult) {
                this.buttonBar.hideWaitIndicator();
            }
        } else {
            this.showPropertyResult = event;
        }
    }

    showResultsProperty(): void {
        if (!this.resultComponent) {
            this.showPropertyResult = false;
        } else if (this.autoSearchFields.action.displayResults) {
            this.resultComponent.resetResultPropertyData();
            this.resultComponent.showPropertyResults(this.autoSearchFields);
        }
    }

    submitOnEnter(event): void {
        if (event.keyCode === KEYCODES.ENTER) {
            this.buttonBarComponent.submitSearch();
        }
    }
}
