<ng-container [formGroup]='parentForm' *ngIf="parentForm && showForm">

    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-textfield ngDefaultControl id="lastName" name="lastName" label="Last Name" aia-label="Last Name" formControlName="lastName"
                [attr.error]="checkForError('lastName', parentForm, appendOption(submitOptions, {label: 'Last Name'}))" disabled required>
            </bolt-textfield>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-textfield ngDefaultControl id="firstName" name="firstName" label="First Name" aria-label="First Name" formControlName="firstName"
                [attr.error]="checkForError('firstName', parentForm, appendOption(submitOptions, {label: 'First Name'}))" disabled required>
            </bolt-textfield>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-textfield ngDefaultControl id="userId" name="userId" label="User ID" aira-label="User ID" formControlName="userId"
                [attr.error]="checkForError('userId', parentForm, appendOption(submitOptions, {label: 'User ID'}))" disabled required>
            </bolt-textfield>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col-sm-6 bolt-space-bottom-lg">
            <bolt-textfield ngDefaultControl id="email" name="email" label="Email" aria-label="Email" formControlName="email"
                [attr.error]="checkForError('email', parentForm, appendOption(submitOptions, {label: 'Email'}))" disabled required>
            </bolt-textfield>
        </div>
    </div>

    <bolt-button-bar class="noprint">
        <bolt-button slot="cancel" type="primary" (click)="resetPassword()">Reset Password</bolt-button>
        <bolt-button slot="forward" type="primary" (click)="unlock()">Unlock</bolt-button>
    </bolt-button-bar>

</ng-container>