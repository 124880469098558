<ng-container [formGroup]='parentForm' *ngIf="parentForm">
    <div class="bolt-row bolt-space-bottom-lg">
        <div class="bolt-col-12">
            <bolt-textfield inputsize="17" ngDefaultControl tpiToUppercaseDirective tpiAlphanumericSpacesDirective formControlName="policyNumber" aria-label="Please provide Auto policy number"
                label="Policy Number" maxlength="12" (paste)="utilsService.pasteAlphanumericAndSpace($event)" required
                [attr.error]="checkForError('policyNumber', parentForm, appendOption(submitOptions, {label: 'Policy Number'}))">
            </bolt-textfield>
        </div>
    </div>
    <div class="bolt-row bolt-space-bottom-lg">
        <div class="bolt-col-12">
            <bolt-textfield inputsize="6" ngDefaultControl tpiAlphanumericDirective tpiToUppercaseDirective formControlName="sixDigitVinNumber" aria-label="Please provide Auto VIN"
                label="VIN (Last 6 Digits)" maxlength="6" (paste)="utilsService.pasteAlphanumeric($event)" required [value]="vin"
                [attr.error]="checkForError('sixDigitVinNumber', parentForm, appendOption(submitOptions, {label: 'VIN Number'}))">
            </bolt-textfield>
        </div>
    </div>
</ng-container>