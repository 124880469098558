import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { LOGGER_CONSTANTS, pageOptions } from '../../../CONSTANTS/tpi-common-constants';
import { RESPONSE_CODES, VALIDATION_REGEX } from '../../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { WaitIndicatorComponent } from '../../../shared/components/wait-indicator/wait-indicator.component';
import { ValidatorService } from '../../../shared/services/validator.service';
import { PaginationSettings } from '../../../tpi-models/general/data-table';
import { AuthorDetails } from '../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { WebSupportUserRequest } from '../../../tpi-models/manage-accounts-company-web/web-support-request';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'tpi-manage-user-web',
    templateUrl: './manage-user-web.component.html',
    styleUrls: ['./manage-user-web.component.scss']
})
export class ManageUserWebComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(WaitIndicatorComponent) waitIndicator;

    currentPage = 0;
    dataSource: any;
    errorMessage: string;
    hasError = false;
    pageSize = pageOptions.paginationRules[0];
    paginationSettings: PaginationSettings = {
        pageSizeOptions: pageOptions.paginationRules,
        arialLabelValue: 'Web Support User list pagination'
    };
    searchUserForm: FormGroup;
    totalSize = 0;
    userAccount: AuthorDetails;
    users = null;

    private searchQueryFormControllerName = 'searchQuery';

    // eslint-disable-next-line max-params
    constructor(public formHelper: RxFormHelperService,
        private validatorService: ValidatorService,
        private manageUsersService: ManageUserAccountsService,
        private sessionService: SessionService,
        private loggerService: LoggerService,
        public utilsService: UtilsService,
        @Inject('window') private window: Window) {
    }

    buildSearchUserForm(): void {
        this.searchUserForm = this.formHelper.build({
            searchQuery: [
                '',
                Validators.compose([
                    this.validatorService.validateWebSupportInputLength(VALIDATION_REGEX.webSupportMinLength)
                ])
            ]
        });
    }

    handlePage(event: any): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.sliceDataSource();
        this.utilsService.scrollToTopOfPage();
    }

    ngOnInit(): void {
        this.userAccount = this.sessionService.getAuthorDetails();
        this.buildSearchUserForm();
    }

    performSearch($event?): void {
        this.showWaitIndicator(true);
        const query = this.searchUserForm.get(this.searchQueryFormControllerName).value;
        if ($event && this.searchUserForm.valid && this.userAccount) {
            const request: WebSupportUserRequest = { userId: this.userAccount.userName, userNumber: this.userAccount.userId, filter: query };
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: performSearch: Start search; query - '${query}'`,
                {
                    user: this.userAccount,
                    request
                });
            this.manageUsersService.findUsersByFilter(request).subscribe(
                {
                    next: (response: any[]) => {
                        this.resetDataSource();
                        this.dataSource = new MatTableDataSource<any[]>(response);
                        this.users = response;
                        this.totalSize = this.users.length;
                        this.sliceDataSource();
                        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: performSearch: ${LOGGER_CONSTANTS.success} search; query - '${query}'`,
                            {
                                user: this.userAccount,
                                request,
                                recordsCount: response.length
                            });
                        this.loggerService.logCurrentContents();
                    },
                    error: (errorResponse) => {
                        this.showWaitIndicator(false);
                        if (errorResponse.status === RESPONSE_CODES.ERROR_400) {
                            this.users = [];
                        } else {
                            this.setError({ hasError: true, errorMessage: tpiSearchError.SYSTEM_DOWN_ERROR });
                        }
                        this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: performSearch: ${LOGGER_CONSTANTS.error} search; query - '${query}'`,
                            {
                                user: this.userAccount,
                                request,
                                error: errorResponse
                            });
                        this.loggerService.logCurrentContents();
                    }
                }
            );
        } else {
            this.showWaitIndicator(false);
            this.resetDataSource();
        }
    }

    resetDataSource(): void {
        this.currentPage = 0;
        this.users = null;
        this.dataSource = null;
        this.hasError = false;
        this.errorMessage = '';
    }

    resetPage(): void {
        this.searchUserForm.get(this.searchQueryFormControllerName).reset();
        this.resetDataSource();
    }

    setError(event: any): void {
        this.hasError = event.hasError;
        this.errorMessage = event.errorMessage;
        if (this.hasError) {
            this.utilsService.scrollToTopOfPage();
        }
    }

    showWaitIndicator(show: boolean): void {
        if (show) {
            this.waitIndicator.openWaitIndicator();
        } else {
            this.waitIndicator.hideWaitIndicator();
        }
    }

    sliceDataSource(): void {
        const start = this.currentPage * this.pageSize;
        const end = (this.currentPage + 1) * this.pageSize;
        const slice = this.users.slice(start, end);
        this.dataSource.data = slice;
        this.showWaitIndicator(false);
    }
}
