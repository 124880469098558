import {
    HttpErrorResponse, HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RequestTimeoutInterceptorService implements HttpInterceptor {
    private TIMEOUT_DURATION = Number(environment.requestTimeoutInMillsec);
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            timeout(this.TIMEOUT_DURATION),
            catchError((error: Error) => {
                if (error instanceof TimeoutError) {
                    const errorResponse = new HttpErrorResponse({
                        error: 'RequestTimeoutException',
                        status: 408,
                        statusText: `Request has been timed out after ${this.TIMEOUT_DURATION} ms`,
                        url: request.url
                    });
                    return throwError(() => errorResponse);
                }
                return throwError(() => error);
            })
        );
    }
}
