import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LOGGER_CONSTANTS } from '../../../../../CONSTANTS/tpi-common-constants';
import { tpiManageUsers } from '../../../../../CONSTANTS/tpi-manage-users';
import { tpiSearchError } from '../../../../../CONSTANTS/tpi-search-errors';
import { CompanyInfo } from '../../../../../tpi-models/general/company-info';
import { AccountRole } from '../../../../../tpi-models/manage-accounts-company-web/account-role';
import { AuthorDetails, ModifyUserAccountInfo } from '../../../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { LoggerService } from '../../../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../../../tpi-services/tpi-session/session.service';
import { SuccessModalComponent } from '../../../shared/success-modal/success-modal.component';
interface Window {
    userManagementWaitIndicator: any;
}
@Component({
    selector: 'tpi-add-new-user',
    templateUrl: './add-new-user.component.html'
})
export class AddNewUserComponent implements OnInit {
    @Input() company: CompanyInfo;
    @Output() errorMessageEmit: EventEmitter<string> = new EventEmitter<string>();
    @Input() parentForm: FormGroup;
    @Output() resetFormEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() resetResultMessagesEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() retrieveUsersByCompanyEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() roleOptions: Array<AccountRole>;
    @Input() submitOptions: any;
    @ViewChild(SuccessModalComponent) successModalComponent;
    authorDetails: AuthorDetails;
    showModal: boolean;
    // eslint-disable-next-line max-params
    constructor(private loggerService: LoggerService,
        private manageUserAccountsService: ManageUserAccountsService,
        @Inject('window') private window: Window,
        private session: SessionService
    ) { }

    closeModal($event): void {
        if ($event) {
            this.showModal = false;
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.authorDetails.userName} closed create-user-modal`);
            this.resetFormEmit.emit(true);
        }
    }

    decipherErrorMessage(error: HttpErrorResponse): void {
        if (error?.error?.systemMessage?.includes(tpiManageUsers.ADD_NEW_USER_ALREADY_EXISTS_ERROR)) {
            this.errorMessageEmit.emit(tpiSearchError.ADD_NEW_USER_DUPLICATE_ERROR);
        } else {
            this.errorMessageEmit.emit(tpiSearchError.UNABLE_PROCESS_ERROR);
        }
    }

    ngOnInit(): void {
        this.authorDetails = this.session.getAuthorDetails();
    }

    openModal($event): void {
        if ($event) {
            this.showModal = true;
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.authorDetails.userName} opened create-user-modal`);
            this.resetResultMessagesEmit.emit(true);
        }
    }

    openSuccessModal($event): void {
        if ($event) {
            this.closeModal(true);
            this.successModalComponent.openModal(true);
        }
    }

    performValidation($event?): void {
        if ($event) {
            this.submitOptions = {
                submitStatus: true,
                onSubmit: true
            };

            if (this.parentForm.valid) {
                this.tryAddNewUser(true);
            } else {
                $event.preventDefault();
            }
        }
    }

    tryAddNewUser($event): void {
        if ($event) {
            const request: ModifyUserAccountInfo = {
                firstName: this.parentForm.get('firstName').value,
                lastName: this.parentForm.get('lastName').value,
                email: this.parentForm.get('companyEmailAddress').value,
                userId: this.parentForm.get('companyEmailAddress').value,
                role: Number(this.parentForm.get('userRole').value),
                userNumber: 0,
                companyNumber: this.company.companyNumber
            };

            this.window.userManagementWaitIndicator.show();
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.authorDetails.userName} started create-user`, { request, companyName: this.company.companyDescription, authorDetails: this.authorDetails });
            this.manageUserAccountsService.createNewUser(request).subscribe({
                next: (successResponse: ModifyUserAccountInfo) => {
                    this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.authorDetails.userName} successfully added user(${request.userId}) to the company ${this.company.companyDescription}`, {
                        successResponse,
                        request,
                        companyName: this.company.companyDescription,
                        authorDetails: this.authorDetails
                    });
                    this.openSuccessModal(true);
                    this.retrieveUsersByCompanyEmit.emit(true);
                    this.window.userManagementWaitIndicator.hide();
                },
                error: (error: any) => {
                    this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.authorDetails.userName} error adding user(${request.userId}) to the company ${this.company.companyDescription}`, {
                        error,
                        request,
                        companyName: this.company.companyDescription,
                        authorDetails: this.authorDetails
                    });
                    this.decipherErrorMessage(error);
                    this.window.userManagementWaitIndicator.hide();
                }
            });

            this.closeModal(true);
        }
    }
}
