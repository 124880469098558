import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { commonConstants, PING_URLS } from '../../CONSTANTS/tpi-common-constants';
import { SetupTransactionService } from '../shared-services/helper/setup-transaction.service';
@Injectable({
    providedIn: 'root'
})
export class RequestHeaderInterceptorService implements HttpInterceptor {
    constructor(private transactionService: SetupTransactionService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isPingUrls(request)) {
            let modifiedRequest = request.clone({
                headers: request.headers.set('X-Nw-Transaction-Id', this.transactionService.getTransactionId())
            });
            if (modifiedRequest.url.includes(commonConstants.PAGES.PERSONAL_LINES_BILLING)) {
                modifiedRequest = modifiedRequest.clone({
                    headers: modifiedRequest.headers.set('connectionType', environment.connectionType)
                });
            }
            return next.handle(modifiedRequest);
        }
        return next.handle(request);
    }

    isPingUrls(request: HttpRequest<any>): boolean {
        const pingUrls = PING_URLS;
        return pingUrls.filter((pingUrl) => request.url.includes(pingUrl)).length > 0;
    }
}
