import { Directive, HostListener } from '@angular/core';
import { SetupTransactionService } from '../../tpi-services/shared-services/helper/setup-transaction.service';

@Directive({
    selector: '[tpiButtonClickDirective]'
})
export class ButtonClicktDirective {
    constructor(private transaction: SetupTransactionService) { }

    @HostListener('click')
    onClick(): void {
        this.transaction.setupTransactionId();
    }
}
