import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LOGGER_CONSTANTS } from '../../../../../CONSTANTS/tpi-common-constants';
import { tpiSearchError } from '../../../../../CONSTANTS/tpi-search-errors';
import { AccountInfo } from '../../../../../tpi-models/general/account-info';
import { AccountRole } from '../../../../../tpi-models/manage-accounts-company-web/account-role';
import { ManageUserAccountsAccountInfo } from '../../../../../tpi-models/manage-accounts-company-web/manage-user-accounts-account-info';
import { ModifyUserAccountInfo } from '../../../../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { LoggerService } from '../../../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../../../tpi-services/tpi-session/session.service';
import { SuccessModalComponent } from '../../../shared/success-modal/success-modal.component';

@Component({
    selector: 'tpi-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent {
    @Input() companyNumber: number;
    @Output() errorMessageEmit: EventEmitter<string> = new EventEmitter<string>();
    @Output() isLoadingEmit: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    @Input() parentForm: FormGroup;
    @Output() resetFormEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() resetResultMessagesEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() retrieveUsersByCompanyEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() roleOptions: Array<AccountRole>;
    @Input() selectedTableEntries: Array<ManageUserAccountsAccountInfo>;
    @Input() submitOptions: any;
    @ViewChild(SuccessModalComponent) successModalComponent;

    showModal: boolean;
    user: AccountInfo;

    constructor(private loggerService: LoggerService,
        private sessionService: SessionService,
        private manageUserAccountsService: ManageUserAccountsService
    ) { }

    autofillUserInfoFields(): void {
        this.parentForm.get('firstName').setValue(this.selectedTableEntries[0]?.firstName);
        this.parentForm.get('lastName').setValue(this.selectedTableEntries[0]?.lastName);
        this.parentForm.get('companyEmailAddress').setValue(this.selectedTableEntries[0]?.email);
        this.parentForm.get('userRole').setValue(this.selectedTableEntries[0]?.role.id);
    }

    closeModal($event): void {
        if ($event) {
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.user.shortName} closed edit-modal`, {
                user: this.user,
                request: this.getEditRequest()
            });
            this.loggerService.logCurrentContents();
            this.showModal = false;
            this.resetFormEmit.emit(true);
        }
    }

    getEditRequest(): ModifyUserAccountInfo {
        return {
            firstName: this.parentForm.get('firstName').value,
            lastName: this.parentForm.get('lastName').value,
            email: this.parentForm.get('companyEmailAddress').value,
            userId: this.selectedTableEntries[0].userId,
            role: this.parentForm.get('userRole').value,
            userNumber: this.selectedTableEntries[0].id,
            companyNumber: this.companyNumber
        };
    }

    ngOnInit(): void {
        const session = this.sessionService.getSession();
        this.user = session.context.session.account;
    }

    openModal($event): void {
        if ($event) {
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.user.shortName} opened edit-modal`, {
                user: this.user,
                request: this.getEditRequest()
            });
            this.showModal = true;
            this.resetResultMessagesEmit.emit(true);
            this.autofillUserInfoFields();
        }
    }

    openSuccessModal($event): void {
        if ($event) {
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.user.shortName} viewed edit success-modal`, {
                user: this.user,
                request: this.getEditRequest()
            });
            this.successModalComponent.openModal(true);
            this.closeModal(true);
        }
    }

    performValidation($event?): void {
        if ($event) {
            this.isLoadingEmit.emit(true);
            this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.user.shortName} submitted edit-modal`, {
                user: this.user,
                request: this.getEditRequest()
            });
            this.submitOptions = {
                submitStatus: true,
                onSubmit: true
            };

            if (this.parentForm.valid) {
                this.tryEditUser(true);
            } else {
                this.isLoadingEmit.emit(false);
                this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: ${this.user.shortName} viewed edited UI validation error`, {
                    user: this.user,
                    request: this.getEditRequest()
                });
                $event.preventDefault();
            }
        }
    }

    reloadPage(isOpened: boolean): void {
        if (isOpened) {
            this.loggerService.info('Edit success modal is opened');
        } else {
            this.retrieveUsersByCompanyEmit.emit(true);
        }
    }

    tryEditUser($event): void {
        if ($event) {
            const request: ModifyUserAccountInfo = this.getEditRequest();

            this.loggerService.info(`tryEditUser(): ${this.user.shortName} started user-edit api call for ${request.userId}`, {
                user: this.user,
                request
            });

            this.manageUserAccountsService.editUser(request).subscribe({
                next: (successResponse: ManageUserAccountsAccountInfo) => {
                    this.isLoadingEmit.emit(false);
                    this.loggerService.info(`tryEditUser(): ${LOGGER_CONSTANTS.splunkDashboard}: ${this.user.shortName} edited ${successResponse.userId}`, {
                        successResponse,
                        request,
                        user: this.user
                    });
                    this.openSuccessModal(true);
                },
                error: (error: any) => {
                    this.isLoadingEmit.emit(false);
                    this.loggerService.error(`tryEditUser(): ${LOGGER_CONSTANTS.splunkDashboard}: ${this.user.shortName} failed to edit ${request.userId}`, {
                        error,
                        request,
                        user: this.user
                    });
                    this.errorMessageEmit.emit(tpiSearchError.UNABLE_PROCESS_ERROR);
                    this.closeModal(true);
                }
            });
        }
    }
}
