import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CONST_SIZES, VALIDATION_REGEX, customErrorFlags } from '../../../../CONSTANTS/tpi-error-tags';
import { FormElementCoreComponent } from '../../../../shared/components/form-element-core/form-element-core.component';
import { ValidatorService } from '../../../../shared/services/validator.service';
import { UtilsService } from '../../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'app-password-form',
    templateUrl: './password-form.component.html',
    styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent extends FormElementCoreComponent {
    @Input() disableCurrentPassword = false;
    @Input() parentForm: FormGroup;
    @Input() submitOptions: any;

    showInteractiveRequirements: boolean;

    constructor(public utilsService: UtilsService,
        private validatorService: ValidatorService) {
        super();
    }

    doesNewPasswordsMatch(): boolean {
        const isValidNewPassword = this.parentForm.get('newPassword').valid;
        return this.newPasswordsMatch() && isValidNewPassword;
    }

    getNewPasswordInstructionaltext(): string {
        return this.disableCurrentPassword ? `` : `Don't use previously used passwords`;
    }

    newPasswordsMatch(): boolean {
        return this.parentForm.get('newPassword').value === this.parentForm.get('confirmNewPassword').value;
    }

    onNewPasswordFocus(): void {
        this.showInteractiveRequirements = true;
    }

    onNewPasswordFocusOut(): void {
        this.showInteractiveRequirements = false;
    }

    resetValidation(): void {
        this.showInteractiveRequirements = false;
    }

    setConfirmNewPasswordValidation(username: string): void {
        this.parentForm.get('confirmNewPassword').clearValidators();
        this.parentForm.get('confirmNewPassword').addValidators([
            Validators.required,
            this.validatorService.validatePasswordWithSpecifiedDigit(CONST_SIZES.PASSWORD_MIN_LENGTH),
            this.validatorService.validatePasswordFormat(VALIDATION_REGEX.isValidPassword),
            ...this.setUserNameValidator(username)
        ]);

        this.parentForm.updateValueAndValidity();
        this.parentForm.get('confirmNewPassword').updateValueAndValidity();
    }

    setCurrentPasswordValidation(): void {
        if (this.disableCurrentPassword) {
            return;
        }
        this.parentForm.get('currentPassword').clearValidators();
        this.parentForm.get('currentPassword').addValidators([
            Validators.required
        ]);

        this.parentForm.updateValueAndValidity();
        this.parentForm.get('currentPassword').updateValueAndValidity();
    }

    setNewPasswordValidation(username: string): void {
        this.parentForm.get('newPassword').clearValidators();
        this.parentForm.get('newPassword').addValidators([
            Validators.required,
            this.validatorService.validatePasswordWithSpecifiedDigit(CONST_SIZES.PASSWORD_MIN_LENGTH),
            this.validatorService.validatePasswordFormat(VALIDATION_REGEX.isValidPassword),
            ...this.setUserNameValidator(username),
            this.validatorService.validateWithCustomError(
                VALIDATION_REGEX.containsLowercase,
                customErrorFlags.PASSWORD_LOWERCASE_ERROR
            ),
            this.validatorService.validateWithCustomError(
                VALIDATION_REGEX.containsUppercase,
                customErrorFlags.PASSWORD_UPPERCASE_ERROR
            ),
            this.validatorService.validateWithCustomError(
                VALIDATION_REGEX.containsNumber,
                customErrorFlags.PASSWORD_NUMBER_ERROR
            ),
            this.validatorService.validatePasswordForbiddenSpecialCharacters(VALIDATION_REGEX.forbiddenSpecialCharacter, VALIDATION_REGEX.containsSpecialCharacter),
            this.validatorService.validateWithCustomError(
                VALIDATION_REGEX.notContainSpace,
                customErrorFlags.PASSWORD_SPACE_ERROR
            )
        ]);

        this.parentForm.updateValueAndValidity();
        this.parentForm.get('newPassword').updateValueAndValidity();
    }

    setPasswordValidation(username: string): void {
        this.setCurrentPasswordValidation();
        this.setNewPasswordValidation(username);
        this.setConfirmNewPasswordValidation(username);
    }

    setUserNameValidator(username: string): any[] {
        if (!this.disableCurrentPassword) {
            return [this.validatorService.validatePasswordContainsUserName(username)];
        }
        return [];
    }
}
