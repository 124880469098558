import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';

@Component({
    selector: 'tpi-filter-by-name-or-email',
    templateUrl: './filter-by-name-or-email.component.html'
})
export class FilterByNameOrEmailComponent {
    @Output() filterEmit: EventEmitter<string> = new EventEmitter<string>();
    @Input() label: string;
    @Input() parentForm: FormGroup;
    isTyping = false;
    constructor(private loggerService: LoggerService) { }

    emitFilter($event): void {
        if ($event) {
            const entry = this.parentForm.get('filterByNameOrEmail').value;
            this.loggerService.info(`emitFilter() - filter by - ${entry}`);
            this.filterEmit.emit(entry);
            this.isTyping = true;
        }
    }

    logUnfocusedEvent(): void {
        if (this.isTyping) {
            this.loggerService.info('logUnfocusedEvent() - filter parentForm unfocused');
            this.isTyping = false;
        }
    }

    onPaste($event): void {
        setTimeout(() => {
            this.emitFilter(true);
        });
    }
}
