import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// Check on bolt migration and remove this if possible
// eslint-disable-next-line @typescript-eslint/naming-convention
import Bolt from '@nationwide-bolt/bundle-core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

Bolt.initialize().then(() => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule);
});
