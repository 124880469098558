import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataTableFilter } from '../../../../tpi-models/general/filter-data';
import { GeneralTypeAllString, Generic } from '../../../../tpi-models/general/untyped';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';

@Component({
    selector: 'tpi-filter-component',
    templateUrl: './filter-component.component.html',
    styleUrls: ['./filter-component.component.scss']
})

export class FilterComponentComponent {
    @Input() dataToFilter: DataTableFilter;
    @Output() filterByNameOrEmail = new EventEmitter<any>();
    @Input() options: GeneralTypeAllString;

    isTyping = false;

    constructor(private loggerService: LoggerService) { }

    filterEmit($event: Generic): void {
        const filterData = this.filterSearch(this.dataToFilter, $event.srcElement.value);
        this.dataToFilter.filteredData = filterData;
        this.filterByNameOrEmail.emit(this.dataToFilter);
    }

    filterSearch(dataToFilter: DataTableFilter, textString: string): Generic {
        this.isTyping = true;
        return dataToFilter.data.filter(
            (row) => {
                let returnFilterValue = false;
                for (const rowField in dataToFilter.fields) {
                    if (rowField) {
                        if (!returnFilterValue) {
                            if (row[dataToFilter.fields[rowField]].toLowerCase().includes(textString.toLowerCase())) {
                                returnFilterValue = true;
                                return returnFilterValue;
                            }
                        }
                    }
                }
                return false;
            }
        );
    }

    logUnfocusedEvent(): void {
        if (this.isTyping) {
            this.loggerService.info(this.options.loggerInfoFocusOut);
            this.isTyping = false;
        }
    }
}
