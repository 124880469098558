<div *ngIf="session" class="side-nav">
    <div class="link-container">
        <a *ngIf="
                tpiSessionConfig.tpiAutoToggle ||
                tpiSessionConfig.tpiPropertyToggle
            " id="linkPolicyInquiry" href="{{
                tpiSessionConfig.tpiLegacyDomain
            }}policyinquiry/secure/policySearchPage" [routerLinkActive]="['side-nav-active']">
            Policy Inquiry
        </a>
        <a *ngIf="
                !tpiSessionConfig.tpiAutoToggle &&
                !tpiSessionConfig.tpiPropertyToggle
            " id="linkPolicyInquiry" [routerLink]="['policy-search']" [routerLinkActive]="['side-nav-active']">
            Policy Inquiry
        </a>
    </div>

    <div class="link-container">
        <a *ngIf="tpiSessionConfig.tpiMortgageBulkUpdateToggle" href="{{
                tpiSessionConfig.tpiLegacyDomain
            }}policyinquiry/secure/bulkUploadPage?selectedValue=policy" [routerLinkActive]="['side-nav-active']" id="linkBulkLien">
            Mortgage Bulk Update
        </a>
        <a *ngIf="!tpiSessionConfig.tpiMortgageBulkUpdateToggle" id="linkBulkLien" [routerLink]="['mortgage-bulk-update']" [routerLinkActive]="['side-nav-active']">
            Mortgage Bulk Update
        </a>
    </div>
    <div *ngIf="session.context.session.account.role.id == manageUser" class="link-container">
        <a *ngIf="tpiSessionConfig.tpiManageUserToggle" href="{{
                tpiSessionConfig.tpiLegacyDomain
            }}policyinquiry/secure/manageAccounts" [routerLinkActive]="['side-nav-active']" id="linkManageUser">
            Manage User Accounts
        </a>
        <a *ngIf="!tpiSessionConfig.tpiManageUserToggle" id="linkManageUser" [routerLink]="['/manage-users']" [routerLinkActive]="['side-nav-active']">
            Manage User Accounts
        </a>
    </div>

    <div *ngIf="session.context.session.account.role.id == manageCompany" class="link-container">
        <a *ngIf="tpiSessionConfig.tpiManageUserToggle" href="{{
                tpiSessionConfig.tpiLegacyDomain
            }}policyinquiry/secure/manageCompanyList" [routerLinkActive]="['side-nav-active']" id="linkCompany">
            Manage Company List
        </a>
        <a *ngIf="!tpiSessionConfig.tpiManageUserToggle" id="linkCompany" [routerLink]="['/company-list']" [routerLinkActive]="['side-nav-active']">
            Manage Company List
        </a>
    </div>

    <div *ngIf="session.context.session.account.role.id == manageWeb" class="link-container">
        <a *ngIf="tpiSessionConfig.tpiManageUserToggle" href="{{
                tpiSessionConfig.tpiLegacyDomain
            }}policyinquiry/secure/loadWebSupport" [routerLinkActive]="['side-nav-active']" id="linkWebSupport">
            Manage User - Web
        </a>
        <a *ngIf="!tpiSessionConfig.tpiManageUserToggle" id="linkWebSupport" [routerLink]="['/web-support']" [routerLinkActive]="['side-nav-active']">
            Manage User - Web
        </a>
    </div>

    <div class="link-container">
        <a *ngIf="tpiSessionConfig.tpiChangePasswordToggle" href="{{
                tpiSessionConfig.tpiLegacyDomain
            }}policyinquiry/secure/resetCustomerPassword" [routerLinkActive]="['side-nav-active']" id="linkChangePassword">
            Change Password
        </a>
        <a *ngIf="!tpiSessionConfig.tpiChangePasswordToggle" id="linkChangePassword" [routerLink]="['password-change']" [routerLinkActive]="['side-nav-active']">
            Change Password
        </a>
    </div>
</div>