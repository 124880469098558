import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'app-password-button-bar',
    templateUrl: './password-button-bar.component.html'
})
export class PasswordButtonBarComponent {
    @Output() primaryEmit = new EventEmitter<boolean>();
    @Input() primaryLabel: string;
    @Output() secondaryEmit = new EventEmitter<boolean>();
    @Input() secondaryLabel: string;

    primaryClick(): void {
        this.primaryEmit.emit(true);
    }

    secondaryClick(): void {
        this.secondaryEmit.emit(true);
    }
}
