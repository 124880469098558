import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../../../tpi-services/tpi-session/session.service';

@Component({
    selector: 'app-temporary-password-failure',
    templateUrl: './temporary-password-failure.component.html'
})
export class TemporaryPasswordFailureComponent implements OnInit {
    constructor(
        private sessionService: SessionService
    ) { }

    ngOnInit(): void {
        this.sessionService.isLoggedIn = false;
        this.sessionService.deleteAllCookies();
        this.sessionService.resetForgotPasswordTokens();
    }
}
