import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { tpiManageUsers } from '../../../../CONSTANTS/tpi-manage-users';
import { AccountRole, RoleCounts } from '../../../../tpi-models/manage-accounts-company-web/account-role';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';

@Component({
    selector: 'tpi-filter-by-role',
    templateUrl: './filter-by-role.component.html',
    styleUrls: ['./filter-by-role.component.scss']
})
export class FilterByRoleComponent {
    @Output() filterEmit: EventEmitter<string> = new EventEmitter<string>();
    @Input() label: string;
    @Input() parentForm: FormGroup;
    @Input() roleUserCounts: Array<RoleCounts>;

    allRoleOption: AccountRole = tpiManageUsers.ALL_ACCOUNT;

    constructor(private loggerService: LoggerService) { }

    emitFilter($event): void {
        if ($event) {
            const selection = this.parentForm.get('filterByRole').value;
            this.loggerService.info(`emitFilter() - filter by - ${selection}`);
            this.filterEmit.emit(selection);
        }
    }
}
