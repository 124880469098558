<div>
    <bolt-button slot="cancel" type="secondary" class="buttons" (click)="openModal($event)">Add new company</bolt-button>
</div>
<div [formGroup]='companyForm' *ngIf="companyForm" class="align-left">
    <tpi-notification-modal *ngIf="showModal">
        <bolt-modal primarybutton="Save" tertiarybutton="Cancel" (bolt-modal-primary-button-click)="saveCompany($event)" (bolt-modal-tertiary-button-click)="closeModal($event)" id="addNewCompanyModal"
            heading="Add new company" [open]="showModal">
            <p><strong>All fields are required.</strong></p>
            <app-company-info-form [parentForm]="companyForm" [submitOptions]="submitOptions" [roleOptions]="roleOptions">
            </app-company-info-form>
        </bolt-modal>
    </tpi-notification-modal>
</div>
<tpi-success-modal (isOpenedEmit)="reloadPage($event)" [modalText]="'A new company has been created. You now have access to the company and can begin creating users.'">
</tpi-success-modal>