import { AfterContentInit, Component, Inject, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { DISALLOWED_EMAIL_DOMAINS, VALIDATION_REGEX } from '../../../CONSTANTS/tpi-error-tags';
import { ModifyUserActions, tpiManageUsers, tpiModifyUserInputs } from '../../../CONSTANTS/tpi-manage-users';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { WaitIndicatorComponent } from '../../../shared/components/wait-indicator/wait-indicator.component';
import { ValidatorService } from '../../../shared/services/validator.service';
import { CompanyInfo } from '../../../tpi-models/general/company-info';
import { UserSession } from '../../../tpi-models/general/tpisession';
import { AccountRole, UserCount } from '../../../tpi-models/manage-accounts-company-web/account-role';
import { ManageUserAccountsAccountInfo } from '../../../tpi-models/manage-accounts-company-web/manage-user-accounts-account-info';
import { ModifyUserInput } from '../../../tpi-models/manage-accounts-company-web/modify-user-input';
import { StoreSelectedCompanyFields } from '../../../tpi-models/store/store-selected-company-fields.model';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { ManageUserAccountsService } from '../../../tpi-services/manage-accounts-company-web-services/manage-user-accounts.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { SelectedCompanyStateService } from '../../../tpi-services/tpi-statemanagement/selected-company-state.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
import { ManageUserAccountsTableComponent } from './manage-user-accounts-table/manage-user-accounts-table.component';
import { DeleteResetUnlockUserComponent } from './modify-users/delete-reset-unlock-user/delete-reset-unlock-user.component';

interface Window {
    userManagementWaitIndicator: any;
}
@Component({
    selector: 'tpi-manage-user-accounts',
    templateUrl: './manage-user-accounts.component.html',
    styleUrls: ['./manage-user-accounts.component.scss']
})
export class ManageUserAccountsComponent implements OnInit, AfterContentInit {
    @ViewChild(DeleteResetUnlockUserComponent) deleteResetUnlockUserComponent;
    @ViewChild(ManageUserAccountsTableComponent) manageUserAccountsTable;
    @Output() showResult: boolean;
    @ViewChild('updateCompanyHelpModal') updateCompanyHelpModal;
    @ViewChild(WaitIndicatorComponent) waitIndicator;

    company: CompanyInfo;
    errorMessage: string;
    filterTableForm: FormGroup;
    filteredTableEntries: Array<ManageUserAccountsAccountInfo>;
    hasError: boolean;
    modifyUserAction: ModifyUserActions;
    modifyUserActionDelete: string = ModifyUserActions.Delete;
    modifyUserActionReset: string = ModifyUserActions.Reset;
    modifyUserActionUnlock: string = ModifyUserActions.Unlock;
    modifyUserInput: ModifyUserInput;
    roleOptions: Array<AccountRole>;
    roleUserCounts = null;
    selectedTableEntries: Array<ManageUserAccountsAccountInfo> = [];
    session: UserSession;
    showSuccessMessage: boolean;
    submitOptionsUser = {
        onSubmit: true,
        submitStatus: false
    };
    successMessage: string;
    tableEntries: Array<ManageUserAccountsAccountInfo>;
    totalUsers: number;
    userForm: FormGroup;

    // eslint-disable-next-line max-params
    constructor(public formHelper: RxFormHelperService,
        private sessionService: SessionService,
        private loggerService: LoggerService,
        private manageUserAccountsService: ManageUserAccountsService,
        public utilsService: UtilsService,
        private route: Router,
        private selectedCompanyStateService: SelectedCompanyStateService,
        private validatorService: ValidatorService,
        private ngZone: NgZone,
        @Inject('window') private window: Window) { }

    buildFilterTableForm(): void {
        this.filterTableForm = this.formHelper.build({
            filterByNameOrEmail: [''],
            filterByRole: ['']
        });
    }

    buildUserForm(): void {
        this.userForm = this.formHelper.build({
            firstName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(VALIDATION_REGEX.isValidFirstName)
                ])],
            lastName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(VALIDATION_REGEX.isValidName)
                ])],
            companyEmailAddress: [
                '',
                Validators.compose([
                    Validators.required,
                    this.validatorService.validateEmailFormat(VALIDATION_REGEX.isEmail),
                    this.validatorService.validateEmailDomain(DISALLOWED_EMAIL_DOMAINS)
                ])],
            userRole: [
                '',
                Validators.compose([
                    Validators.required
                ])]
        });
        this.resetUserForm(true);
    }

    calculateUserstatus(userList: Array<ManageUserAccountsAccountInfo>): UserCount {
        const userCount: UserCount = { totalUsers: 0, roleCounts: [] };
        if (userList && this.roleOptions?.length > 0) {
            for (const roleOption in this.roleOptions) {
                if (roleOption) {
                    const userRole = userList.filter((users) => users.role.id === this.roleOptions[roleOption].id);
                    userCount.roleCounts.push({
                        role: this.roleOptions[roleOption].roleDescription,
                        totalUsers: userRole.length,
                        roleId: this.roleOptions[roleOption].id
                    });
                    userCount.totalUsers += userRole.length;
                }
            }
        }
        return userCount;
    }

    filterByNameOrEmail($event): void {
        this.loggerService.info(`filterByNameOrEmail(): Filter by input: ${$event.toLowerCase()}`);
        if ($event) {
            this.filteredTableEntries = this.tableEntries.filter(
                (row) =>
                    row.email.toLowerCase().includes($event.toLowerCase()) ||
                    row.firstName.toLowerCase().concat(' ').concat(row.lastName.toLowerCase()).includes($event.toLowerCase())
            );
        } else {
            this.filteredTableEntries = this.tableEntries;
        }
        this.resetFilterByRoleValue();
        this.manageUserAccountsTable?.resetSelection(true);
    }

    filterByRole($event): void {
        this.loggerService.info(`filterByRole(): Filter by role: ${$event}`);
        if ($event) {
            if ($event === tpiManageUsers.ALL_ACCOUNT.id.toString()) {
                this.filteredTableEntries = this.tableEntries;
            } else {
                this.filteredTableEntries = this.tableEntries.filter(
                    (row) =>
                        row.role.id.toString() === $event
                );
            }
            this.resetFilterByNameOrEmailValue();
            this.manageUserAccountsTable?.resetSelection(true);
        }
    }

    filterTableEntriesByRoles(roles: Array<AccountRole>): void {
        this.loggerService.info(`filterTableEntriesByRoles(): Filter by roles: ${roles}`);

        this.tableEntries = this.tableEntries.filter((row) =>
            !!roles.find((role) => role.id === row.role.id)
        );
        this.filteredTableEntries = this.tableEntries;
    }

    ngAfterContentInit(): void {
        this.retrieveUsersByCompany(true);
        this.buildFilterTableForm();
        this.buildUserForm();
        this.resetFilterByNameOrEmailValue();
        this.resetFilterByRoleValue();
        this.resetResultMessages(true);
    }

    ngOnInit(): void {
        this.session = this.sessionService.getSession();
        this.setCompany();
        this.setRoleOptions();
    }

    openModifyUserModal($event: ModifyUserActions): void {
        if ($event) {
            this.modifyUserAction = $event;
            switch (this.modifyUserAction) {
                case ModifyUserActions.Delete:
                    this.modifyUserInput = tpiModifyUserInputs.MODIFY_USER_INPUT_DELETE;
                    break;
                case ModifyUserActions.Unlock:
                    this.modifyUserInput = tpiModifyUserInputs.MODIFY_USER_INPUT_UNLOCK;
                    break;
                case ModifyUserActions.Reset:
                    this.modifyUserInput = tpiModifyUserInputs.MODIFY_USER_INPUT_RESET;
                    break;
            }
            this.deleteResetUnlockUserComponent.openModal(this.modifyUserAction, true);
        }
    }

    openUpdateCompanyHelpModal(): void {
        this.loggerService.info('openUpdateCompanyHelpModal(): Open How do I update my company info modal.');
        this.updateCompanyHelpModal.nativeElement.openModal();
    }

    resetFilterByNameOrEmailValue(): void {
        this.filterTableForm.get('filterByNameOrEmail').setValue('');
    }

    resetFilterByRoleValue(): void {
        this.filterTableForm.get('filterByRole').setValue(tpiManageUsers.ALL_ACCOUNT.id);
    }

    resetResultMessages($event): void {
        if ($event) {
            this.showSuccessMessage = false;
            this.hasError = false;
        }
    }

    resetTableSelection($event): void {
        if ($event) {
            this.selectedTableEntries = [];
        }
    }

    resetUserForm($event): void {
        if ($event) {
            this.setVariablesUser();
            this.userForm.get('firstName').setValue('');
            this.userForm.get('lastName').setValue('');
            this.userForm.get('companyEmailAddress').setValue('');
            this.userForm.get('userRole').setValue('');
        }
    }

    retrieveUsersByCompany($event): void {
        if ($event) {
            this.manageUserAccountsTable?.resetSelection(true);
            this.window.userManagementWaitIndicator.show();
            this.tableEntries = [];
            const companyNumber = this.company.companyNumber;
            this.manageUserAccountsService.retrieveUsersByCompany(companyNumber).subscribe({
                next: (successResponse: Array<ManageUserAccountsAccountInfo>) => {
                    this.loggerService.info('retrieveUsersForCompany(): successfully retrieved users for the company', {
                        company: this.company
                    });
                    this.tableEntries = successResponse;
                    this.filteredTableEntries = this.tableEntries;
                    this.filterTableEntriesByRoles(this.roleOptions);
                    this.selectedTableEntries = [];
                    const calculateUsers = this.calculateUserstatus(successResponse);
                    this.totalUsers = calculateUsers.totalUsers;
                    this.roleUserCounts = calculateUsers.roleCounts;
                    this.window.userManagementWaitIndicator.hide();
                },
                error: (error: any) => {
                    this.loggerService.error('retrieveUsersForCompany(): error retrieving users for the company', {
                        error,
                        company: this.company
                    });
                    this.showResultsError(tpiSearchError.UNABLE_PROCESS_ERROR);
                    this.window.userManagementWaitIndicator.hide();
                }
            });
        }
    }

    setCompany(): void {
        const userRole = this.session.context.session.account.role.id;
        const userCompany = this.session.context.session.account.company;
        if (userRole === tpiManageUsers.SUPER_USER_ACCOUNT.id) {
            this.selectedCompanyStateService.getSelectedCompanyFields().subscribe((selectedCompanyFields: StoreSelectedCompanyFields) => {
                this.company = selectedCompanyFields?.company;
            });
            if (this.company == null) {
                this.loggerService.info('setCompany(): no selected company was found for the super user, routing to /company-list');
                this.ngZone.run(() => {
                    this.route.navigate(['/company-list']);
                });
            } else {
                this.loggerService.info('setCompany(): set the company to the selected company for super user.', {
                    company: this.company
                });
            }
        } else {
            this.company = {
                companyNumber: userCompany.id,
                companyDescription: userCompany.companyDescription,
                companyAutoAuth: userCompany.autoAuthorization,
                companyPropertyAuth: userCompany.propertyAuthorization
            };
            this.loggerService.info('setCompany(): set the company for non super user.', {
                company: this.company
            });
        }
    }

    setRoleOptions(): void {
        const userRole = this.session.context.session.account.role.id;
        if (userRole === tpiManageUsers.ADMIN_ACCOUNT.id) {
            this.roleOptions = [
                tpiManageUsers.AGENT_ACCOUNT,
                tpiManageUsers.ADMIN_ACCOUNT
            ];
        } else if (userRole === tpiManageUsers.SUPER_USER_ACCOUNT.id) {
            this.roleOptions = [
                tpiManageUsers.AGENT_ACCOUNT,
                tpiManageUsers.ADMIN_ACCOUNT,
                tpiManageUsers.WEB_SUPPORT_ACCOUNT,
                tpiManageUsers.SUPER_USER_ACCOUNT
            ];
        }
    }

    setVariablesUser(): void {
        this.submitOptionsUser = {
            submitStatus: false,
            onSubmit: true
        };
    }

    showResultsError(errorMessage: string): void {
        this.hasError = true;
        this.errorMessage = errorMessage;
        this.utilsService.scrollToTopOfPage();
    }

    showResultsSuccess(successMessage: string): void {
        this.showSuccessMessage = true;
        this.successMessage = successMessage;
        this.utilsService.scrollToTopOfPage();
    }

    showWaitIndicator(event: any): void {
        if (event) {
            this.window.userManagementWaitIndicator.show();
        } else {
            this.window.userManagementWaitIndicator.hide();
        }
    }
}
