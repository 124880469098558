<div>
    <bolt-button slot="cancel" type="secondary" class="buttons" [disabled]="selectedTableEntries.length !== 1" (click)="openModal($event)">Edit user</bolt-button>
</div>

<ng-container [formGroup]='parentForm' *ngIf="parentForm">
    <tpi-notification-modal *ngIf="showModal">
        <bolt-modal primarybutton="Save" tertiarybutton="Cancel" (bolt-modal-primary-button-click)="performValidation($event)" (bolt-modal-tertiary-button-click)="closeModal($event)"
            id="editUserModal" heading="Edit user" [open]="showModal">
            <p class="min-width-600">All fields are required.</p>
            <tpi-user-info-form [parentForm]="parentForm" [submitOptions]="submitOptions" [roleOptions]="roleOptions">
            </tpi-user-info-form>
        </bolt-modal>
    </tpi-notification-modal>

    <tpi-success-modal [modalText]="'The user information you entered has been updated in our system.'" (isOpenedEmit)="reloadPage($event)">
    </tpi-success-modal>
</ng-container>