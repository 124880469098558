import { Injectable } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { FORM_STATUS, commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { CONST_SIZES, DATE_VALIDATION_TAG, customErrorFlags } from '../../CONSTANTS/tpi-error-tags';
import { CustomPasswordErrorValidation, ErrorFields } from '../../tpi-models/general/form-models';
import { UtilsService } from '../../tpi-services/utility/utils-service.service';
import { GridValidatorService } from './grid-validator.service';

@Injectable()
export class ValidatorService {
    constructor(private utilService: UtilsService, private gridValidatorService: GridValidatorService) { }
    calculatePastDate(dayCheck: number): ValidatorFn {
        return (formControl: UntypedFormControl): {
            dateMinErrorFive?: boolean;
        } => {
            let daysToValidate = 0;
            let returnError = null;
            switch (dayCheck) {
                case commonConstants.VALIDATION_CONSTANT.MIN_FIVE_DAYS:
                    daysToValidate = commonConstants.VALIDATION_CONSTANT.MIN_FIVE_DAYS;
                    returnError = { dateMinErrorFive: true };
                    break;
                case commonConstants.VALIDATION_CONSTANT.MIN_THIRTY_DAYS:
                    daysToValidate = commonConstants.VALIDATION_CONSTANT.MIN_THIRTY_DAYS;
                    returnError = { dateMinErrorThirty: true };
                    break;
            }
            const minDate = new Date(this.utilService.minDateValue(daysToValidate + 1));
            if (minDate > new Date(formControl.value)) {
                return returnError;
            }
            return null;
        };
    }

    clearValidateFields(form: UntypedFormGroup): void {
        for (const key of Object.keys(form.controls)) {
            const control = form.controls[key];
            if (control.status === FORM_STATUS.INVALID) {
                control.markAsUntouched();
            }
        }
    }

    validateBulkEditForGrid(): ValidatorFn {
        return (formControl: UntypedFormControl): {
            bulkError?: Array<ErrorFields>;
            duplicatePolicyNumber?: string;
        } | {
            required: boolean;
        } => {
            if (formControl?.value?.length > 0) {
                const policyNumbers = [];
                for (const item of formControl.value) {
                    policyNumbers.push(item[1]);
                }
                const processedPolicyValues = this.gridValidatorService.getDuplicatePoliciesAndNonEmptyValues({ policyNumbers, formControl });
                const duplicatePolicies = processedPolicyValues.duplicatePolicies;
                const notEmptyValues = processedPolicyValues.notEmptyValues;
                if (this.utilService.getNonEmptyValuesForGrid(notEmptyValues).length <= 0) {
                    return { required: true };
                }
                const errorField = this.gridValidatorService.captureBulkError(notEmptyValues);
                return this.gridValidatorService.validateBulkEditReturn(errorField, duplicatePolicies);
            }
            return { required: true };
        };
    }

    validateDate(regexMap: Map<string, RegExp>): ValidatorFn {
        return (formControl: UntypedFormControl): {
            dateFormatError: boolean;
        } => {
            const pattern01to28 = RegExp(regexMap.get(DATE_VALIDATION_TAG.ISVALID_01TO28));
            const pattern29to30 = RegExp(regexMap.get(DATE_VALIDATION_TAG.ISVALID_29TO30));
            const pattern31 = RegExp(regexMap.get(DATE_VALIDATION_TAG.ISVALID_31));
            const patternLeapyear = RegExp(regexMap.get(DATE_VALIDATION_TAG.ISVALID_LEAPDAY));
            if (pattern01to28.test(formControl.value) || pattern29to30.test(formControl.value) || pattern31.test(formControl.value) || patternLeapyear.test(formControl.value)) {
                return null;
            }
            return { dateFormatError: true };
        };
    }

    validateEmailDomain(disallowedDomains: string[]): ValidatorFn {
        return (email: UntypedFormControl): {
            emailDomainError: boolean;
        } => {
            const domain = email.value.slice(email.value.indexOf('@') + 1);
            if (disallowedDomains.includes(domain.toLocaleLowerCase())) {
                return { emailDomainError: true };
            }
            return null;
        };
    }

    validateEmailFormat(regex: any): ValidatorFn {
        return (email: UntypedFormControl): {
            emailError: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(email.value)) {
                return { emailError: true };
            }
            return null;
        };
    }

    validateFullVin(regex: any): ValidatorFn {
        return (vinValue: UntypedFormControl): {
            fullVinFormatError: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(vinValue.value) ||
                vinValue.value.length < CONST_SIZES.VIN_MAX_LENGTH ||
                !this.utilService.isFullVinFormatValid(vinValue.value)) {
                return { fullVinFormatError: true };
            }
            return null;
        };
    }

    validateLoanFormat(regex: any): ValidatorFn {
        return (formControl: UntypedFormControl): {
            loanFormatError: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(formControl.value)) {
                return { loanFormatError: true };
            }
            return null;
        };
    }

    validateMFAFormat(regex: any): ValidatorFn {
        return (formControl: UntypedFormControl): any => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(formControl.value)) {
                return { pattern: true };
            }
            return null;
        };
    }

    validateMFAWithSpecifiedDigit(length: number): ValidatorFn {
        return (formControl: FormControl): any => {
            if (formControl.value.length !== length) {
                return { lengthForMFA: true };
            }
            return null;
        };
    }

    validatePasswordContainsUserName(userName: string): ValidatorFn {
        return (formControl: FormControl): {
            passwordUserNameFieldError: boolean;
        } => {
            if (formControl.value.includes(userName)) {
                return { passwordUserNameFieldError: true };
            }
            return null;
        };
    }

    validatePasswordForbiddenSpecialCharacters(forbiddenCharactersRegex: any, allowedCharactersRegex: any): ValidatorFn {
        return (vinValue: UntypedFormControl): {
            passwordSpecialCharacterError: boolean;
        } => {
            const forbiddenMatchPattern = RegExp(forbiddenCharactersRegex);
            const allowedMatchPattern = RegExp(allowedCharactersRegex);
            if (forbiddenMatchPattern.test(vinValue.value) || !allowedMatchPattern.test(vinValue.value)) {
                return { passwordSpecialCharacterError: true };
            }
            return null;
        };
    }

    validatePasswordFormat(regex: any): ValidatorFn {
        return (formControl: FormControl): {
            passwordFormatFieldError: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(formControl.value)) {
                return { passwordFormatFieldError: true };
            }
            return null;
        };
    }

    validatePasswordWithSpecifiedDigit(minLength: number): ValidatorFn {
        return (formControl: FormControl): {
            minLengthForPassword: boolean;
        } => {
            if (formControl.value.length < minLength) {
                return { minLengthForPassword: true };
            }
            return null;
        };
    }

    validatePolicy(regex: any): ValidatorFn {
        return (policyValue: UntypedFormControl): {
            policyFormatError: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(policyValue.value)) {
                return { policyFormatError: true };
            }
            return null;
        };
    }

    validateUserId(regex: any): ValidatorFn {
        return (userId: UntypedFormControl): {
            usernameError: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(userId.value)) {
                return { usernameError: true };
            }
            return null;
        };
    }

    validateVin(regex: any): ValidatorFn {
        return (vinValue: UntypedFormControl): {
            vinNumberValidationError: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(vinValue.value)) {
                return { vinNumberValidationError: true };
            }
            return null;
        };
    }

    validateVinWithSpecifiedDigit(minLength: number): ValidatorFn {
        return (vinValue: UntypedFormControl): {
            minLengthForVinNumber: boolean;
        } => {
            if (vinValue.value.length < minLength) {
                return { minLengthForVinNumber: true };
            }
            return null;
        };
    }

    validateWebSupportInputLength(regex: any): ValidatorFn {
        return (input: UntypedFormControl): {
            webSupportMinLength: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(input.value)) {
                return { webSupportMinLength: true };
            }
            return null;
        };
    }

    /**
     * @description validateWithCustomError, stop creating new custom validation on pattern matching, extend this.
     * @param {any} regex
     * @param {string} customError
     * @return {ValidatorFn} pattern error flag for reactive form changes
     */
    validateWithCustomError(regex: any, customError?: string): ValidatorFn {
        const errorMappings = {};
        errorMappings[customErrorFlags.ZIP_CODE_PROPERTY_ERROR] = { zipCodePropertySearchError: true };
        errorMappings[customErrorFlags.PASSWORD_LOWERCASE_ERROR] = { passwordLowercaseError: true };
        errorMappings[customErrorFlags.PASSWORD_UPPERCASE_ERROR] = { passwordUppercaseError: true };
        errorMappings[customErrorFlags.PASSWORD_NUMBER_ERROR] = { passwordNumberError: true };
        errorMappings[customErrorFlags.PASSWORD_SPECIAL_CHARACTER_ERROR] = { passwordSpecialCharacterError: true };
        errorMappings[customErrorFlags.PASSWORD_SPACE_ERROR] = { passwordSpaceError: true };
        return (value: UntypedFormControl): CustomPasswordErrorValidation | {
            pattern: boolean;
        } => {
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(value.value)) {
                if (customError && errorMappings[customError]) {
                    return errorMappings[customError];
                } else {
                    return { pattern: true };
                }
            }
            return null;
        };
    }
}
