<ng-container [formGroup]='parentForm' *ngIf="parentForm">

    <div class="bolt-row">
        <div class="bolt-col-sm-12 bolt-space-bottom-lg">
            <bolt-textfield ngDefaultControl id="companyName" name="companyName" label="Company Name" aria-label="Company Name" maxlength="50" formControlName="companyName" (blur)="sanitizeCompany()"
                [attr.error]="checkForError('companyName', parentForm, appendOption(submitOptions, {label: 'Company Name'}))" required>
            </bolt-textfield>
        </div>
    </div>

    <div class="bolt-row">
        <div class="bolt-col-sm-12 bolt-space-bottom-lg">
            <bolt-checkbox-group label="Policy access" required [error]="parentForm.getError('policyAccessError') ? 'Policy Access is required.': ''">
                <bolt-checkbox ngDefaultControl formControlName="policyAccessPropertyAuth" [checked]="parentForm.get('policyAccessPropertyAuth').value === true"
                    (change)="setCheckBoxValue('policyAccessPropertyAuth')">Property</bolt-checkbox>
                <bolt-checkbox ngDefaultControl formControlName="policyAccessAutoAuth" [checked]="parentForm.get('policyAccessAutoAuth').value === true"
                    (change)="setCheckBoxValue('policyAccessAutoAuth')">Auto</bolt-checkbox>
            </bolt-checkbox-group>
        </div>
    </div>
</ng-container>