import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormElementCoreComponent } from '../../../../shared/components/form-element-core/form-element-core.component';
import { AccountRole } from '../../../../tpi-models/manage-accounts-company-web/account-role';
import { UtilsService } from '../../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'tpi-user-info-form',
    templateUrl: './user-info-form.component.html'
})
export class UserInfoFormComponent extends FormElementCoreComponent {
    @Input() parentForm: FormGroup;
    @Input() roleOptions: Array<AccountRole>;
    @Input() submitOptions: any;

    constructor(public utilsService: UtilsService) {
        super();
    }
}
