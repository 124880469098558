import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { PolicyInformationDetails } from '@nationwide/dgs-internet-servicing-policy-requests';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { ValidatorService } from '../../../../app/shared/services/validator.service';
import { environment } from '../../../../environments/environment';
import { commonConstants, FORM_STATUS } from '../../../CONSTANTS/tpi-common-constants';
import { MORTGAGE_EDIT_ERROR, VALIDATION_REGEX } from '../../../CONSTANTS/tpi-error-tags';
import { FormElementCoreComponent } from '../../../shared/components/form-element-core/form-element-core.component';
import { MortgageCompany } from '../../../tpi-models/general/mortgage-companies-response';
import { MortgageCompanyDetails, MortgageInformation } from '../../../tpi-models/general/mortgage-company';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { MortgageCompaniesAdapterService } from '../../../tpi-services/tpi-policy-inquiry/mortgage-companies-adapter.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'app-property-mortgage-info',
    templateUrl: './property-mortgage-info.component.html',
    styleUrls: ['./property-mortgage-info.component.scss']
})
export class PropertyMortgageInfoComponent
    extends FormElementCoreComponent
    implements OnInit {
    @Output() editFlowErrorEmit: EventEmitter<boolean> =
        new EventEmitter();
    @Output() mortgageCompanyEmit: EventEmitter<MortgageCompanyDetails> =
        new EventEmitter();
    @Input() mortgageInformation: Array<MortgageInformation>;
    @Input() policyInformationDetails: PolicyInformationDetails;
    @Input() policyNumber: string;
    @ViewChild('propertyEdit') propertyEdit;
    companyAddressRequired: boolean;
    companyForm: FormGroup;
    companyMap = new Map<string, MortgageCompany>();
    companyNameRequired: boolean;
    companySearchForm: FormGroup;
    disableSearch = false;
    emptyCompanyResult: boolean;
    minCalDate: Date;
    mortgageComanySelactionRequired = MORTGAGE_EDIT_ERROR.mortgageCompanySelectionrequired;
    mortgageCompanyAddressRequired = MORTGAGE_EDIT_ERROR.mortgageCompanyAddressRequired;
    mortgageInformationErrorMsg = MORTGAGE_EDIT_ERROR.mortgageInformationError;
    mortgageRecordsNotFound = MORTGAGE_EDIT_ERROR.mortgageRecordsNotFound;
    performSearchError = false;
    property = 'property';
    regexMap = new Map([
        ['isValidDate01to28', VALIDATION_REGEX.isValidDate01to28],
        ['isValidDate29to30', VALIDATION_REGEX.isValidDate29to30],
        ['isValidDate31', VALIDATION_REGEX.isValidDate31],
        ['isValidLeapDay', VALIDATION_REGEX.isValidLeapDay]
    ]);
    searchComapnyError: boolean;
    stateRequired: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };

    buildForm(): void {
        this.companyForm = this.fh.build({
            loanNumber: [
                '',
                Validators.compose([
                    Validators.required,
                    this.validatorService.validateLoanFormat(
                        VALIDATION_REGEX.isAlphaNumericWithoutSpaces
                    )
                ])
            ],
            changeEffectiveDate: [
                this.currentCalDateValue(),
                Validators.compose([
                    Validators.required,
                    this.validatorService.validateDate(
                        this.regexMap
                    ),
                    this.validatorService.calculatePastDate(commonConstants.VALIDATION_CONSTANT.MIN_FIVE_DAYS)
                ])
            ],
            selectedCompany: ['', Validators.required]
        });
        this.companySearchForm = this.fh.build({
            state: ['', Validators.compose([Validators.required])],
            companyName: ['', Validators.required]
        });
    }

    clearForm(): void {
        this.submitOptions.submitStatus = false;
        this.companyAddressRequired = false;
        this.stateRequired = false;
        this.companyNameRequired = false;
        this.companyForm.controls.loanNumber.reset();
        this.companyForm.controls.selectedCompany.reset();
        this.companyForm.controls.changeEffectiveDate.setValue(
            this.currentCalDateValue()
        );
        this.companySearchForm.reset();
        this.performSearchError = false;
        this.companyMap.clear();
    }

    // eslint-disable-next-line max-params
    constructor(
        public utilsService: UtilsService,
        private fh: RxFormHelperService,
        private validatorService: ValidatorService,
        private loggerService: LoggerService,
        private mortgageCompaniesAdapterService: MortgageCompaniesAdapterService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    currentCalDateValue(): string {
        return this.utilsService.currentDateValue();
    }

    focusToErrorMessage(): void {
        const focusDelay = environment.errorFocusDelayInMillsec;
        setTimeout(() => {
            this.editFlowErrorEmit.emit(true);
        }, focusDelay);
    }

    minCalDateValue(): void {
        const numberDays = 5;
        this.minCalDate = this.utilsService.minDateValue(numberDays);
    }

    ngOnInit(): void {
        this.emptyCompanyResult = false;
        this.minCalDateValue();
        this.buildForm();
    }

    onResetCompany(): void {
        this.companySearchForm.reset();
        this.emptyCompanyResult = false;
        this.searchComapnyError = false;
        this.stateRequired = false;
        this.companyNameRequired = false;
        this.companyAddressRequired = false;
        this.companyForm.controls.selectedCompany.reset();
        this.searchComapnyError = false;
        this.performSearchError = false;
        this.companyMap.clear();
    }

    onSave(event): void {
        this.searchComapnyError = false;
        this.stateRequired = false;
        this.companyNameRequired = false;
        this.performSearchError = false;
        if (this.companyForm.status === FORM_STATUS.VALID && this.companySearchForm.status === FORM_STATUS.VALID) {
            this.loggerService.info('Save of Edit modal', {
                UserId: sessionStorage.getItem('userName'),
                PolicyNumber: this.policyNumber,
                PolicySystem: 'Policy Center',
                LOB: 'Property'
            });
            this.submitOptions.submitStatus = false;
            const mortgageCompany: MortgageCompanyDetails = {
                loanNumber: this.companyForm.value.loanNumber,
                changeEffectiveDate: this.companyForm.value.changeEffectiveDate,
                mortgageCompanyAddress: this.companyMap.get(this.companyForm.value.selectedCompany)
            };
            this.mortgageCompanyEmit.emit(mortgageCompany);
        } else {
            event.preventDefault();
            if (this.companySearchForm.status === FORM_STATUS.INVALID) {
                this.searchComapnyError = this.companySearchForm.get('companyName').invalid;
                this.stateRequired = this.companySearchForm.get('state').invalid;
                this.companyNameRequired = true;
            } else if (this.companySearchForm.status === FORM_STATUS.VALID && this.companyMap.size === 0) {
                this.performSearchError = true;
            }
            this.submitOptions.submitStatus = true;
            if (this.companyMap?.size > 0) {
                this.companyAddressRequired = this.companyForm.controls.selectedCompany.invalid;
            }
        }
    }

    onSearchCompany(): void {
        this.performSearchError = false;
        if (this.companySearchForm.valid) {
            this.companyNameRequired = false;
            this.stateRequired = false;
            this.searchComapnyError = false;
            this.emptyCompanyResult = false;
            this.companyMap.clear();
            this.emptyCompanyResult = false;
            this.companyForm.controls.selectedCompany.reset();
            this.disableSearch = true;

            this.mortgageCompaniesAdapterService.getCompanyList(this.companySearchForm.get('state').value, this.companySearchForm.get('companyName').value).subscribe(
                (companyList) => {
                    if (companyList.error) {
                        this.emptyCompanyResult = true;
                    } else if (companyList.apiError) {
                        this.propertyEdit.nativeElement.closeModal();
                        this.focusToErrorMessage();
                    } else {
                        this.companyMap = companyList;
                    }
                    this.cd.markForCheck();
                    this.disableSearch = false;
                }
            );
        } else {
            this.companyNameRequired = this.companySearchForm.get('companyName').invalid;
            this.stateRequired = this.companySearchForm.get('state').invalid;
            this.emptyCompanyResult = false;
            this.searchComapnyError = true;
        }
    }

    openEditModal(): void {
        this.loggerService.info('Open the Edit Mortgage Modal', {
            UserId: sessionStorage.getItem('userName'),
            PolicyNumber: this.policyNumber,
            PolicySystem: 'Policy Center',
            LOB: 'Property'
        });
        this.emptyCompanyResult = false;
        this.searchComapnyError = false;
        this.clearForm();
        this.minCalDateValue();
        this.propertyEdit.nativeElement.openModal();
    }

    selectedCompanyhandler(): void {
        this.companyAddressRequired = this.companyForm.controls.selectedCompany.invalid;
    }

    setCompanyNameError(): void {
        this.companyNameRequired = false;
    }

    setStateError(): void {
        this.stateRequired = false;
    }
}
