import { commonConstants } from '../CONSTANTS/tpi-common-constants';
import { StoreSelectedCompanyFields } from '../tpi-models/store/store-selected-company-fields.model';

export function updateSelectedCompanyFieldsReducer(state: StoreSelectedCompanyFields, action): StoreSelectedCompanyFields {
    if (action.type === commonConstants.STORE.MODIFY_SELECTED_COMPANY) {
        return action.payload;
    } else {
        return state;
    }
}
