import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    AdditionalInterest,
    Address,
    Coverage,
    CoveragesRequestsService,
    DriverRequestsService,
    Drivers,
    Person,
    PolicyConsumerData,
    PolicyInformationDetails,
    PolicyRequestsService,
    VehicleRequestsService,
    Vehicles
} from '@nationwide/dgs-internet-servicing-policy-requests';
import { PolicyHolders } from '@nationwide/dgs-internet-servicing-policy-requests/interfaces/policyInformationDetails';
import { DpimRoleInfoResponse } from '@nationwide/internet-servicing-dpim-requests/api-response-types/distribution-partner-information-api';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import {
    CancellationDetailsReason,
    commonConstants,
    retrieveMailingAddress
} from '../../../../CONSTANTS/tpi-common-constants';
import { tpiSearchError } from '../../../../CONSTANTS/tpi-search-errors';
import { DistributionPartnerIndividualInfoService } from '../../../../shared/services/distribution-partner-individual-info/distribution-partner-individual-info.service';
import { SA360Service } from '../../../../shared/services/sa360-service/sa360-service';
import { AutoDriver } from '../../../../tpi-models/auto-driver';
import { CoveredLocationResponse } from '../../../../tpi-models/covered-locations';
import { AwdWorkflowServiceRequest } from '../../../../tpi-models/general/awd-workflow-service-request';
import { CancellationDetailsForTPI } from '../../../../tpi-models/general/cancellationReasons';
import { EditMortgageServiceRequest } from '../../../../tpi-models/general/edit-mortgage-services-request';
import { MailingAddress } from '../../../../tpi-models/general/mailingAddress';
import { Window } from '../../../../tpi-models/general/model-window';
import { MortgageCompanyDetails, MortgageInformation } from '../../../../tpi-models/general/mortgage-company';
import { PolicyInformation } from '../../../../tpi-models/general/policy-information';
import { PropertyPolicyInformation } from '../../../../tpi-models/general/property-details';
import { BillingMappingResponse, PropertyEndorsement } from '../../../../tpi-models/general/property-endorsement';
import { SA360Response } from '../../../../tpi-models/general/sa360Response';
import { SearchFields } from '../../../../tpi-models/general/search-forms';
import { TPISession } from '../../../../tpi-models/general/tpisession';
import { LoggerService } from '../../../../tpi-services/logger/logger.service';
import { AwdWorkflowService } from '../../../../tpi-services/shared-services/awd-workflow.service';
import { EditMortgageService } from '../../../../tpi-services/shared-services/edit-mortgage.service';
import { RequestMapperService } from '../../../../tpi-services/shared-services/helper/request-mapper.service';
import { PolicySearchResultsService } from '../../../../tpi-services/tpi-policy-inquiry/policy-search-results.service';
import { PolicySearchStateServiceService } from '../../../../tpi-services/tpi-statemanagement/policy-search-state-service.service';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { LienHolder } from './../../../../tpi-models/general/lien-holder';
import { EditLienholderService } from './../../../../tpi-services/shared-services/edit-lienholder.service';
import { SearchResultsAdapterService } from './search-results-adapter.service';

@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {
    @ViewChild(ConfirmationModalComponent) confirmation: ConfirmationModalComponent;

    @Output() editFlowError = new EventEmitter<object>();
    @Input() setIsActiveTab: number;
    @Input() showAutoResult: boolean;
    @Output() showAutoResultEmit = new EventEmitter<boolean>();
    @Input() showPropertyResult: boolean;
    @Output() showPropertySearchEmit = new EventEmitter<boolean>();

    autoDriver: AutoDriver = {
        driversFullName: [],
        excludedNonDriver: []
    };
    autoSearchFields: SearchFields = {
        action: {
            displayResults: false,
            policyNumber: '',
            vin: ''
        },
        policyNumber: '',
        vin: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        fullVin: '',
        hasError: false,
        errorMessage: '',
        invalidForm: true,
        isYesSearch: false,
        isNoSearch: false,
        isPropertySearch: false
    };
    awdWorkflowServiceRequest: AwdWorkflowServiceRequest;
    billingDetails: BillingMappingResponse;
    cancellationDetailsForTPI: CancellationDetailsForTPI;
    coverages: Array<Coverage>;
    dpimContactInformation: DpimRoleInfoResponse;
    dpimSA360: SA360Response = { SA360: false };
    drivers: Array<Drivers>;
    isEditFlow = false;
    isPrintResult = true;
    lienHolder: LienHolder;
    mailingAddress: MailingAddress;
    mortgageCompanyDetails: MortgageCompanyDetails;
    mortgageInformation: MortgageInformation;
    person: Person;
    policyInformation: PolicyInformation;
    policyInformationDetails: PolicyInformationDetails;
    policyNumber: string;
    productType: string;
    propertyEndorsement: any;
    propertyLocation: any;
    propertyResults = {
        mortgageDetails: null,
        billingDetails: null
    };
    renewalInformation: any;
    type: string;
    vehicles: Array<Vehicles>;
    vin: string;
    zipCode: string;

    // eslint-disable-next-line max-params
    constructor(
        private driverRequestService: DriverRequestsService,
        private policyRequestService: PolicyRequestsService,
        private vehicleRequestService: VehicleRequestsService,
        private coverageRequestService: CoveragesRequestsService,
        private editLienholderService: EditLienholderService,
        private sa360Service: SA360Service,
        private loggerService: LoggerService,
        private searchResultsAdapter: SearchResultsAdapterService,
        private distributionPartnerIndividualInfoService: DistributionPartnerIndividualInfoService,
        private datePipe: DatePipe,
        private policySearchResultService: PolicySearchResultsService,
        private editMortgageService: EditMortgageService,
        private awdWorkflowService: AwdWorkflowService,
        private requestMapper: RequestMapperService,
        @Inject('window') private window: Window,
        private storeSearchFields: PolicySearchStateServiceService
    ) { }

    /**
     *  trigger edit lien process
     * @param {LienHolder}lienHolder : user entered lien info
     * @public
     * @return {void}
     */
    editLienHolder(lienHolder: LienHolder): void {
        const session: TPISession = JSON.parse(
            sessionStorage.getItem('session')
        );
        this.loggerService.info('TPI-PLDS-V2: Start edit Lien Holder', {
            UserId: session.context.session.account.shortName,
            PolicyNumber: this.policyNumber,
            Vin: this.vin,
            lienHolder
        });
        this.openWaitIndicator();
        this.type = commonConstants.AUTO_FLOW;
        this.editFlowError.emit({
            errorMessage: '',
            hasError: false
        });
        const address = <Address>{};
        const additionalInterest = <AdditionalInterest>{ address };
        this.lienHolder = <LienHolder>{ additionalInterest };
        this.lienHolder = lienHolder;
        this.lienHolder.policyTerm = this.getPolicyTerm();
        this.lienHolder.policyNumber = this.policyNumber;
        this.editLienholderService

            .editLeinHolder(this.policyNumber, lienHolder, this.vehicles[0]).pipe(switchMap((response) => {
                if (response.error) {
                    const lienholderRequest: LienHolder = lienHolder;
                    const vin = this.autoSearchFields.action.vin;
                    const awdWorkflowServiceRequest: AwdWorkflowServiceRequest = this.requestMapper.buildAwdWorkflowAutoServiceRequest(lienholderRequest, vin);
                    this.loggerService.info('Awd Workflow Service Request => ', {
                        lienHolder
                    });
                    return this.awdWorkflowService.postAwdWorkflowService(awdWorkflowServiceRequest);
                } else {
                    return of(response);
                }
            })).subscribe((response) => {
                if (response.error) {
                    this.hideWaitIndicator();
                    this.editFlowError.emit(
                        {
                            errorMessage: tpiSearchError.EDIT_FLOW_ERROR,
                            hasError: true
                        }
                    );
                    this.loggerService.error(
                        'Failure to Update Loan or Lease Modal',
                        {
                            UserId: sessionStorage.getItem('userName'),
                            PolicyNumber: this.lienHolder.policyNumber,
                            PolicySystem: 'Policy Center',
                            LOB: this.type,
                            ErrorMessage: response?.error?.userMessage || this.autoSearchFields.errorMessage,
                            DetailError: response?.error || {}
                        }
                    );
                } else {
                    this.getFetchedData(this.policyNumber, this.vin)
                        .pipe(
                            finalize(() => {
                                this.hideWaitIndicator();
                                this.openConfirmationModal();
                            })
                        )
                        .subscribe();
                }
            });
    }

    editMortgageCompany(mortgageCompany: MortgageCompanyDetails): void {
        this.openWaitIndicator();
        this.type = commonConstants.PROPERTY_FLOW;
        this.mortgageCompanyDetails = mortgageCompany;
        this.mortgageCompanyDetails.policyNumber = this.policyNumber;
        this.mortgageCompanyDetails.zipCode = this.zipCode;
        this.mortgageCompanyDetails.policyTerm = this.getPolicyTerm();
        this.mortgageCompanyDetails.propertyType = this.productType;
        this.loggerService.info('Mortgage Company => ', {
            mortgageCompany
        });
        const editMortgageServiceRequest: EditMortgageServiceRequest = this.requestMapper.buildEditMortgageServiceRequqest(this.mortgageCompanyDetails);
        this.editMortgageService.addOrUpdateMortgageCompany(editMortgageServiceRequest).pipe(switchMap((response) => {
            if (response.error) {
                const awdWorkflowServiceRequest: AwdWorkflowServiceRequest = this.requestMapper.buildAwdWorkflowServiceRequest(editMortgageServiceRequest);
                this.loggerService.info('Awd Workflow Service Request => ', {
                    awdWorkflowServiceRequest
                });
                return this.awdWorkflowService.postAwdWorkflowService(awdWorkflowServiceRequest);
            } else {
                return of(response);
            }
        })).subscribe((value) => {
            if (value?.error) {
                this.hideWaitIndicator();
                const errorMessage = tpiSearchError.EDIT_FLOW_ERROR;
                this.editFlowError.emit({
                    hasError: true,
                    errorMessage
                });
                this.loggerService.error('Failure to update Edit Mortgage Modal', {
                    UserId: sessionStorage.getItem('userName'),
                    PolicyNumber: this.policyInformationDetails.policyNumber,
                    PolicySystem: 'Policy Center',
                    LOB: 'Property',
                    ErrorMessage: value?.error?.userMessage || errorMessage,
                    DetailError: value?.error || {}

                });
            } else {
                this.getFetchedPropertyData(this.autoSearchFields)
                    .pipe(
                        finalize(() => {
                            this.hideWaitIndicator();
                            this.openConfirmationModal();
                        })
                    )
                    .subscribe();
            }
        });
    }

    enablePrintResult(status): void {
        this.isPrintResult = status;
    }

    errorEditFlow(): void {
        this.editFlowError.emit({
            hasError: true,
            errorMessage: tpiSearchError.EDIT_FLOW_ERROR
        });
    }

    getFetchedData(policyNumber: string, vin: string): Observable<boolean> {
        this.resetResultData();
        const euaToken = sessionStorage.getItem('jwt');
        const consumerData: PolicyConsumerData = {
            accessToken: euaToken,
            camSessionId: '',
            policyNumber
        };
        const initApiCalls = [
            this.policyRequestService
                .retrievePolicyInformation(policyNumber, consumerData)
                .pipe(
                    switchMap((policyInformationResponse: PolicyInformationDetails) => {
                        if (
                            !(
                                policyInformationResponse instanceof
                                HttpErrorResponse
                            )
                        ) {
                            this.loggerService.info(
                                'Policy Information API response - retrievePolicyInformation()',
                                {
                                    Policy: policyNumber,
                                    Vin: vin,
                                    policyInformation: policyInformationResponse
                                }
                            );
                            this.policyInformationDetails =
                                policyInformationResponse;
                            if (
                                this.policyInformationDetails
                                    .cancellationDetails == null
                            ) {
                                this.policyInformation = {
                                    policyStatus: 'Active',
                                    policyType: 'Auto'
                                };
                            } else {
                                this.cancellationDetailsForTPI =
                                    this.policyInformationDetails.cancellationDetails;
                                this.cancellationDetailsForTPI.reason =
                                    CancellationDetailsReason[
                                    this.policyInformationDetails.cancellationDetails?.reason
                                    ];
                                this.policyInformation = {
                                    policyStatus: 'Cancelled',
                                    policyType: 'Auto'
                                };
                            }

                            this.mailingAddress =
                                retrieveMailingAddress[
                                this.policyInformationDetails.policyAddress.state
                                ];
                            this.loggerService.info('Mailing address Details', {
                                policyNumber,
                                vin,
                                mailingAddress: this.mailingAddress
                            });
                            this.person =
                                this.policyInformationDetails.policyHolders.filter(
                                    (policyHolderType: PolicyHolders) =>
                                        policyHolderType.policyHolderType ===
                                        'PolicyPriNamedInsured'
                                )[0].person;
                        }

                        return of(policyInformationResponse);
                    }),
                    catchError((error) => {
                        this.loggerService.error(
                            'Error In  Retrieve Policy Information API - retrievePolicyInformation()',
                            {
                                policyNumber,
                                error
                            }
                        );
                        return of(error);
                    })
                ),
            this.driverRequestService.retrieveAllDrivers(consumerData).pipe(
                switchMap((driverInformationResponse: Array<Drivers>) => {
                    if (
                        !(
                            driverInformationResponse instanceof
                            HttpErrorResponse
                        )
                    ) {
                        this.loggerService.info(
                            'Driver API response - retrieveAllDrivers()',
                            {
                                Policy: policyNumber,
                                Vin: vin,
                                driverInformation: driverInformationResponse
                            }
                        );
                        this.drivers = driverInformationResponse.filter(
                            (driver) =>
                                [
                                    'Driver',
                                    'PrincipalDriver',
                                    'Excluded',
                                    'NonDriver',
                                    'OccasionalDriver'
                                ].includes(driver.driverType.toString())
                        );
                        for (const driver of this.drivers) {
                            const firstName = driver.person.firstName;
                            const middleName =
                                driver.person.middleName?.length > 0 ?
                                    ` ${driver.person.middleName.slice(
                                        0,
                                        1
                                    )}` :
                                    '';
                            const lastName = driver.person.lastName;
                            const suffix = driver.person.suffix || '';
                            const fullName = `${firstName} ${middleName} ${lastName} ${suffix}`;
                            if (
                                driver.driverType === 'Excluded' ||
                                driver.driverType === 'NonDriver'
                            ) {
                                this.autoDriver.excludedNonDriver.push(
                                    fullName
                                );
                            } else {
                                this.autoDriver.driversFullName.push(fullName);
                            }
                        }
                        return driverInformationResponse;
                    }
                }),
                catchError((error) => {
                    this.loggerService.error(
                        'Error In Retrieve Driver Information API - retrieveAllDrivers() ',
                        {
                            policyNumber,
                            error
                        }
                    );
                    return of(error);
                })
            ),
            this.vehicleRequestService.retrieveAllVehicles(consumerData).pipe(
                switchMap((vehicleInformationResponse: Array<Vehicles>) => {
                    if (
                        !(
                            vehicleInformationResponse instanceof
                            HttpErrorResponse
                        )
                    ) {
                        this.loggerService.info(
                            'Vehicle API response - retrieveAllVehicles()',
                            {
                                Policy: policyNumber,
                                Vin: vin,
                                vehicleInformation: vehicleInformationResponse
                            }
                        );
                        this.vehicles = vehicleInformationResponse.filter(
                            (vehicle) => vehicle.vin === vin
                        );
                        return this.coverageRequestService
                            .retrieveCoverages(consumerData)
                            .pipe(
                                switchMap(
                                    (coverageInformationResponse: any) => {
                                        if (
                                            !(
                                                coverageInformationResponse instanceof
                                                HttpErrorResponse
                                            )
                                        ) {
                                            this.loggerService.info(
                                                'Coverage API response - retrieveCoverages()',
                                                {
                                                    Policy: policyNumber,
                                                    Vin: vin,
                                                    coverageInformation:
                                                        coverageInformationResponse
                                                }
                                            );

                                            this.coverages =
                                                coverageInformationResponse.coverages.filter(
                                                    (coverage) =>
                                                        coverage.available ===
                                                        true &&
                                                        this.searchResultsAdapter.isPolicyLevelCoverageAndSelected(
                                                            coverage,
                                                            this.vehicles[0]
                                                                .vehicleId
                                                        )
                                                );
                                        }
                                        return of(coverageInformationResponse);
                                    }
                                ),
                                catchError((error) => {
                                    this.loggerService.error(
                                        'Error In Retrieve Coverage Information API - retrieveCoverages()',
                                        {
                                            policyNumber,
                                            error
                                        }
                                    );
                                    return of(error);
                                })
                            );
                    }
                    return of(vehicleInformationResponse);
                }),
                catchError((error) => {
                    this.loggerService.error(
                        'Error In Retrieve Vehicle Information API - retrieveAllVehicles()',
                        {
                            policyNumber,
                            error
                        }
                    );
                    return of(error);
                })
            ),
            this.distributionPartnerIndividualInfoService
                .fetchDistributionPartnerIndividualInfo(policyNumber, euaToken)
                .pipe(
                    switchMap((agentResponse: DpimRoleInfoResponse) => {
                        if (!(agentResponse instanceof HttpErrorResponse)) {
                            this.loggerService.info(
                                'Agent API response - fetchDistributionPartnerIndividualInfo()',
                                {
                                    agentInformation: agentResponse
                                }
                            );

                            this.dpimContactInformation = <DpimRoleInfoResponse>{};
                            this.dpimContactInformation = agentResponse;
                        }
                        return of(agentResponse);
                    }),
                    catchError((error) => {
                        this.loggerService.error(
                            'Error In Retrieve agent Information API - fetchDistributionPartnerIndividualInfo()',
                            {
                                policyNumber,
                                error
                            }
                        );
                        return of(error);
                    })
                ),
            this.getSA360Results(policyNumber, euaToken)
        ];

        return forkJoin(initApiCalls).pipe(
            switchMap(() => {
                this.showAutoResultEmit.emit(true);
                this.policyNumber = policyNumber;
                this.vin = vin;
                return of(true);
            })
        );
    }

    getFetchedPropertyData(searchFields: SearchFields): Observable<boolean> {
        this.resetResultPropertyData();
        const policyNumber = searchFields.action.policyNumber;
        const zipCode = searchFields.zipCode;
        const productType = searchFields.propertyType;
        const ecn = searchFields.ecn;
        const euaToken = sessionStorage.getItem('jwt');
        const consumerData: PolicyConsumerData = {
            accessToken: euaToken,
            camSessionId: '',
            policyNumber
        };
        const initApiCalls = [
            this.policySearchResultService.getPropertySearchResults(
                { policyNumber, zipCode, productType, ecn },
                consumerData,
                euaToken
            ).pipe(
                switchMap((response) => {
                    this.setPropertyResultsTemplate(response, policyNumber);
                    return of(true);
                })
            ),
            this.getSA360Results(policyNumber, euaToken)
        ];
        return forkJoin(initApiCalls).pipe(
            switchMap(() => {
                this.showPropertySearchEmit.emit(true);
                this.policyNumber = policyNumber;
                this.window.waitIndicatorFullscreen.hide();
                return of(true);
            })
        );
    }

    getPolicyTerm(): string {
        const startDate = this.datePipe.transform(
            this.policyInformationDetails.policyStartDate,
            'MM/dd/yyyy'
        );
        const endDate = this.datePipe.transform(
            this.policyInformationDetails.policyEndDate,
            'MM/dd/yyyy'
        );
        return startDate.concat(' - ', endDate);
    }

    getSA360Results(policyNumber: string, euaToken: string): Observable<SA360Response> {
        return this.sa360Service
            .fetchSA360Info(policyNumber, euaToken)
            .pipe(
                switchMap((sa360Response: any) => {
                    if (!(sa360Response instanceof HttpErrorResponse)) {
                        this.loggerService.info(
                            'SA 360 API response - fetchSA360Info()',
                            {
                                SA360: sa360Response
                            }
                        );
                        this.dpimSA360 = sa360Response;
                    }
                    return of(sa360Response);
                }),
                catchError((error) => {
                    this.loggerService.error(
                        'Error In Retrieve SA 360 API - fetchSA360Info()',
                        {
                            policyNumber,
                            error
                        }
                    );
                    return of(error);
                })
            );
    }

    hideWaitIndicator(): void {
        this.window.waitIndicatorFullscreen.hide();
    }

    ngOnInit(): void {
        this.storeSearchFields.getSearchFields().subscribe((searchFields) => {
            this.autoSearchFields = searchFields;
        });
    }

    openConfirmationModal(): void {
        this.confirmation.openModal();
    }

    openWaitIndicator(): void {
        this.window.waitIndicatorFullscreen.show();
    }

    resetResultData(): void {
        this.autoDriver = {
            driversFullName: [],
            excludedNonDriver: []
        };
        this.coverages = [];
        this.drivers = [];
        this.person = undefined;
        this.vehicles = [];
        this.showAutoResultEmit.emit(false);
    }

    resetResultPropertyData(): void {
        this.showPropertySearchEmit.emit(false);
    }

    setBillingDetails(response: any): void {
        this.billingDetails = response;
    }

    setDistributionPartner(response: DpimRoleInfoResponse): void {
        this.dpimContactInformation = response;
    }

    setPropertyEndorsement(response: PropertyEndorsement): void {
        this.propertyEndorsement = response;
    }

    setPropertyLocation(response: CoveredLocationResponse): void {
        this.propertyLocation = response?.location;
    }

    setPropertyMortgages(response: MortgageInformation): void {
        this.mortgageInformation = response;
    }

    setPropertyPolicyHolder(response: Person): void {
        this.person = response || null;
    }

    setPropertyPolicyInformation(response: PropertyPolicyInformation): void {
        this.policyInformationDetails =
            response.policyInformationDetails || null;
        this.cancellationDetailsForTPI =
            response.cancellationDetailsForTPI || null;
        this.mailingAddress = response.mailingAddress || null;
        this.policyInformation = response.policyInformation || null;
    }

    setPropertyResultsTemplate(response: any, policyNumber: string): void {
        const POLICY_INFORMATION_RESPONSE = 0;
        const POLICY_HOLDERS_RESPONSE = 1;
        const RETRIEVE_MORTGAGES_RESPONSE = 2;
        const RETRIEVE_COVERAGES_RESPONSE = 3;
        const FETCH_DISTRIBUTION_PARTNER_RESPONSE = 4;
        const COVERED_LOCATION = 5;
        const BILLING_DETAILS = 6;
        const RENEWAL_DETAILS = 7;

        this.setPropertyPolicyInformation(response[POLICY_INFORMATION_RESPONSE]);
        this.setPropertyPolicyHolder(response[POLICY_HOLDERS_RESPONSE]);
        this.setPropertyMortgages(response[RETRIEVE_MORTGAGES_RESPONSE]);
        this.setPropertyEndorsement(response[RETRIEVE_COVERAGES_RESPONSE]);
        this.setDistributionPartner(response[FETCH_DISTRIBUTION_PARTNER_RESPONSE]);
        this.setPropertyLocation(response[COVERED_LOCATION]);
        this.setBillingDetails(response[BILLING_DETAILS]);
        this.setRenewalInformation(response[RENEWAL_DETAILS]);
        this.showPropertySearchEmit.emit(true);
        this.policyNumber = policyNumber;
        this.window.waitIndicatorFullscreen.hide();
    }

    setRenewalInformation(response: PropertyEndorsement): void {
        this.renewalInformation = response;
    }

    showAutoResults(searchFields: SearchFields): void {
        const policyNumber = searchFields.action.policyNumber;
        const vin = searchFields.action.vin;
        this.getFetchedData(policyNumber, vin).subscribe();
    }

    showPropertyResults(searchFields: SearchFields): void {
        this.getFetchedPropertyData(searchFields).subscribe();
    }
}
