import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewChecked {
    @ViewChild('teconsent') teconsent;

    nonSA360Contact = environment.nonSA360Contact;

    ngAfterViewChecked(): void {
        // for ADA compliance
        if (this?.teconsent?.nativeElement && this.teconsent.nativeElement.getAttribute('role') !== 'none') {
            this.teconsent.nativeElement.setAttribute('role', 'none');
        }
    }
}
