import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementCoreComponent } from '../../../shared/components/form-element-core/form-element-core.component';
import { SubmitOptions } from '../../../tpi-models/general/form-models';
import { SearchFields } from '../../../tpi-models/general/search-forms';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';

@Component({
    selector: 'app-auto-no-search',
    templateUrl: './auto-no-search.component.html'
})
export class AutoNoSearchComponent extends FormElementCoreComponent {
    @Output() autoNoSearchEmit: EventEmitter<SearchFields> = new EventEmitter();
    @Input() parentForm: UntypedFormGroup;
    @Input() submitOptions: SubmitOptions;
    autoSearchFields: SearchFields = {
        policyNumber: '',
        vin: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        fullVin: '',
        hasError: false,
        errorMessage: '',
        invalidForm: true,
        isYesSearch: false,
        isNoSearch: true,
        action: {
            displayResults: false,
            vin: '',
            policyNumber: ''
        },
        isPropertySearch: false
    };

    constructor(public utilsService: UtilsService) {
        super();
    }
}
