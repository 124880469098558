import { Component } from '@angular/core';
import * as constants from '../../../CONSTANTS/tpi-error-tags';

export interface ErrorOptions {
    onSubmit: boolean;
    submitStatus: boolean;
    label: string;
    [x: string]: string | boolean;
}

@Component({
    selector: 'tpi-form-element-core',
    template: ''
})
export class FormElementCoreComponent {
    appendOption(options, newOption: { [x: string]: string }): ErrorOptions {
        return { ...options, ...newOption };
    }

    checkForError(formControlName: string, formGroup: any, options: ErrorOptions): string {
        return this.checkFormElementAccessToSet({
            formControlName,
            formGroup,
            options
        });
    }

    checkForTextTransform(label: string): string {
        const exceptionText = constants.exceptionLowerCaseErrors;
        let labelFound = false;
        for (const value of exceptionText) {
            if (label.includes(value)) {
                labelFound = true;
                label = label.toLowerCase();
                for (const exceptionTextValue of exceptionText) {
                    if (label.includes(exceptionTextValue.toLowerCase())) {
                        label = label.replace(exceptionTextValue.toLowerCase(), exceptionTextValue);
                    }
                }
            }
        }
        return labelFound ? label : label.toLowerCase();
    }

    checkFormElementAccessToSet(formAttributes: {
        formControlName: string; formGroup: any; options: ErrorOptions;
    }): string {
        if (formAttributes) {
            if (!formAttributes.formGroup) {
                throw new Error('Set form name as form group');
            }
            return this.formError(formAttributes.formControlName, formAttributes.formGroup, formAttributes.options);
        }
        throw new Error('Should pass attributes as {formControlName: string; formGroup: any; options: ErrorOptions;}');
    }

    formError(formControlName: string, formGroup: any, options: ErrorOptions): string {
        if (formControlName) {
            if (formGroup.get(formControlName).invalid) {
                if (options.onSubmit) {
                    if (options.submitStatus) {
                        return this.mapError(formGroup.get(formControlName), options.label);
                    }
                } else if (formGroup.get(formControlName).touched) {
                    return this.mapError(formGroup.get(formControlName), options.label);
                }
            } else {
                return '';
            }
        }
        return '';
    }

    getErrorText(keyValue, label): { flagAlreadySet: boolean; errorText: string } {
        const errorDictionary = constants.ERROR_MAP_COPYTEXTS;
        let errorText = '';
        let flagAlreadySet = false;
        const defaultFieldText = constants.fieldConstants;
        const checkIndexForField = errorDictionary[keyValue].indexOf('@@fieldname@@') === 0;
        const thisFieldText = checkIndexForField ? defaultFieldText.thisField : defaultFieldText.thisField.toLowerCase();
        const labelTransform = checkIndexForField ? label : this.checkForTextTransform(label);
        errorText = errorDictionary[keyValue].replace('@@fieldname@@',
            `${label ? labelTransform : thisFieldText}`);
        flagAlreadySet = true;
        return {
            flagAlreadySet, errorText
        };
    }

    mapError(formControl: any, label: string): string {
        let errorText = '';
        const errorDictionary = constants.ERROR_MAP_COPYTEXTS;
        const keyValues = Object.keys(formControl.errors);
        let flagAlreadySet = false;
        const errorFieldsWithoutLabel = constants.fieldsForDirectErrorMessages;
        for (const keyValue of keyValues) {
            let errorObject = {
                errorText: '',
                flagAlreadySet: false
            };
            if (!flagAlreadySet) {
                if (errorFieldsWithoutLabel.includes(keyValue)) {
                    errorText = `${errorText}${errorDictionary[keyValue]}`;
                    flagAlreadySet = true;
                } else {
                    errorObject = this.getErrorText(keyValue, label);
                    errorText = errorObject.errorText;
                    flagAlreadySet = errorObject.flagAlreadySet;
                }
            }
        }
        return errorText;
    }
}
