import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-search-error-panel',
    templateUrl: './search-error-panel.component.html'
})
export class SearchErrorPanelComponent implements OnInit {
    @Input()
    errorMessage: string;

    @Input()
    hasError: boolean;

    ngOnInit(): void {
        this.hasError = false;
    }
}
