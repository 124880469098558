import { Component, Input } from '@angular/core';

@Component({
    selector: 'tpi-generic-prompt',
    templateUrl: './generic-prompt.component.html',
    styleUrls: ['./generic-prompt.component.scss']
})
export class GenericPromptComponent {
    @Input() boltIconName: string;
    @Input() title: string;
}
